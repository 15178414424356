import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  UilArrowLeft,
} from '@iconscout/react-unicons';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Doubts } from '../doubts/doubts';
import { Loader } from '../general/spinner';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const branches = [
  {
    id: 4,
    baseId: 5,
    name: 'Palmas Plaza Drive Thru',
    address:
      'Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
    mapsId: '',
    coordinates: ['19.0267687', '-98.2311346'],
  },
  {
    id: 8,
    baseId: 14,
    name: 'Labopat Palmas Plaza planta baja',
    address:
      'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
    mapsId: '',
    coordinates: ['19.0267687', '-98.2311346'],
  },
  {
    id: 1,
    baseId: 9,
    name: 'Plaza Zavaleta',
    address:
      'Cerca de Olivos 113, Santa Cruz Buenavista Norte, Sta. Cruz Buena Vista, 72150 Puebla, Pue. “Puerta Olivos” (puerta trasera de PlaZavaleta)',
    mapURL: 'https://goo.gl/maps/FjxjiZvngk6dyZxv9',
    mapsId: 'ChIJETt5bL_Az4URV1vbyxVEMfU',
    coordinates: ['19.067373', '-98.248656'],
  },
  {
    id: 3,
    baseId: 13,
    name: 'Torres médicas',
    address:
      'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
    mapURL: 'https://goo.gl/maps/GKRKLQSnVXQPgt8o7',
    mapsId: '',
    coordinates: ['19.02051237624928', '-98.26456209191247'],
  },
  {
    id: 7,
    baseId: 21,
    name: 'Pabellón R&M',
    address:
      'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
    mapURL: 'https://goo.gl/maps/nfcNL45qi89A5Lhw7',
    mapsId: '',
    coordinates: ['19.0522198', '-98.2362645'],
  },
  {
    id: 2,
    baseId: 11,
    name: 'Elementa',
    address:
      'Torre Elementa. Blvd. América 308, local 3; Lomas de Angelópolis, 72228 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/wPfV3oCtYEcmmgxVA',
    mapsId: '',
    coordinates: ['18.9976678', '-98.2818113'],
  },
  {
    id: 6,
    baseId: 20,
    name: 'Bosques de Zavaleta',
    address:
      'Plaza Bosques De Zavaleta, Calz Zavaleta 133-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
    mapURL: 'https://goo.gl/maps/S7DapoFhy9F8zgNM9',
    mapsId: '',
    coordinates: ['19.066824', '-98.2503983'],
  },
];

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
`;

const Title = styled.h1`
 font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 56px;
  margin: 0;
  color: #77CCD5;
  margin-bottom: 24px;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 115%;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #77CCD5;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const RegularP = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #474A4B;
  margin-bottom: 24px;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

const BranchItem = styled(Col)`
  margin-top: 20px;
  > h1 {
    display: inline;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #00ad90;
  }
  > p {
    font-weight: 500;
    font-size: 15px;
    color: #717171;
    margin-bottom: 2px;
  }
  > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #c7c7c7;
    margin-top: 8px;
    margin-bottom: 24px;
    text-decoration: underline;
  }
`;

const BlueButton = styled.button`
  width: 100%;
  max-width: 208px;
  height: 40px;
  background: #77CCD5;
  border-radius: 102px;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  padding: 0;
  font-weight: 800;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #77CCD5;
    border: none;
  }
  @media only screen and (max-width: 576px) {
    max-width: 146px;
  }
`;

const WhiteButton = styled.button`
  width: 100%;
  max-width: 208px;
  height: 40px;
  background: none;
  border: 2px solid #77CCD5;
  border-radius: 102px;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #77CCD5;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
  }
  @media only screen and (max-width: 1200px) {
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 146px;
  }
`;

const Prices = styled(Row)`
  background: linear-gradient(180deg, #85b4d2 -35.38%, #8acca4 166.15%);
  border-radius: 5px;
  width: 100%;
  color: #fff;
  letter-spacing: -0.03em;
  margin: 8px auto 18px auto;
  padding-top: 9px;
  p {
    margin-bottom: 7px;
  }
  #linea {
    margin-top: 2px;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
  }
  #sucursal {
    margin-top: 2px;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
  }
  #price-online {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    margin-left: -20px;
  }
  #price-sucursal {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: right;
  }
`;

const BackBtn = styled(Button)`
  border: none;
  background: none;
  padding: 0;
  :hover{
    background: none;
  }
`;

const Category = styled(Row)`
  padding-bottom: 25px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    img {
      width: 75px;
      @media only screen and (max-width: 600px) {
        width: 62px;
      }
    }
    p {
      color: #00ad90;
      display: inline;
      margin-bottom: 0px;
      text-align: center;
    }
    #cat {
      font-weight: 900;
      font-size: 38px;
      line-height: 115%;
      @media only screen and (max-width: 768px) {
        font-size: 22px;
      }
    }
    #sign {
      font-size: 30px;
    }
    #name {
      font-weight: 800;
      font-size: 20px;
      line-height: 130%;
      text-align: left;
      @media only screen and (max-width: 768px) {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }
`;

const DescCol = styled(Col)`
  @media only screen and (max-width: 992px) {
    margin-top: 30px;
  }
`;

const MainContainer = styled(Container)`
  padding-bottom: 110px;
  transition: all 300ms;
  @media only screen and (max-width: 1400px) {
    padding-bottom: 114px;
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 166px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 188px;
  }
  @media only screen and (max-width: 383px) {
    padding-bottom: 255px;
  }
`;

const Price = styled.p`
  font-family: 'Assitant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #77CCD5;
  margin: 0;
`;

const ExtraInfo = styled.div`
  background: #F2F7F9;
  border-radius: 16px;
  padding: 16px;
`;

const ExtraTitle = styled.h3`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  margin: 0;
  color: #000000;
  margin-bottom: 8px;
`;

const ExtraText = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #6A6A6A;
  margin: 0;
`;

const ExtraRow = styled(Row)`
  margin-bottom: 24px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const FloatingTest = styled.div`
  position: ${props => props.limit === 1 ? 'absolute' : 'fixed'};
  bottom: 20px;
  left: 0;
  width: 100%;
`;

const FloatingTestContainer = styled(Container)`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #E9E9E9;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`;

const FloatingRow = styled(Row)`
  padding: 24px 40px;
  @media only screen and (max-width: 768px) {
    padding: 16px 24px;
  }
`;

const FloatingTitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin: 0;
  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;

const StyledA = styled.a`
  position:absolute;
	width:60px;
	height:60px;
	top: -40px;
	right:0px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
  :hover {
    color: #fff;
  }
`;

export const StudyDetail = () => {
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogged = useSelector((state) => state.auth.uid);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(false);
  const { studyId } = useParams();
  const [bottomPadding, setBottomPadding] = useState(0);
  const [study, setStudy] = useState({
    name: '',
    description: '',
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get('https://lpqsystem.com/get-study/' + studyId);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    dispatch(handleAddItemToCart(study));
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(study));
      history.push('/checkout');
    }
  };

  useEffect(() => {
    getInfo();
  }, [studyId]);

  const footerObserver = new IntersectionObserver((entries) => {
    if(entries[0].intersectionRatio <= 0) {
      setLimit(false);
    } else {
      setLimit(true);
    }
  });

  useEffect(() => {
    footerObserver.observe(document.getElementById('footer'));
  },[]);

  return (
    <>
    <Loader show={isLoading} />
      {study.detalleCategoria && (
        <div style={{ background: '#ffffff' }}>
          <Container>
            <Top>
              <BackBtn variant='light' onClick={() => window.history.back()}>
                <UilArrowLeft color='#77CCD5' size='26' className='me-2' />
                Regresar
              </BackBtn>
              {/* {study.tag ? (
                <Tag
                  style={{
                    backgroundColor: '#FFE769',
                    color: '#000000',
                    marginTop: '6px',
                  }}
                >
                  Etiqueta
                  {study.tag.content}
                </Tag>
              ) : null} */}
            </Top>
          </Container>
        </div>
      )}
      <div
        style={{
          background:
            '#ffffff',
          position: 'relative',
        }}
      >
        <MainContainer>
          <Row className='justify-content-between' id='studyDetails'>
            <Col xs={12} sm={12} md={7} lg={7} xl={6} className='p-0'>
              <Row>
                <Title>{study.name}</Title>
              </Row>
              <Row className='align-items-end' style={{marginBottom: '24px'}}>
                <RegularP className='w-auto mb-0 pe-0'>
                  Precio:
                </RegularP>
                <Price className='w-auto ps-2'>
                  {currency(study.normal_price)}
                </Price>
              </Row>
              <Row>
                <Subtitle style={{marginBottom: '8px'}}>
                  Descripción
                </Subtitle>
              </Row>
              <Row>
                <RegularP
                  dangerouslySetInnerHTML={{ __html: study.description }}
                ></RegularP>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} className='p-0'>
              <ExtraInfo>
              {study.tEntrega && (
                <ExtraRow>
                  <ExtraTitle>
                    Tiempo de entrega:
                  </ExtraTitle>
                  <ExtraText 
                    dangerouslySetInnerHTML={{ __html: study.tEntrega }}>
                  </ExtraText>
                </ExtraRow>
              )}
              {study.muestra && (
                <ExtraRow>
                  <ExtraTitle>
                    Tipo de muestra
                  </ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.muestra }}
                  >
                  </ExtraText>
                </ExtraRow>
              )}
              {study.indicaciones && (
                <ExtraRow>
                  <ExtraTitle>
                    Indicaciones
                  </ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                  >
                  </ExtraText>
                </ExtraRow>
              )}
              </ExtraInfo>
            </Col>
          </Row>
          <Doubts />
          <FloatingTest limit={limit ? 1 : 0} id='floatingActions'>
            <FloatingTestContainer>
              <StyledA href="https://wa.me/525615077271" target="_blank" rel='noreferrer'>
                <i class="fa fa-whatsapp my-float" />
              </StyledA>
              <FloatingRow className='align-items-center'>
                <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={7} className='ps-0'>
                  <FloatingTitle>{study.name}</FloatingTitle>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={6} xxl={5} className='pe-0'>
                  <Row className='p-0 justify-content-around'>
                    <WhiteButton onClick={() => addStudy()}>
                      Agregar al carrito
                    </WhiteButton>
                    <BlueButton onClick={() => payStudy()}>
                      Pagar ahora
                    </BlueButton>
                  </Row>
                </Col>
              </FloatingRow>
            </FloatingTestContainer>
          </FloatingTest>
        </MainContainer>
      </div>
    </>
  );
};
