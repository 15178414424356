import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Button } from "react-bootstrap";
import { UilCheck, UilDownloadAlt } from "@iconscout/react-unicons";
import { useSelector, useDispatch } from "react-redux";
import { resetCart } from "../../actions/shoppingCart";
import { useEffect, useState } from "react";
import Hiwbg from "../../assets/checkout/hiwbg.svg";
import printJS from "print-js";
import axios from "axios";

const VG70Box =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG70Box.png?alt=media&token=3b33240a-6380-4e85-9fdb-e369a995079d";
const VG45Box =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG45Box.png?alt=media&token=d268cba9-b484-4452-a3fd-0679194c2655";
const CeliaquiaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCeliaquiaBox.png?alt=media&token=8e9a036b-aa19-4f61-82e4-db1746d3e4b2";
const LactosaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FLactosaBox.png?alt=media&token=6fef9634-78cb-4fe4-9962-b23c66fddf7e";
const CafeinaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCafeinaBoxFull.png?alt=media&token=2874b510-71fa-49dc-8edf-4b5be251895e";

const StyledForDesktop = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ffffff;
`;

const Title = styled.h1`
  text-transform: capitalize;
  text-transform: capitalize;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  margin: 0;
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  > span {
    font-weight: 800;
  }
`;

const Done = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 86px;
  height: 86px;
  background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  > svg {
    color: #fff;
    width: 36px;
  }
`;

const StyledHr = styled.hr`
  margin: 0;
  height: 0.5px;
  background-color: #c1c8d0;
`;

const StudyTitle = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 375;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  width: auto;
  margin: 0;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const StudyDetail = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 19px;
  color: #515151cc;
  margin: 0;
  width: auto;
  padding: 0;
`;

const BoxImg = styled.img`
  max-width: 282px;
  @media only screen and (max-width: 768px) {
    max-width: 202px;
  }
`;

export const ThanksScreenCom = (props) => {
  const { currentCheckin } = useSelector((state) => state);
  const studyContent = {
    vg70: [
      {
        detail:
          "Versión más completa de nuestras pruebas genéticas, incluye todos los genes de la prueba Vg45 y más",
      },
      {
        detail:
          "Incluye apartado de genetic insights (lo último en investigación)",
      },
      {
        detail:
          "Logra personalizar tu alimentación y realiza cambios en tu estilo de vida",
      },
    ],
    vg45: [
      {
        detail: "Personaliza tu alimentación (básica)",
      },
      {
        detail:
          "¿Qué vitaminas y minerales necesitas vigilar en tu alimentación?",
      },
      {
        detail: "¿Qué alimentos implican un riesgo para tu corazón?",
      },
      {
        detail:
          "¿Ejercicio de potencia o resistencia? ¿A cuál tienes una mejor respuesta?",
      },
    ],
    celiaquia: [
      {
        detail: "Análisis de variantes HLA- DQ 2.5 y 8",
      },
      {
        detail:
          "Determinación del riesgo genético a desarrollar intolerancia al gluten",
      },
      {
        detail: "Entrega de resultados de 3-5 días hábiles*",
      },
      {
        delivery: "* Una vez que la muestra esté en nuestro laboratorio",
      },
    ],
    lactosa: [
      {
        detail:
          "Análisis del gen MCM6, regulador de la producción de la lactasa",
      },
      {
        detail:
          "Las personas que poseen la variante de riesgo CC o CT tienen una capacidad reducida de procesar la lactosa, creando una intolerancia",
      },
      {
        detail: "Entrega de resultados de 3-5 días hábiles*",
      },
      {
        delivery: "* Una vez que la muestra esté en nuestro laboratorio ",
      },
    ],
    cafeina: [
      {
        detail: "Análisis del gen ADORA2 y CYP1A2.",
      },
      {
        detail:
          "Las personas que presentan el polimorfismo AC o CT respectivamente tienen un metabolismo menor de la cafeína y por ende pueden presentar ansiedad o enfermedades cardiovasculares.",
      },
      {
        detail: "Entrega de resultados de 3-5 días hábiles*",
      },
      {
        delivery: "* Una vez que la muestra esté en nuestro laboratorio ",
      },
    ],
  };
  const gender = "M"; // TODO: Add field to state
  const dispatch = useDispatch();
  const isDoctor = props.isDoctor;
  const cardPayment = props.pay === "web";
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user?.data?.data);
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const openLink = () => {
    window.open(
      "https://clienteslabopat.com/comprobante-ecommerce?id=" +
        props.confirm.data.id
    );
  };

  const openLinkB = async () => {
    setLoading(true);
    // window.open('https://lpqsystem.com/getReceta?id=' + props.confirm.data.id);

    const orderBase64 = await axios.get(
      "https://lpqsystem.com/obtenerReceta?id=" + props.confirm.data.id
    );
    console.log(
      "🚀 ~ file: thanksScreen.jsx:336 ~ openLinkB ~ orderBase64",
      orderBase64
    );
    if (orderBase64?.data?.ok && !mobile) {
      printJS({ printable: orderBase64?.data?.pdf, type: "pdf", base64: true });
      setLoading(false);
    } else {
      window.open(
        "https://lpqsystem.com/getReceta?id=" + props.confirm.data.id
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetCart());
    localStorage.removeItem("cartId");
    localStorage.setItem("cartId", "");
    // console.log(localStorage);
    console.log("thanks");
    // console.log(props);
    window.scroll({
      top: 0,
    });
  }, []);

  var mobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        mobile.Android() ||
        mobile.BlackBerry() ||
        mobile.iOS() ||
        mobile.Opera() ||
        mobile.Windows()
      );
    },
  };

  console.log(currentCheckin);
  return (
    <StyledForDesktop className="pt-5">
      <Container>
        <Col xs={12}>
          <Row className="mb-4">
            <Done>
              <UilCheck size="36" />
            </Done>
          </Row>
          <Row className="mb-3">
            <Title>
              Gracias{isLoggedIn ? "," : "."} {user?.name}
            </Title>
          </Row>
          <Row className="mb-4">
            {props.pay === "orderToPatient" ? (
              props.firstTimeDoctor ? (
                <RegularP>
                  ¡La orden de estudios para {props.personalData?.name?.app} se
                  completó exitosamente!
                </RegularP>
              ) : (
                <RegularP>
                  ¡La orden de estudios para {props.personalData.name}{" "}
                  {props.personalData.app} se completó exitosamente!
                </RegularP>
              )
            ) : (
              <RegularP>¡Tu pago se realizó con éxito!</RegularP>
            )}
          </Row>

          <Row className="mb-5 justify-content-center">
            <RegularP style={{ maxWidth: "840px" }}>
              Te hemos envíado un correo a{" "}
              <span style={{ fontWeight: "600" }}>
                {props.confirm?.data?.sol?.emailContacto}
              </span>{" "}
              confirmando tu pago.
            </RegularP>
          </Row>
          <Row className="mb-4">
            <div>
              <StyledHr />
            </div>
          </Row>
          {/* Studies List */}
          {props.items?.map((item, index) => (
            <Row className="mb-5 pt-2" key={index}>
              <Col
                xs={12}
                sm={12}
                md={5}
                lg={4}
                xl={4}
                xxl={3}
                className="d-flex flex-column align-items-center p-0 mb-4"
              >
                <BoxImg
                  src={
                    item.id === 598
                      ? VG70Box
                      : item.id === 519
                      ? VG45Box
                      : item.id === 166
                      ? CeliaquiaBox
                      : item.id === 974
                      ? CafeinaBox
                      : item.id === 590 && LactosaBox
                  }
                  alt="Imagen prueba"
                  className="img-fluid"
                />
              </Col>
              <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={9} className="p-0">
                <Row className="mb-4">
                  <StudyTitle
                    color={
                      item.id === 166
                        ? "#E49D5B"
                        : item.id === 519
                        ? "#8355B7"
                        : item.id === 974
                        ? "#8355B7"
                        : item.id === 598
                        ? "#D44DAD"
                        : item.id === 590 && "#90CB15"
                    }
                  >
                    {item.title}
                  </StudyTitle>
                </Row>
                {item.id === 166
                  ? studyContent.celiaquia.map((study, index) => (
                      <Row className="mb-2" key={index}>
                        <tr className="d-flex flex-row align-items-center">
                          {!study.delivery && (
                            <td style={{ paddingRight: "10px" }}>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  background: "#515151",
                                  borderRadius: "100px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              >
                                <UilCheck
                                  size={12}
                                  color={"#ffffff"}
                                  className="w-auto"
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <StudyDetail>{study.detail}</StudyDetail>
                          </td>
                        </tr>
                        {study.delivery && (
                          <tr>
                            <td className="pt-2">
                              <StudyDetail style={{ color: "#A6AEB6" }}>
                                {study.delivery}
                              </StudyDetail>
                            </td>
                          </tr>
                        )}
                      </Row>
                    ))
                  : item.id === 519
                  ? studyContent.vg45.map((study, index) => (
                      <Row className="mb-2" key={index}>
                        <tr>
                          <td style={{ paddingRight: "10px" }}>
                            <div
                              className="d-flex flex-row justify-content-center align-items-center"
                              style={{
                                background: "#515151",
                                borderRadius: "100px",
                                height: "12px",
                                width: "12px",
                              }}
                            >
                              <UilCheck
                                size={12}
                                color={"#ffffff"}
                                className="w-auto"
                              />
                            </div>
                          </td>
                          <td>
                            <StudyDetail>{study.detail}</StudyDetail>
                          </td>
                        </tr>
                      </Row>
                    ))
                  : item.id === 598
                  ? studyContent.vg70.map((study, index) => (
                      <Row className="mb-2" key={index}>
                        <tr>
                          <td style={{ paddingRight: "10px" }}>
                            <div
                              className="d-flex flex-row justify-content-center align-items-center"
                              style={{
                                background: "#515151",
                                borderRadius: "100px",
                                height: "12px",
                                width: "12px",
                              }}
                            >
                              <UilCheck
                                size={12}
                                color={"#ffffff"}
                                className="w-auto"
                              />
                            </div>
                          </td>
                          <td>
                            <StudyDetail>{study.detail}</StudyDetail>
                          </td>
                        </tr>
                      </Row>
                    ))
                  : item.id === 590
                  ? studyContent.lactosa.map((study, index) => (
                      <Row className="mb-2" key={index}>
                        <tr>
                          {!study.delivery && (
                            <td style={{ paddingRight: "10px" }}>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  background: "#515151",
                                  borderRadius: "100px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              >
                                <UilCheck
                                  size={12}
                                  color={"#ffffff"}
                                  className="w-auto"
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <StudyDetail>{study.detail}</StudyDetail>
                          </td>
                        </tr>
                        {study.delivery && (
                          <tr>
                            <td className="pt-2">
                              <StudyDetail style={{ color: "#A6AEB6" }}>
                                {study.delivery}
                              </StudyDetail>
                            </td>
                          </tr>
                        )}
                      </Row>
                    ))
                  : item.id === 974 &&
                    studyContent.cafeina.map((study, index) => (
                      <Row className="mb-2" key={index}>
                        <tr>
                          {!study.delivery && (
                            <td style={{ paddingRight: "10px" }}>
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  background: "#515151",
                                  borderRadius: "100px",
                                  height: "12px",
                                  width: "12px",
                                }}
                              >
                                <UilCheck
                                  size={12}
                                  color={"#ffffff"}
                                  className="w-auto"
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <StudyDetail>{study.detail}</StudyDetail>
                          </td>
                        </tr>
                        {study.delivery && (
                          <tr>
                            <td className="pt-2">
                              <StudyDetail style={{ color: "#A6AEB6" }}>
                                {study.delivery}
                              </StudyDetail>
                            </td>
                          </tr>
                        )}
                      </Row>
                    ))}
              </Col>
            </Row>
          ))}
        </Col>
      </Container>
    </StyledForDesktop>
  );
};
