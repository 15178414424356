import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import loading from "../../../../assets/home/ADN 2_1.gif";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import Slider from "react-slick";
//import { BannerGluten} from './gluten/bannerGluten';

import { useSelector } from "react-redux";

//import Vimeo from '@u-wave/react-vimeo';
import axios from "axios";

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 34px;
  line-height: 36px;
  //margin-top: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    display: flex !important;
    align-items: center;
    align-content: center;
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
    @media only screen and (max-width: 768px) {
      position: unset;
      justify-content: center;
      padding: 1rem 0 0 0 !important;
    }
  }
  .slick-dots li {
    height: 12px;
    width: 24px;
    display: flex;
    align-items: center;
    align-content: center;
  }
  .slick-dots li button {
    height: 20px;
    display: flex;
    align-items: center;
  }
  .slick-dots li button:before {
    font-size: 10px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 300ms !important;
    color: #f14cae;
  }
  .slick-dots li.slick-active button:before {
    color: #f14cae;
    background: #f14cae;
    border-radius: 81px;
    height: 11px;
    width: 45px;
    transition: all 300ms !important;
  }
`;

const DotsDiv = styled.div`
  position: absolute;
  bottom: 700px;
  @media only screen and (max-width: 1600px) {
  margin-left: 18%;
  position: absolute;
  bottom: 545px;
  }
  @media only screen and (max-width: 1400px) {
  margin-left: 12%;
  position: absolute;
  bottom: 445px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 576px) {
    margin-left: 0%;
    bottom: 500px;
  }
  @media only screen and (max-width: 346px) {
    display: none;
  }
`;

const DotsContainer = styled(Container)`
  @media only screen and (max-width: 992px) {
    margin: 0;
    padding: 0;
  }
`;

const SampleCard = styled.div`
  position: relative;
  height: 84vh;
  width: 90vw;
  background-color: none;
  // background-color: #111f301A;
  margin-right: 75px;
  padding-top: 150px;
  flex-shrink: 0;
  @media only screen and (max-width: 768px) {
    padding-top: 100px;
    height: 60vh;
  }
  .text-col {
    display: flex;
    position: absolute;
    padding-left: 100px;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
    h1 {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 45px;
      line-height: 80%;
      color: #f14cae;
      margin-right: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 38px;
        margin-top: 5px;
        margin-right: 18px;
      }
    }
    p {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 30px;
      line-height: 50px;
      color: #000;
      span {
        font-weight: 700;
      }
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 30px;
        max-width: 245px;
      }
    }
  }
  img {
    width: 65%;
    // max-height: 85vh;
    height: auto;
    position: relative;
    margin-left: 200px;

    @media only screen and (max-width: 768px) {
      width: 165%;
      height: auto;
      //left: 40%;
      transform: translate(-50%, 0);
    }
  }
  a {
    color: #000;
    text-decoration: underline;
    font-weight: 600;
  }
  #create {
    h4 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000 !important;
      max-width: 237px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  #create-bottom {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #000;
    position: absolute;
    bottom: 70px;
    left: 80px;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const Carrousel = (props) => {
  const [load, setLoaded] = useState(false);
  const { width } = useWindowDimensions();
  const [slide, setSlide] = useState(0);
  let isTablet = width <= 992;
  let isMobile = width <= 576;

  const onLoad = () => {
    setLoaded(true);
  };

  const [study, setStudy] = useState();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-study-test/675/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
    } catch (error) {
      console.log(error);
      window.location.href = "/not-found";
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const CustomDots = (props) => {
    return (
      <DotsContainer>
        <DotsDiv slide={slide}>
          <ul className="slick-dots" key={slide}>
            {props.dots}
          </ul>
        </DotsDiv>
      </DotsContainer>
    );
  };

  const settings = {
    appendDots: (dots) => <CustomDots dots={dots} />,
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 400,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    pauseOnHover: false,
    initialSlide: 0,
    dotClass: "slick-dots",
  };

  return (
    <>
      {isMobile ? (
        <>
          <Title>¿Qué información proporciona la prueba?</Title>
          <Col xs={4} md={4} className="text-col">
            <img
              src={loading}
              alt="loading"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40vh",
                width: "60vw",
                position: "relative",
                marginLeft: "50%",
                marginTop: "-10%",
              }}
            />
          </Col>
          <StyledSlider
            {...settings}
            beforeChange={(current, next) => setSlide(next)}
          >
            <SampleCard data-aos="fade-up">
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F1.png?alt=media&token=ab556a78-a37f-421a-80e0-37773a440c06"
                  alt="paso 1"
                />
              </Col>
            </SampleCard>
            <SampleCard data-aos="fade-up">
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F2.png?alt=media&token=07fa259c-c506-413a-9a89-d67386679076"
                  alt="paso 2"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F3.png?alt=media&token=273ec8c4-a619-4ae7-8b97-9978c4827355"
                  alt="paso 3"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F4.png?alt=media&token=b00b457d-f40d-4299-a3d5-d13c14eb006b"
                  alt="paso 4"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F5.png?alt=media&token=a10883b7-2b29-46b5-aee0-00d941bcc642"
                  alt="paso 5"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F6.png?alt=media&token=68288b7d-b372-47de-881e-8b33f2cd918c"
                  alt="paso 6"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F7.png?alt=media&token=0895d708-d4f2-4ee2-a46d-b4acc899d79b"
                  alt="paso 7"
                />
              </Col>
            </SampleCard>
          </StyledSlider>
        </>
      ) : (
        <>
        <Title>¿Qué información proporciona la prueba?</Title>
          <StyledSlider
            {...settings}
            beforeChange={(current, next) => setSlide(next)}
          >
            <SampleCard data-aos="fade-up">
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F1.png?alt=media&token=ab556a78-a37f-421a-80e0-37773a440c06"
                  alt="paso 1"
                />
              </Col>
            </SampleCard>
            <SampleCard data-aos="fade-up">
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F2.png?alt=media&token=07fa259c-c506-413a-9a89-d67386679076"
                  alt="paso 2"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F3.png?alt=media&token=273ec8c4-a619-4ae7-8b97-9978c4827355"
                  alt="paso 3"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F4.png?alt=media&token=b00b457d-f40d-4299-a3d5-d13c14eb006b"
                  alt="paso 4"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F5.png?alt=media&token=a10883b7-2b29-46b5-aee0-00d941bcc642"
                  alt="paso 5"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F6.png?alt=media&token=68288b7d-b372-47de-881e-8b33f2cd918c"
                  alt="paso 6"
                />
              </Col>
            </SampleCard>
            <SampleCard>
              <Col xs={6} md={6} className="text-col">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F7.png?alt=media&token=0895d708-d4f2-4ee2-a46d-b4acc899d79b"
                  alt="paso 7"
                />
              </Col>
            </SampleCard>
          </StyledSlider>
          <Col xs={4} md={4} className="text-col">
            <img
              src={loading}
              alt="loading"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "25vw",
                position: "relative",
                marginLeft: "180%",
                marginTop: "-115%",
              }}
            />
          </Col>
        </>
      )}
    </>
  );
};
