import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Doubts } from '../doubts/doubts';
import { DocsSlider } from './Slider';

const Title = styled.h1`
  margin-top: 65px;
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 38px;
  line-height: 115%;
  text-align: center;
  color: #00ad90;
  @media only screen and (max-width: 992px) {
    font-size: 22px;
  }
`;

const VideoDiv = styled.div`
  margin-left: 5%;
  width: 90%;
  @media only screen and (min-width: 768px) {
    margin-left: 15%;
    width: 70%;
  }
`;

export const Doctors = () => {
  return (
    <>
      <div
        style={{
          background: 'linear-gradient(180deg, #C4C4C4 0%, #85B4D2 100%)',
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <DocsSlider />
            </Col>
          </Row>
        </Container>
      </div>
      <Title>Mas información</Title>
      <div>
        <Container>
          <VideoDiv>
            <Vimeo
              color='ffbc45'
              video='https://vimeo.com/714575744/53fd23bda8'
              responsive={true}
              autopause
            />
          </VideoDiv>
        </Container>
      </div>
      <Doubts />
    </>
  );
};
