import React from "react";
import { Sport } from "./sport";
import { BannerSport } from "./bannerSport";
import Carrusel from "./Carousel/Carrusel";

export const NavSport = () => {
    return (
      <>
        <Sport/>
        <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FVideo%204.png?alt=media&token=5d6d917b-6e19-4d2e-9ae7-04ee7703fce6&_gl=1*1eic4uk*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODQyNDUxNC4xMTcuMS4xNjk4NDI1OTYwLjYwLjAuMA.." alt="vg70" style={{width: '100%'}}/>
        <BannerSport/>
        <Carrusel/>
      </>
    );
  };