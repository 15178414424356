import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Modal, Col, Row } from 'react-bootstrap';

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .modal-content {
    margin-top: -30%;
  }
  .btn-close {
    right: 16px;
    left: 0;
  }
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  width: 100%;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;

export const ErrorModal = (props) => {
  const [showErrorModal, setShowErrorModal] = useState(props.show);

  return(
    <StyledModal
      show={showErrorModal}
      onHide={() => setShowErrorModal(!showErrorModal)}
    >
      <StyledModal.Header style={{border: 'none'}} closeButton>
      </StyledModal.Header>
      <StyledModal.Body>
        <p className='text-center'>El correo <b>{props.email}</b> ya está en uso. Ingresa usando <b>{props.provider === 'Email' ? 'tu correo y contraseña' : props.provider} en su lugar.</b></p>
        {/* <p className='text-center'>Puedes vincular tu cuenta {props.currentProvider === 'password' ? `` : (<>de <b>{props.currentProvider}</b></>)} en {'"Mi perfil"'} después de haber ingresado a tu cuenta.</p> */}
        <ContinueButton className='p-2' onClick={() => setShowErrorModal(false)}>Cerrar</ContinueButton>
      </StyledModal.Body>
    </StyledModal>
  );
};