import ReactDOM from 'react-dom';
import {
  getAuth,
  signOut,
  updateProfile,
  signInWithPopup,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signInWithCredential,
  linkWithCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  FacebookAuthProvider,
  GoogleAuthProvider,
  EmailAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import {
  facebookAuthProvider,
  googleAuthProvider,
} from '../firebase/firebase-config';
import { types } from '../types/types';
import { getUserInfo, userInfo } from './user';
import {
  uiFinishLoading,
  uiRemoveError,
  uiSetError,
  uiStartLoading,
} from './loading';
import { updateSpinner } from './spinner';
import { cleanSession, createUser, doesUserExists } from './user';
import Swal from 'sweetalert2';
import translate from 'translate';
import { checkCart, resetCart, setTotalPrice, removeItemFromCart } from './shoppingCart';
import { ModalCode } from '../components/login/modals/ModalCode';
import { ErrorModal } from '../components/login/modals/errorModal';
import { useState } from 'react';
import { updateCartDB } from '../helpers/api';

const auth = getAuth();
//const history = useHistory();

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const emailProvider = new EmailAuthProvider();

const AllProviders = [
  googleProvider.providerId,
  facebookProvider.providerId,
  emailProvider.providerId,
];

export const startRegisterWithEmailPasswordName = (
  email,
  password,
  name,
  fathersLastname,
  mothersLastname,
  phone
) => {
  return (dispatch, getState) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async ({ user }) => {
        await updateProfile(user, { displayName: name });
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        dispatch(
          createUser(
            user.uid,
            user.displayName,
            user.email,
            '',
            phone,
            fathersLastname,
            mothersLastname
          )
        );
        Swal.fire({
          icon: 'success',
          title: '¡Muy bien!',
          text: 'Usuario creado exitosamente',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
      })
      .catch((e) => {
        if(e.code.includes('auth/email-already-in-use')) {
          Swal.fire({
            title: 'Algo salió mal...',
            text: 'El correo que ingresaste ya está en uso.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          });
        } else {
          Swal.fire({
            title: 'Algo salió mal...',
            text: 'Ocurrió un problema. Intenta de nuevo o prueba otro método de inicio de sesión.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          });
        }
        // showToast('error', e.message.split(':')[1]);
      });
  };
};

const getProvider = (providerId, email, credentials) => {
  const divError = document.getElementById('div-for-error');
  const div = document.createElement('div');
  div.setAttribute('id', 'error-login-modal');
  divError.appendChild(div);
  if (providerId === googleProvider.providerId) {
    ReactDOM.render(
      <ErrorModal
        show
        provider={'Google'}
        currentProvider={credentials.providerId}
        email={email}
      />,
      div
    );
  } else if (providerId === facebookProvider.providerId) {
    ReactDOM.render(
      <ErrorModal
        show
        provider={'Facebook'}
        currentProvider={credentials.providerId}
        email={email}
      />,
      div
    );
  } else if (providerId === emailProvider.providerId) {
    ReactDOM.render(
      <ErrorModal
        show
        provider={'Email'}
        currentProvider={credentials.providerId}
        email={email}
      />,
      div
    );
  } else {
    throw new Error(`No provider implemented for ${providerId}`);
  }
};

export const startGoogleLogin = (phone = null) => {
  return (dispatch) => {
    signInWithPopup(auth, googleAuthProvider)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        doesUserExists(user.uid)
          .then((res) => {
            dispatch(updateSpinner(false));
            if (res.exists) {
              console.log("res* ", res)
              // Redireccionar a perfil
              console.log('USUARIO EXISTENTE');
              console.log('Se inició sesión');
              dispatch(isNewUser(false));
              dispatch(checkCart());
            } else {
              // Redireccionar a registro
              console.log('NUEVO USUARIO');
              dispatch(isNewUser(true));
              dispatch(
                createUser(
                  user.uid,
                  user.displayName,
                  user.email,
                  phone,
                  user.photoURL
                )
              );
            }
            getUserInfo(user?.uid).then((res) => dispatch(userInfo(res)));
          })
          .catch((error) => {
            console.log(
              'Error intentando verificar si el usuario existe.',
              error
            );
          });
      })
      .catch((e) => {
        const email = e.customData.email;
        const credentials = FacebookAuthProvider.credentialFromError(e);
        const errorCode = e.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(auth, email).then((res) => {
            const findProvider = res?.find((p) => AllProviders.includes(p));
            getProvider(findProvider, email, credentials);
          });
        }
      });
  };
};

export const startFacebookLogin = (phone = null) => {
  return (dispatch) => {
    signInWithPopup(auth, facebookAuthProvider)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        doesUserExists(user.uid)
          .then((res) => {
            dispatch(updateSpinner(false));
            if (res) {
              // Redireccionar a perfil
              console.log('USUARIO EXISTENTE');
              console.log('Se inició sesión');
              dispatch(isNewUser(false));
              dispatch(checkCart());
            } else {
              // Redireccionar a registro
              console.log('NUEVO USUARIO');
              dispatch(isNewUser(true));
              dispatch(
                createUser(
                  user.uid,
                  user.displayName,
                  user.email,
                  phone,
                  user.photoURL
                )
              );
              getUserInfo(user?.uid).then((res) => dispatch(userInfo(res)));
            }
          })
          .catch((error) => {
            console.log(
              'Error intentando verificar si el usuario existe.',
              error
            );
          });
      })
      .catch((e) => {
        const email = e.customData.email;
        const credentials = FacebookAuthProvider.credentialFromError(e);
        const errorCode = e.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(auth, email).then((res) => {
            const findProvider = res?.find((p) => AllProviders.includes(p));
            getProvider(findProvider, email, credentials);
          });
        }
      });
  };
};

const translateText = async (message) => {
  translate.engine = 'google'; // Or "yandex", "libre", "deepl"
  translate.key = process.env.GOOGLE_KEY;
  const text = await translate(message, 'es');
  return text;
};

export const startLoginEmailPassword = (email, password) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        dispatch(checkCart());
        dispatch(uiFinishLoading());
        // showToast('success', `Welcome, ${user.displayName}!`)
        // alert('¡Bienvenido!')
      })
      .catch(async (error) => {
        dispatch(uiFinishLoading());
        translate.engine = 'google';
        translate.key = process.env.GOOGLE_KEY;
        // Traduce e imprime el error
        error = await translateText(
          error.message.split(':')[1].replace('Error ', '')
        );
        error = error.split('/')[1].split(')')[0];
        // error = error.charAt(0).toUpperCase() + error.slice(1);
        dispatch(uiSetError(error));
        setTimeout(() => {
          dispatch(uiRemoveError());
        }, 5000);
      });
  };
};

export const login = (uid, displayName) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
  },
});

export const isNewUser = (value) => ({
  type: types.isNew,
  payload: value,
});

export const startLogout = () => {
  return (dispatch) => {
    signOut(auth);
    dispatch(logout());
    dispatch(cleanSession());
    dispatch(resetCart());
    dispatch(removeItemFromCart([]));
    dispatch(setTotalPrice(0));
    dispatch(updateCartDB([], 0));
  };
};

export const isLogged = () => ({
  type: types.isLogged,
});

export const logout = () => ({
  type: types.logout,
});

export const verifiedPhone = (phone, verified, status) => ({
  type: types.phoneVerification,
  payload: {
    phone,
    verified,
    status,
  },
});

export const removeDialog = () => {
  const div = document.getElementById('modal-code-container');
  const divCode = document.getElementById('div-for-code');
  divCode.removeChild(div);
};

export const addDialog = (resolve) => {
  const divCode = document.getElementById('div-for-code');
  const div = document.createElement('div');
  div.setAttribute('id', 'modal-code-container');
  divCode.appendChild(div);
  ReactDOM.render(<ModalCode show resolve={resolve} />, div);
};

const getCode = () => {
  return new Promise((resolve) => {
    addDialog(resolve);
  });
};

export const resetPasswordAuth = (email) => {
  return () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Swal.fire({
          title: 'Revisa tu bandeja',
          text: 'Te hemos enviado el correo para restablecer tu contraseña',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
};

export const startLoginWithPhoneNumber = (phone, appVerifier) => {
  return (dispatch, getState) => {
    const newPhone = getState().recaptcha.phone;

    signInWithPhoneNumber(auth, newPhone, appVerifier)
      .then(async (confirmationResult) => {
        // Ask the users for the code
        const value = await getCode();
        removeDialog();

        // Check if code sent is correct
        confirmationResult
          .confirm(value)
          .then(({ user }) => {
            const newUid = user.uid;
            // Update phone verification in Redux
            dispatch(verifiedPhone(phone, true, 'All good'));
            // Check if it's a new user or already exists
            dispatch(updateSpinner(true));
            dispatch(login(user.uid, user.displayName));
            dispatch(isLogged());
            doesUserExists(user.uid)
              .then((user) => {
                dispatch(updateSpinner(false));
                if (user) {
                  // Redireccionar a perfil
                  console.log('USUARIO EXISTENTE');
                  dispatch(isNewUser(false));
                  dispatch(checkCart());
                } else {
                  // Redireccionar a registro
                  console.log('NUEVO USUARIO');
                  dispatch(isNewUser(true));
                  dispatch(createUser(newUid, '', '', newPhone));
                }
              })
              .catch((error) => {
                console.log('Error intentando verificar si el usuario existe.');
              });
          })
          .catch((error) => {
            console.log('ERROR CODE: ', error);
            alert('BAD CODE');
            dispatch(verifiedPhone(phone, false, 'Bad code'));
          });
      })
      .catch((error) => {
        dispatch(verifiedPhone(phone, false, 'SMS not sent'));
        // appVerifier.render().then(function(widgetId) {
        //     grecaptcha.reset(widgetId);
        // });
        // const message = `
        //     Has excedido el límite de veces para poder enviarte un código.
        //     Por favor prueba otro método de inicio de sesión.
        // `;
        Swal.fire({
          title: 'Algo salió mal...',
          text: 'Ocurrió un problema. Intenta de nuevo o prueba otro método de inicio de sesión.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
        });
        console.log(error);
      });
  };
};
