import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { MobileSubmenu } from './mobileSubMenu';
import styled from 'styled-components';
import { useState } from 'react';

const LinkContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom-color: #c1d9d8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const ButtonMenu = styled.a`
  text-align: inherit;
  color: black;
  background-color: white;
  border: none;
`;

const MenuTitle = styled.span`
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

const ModalScroll = styled(Modal)`
  height: 100vh;
`;

export const Menu = (props) => {
  const [opened, setOpened] = useState(false);

  return (
    <ModalScroll show={props.show} fullscreen={true} onHide={props.hide}>
      <Modal.Body
        style={{
          display: opened ? 'block' : 'flex',
          paddingTop: 'auto',
          paddingBottom: 'auto',
        }}
      >
        <Container>
          {props.menu.map((item, index) => (
            <LinkContainer
              key={index}
              className=' d-flex align-items-center justify-content-center'
            >
              {!item.arrow ? (
                <ButtonMenu
                  variant='light'
                  style={{ width: '100%' }}
                >
                  <Row className='justify-content-center'>
                    <Col sm={10} xs={10} className='text-center'>
                      <MenuTitle onClick={() => {
                        item.section === "curso" 
                          ? window.open(item.link)
                          : item.section === "programa-transforma"
                          ? window.location.replace(`/${item.section}`)
                          : window.location.replace(`/#${item.section}`)
                        setTimeout(() => {
                          props.setShowMenu(!props.show)
                        }, 400)
                      }}>
                        {item.name}
                      </MenuTitle>
                    </Col>
                  </Row>
                </ButtonMenu>
              ) : (
                <MobileSubmenu
                  menu={item}
                  onHide={props.hide}
                  opening={() => setOpened(!opened)}
                />
              )}
            </LinkContainer>
          ))}
        </Container>
      </Modal.Body>
    </ModalScroll>
  );
};
