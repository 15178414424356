import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { StudiesSlider } from './Slider';
import axios from 'axios';

const Title = styled.h5`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 33px;
  /* identical to box height */
  color: #00ad90;
  font-weight: 500;
  @media only screen and (min-width: 992px) {
    font-size: 38px;
    line-height: 115%;
    /* or 44px */
    text-align: center;
    letter-spacing: -0.01em;
    color: #0a7a71;
    margin-top: 46px;
  }
`;

export const Highlights = (props) => {
  const [studies, setStudies] = useState([]);

  const getStudies = async (id) => {
    try {
      let request = axios.get(
        'https://lpqsystem.com/get-featured-studies/' + id
      );
      let data = await request;
      setStudies(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudies(props.data.id);
  }, [props.data.id]);
  return (
    <div
      style={{
        background:
          'linear-gradient(157.11deg, rgba(0, 173, 144, 0.5), rgba(138, 204, 164, 0.5))',
      }}
    >
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <Title>Estudios Destacados</Title>
          </Col>
          <Col md={12}>
            <StudiesSlider catID={props.data.id} studies={studies} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
