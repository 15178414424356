import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import Texture from "../../../assets/home/textureFaq.png";

const FAQSection = styled.div`
  background-color: #ffffff;
  background-image: url(${Texture});
  background-repeat: no-repeat;
  background-size: cover;
`;

const FAQContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const FAQTitle = styled.div`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2e363e;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const StyledAccordion = styled(Accordion)`
  .accordion-button {
    height: 100%;
    padding: 1.5rem 0 1.5rem 0;
    background: none;
  }
  .accordion-button::after {
    margin-top: 0;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%238355B7%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #ffffff;
    box-shadow: none;
  }
  .accordion-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 1.5rem;
  }
`;

const StyledAccordionItem = styled(Accordion.Item)`
  border-bottom: ${(props) =>
    props.islast === 1 ? "none" : "1px solid #E2EAF2 !important"};
  background: none;
`;

const Question = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  color: #8355b7;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

const Answer = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #515151;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const StyledA = styled.a`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #8355b7;
  margin: 0;
  text-decoration: underline;
  :hover {
    color: #8355b7;
  }
`;

export const FAQ = () => {
  const Questions = [
    {
      question:
        "¿Los resultados de las pruebas genéticas cambian con el tiempo?",
      answer:
        "No, los resultados de las pruebas de Vitalgene no cambian con la edad, ni con el tiempo. El conocimiento de la información genética es una inversión única y los resultados te servirán para toda la vida.",
    },
    {
      question: "¿Cómo pueden obtener los resultados a partir de saliva?",
      answer:
        "La saliva contiene células de las cuales podemos extraer ADN, el cual contiene toda la información genética necesaria para conocer las variantes heredadas por nuestros padres y así analizarlas en nuestras pruebas. ",
      bold: "¡Así de práctico y sin ser invasivo!",
    },
    {
      question: "¿Cómo asegura Vitalgene mi privacidad?",
      answer:
        "Todos los datos personales proporcionados, así como todo lo relacionado con tu estado de salud y antecedentes heredofamiliares, están cubiertos bajo nuestro compromiso de protección de datos. Para más información con respecto a nuestro aviso de privacidad de datos ",
      link: "consulta aquí.",
    },
    {
      question: "¿Qué tecnología utiliza Vitalgene?",
      answer:
        "Las principales metodologías que utilizamos en nuestras pruebas son técnicas de biología molecular como: reacción en cadena de la polimerasa (PCR), secuenciación, espectometría de masas, entre otras. Todas tienen un alto grado de sensibilidad y especificidad.",
    },
    {
      question: "¿Por qué debo activar mi kit?",
      answer:
        "Al activar tu kit en tu expediente digital sabremos que la muestra es tuya. El laboratorio no puede procesar la muestra si el kit no está activado y vinculado a tus datos personales. Solo debes activar la prueba cuando estés listo para tomar tu muestra.",
    },
    {
      question: "¿Cómo hago mi toma de muestra?",
      answer:
        "Antes de realizar la toma deben pasar 30 minutos sin haber ingerido alimentos o bebidas, lavarse los dientes, masticar chicle, utilizar enjuague bucal o fumar. Después, es importante seguir las instrucciones de toma de muestra que vienen indicadas en tu kit. Si necesitas asesoramiento escríbenos al ",
      bold: "56 1507 7271",
    },
    {
      question: "¿En qué consisten las alianzas vitales?",
      answer:
        "Nuestras alianzas vitales te permiten tener exclusividad de nuestras pruebas en tu ciudad, para que puedas ofertar una consulta de nutrición mucho más completa a tus pacientes.",
    },
    {
      question: "¿Cómo puedo ser un aliado vital?",
      answer:
        "Contactanos al número 56 1507 7271 para agendar una videollamada con nuestro equipo. En esta videollamada te explicaremos todo lo que conlleva y los beneficios de ser un aliado de Vitalgene.",
    },
    {
      question: "¿Cuáles son los beneficios de ser un aliado vital?",
      answer0: "*Exclusividad en tu ciudad/estado",
      answer1: "*Capacitaciones continuas",
      answer2: "*Capacitaciones continuas",
      answer3: "*Apoyo por parte del equipo de vitalgene",
      answer4: "*Recomendación de tus servicios en nuestras redes sociales y página web",
      answer5: "*Precio preferencial",
      answer6: "*Material educativo para tus pacientes",
      answer7: "*Comunidad con nutriólogos, médicos y genetistas",
    },
  ];
  return (
    <FAQSection>
      <FAQContainer>
        <Row className="mb-4 mb-lg-5">
          <FAQTitle>¿Tienes dudas?</FAQTitle>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={7} className="p-0">
            <StyledAccordion flush defaultActiveKey="0">
              {Questions.map((question, index) => (
                <StyledAccordionItem
                  key={index}
                  eventKey={`${index}`}
                  islast={index === Questions.length - 1 ? 1 : 0}
                >
                  <StyledAccordion.Header>
                    <Question>{question.question}</Question>
                  </StyledAccordion.Header>
                  <StyledAccordion.Body>
                    <Answer>
                      {question.answer}
                      {question.bold ? (
                        <b>{question.bold}</b>
                      ) : (
                        <>
                          {question.link && (
                            <StyledA
                              href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Aviso%20de%20privacidad.pdf?alt=media&token=433f0150-15bc-4ed3-bf68-8f881c29a057"
                              target="_blank"
                            >
                              <b>{question.link}</b>
                            </StyledA>
                          )}
                        </>
                      )}
                      {question.answer0}
                      <br />
                      {question.answer1}
                      <br />
                      {question.answer2}  
                      <br />
                      {question.answer3}
                      <br />
                      {question.answer4} 
                      <br />
                      {question.answer5} 
                      <br />
                      {question.answer6} 
                      <br />
                      {question.answer7}
                    </Answer>
                  </StyledAccordion.Body>
                </StyledAccordionItem>
              ))}
            </StyledAccordion>
          </Col>
        </Row>
      </FAQContainer>
    </FAQSection>
  );
};
