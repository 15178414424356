import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { toastSweet } from '../../helpers/sweetAlert2';

const Container = styled(Row)`
  background-color: rgba(117, 145, 182, 0);
  border-bottom: 1px solid #8acca4;
  padding-bottom: 10px;
  padding-top: 15px;
  :hover {
    background-color: #f1f6f6;
    cursor: pointer;
  }
`;
const Title = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  max-width: 95%;
  text-align: left;
  /* identical to box height */
  color: #1AC7C7;
`;

const Price = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height */
  color: #D44DAD;
  text-align: right;
`;

const Column = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const MbColumn = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  text-align: right;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
  @media only screen and (max-width: 768px) {
    height: 30px;
    width: 35px;
  }
`;

const AddButton = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  font-size: 18px;
  background: #f9ad62;
  color: #fff;
`;

export const SearchItem = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const history = useHistory();
  const dispatch = useDispatch();
  const myClic = () => {
    history.push(`/estudio/${props.data.id}`);
    props.handleClic();
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 768;

  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    const exists = items.filter((item) => item.id === props.data.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(props.data));
    }
  };

  return (
    <Container>
      {/* <Column md={1} sm={1} xs={1} className='text-center'>
        <Icon src={icon} className='img-fluid' />
      </Column> */}
      <Column
        md={{ span: 5 }}
        sm={5}
        xs={5}
        style={{ textAlign: 'left' }}
        onClick={() => myClic()}
      >
        <Title>{props.data.title}</Title>
      </Column>
      {isMobile ? (
        <>
          <MbColumn xs={5} style={{ justifyContent: 'right' }}>
            <Price style={{ color: '#1AC7C7' }}>
              {currency(props.data.price)} MXN
            </Price>
            <br />
            <Price>{currency(props.data.oldPrice)} MXN</Price>
          </MbColumn>
          <MbColumn
            xs={2}
            className='d-flex align-items-center justify-content-center'
          >
            <AddButton
              onClick={() => dispatch(handleAddItemToCart(props.data))}
            >
              +
            </AddButton>
          </MbColumn>
        </>
      ) : (
        <>
          <Column
            md={3}
            sm={6}
            xs={6}
            onClick={() => myClic()}
            style={{ justifyContent: 'right' }}
          >
            <Price style={{ color: '#1AC7C7' }}>
              {currency(props.data.price)} MXN
            </Price>
          </Column>
          <Column
            md={3}
            sm={6}
            xs={6}
            onClick={() => myClic()}
            style={{ justifyContent: 'right' }}
          >
            <Price>{currency(props.data.oldPrice)} MXN</Price>
          </Column>
          <Column
            md={1}
            sm={6}
            xs={6}
            className='d-flex align-items-center justify-content-center'
          >
            <AddButton onClick={() => addStudy()}>+</AddButton>
          </Column>
        </>
      )}
    </Container>
  );
};
