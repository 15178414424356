import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { BannerVg70 } from "./bannerVg70";

const MySlider = styled(Slider)`
  position: relative;
  .slick-arrow {
    width: 60px;
    height: 60px;
    @media only screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 2px;
    @media only screen and (max-width: 768px) {
      bottom: -30px !important;
    }
    button {
      background: #ccc;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px 1px rgba(100, 100, 100, 0.2);
      ::before {
        display: none;
      }
    }

    .slick-active {
      width: 24px;
      margin-right: 10px;
    }
    .slick-active button {
      border-radius: 81px;
      background: #1379BC;
      width: 24px;
    }
  }
`;

export const Banners = (props) => {

  return (
    <MySlider
      width="100%"
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={false}
      autoplaySpeed={5000}
      cssEase={"linear"}
      speed={500}
    >
      <BannerVg70/>
      
    </MySlider>
  );
};
