import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Category } from './Category';
import Slider from 'react-slick';
import { UilArrowCircleLeft } from '@iconscout/react-unicons';
import { UilArrowCircleRight } from '@iconscout/react-unicons';

const StyledSlider = styled(Slider)`
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #e2e2e2;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #00AD90;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

let tests = [
  {
    results: 2,
    category: 'Check up',
    description:
      'Texto que describa el servicio o estudio brevemente en una oración',
  },
  {
    results: 30,
    category: 'Laboratorio clínico',
    description:
      'Texto que describa el servicio o estudio brevemente en una oración',
  },
  {
    results: 10,
    category: 'Servicios a domicilio',
    description:
      'Texto que describa el servicio o estudio brevemente en una oración',
  },
];

export const SearchSlider = (props) => {
  // const [index, setIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleLeft
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleRight
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  return (
    <Container style={{ paddingBottom: '50px' }}>
      <StyledSlider
        dots={true}
        infinite={true}
        slidesToShow={
          screenWidth > 1199
            ? 4
            : screenWidth > 991
            ? 3
            : screenWidth > 767
            ? 2
            : 1
        }
        slidesToScroll={
          screenWidth > 1199
            ? 4
            : screenWidth > 991
            ? 3
            : screenWidth > 767
            ? 2
            : 1
        }
        autoplay={true}
        autoplaySpeed={4000}
        cssEase={'linear'}
        speed={600}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
        {tests.map((test) => (
          <div>
            <Category data={test} />
          </div>
        ))}
      </StyledSlider>
    </Container>
  );
};
