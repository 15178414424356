import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Dropdown } from 'react-bootstrap';
import {
  UilAngleDown,
  UilListUl,
  UilApps,
  UilFilter,
} from '@iconscout/react-unicons';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { TestCard } from '../../components/studies/testCard';
import { StudyL } from '../../components/studies/studyL';

const Title = styled.h4`
  font-weight: 500;
  font-size: 38px;
  line-height: 56px;
  color: #00ad90;
`;

const FilterButton = styled(Button)`
  background-color: white;
  border: none;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
`;

const OrderButton = styled(Dropdown.Toggle)`
  background-color: white;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 992px) {
    width: 189px;
    height: 81px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 16px;
  }

  ::after {
    display: none;
  }
`;

const OrderMenu = styled(Dropdown.Menu)`
  margin-top: 20px;
  width: 262px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  box-shadow: 0px 15px 30px rgba(164, 164, 164, 0.5);

  > a {
    color: #717171;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  > a::hover {
    color: #000000;
  }

  > .dropdown-item {
    width: 90%;
  }
  > .dropdown-item:active {
    background-color: ##f1f6f6;
  }
`;

const DropdownOrder = styled(Dropdown)`
  z-index: 1;
  > .dropdown-menu:before {
    content: '';
    background-color: transparent;
    border-right: 8px solid transparent;
    position: absolute;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #fff;
    border-top: 8px solid transparent;
    top: -15px;
    left: 144px;
  }
`;

const Description = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  color: #717171;
`;

const ShowMoreButton = styled.button`
  width: 297px;
  height: 48px;
  background: #6fbea0;
  border: none;
  border-radius: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  :hover {
    background: #81d6b6;
  }
`;

export const Studies = (props) => {
  // For mobile filter
  const [showMenu, setShowMenu] = useState(false);
  const [order, setOrder] = useState(['Ordenar por', 0]);
  const [items, setItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(12);

  //Change order button content
  const handleChange = (e, index) => {
    setOrder([e, index]);
  };

  const [filters, setFilters] = useState([
    { id: 1, select: false, name: 'filter1a', group: 1 },
    { id: 2, select: false, name: 'filter1b', group: 1 },
    { id: 3, select: false, name: 'filter1c', group: 1 },
    { id: 4, select: false, name: 'filter2a', group: 2 },
    { id: 5, select: false, name: 'filter2b', group: 2 },
    { id: 6, select: false, name: 'filter2c', group: 2 },
  ]);

  // Make filters request
  // Add axios request
  const getFilteredInfo = () => {
    const toFilter = [];
    filters.map((f) => {
      if (f.select) {
        toFilter.push(f.name);
      }
    });
    console.log(toFilter);
    console.log(order);
    const endOffset = itemOffset + itemsPerPage;
    getStudies(props.data.id, endOffset);
  };

  useEffect(getFilteredInfo, [filters, order]);

  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 991;

  const [showList, setShowList] = useState(false);

  // Set items per page based on the width & list
  const itemsPerPage = showList
    ? 5
    : width > 1199
    ? 12
    : width > 991
    ? 12
    : width > 767
    ? 8
    : 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const getStudies = async (id, endOffset) => {
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    try {
      let request = axios.get('https://lpqsystem.com/get-studies/0/' + id); //orden/service
      let response = await request;
      setItems(response.data);
      setCurrentItems(response.data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(response.data.length / itemsPerPage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    getStudies(props.data.id, endOffset);
  }, [itemOffset, itemsPerPage, props.data.id]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Container>
      <HelmetTitle title='Estudios' name='description' content='content' />
      <Row>
        <Col className='text-center mt-5'>
          <Title>Todos los estudios</Title>
        </Col>
      </Row>
      <Row className='mt-1 mb-3'>
        <Col className='text-center'>
          <Description>
            Utiliza los filtros para encontrar fácilmente los estudios que
            necesitas
          </Description>
        </Col>
      </Row>

      <Row>
        <Col
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Row>
            <Col
              xs={{ span: 3, offset: 6 }}
              sm={{ span: 3, offset: 6 }}
              md={{ span: 2, offset: 8 }}
              lg={{ span: 1, offset: 10 }}
            >
              <FilterButton
                className='view'
                variant='light'
                onClick={() => setShowList(!showList)}
                style={{ background: !showList ? '#FFF' : '#00AD90' }}
              >
                <UilListUl size='30' color={!showList ? '#00AD90' : '#FFF'} />
              </FilterButton>
            </Col>
            <Col
              xs={{ span: 3 }}
              sm={{ span: 3 }}
              md={{ span: 2 }}
              lg={{ span: 1 }}
            >
              <FilterButton
                className='view'
                variant='light'
                onClick={() => setShowList(!showList)}
                style={{ background: showList ? '#FFF' : '#00AD90' }}
              >
                <UilApps size='30' color={showList ? '#00AD90' : '#FFF'} />
              </FilterButton>
            </Col>
          </Row>
          <Row>
            {items &&
              items.map((item, index) => (
                <>
                  {index < itemsToShow ? (
                    showList ? (
                      <StudyL key={index} data={item} />
                    ) : (
                      <TestCard
                        key={index}
                        data={item}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        xxl={3}
                      />
                    )
                  ) : null}
                </>
              ))}
          </Row>
          <p
            style={{ textAlign: 'center', fontSize: '18px', marginTop: '40px' }}
          >
            Mostrando {itemsToShow > items.length ? items.length : itemsToShow}{' '}
            de {items.length} estudios
          </p>
          <div className='d-flex justify-content-center'>
            {itemsToShow < items.length && (
              <ShowMoreButton onClick={() => setItemsToShow(itemsToShow + 6)}>
                Mostrar más
              </ShowMoreButton>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
