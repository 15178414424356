import React from "react";
import { HelmetTitle } from "../../../../../helpers/HelmetTitle";
import { ContactoVG } from "./contactoVG";

const AliadosVG = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <HelmetTitle
        title="Contacto"
        name="description"
        content="content"
      />
      <ContactoVG/>
    </div>
  )
};

export default AliadosVG;