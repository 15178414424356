import React, { useState, useEffect, useRef } from "react";
import "./carruselstyles.css";
import { Row, Col} from "react-bootstrap";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Doubts } from "../../../doubts/doubts";

const BannerBackground = styled.div`
  width: 100%;
  height: 165px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fbanner1.png?alt=media&token=d7bdcac7-69c7-41c8-b68f-1bd6e25136b7")
    center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38"centerno-repeat);
    height: 130px;
    margin-bottom: 70px;
  }
  .row {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 48px;
  font-weight: 300;
  line-height: 180%;
  margin-top: revert;
  p {
    width: 59vw;
    margin-bottom: 60px;
    color: #000;
    text-align: center;
    font-size: 34px;
    font-weight: 300;
    line-height: 100%;
  }
  span {
    background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 95%;
      width: 100%;
      left: 0;
      height: 7px;
      border-radius: 2px;
      background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
    p {
      width: 96vw;
      margin-top: -70px;
      font-size: 17px;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #e16e46;
  border-radius: 20px;
  border: none;
  text-align: center;
  width: 535px;
  height: 70px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 151px;
    font-size: 14px;
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 270px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const imag2 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fencuesta.png?alt=media&token=68d0a604-df7d-45dd-9697-416bbb0a60c4";
const imag3 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fkits.png?alt=media&token=286ba961-3de1-44ca-b807-67c9b8dc1229";
const imag4 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fmuestra.png?alt=media&token=9cbb13e9-6ad0-4ed9-8fe4-be3566f974e8";
const imag5 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fenvio.png?alt=media&token=66e47947-8539-401a-b8ad-0d2514b932ab";
const imag6 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fcosto.png?alt=media&token=cfa0a851-45d0-4c74-803f-dc16ffff6114";

const images = [imag2, imag3, imag4, imag5, imag6];

export const Funciones = () => {
  const carousel = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    console.log(carousel.current?.scrollWidth, carousel.current?.offsetWidth);
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);
  }, []);

  return (
    <>
      <Row>
        <DescColumn
          xs={12}
          md={12}
          className="justify-content-center"
        >
          <Title>
            <p>¿Cómo funciona?</p>
          </Title>
        </DescColumn>
      </Row>
      <AnimateSharedLayout>
        <AnimatePresence>
          <div className="contapp">
            <motion.div
              ref={carousel}
              className="carousel"
              whileTap={{ cursor: "grabbing" }}
            >
              <motion.div
                className="inner"
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {images.map((image) => (
                  <motion.div className="item" key={image}>
                    <img src={image} alt="Texto alt" />
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </div>
        </AnimatePresence>
      </AnimateSharedLayout>
      <BannerBackground>
        <Title className="mb-2 ms-auto me-auto">
          <Desc>
            <a href="." target="_blank" rel="noopener noreferrer">
              Únete a nuestro programa de Aliados Vitales
            </a>
          </Desc>
        </Title>
      </BannerBackground>
      <Doubts />
    </>
  );
};
