import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoginEmailPassword, startRegisterWithEmailPasswordName } from '../../../actions/auth';
import { toastSweet } from '../../../helpers/sweetAlert2';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';

const smallText = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    color: '#A6A6A6',
    margin: '0 0 2px 5px',
};

const inputStyle = {
    width: '240px',
    height: '43px',
    left: '40px',
    top: '315px',
    background: '#F6F6F6',
    borderRadius: '12px',
};

const buttonStyle = {
    width: '214px',
    height: '48px',
    border: 'none',
    background: '#77CCD5',
    borderRadius: '102px',
    fontweight: '800',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const linkCreateAccount = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    color: '#77CCD5',
    cursor: 'pointer',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
};

const normalText = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'left',
    color: '#000000',
    margin: '0 21px 0 15px',
};

const listInfoStyle = {
    textDecoration: 'none',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    color: '#717171',
};

const StyledCheckbox = styled(Form.Check)`
    .form-check-input[type="checkbox"]#register-email {
        border: 1px solid #77CCD5;
    }
    .form-check-input:checked {
        background-color: #77CCD5 !important;
    }
`;

const StyledBtn = styled(Button)`
    transition: all 300ms ease-in-out;
    :disabled {
        filter: grayscale();
    }
`;

const itemListStyle = { margin: '5px 0' }

export const RegisterEmailModal = () => {
    const [newsLetterCheck, setChecked] = useState(false);
    const [name, setName] = useState('');
    const [fathersLastname, setFathersLastname] = useState('');
    const [mothersLastname, setMothersLastname] = useState('');
    const [termAndConditions, setTermsAndConditions] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const { msgError } = useSelector(state => state.ui);

    const newsletterCheckBox = (
        <StyledCheckbox
            key='register-email'
            id='register-email'
            type="checkbox"
            name="radio"
            label="Acepto términos y condiciones."
            value={newsLetterCheck}
            style={{ margin: '10px 0' }}
            checked={newsLetterCheck}
            onChange={() => setChecked(!newsLetterCheck)}
        />
    );

    const dispatch = useDispatch();

    const handleEmailAndPasswordRegister = () => {
        console.table([name, fathersLastname, mothersLastname, email, password, password2]);
        try {
            dispatch(startRegisterWithEmailPasswordName(email, password, name, fathersLastname, mothersLastname, ''));
        } catch (error) {
            setTermsAndConditions(false);
            console.log(error);
        }
    };

    const showTerms = () => {
        if (name && email) {
            if (password === password2) {
                setTermsAndConditions(true);
            } else {
                toastSweet('warning', 'Las contraseñas no coinciden.');
            }
        } else {
            toastSweet('warning', 'Ocurrió un error, verifica tus datos.');
        }
    };

    return (
        <>

            {
                (termAndConditions) ? (<>
                    <br />
                    <span style={{ ...normalText, fontSize: '15px', margin: '5px 0 5px 0' }}>
                        Guardamos tus datos de manera 100% segura para:
                    </span>
                    <ul style={listInfoStyle}>
                        <li style={itemListStyle}>Identificar tu perfil</li>
                        <li style={itemListStyle}>Aplicar descuento por membresía</li>
                        <li style={itemListStyle}>Guardar el historial de los estudios</li>
                        <li style={{ margin: '5px 0 0 0' }}>Facilitar el proceso de compra</li>
                    </ul>
                    <a target="_blank" rel="noreferrer" href="https://www.google.com">
                        Términos y condiciones
                    </a>
                    {newsletterCheckBox}
                </>)
                    : (<>
                        <div className="mb-1">
                            {
                                msgError && (
                                    <Form.Label style={{ ...smallText, color: 'red' }}>
                                        <b>Error:</b> {msgError}.
                                    </Form.Label>
                                )
                            }
                            <Form.Label className="mt-3" style={smallText}>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre"
                                style={inputStyle}
                                value={name}
                                name="name"
                                id="name"
                                onChange={(e) => setName(e.target.value)}
                            />

                            <Form.Label className="mt-3" style={smallText}>Apellido paterno</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Apellido paterno"
                                style={inputStyle}
                                value={fathersLastname}
                                name="secondName"
                                id="secondName"
                                onChange={(e) => setFathersLastname(e.target.value)}
                            />

                            <Form.Label className="mt-3" style={smallText}>Apellido materno</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Apellido materno"
                                style={inputStyle}
                                value={mothersLastname}
                                name="lastName"
                                id="lastName"
                                onChange={(e) => setMothersLastname(e.target.value)}
                            />

                            <Form.Label className="mt-3" style={smallText}>Correo electrónico</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="pruebas@labopat.mx"
                                style={inputStyle}
                                value={email}
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <Form.Label className="mt-3" style={smallText}>Contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="********"
                                style={inputStyle}
                                value={password}
                                name="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <Form.Label className="mt-3" style={smallText}>Repetir contraseña</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="********"
                                style={inputStyle}
                                value={password2}
                                name="password2"
                                id="password2"
                                onChange={(e) => setPassword2(e.target.value)}
                            />
                        </div>
                    </>)
            }
            <StyledBtn
                variant="primary"
                className="mt-3"
                disabled={termAndConditions ? !newsLetterCheck : false}
                style={buttonStyle}
                onClick={() => termAndConditions ? handleEmailAndPasswordRegister() : showTerms()}
            >
                <b>{termAndConditions ? 'Registrarme' : 'Continuar'}</b>
            </StyledBtn>
            {
                (termAndConditions) && (
                    <span
                        style={linkCreateAccount}
                        onClick={() => setTermsAndConditions(false)}
                    >
                        Regresar al formulario
                    </span>
                )
            }
        </>
    )
}
