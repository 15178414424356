import React, { useState } from 'react';
import { Button, FloatingLabel, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilQuestionCircle, UilCameraPlus } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { MedicalSheetModal } from './medicalSheetModal';
import { useForm } from 'react-hook-form';
import { db, app } from '../../firebase/firebase-config';
import { doc, updateDoc } from '@firebase/firestore';
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import PhoneInput from 'react-phone-number-input';
import { Timestamp } from 'firebase/firestore';
import { getUserInfo, updateInfoUser, userInfo } from '../../actions/user';

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-top: -45px;
    margin-bottom: 24px;
  }
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

const StyledSelect = styled(Form.Select)`
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
`;

const DataForm = styled.form`
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'Assistant', sans-serif;
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'Assistant', sans-serif;
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }

  > div input.form-check-input:checked {
    background-color: #00ad90;
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const StyledDiv = styled.div`
  margin-bottom: 24px;
  background: #dbf1ff;
  padding: 16px 20px;
  border-radius: 12px;
  > p {
    text-align: left;
    margin-bottom: 0px;
    > a {
      color: #00ad90;
      text-decoration: underline;
    }
  }
`;

const ContinueButton = styled(Button)`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const UploadImage = styled.div`
  width: 120px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #e0ecec;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

export const FirstTimeDoctor = (props) => {
  const dispatch = useDispatch();
  const [personalData, setPersonalData] = useState(props.personalData);
  const [showMedicSheet, setShowMedicSheet] = useState(false);
  const [picture, setPicture] = useState('');
  const [phone, setPhone] = useState();
  const userId = useSelector((state) => state.auth?.uid);
  const [userData, setUserData] = useState();

  getUserInfo(userId).then((data) => {
    setUserData(data);
  });

  const especialidades = [
    { value: 'MEDICINA GENERAL' },
    { value: 'ANATOMÍA PATOLÓGICA' },
    { value: 'ANESTESIOLOGÍA' },
    { value: 'AUDIOLOGÍA, OTONEUROLOGÍA Y FONIATRÍA' },
    { value: 'CALIDAD DE LA ATENCIÓN CLÍNICA' },
    { value: 'CIRUGÍA GENERAL' },
    { value: 'EPIDEMIOLOGÍA' },
    { value: 'GENÉTICA MÉDICA' },
    { value: 'GERIATRÍA' },
    { value: 'GINECOLOGÍA Y OBSTETRICIA' },
    { value: 'IMAGENOLOGÍA DIAGNÓSTICA Y TERAPÉUTICA' },
    { value: 'MEDICINA DE LA ACTIVIDAD FISICA Y DEPORTIVA' },
    { value: 'MEDICINA DE REHABILITACIÓN' },
    { value: 'MEDICINA DE URGENCIAS' },
    { value: 'MEDICINA DEL TRABAJO Y AMBIENTAL' },
    { value: 'MEDICINA FAMILIAR' },
    { value: 'MEDICINA INTERNA' },
    { value: 'MEDICINA LEGAL' },
    { value: 'MEDICINA NUCLEAR E IMAGENOLOGÍA MOLECULAR' },
    { value: 'MEDICINA PREVENTIVA' },
    { value: 'NEUMOLOGÍA' },
    { value: 'OFTALMOLOGÍA' },
    { value: 'OTORRINOLARINGOLOGÍA Y CIRUGIA DE CABEZA Y CUELLO' },
    { value: 'PATOLOGÍA CLÍNICA' },
    { value: 'PEDIATRÍA' },
    { value: 'PSIQUIATRÍA' },
    { value: 'RADIO ONCOLOGÍA' },
    { value: 'TRAUMATOLOGÍA Y ORTOPEDIA' },
    { value: 'ANESTESIOLOGÍA' },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    const docData = {
      name: data.name,
      fathersLastname: data.fathersLastname,
      mothersLastname: data.mothersLastname,
      email: data.email,
      phone: data.phone,
      gender: data.gender,
      profile: {
        type: 1,
        verified: true,
        cedula_profesional: data.professionalLicense,
        especialidad: data.speciality,
        //otraEspecialidad: data.otraEspecialidad,
        cedula_especialidad: data.specialityLicense,
        num_consejo: data.councilNumber,
        logo: picture,
        first_time: false,
      },
    };
    console.log(docData);
    //return;
    const dbRef = doc(db, 'users', `${userId}`);

    updateDoc(dbRef, docData)
      .then((res) => {
        console.log('updated');
        props.onFirstTimeDoctor({
          personalData: data,
        });
        console.log(data);
        window.scroll({
          top: 0,
        });
      })
      .catch((error) => {
        console.log('[Error] ', error);
      });
  };

  let days = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  let months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const years = [];
  for (var i = 2024; i >= 1950; i--) {
    years.push(i.toString());
  }

  const forceShow = () => {
    if (showMedicSheet) {
      setShowMedicSheet(false);
      setTimeout(() => {
        setShowMedicSheet(true);
      }, 1);
    } else {
      setShowMedicSheet(true);
    }
  };

  const handleInputFile = () => {
    document.getElementById('inputFile').click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      dispatch(uploadFile(file, `/ecommerce/drLogos/${file.name}`));
    }
  };

  const uploadFile = (file, path) => {
    const storageRef = getStorage(app, 'gs://covid19-cc1fe.appspot.com/');
    const fileRef = ref(storageRef, path);
    uploadBytes(fileRef, file)
      .then((res) => {
        console.log(res);
        getDownloadURL(fileRef)
          .then((url) => {
            console.log(url);
            setPicture(url);
            //TODO: Save url in firebase
          })
          .catch((error) => {
            console.log('Error en URL');
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <StyledForDesktop>
      {/* ------ Logged Doctor 1st time ----- */}
      <Title>Ingresa tus datos para crear tu carpeta médica</Title>
      <DataForm onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId='genderM'>
          <Form.Check
            inline
            {...register('gender', {
              required: false,
            })}
            required
            value='Masculino'
            type='radio'
            aria-label='radio 1'
            label='Doctor'
          />
          <Form.Check
            inline
            {...register('gender', {
              required: false,
            })}
            required
            value='Femenino'
            type='radio'
            aria-label='radio 3'
            label='Doctora'
          />
        </Form.Group>
        <hr />
        <FloatingLabel controlId='floatingName' label='Nombre'>
          <Form.Control
            {...register('name', {
              required: false,
            })}
            placeholder='Nombre'
            value={userData?.data.name}
            defaultValue={userData?.data.name}
          />
        </FloatingLabel>
        {/* {errors.name ? <p>{errors.name.message}</p> : null} */}
        <FloatingLabel controlId='floatingSecondName' label='Apellido paterno'>
          <Form.Control
            {...register('fathersLastname', {
              required: false,
            })}
            placeholder='Apellido Paterno'
            value={userData?.data.fathersLastname}
            defaultValue={userData?.data.fathersLastname}
          />
        </FloatingLabel>
        <FloatingLabel controlId='floatingLastName' label='Apellido materno'>
          <Form.Control
            {...register('mothersLastname', {
              required: false,
            })}
            placeholder='Apellido Materno'
            value={userData?.data.mothersLastname}
            defaultValue={userData?.data.mothersLastname}
          />
        </FloatingLabel>

        <StyledPhoneInput
          {...register('phone', {
            required: false,
            min: 10,
            maxLength: 16,
          })}
          international
          type='tel'
          defaultCountry='MX'
          placeholder='Teléfono celular'
          id='phoneNumber'
          value={userData?.data.phone !== '' ? userData?.data.phone : phone}
          onChange={setPhone}
          // disabled={userData?.data.email !== ""}
        />
        <FloatingLabel
          controlId='floatingInput'
          label='Email address'
          className='mb-3'
        >
          <Form.Control
            {...register('email', {
              required: false,
            })}
            type='email'
            placeholder={'name@example.com'}
            // disabled={userData?.data.email !== ""}
            value={userData?.data.email !== '' ? userData?.data.email : null}
          />
        </FloatingLabel>

        <RegularP>Tus datos profesionales</RegularP>
        <StyledDiv>
          <RegularP>
            Los datos serán verificados para que puedas crear la orden de
            estudios de tus pacientes.
          </RegularP>
        </StyledDiv>
        <FloatingLabel controlId='floatingCedula' label='Cédula profesional'>
          <Form.Control
            {...register('professionalLicense', {
              required: true,
            })}
            placeholder='Cédula profesional'
          />
        </FloatingLabel>
        <FloatingLabel
          controlId='floatingEspecialidad'
          label='Especialidad (opcional)'
        >
          <StyledSelect
            {...register('speciality', {})}
            defaultValue='Especialidad'
            style={{ paddingTop: '10px' }}
          >
            <option disabled>Especialidad</option>
            {especialidades.map((especialidad) => (
              <option value={especialidad.value}>{especialidad.value}</option>
            ))}
          </StyledSelect>
        </FloatingLabel>
        <FloatingLabel
          controlId='floatingCedulaEspecialidad'
          label='Cédula de especialidad (opcional)'
        >
          <Form.Control
            {...register('specialtyLicense', {})}
            placeholder='Cédula de especialidad (opcional)'
          />
        </FloatingLabel>
        <FloatingLabel
          controlId='floatingNoConsejo'
          label='Número de consejo (opcional)'
        >
          <Form.Control
            {...register('councilNumber', {})}
            placeholder='Número de consejo (opcional)'
          />
        </FloatingLabel>
        <br />
        <RegularP>
          &nbsp;Sube tu logo para tu hoja médica &nbsp;{' '}
          <UilQuestionCircle
            style={{ color: '#00AD90', cursor: 'pointer' }}
            onClick={forceShow}
          />
        </RegularP>
        <MedicalSheetModal
          show={showMedicSheet}
          onHide={() => {
            setShowMedicSheet(false);
          }}
        />
        {picture !== '' ? (
          <UploadImage style={{ cursor: 'auto' }}>
            <img
              src={picture}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              alt='Your logo'
            />
          </UploadImage>
        ) : (
          <UploadImage onClick={handleInputFile}>
            <input
              type='file'
              hidden
              id='inputFile'
              onChange={handleFileChange}
            />
            <UilCameraPlus size={40} color='#00AD90' />
          </UploadImage>
        )}

        <Row className='justify-content-center'>
          <ContinueButton
            variant='primary'
            type='submit'
            disabled={(personalData.name = '')}
          >
            Continuar
          </ContinueButton>
        </Row>
      </DataForm>
    </StyledForDesktop>
  );
};
