import React, { useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import whiteLogo from "../../assets/logos/labopat.png";
import logo from "../../assets/footer/logo.png";
import axiona from "../../assets/logos/axiona.png";
import heilab from "../../assets/logos/heilab.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { addMember, getLists } from "../../mailchimp/maichimp";
import {
  UilFacebook,
  UilFacebookF,
  UilInstagram,
  UilWhatsapp,
} from "@iconscout/react-unicons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";

const LogosContainer = styled(Row)`
  justify-content: space-between;
  #labopat {
    max-width: 110px;
    width: 100%;
    height: auto;
  }
  #axiona2 {
    max-width: 81px;
    width: 100%;
    height: auto;
  }
  #heilab2 {
    max-width: 75px;
    width: 100%;
    height: auto;
  }
`;

const StyledHr = styled.hr`
  border: 0px solid #ffffff;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  height: 0;
  color: #ffffff;
  opacity: 1;
`;

const TextDescription = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  max-width: 255px;
`;

const Header = styled.a`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  width: auto;
  :hover {
    color: #ffffff;
  }
`;

const LineVitalgeneText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  width: auto;
`;

const BottomText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  > a {
    font-family: "Assistant", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
`;

const WhiteSection = styled.div`
  //background: linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 100%);
  padding: 62px 0 0px;
  font-family: "IBM Plex Sans";
  // ---------------- FIRST COL ----------------
  #lb-logo {
    width: 80%;
    max-width: 276px;
    margin: 0 0 23px 15px;
  }
  #iso {
    width: 75%;
    max-width: 264px;
    margin: 0 0 15px 0px;
  }
  #nosotros {
    text-align: start;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #ffffff;
    max-width: 280px;
    a {
      color: #474a4b;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  // ---------------- SECOND COL ----------------
  #linea-lb {
    width: 90%;
    max-width: 277px;
    margin-bottom: 32px;
  }
  ul {
    text-align: start;
    list-style: none;
    padding-left: 5px;
    li {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 10px;
    }
    a {
      color: #ffffff;
    }
  }
  // ---------------- THIRD COL ----------------
  #social-logos {
    width: 100%;
    max-width: 246px;
    display: flex;
  }
  hr {
    color: #ffffff;
    opacity: 1;
  }
  #sub {
    color: #ffffff;
    text-align: start;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
    width: 100%;
    max-width: 351px;
    span {
      color: #ffffff;
      font-weight: 700;
    }
  }
`;

const SubscribeForm = styled.form`
  margin-top: 30px;
  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 5px;
    font-family: "IBM Plex Sans";
    height: 35px;
    padding: 2px 10px;
  }

  .form-floating > .form-control:focus {
    padding-top: 0.5em;
  }

  .form-floating > label {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #000;
    border-radius: 12px;
    transform-origin: 50px -70px;
    background: #f6f6f6;
    padding: 2px 10px;
    opacity: 1;
  }

  .form-floating > .form-control {
    padding-left: 10px;
    padding-top: 0.5em;
  }
`;

const BottomSection = styled(Container)`
  padding: 31px 16px 28px;
  #logos {
    div {
      width: 100%;
      max-width: 372px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
  #axiona {
    width: 112px;
  }
  #vitalgene {
    width: 116px;
  }
  #heilab {
    width: 80px;
  }
  p {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #ffffff;
    text-align: initial;
  }
  @media only screen and (max-width: 992px) {
    #logos div {
      margin: 0 auto 16px;
    }
    padding: 31px 26px 41px;
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 11px;
    p {
      max-width: 246px;
      margin: 0 auto 30px;
    }
  }
`;

const GreenButton = styled.button`
  width: 100%;
  max-width: 145px;
  height: 35px;
  background: #90cb15;
  border-radius: 10px;
  border: none;
  color: #fff;
`;

export const Footer = (props) => {
  const number = "525615077271";
  const { width } = useWindowDimensions();
  const [newsletter, setNewsletter] = useState(true);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const location = useLocation();
  let isMobile = width <= 767;

  const onSubmit = async (data) => {
    if (newsletter) {
      console.log(data.email);
      await addMember(data.email).then([window.location.replace("/")]);
    }
  };

  return (
    <>
      {props.onDashboard === 1 ? (
        <div style={{ background: "#202020" }}>
          <Container className="pt-4 pb-4">
            <Row className="mb-4">
              <Col xs={12} sm={12} md={4}>
                <Row className="mb-4">
                  <TextDescription>
                    Somos una empresa digital dedicada a mejorar la calidad de
                    vida de las personas a través de pruebas genéticas para
                    nutrición personalizada con tecnología de última generación
                    y herramientas educativas para tomar decisiones más
                    informadas y conscientes sobre la salud.
                  </TextDescription>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Row className="mb-4">
                  <Header
                    href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FVG_Terminos_y_Condiciones_del_servicio.pdf?alt=media&token=bc0ecc4b-2590-4293-86d6-4442098c0d81"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Términos y condiciones
                  </Header>
                </Row>
                {isMobile && (
                  <>
                    <Row className="mb-4">
                      <Header
                        href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FAviso%20privacidad%20Pacientes%20(3).pdf?alt=media&token=b8cbab95-a094-4537-addc-400c55c1245e"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Aviso de privacidad - Pacientes
                      </Header>
                    </Row>
                    <Row className="mb-4">
                      <Header
                        href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FVG_avisoprivacidad_ALIADOS%20ESTRATEGICOS%20(1)%20(1).pdf?alt=media&token=3ae0b88b-f573-468c-a9cd-6fd29f504427"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Aviso de privacidad - Aliados
                      </Header>
                    </Row>
                  </>
                )}
                <Row className="mb-4">
                  <UilWhatsapp
                    src={36}
                    color={"#FFFFFF"}
                    className="w-auto ps-0"
                  />
                  <a
                    href={`https://wa.me/${number}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto p-0"
                  >
                    <LineVitalgeneText>
                      Línea Vitalgene
                      <br />
                      +52 561 507 72 71
                    </LineVitalgeneText>
                  </a>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={4}>
                {!isMobile && (
                  <>
                    <Row className="mb-4">
                      <Header
                        href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FAviso%20privacidad%20Pacientes%20(3).pdf?alt=media&token=b8cbab95-a094-4537-addc-400c55c1245e"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Aviso de privacidad - Pacientes
                      </Header>
                    </Row>
                    <Row className="mb-4">
                      <Header
                        href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FVG_avisoprivacidad_ALIADOS%20ESTRATEGICOS%20(1)%20(1).pdf?alt=media&token=3ae0b88b-f573-468c-a9cd-6fd29f504427"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Aviso de privacidad - Aliados
                      </Header>
                    </Row>
                  </>
                )}
                <LogosContainer>
                  <a
                    href="https://www.labopat.mx/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto mb-3 ps-0"
                  >
                    <img
                      src={whiteLogo}
                      alt="Labopat Logo"
                      id="labopat"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    href="https://www.axionagenetics.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto mb-2"
                  >
                    <img src={axiona} alt="Axiona Logo" id="axiona2" />
                  </a>
                  <a
                    href="https://www.heilab.com.mx/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto pe-0"
                  >
                    <img
                      src={heilab}
                      alt="Heilab Logo"
                      id="heilab2"
                      className="img-fluid"
                    />
                  </a>
                </LogosContainer>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <StyledHr className="mb-4" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <Row className="mb-4">
                  <a
                    href="https://www.facebook.com/VitalGeneNutrigenetics"
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto"
                  >
                    <UilFacebookF
                      size={20}
                      color={"#FFFFFF"}
                      className="w-auto"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/vitalgene"
                    target="_blank"
                    rel="noreferrer"
                    className="w-auto"
                  >
                    <UilInstagram
                      size={20}
                      color={"#FFFFFF"}
                      className="w-auto"
                    />
                  </a>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Row>
                  <BottomText>
                    ®2024 Vitalgene - Todos los derechos reservados
                  </BottomText>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Row>
                  <BottomText>
                    Sitio web creado por: Heilab!{" "}
                    <a target="_blank" href="https://www.heilab.com.mx/">
                      www.heilab.com.mx
                    </a>
                  </BottomText>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          style={
            location.pathname === "/estudio/166"
              ? {
                  background:
                    "linear-gradient(93.03deg, #FF8E3D 25.11%, #DC2659 96.98%)",
                }
              : location.pathname === "/gluten/estudio/166"
              ? {
                  background:
                    "linear-gradient(93.03deg, #FF8E3D 25.11%, #DC2659 96.98%)",
                }
              : location.pathname === "/vg70/estudio/598"
              ? {
                  background:
                    "linear-gradient(97.75deg, #D44DAD -1.83%, #24CAD3 104.81%)",
                }
              : location.pathname === "/aliadosvitales"
              ? {
                  background:
                    "linear-gradient(180deg, #4528D0 0%, #E36F44 100%)",
                }
              : location.pathname === "/estudio/519"
              ? {
                  background:
                    "linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 151.21%)",
                }
              : location.pathname === "/lactosa/estudio/590"
              ? {
                  background:
                    "linear-gradient(93.03deg, #008108 1.16%, #009899 96.98%)",
                }
              : location.pathname === "/cafeina/estudio/974"
              ? {
                  background:
                    "linear-gradient(82.56deg, #542F71 0%, #FF5C6D 102.07%)",
                }
              : props.onDashboard
              ? {
                  background:
                    "linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 151.21%)",
                }
              : {
                  background:
                    "linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 151.21%)",
                }
          }
          id="footer"
        >
          <WhiteSection>
            <Container>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  className="pt-3 d-flex flex-column justify-content-start mb-5"
                >
                  <img src={logo} alt="Logo Vitalgene" id="lb-logo" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={7}>
                  <Row>
                    {/* ISO and abstract */}
                    <Col xs={12} md={12} lg={6}>
                      <p id="nosotros">
                        Somos una empresa digital dedicada a mejorar la calidad
                        de vida de las personas a través de pruebas genéticas
                        para nutrición personalizada con tecnología de última
                        generación y herramientas educativas para tomar
                        decisiones más informadas y conscientes sobre la salud.
                      </p>
                    </Col>
                    {/* Phone and links */}
                    <Col xs={12} md={6} lg={5}>
                      <a
                        href={`https://wa.me/${number}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="Linea Vitalgene"
                          id="linea-lb"
                          src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ffooter%2FLINEA-LABO.png?alt=media&token=066cef5a-057e-499c-831a-ff3e2fd8b8af"
                        />
                      </a>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FVG_Terminos_y_Condiciones_del_servicio.pdf?alt=media&token=bc0ecc4b-2590-4293-86d6-4442098c0d81"
                          >
                            Términos y condiciones
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FAviso%20privacidad%20Pacientes%20(3).pdf?alt=media&token=b8cbab95-a094-4537-addc-400c55c1245e"
                          >
                            Aviso de privacidad - Pacientes
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FVG_avisoprivacidad_ALIADOS%20ESTRATEGICOS%20(1)%20(1).pdf?alt=media&token=3ae0b88b-f573-468c-a9cd-6fd29f504427"
                          >
                            Aviso de privacidad - Aliados
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/pdf%2FDerechos%20ARCO%20VG%20(1).pdf?alt=media&token=788c5145-a0e4-48ec-943e-1d07aa1917c3"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Derechos ARCO
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                {/* Social networks and subscribe */}
                <Col xs={12} lg={5}>
                  <div id="social-logos">
                    <a
                      href="https://www.facebook.com/VitalGeneNutrigenetics"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingLeft: "10px" }}
                    >
                      <UilFacebook size="26" color="#ffffff" />
                    </a>
                    <a
                      href="https://www.instagram.com/vitalgene/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingLeft: "20px" }}
                    >
                      <UilInstagram size="26" color="#ffffff" />
                    </a>
                  </div>
                  <hr
                    style={
                      location.pathname === "/estudio/166"
                        ? { background: "#90CB15" }
                        : location.pathname === "/gluten/estudio/166"
                        ? { background: "#90CB15" }
                        : location.pathname === "/vg70/estudio/598"
                        ? { background: "#90CB15" }
                        : location.pathname === "/estudio/519"
                        ? { background: "#90CB15" }
                        : location.pathname === "/lactosa/estudio/590"
                        ? { background: "#90CB15" }
                        : location.pathname === "/aliadosvitales"
                        ? { background: "#90CB15" }
                        : location.pathname === "/cafeina/estudio/974"
                        ? { background: "#90CB15" }
                        : location.pathname === "/"
                        ? { background: "#fff" }
                        : null
                    }
                  />
                  <p id="sub">
                    <span>Suscríbete</span> a nuestra Newsletter.
                    <br />
                    Tenemos información de valor, interesante y lo mejor de
                    todo, ¡es gratis!
                  </p>
                  {/* Subscribe form */}
                  <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col xs={7} style={{ paddingLeft: "0" }}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Correo electrónico"
                          className="mb-3"
                        >
                          <Form.Control
                            {...register("email", {
                              required: true,
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                                message: "Correo inválido",
                              },
                            })}
                            key="newsletter"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="name@example.com"
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={5}>
                        <GreenButton onClick={onSubmit} type="submit">
                          Suscribirme
                        </GreenButton>
                      </Col>
                    </Row>
                  </SubscribeForm>
                </Col>
              </Row>
            </Container>

            <BottomSection>
              <Row>
                {/* Logos */}
                <Col
                  xs={{ span: 12, order: 1 }}
                  lg={{ span: 5, offset: 7, order: 1 }}
                  id="logos"
                >
                  <div>
                    <a
                      href="https://www.labopat.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={whiteLogo} alt="Labopat" id="vitalgene" />
                    </a>
                    <a
                      href="https://www.axionagenetics.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={axiona} alt="axiona" id="axiona" />
                    </a>
                    <a
                      href="https://www.heilab.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={heilab} alt="heilab" id="heilab" />
                    </a>
                  </div>
                </Col>
                {/* License and rights */}
                <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 3 }}>
                  <p>Sitio web creado por: Heilab! www.heilab.com.mx</p>
                </Col>
                <Col xs={{ span: 12, order: 3 }} lg={{ span: 7, order: 2 }}>
                  <p>© 2024 Vitalgene - Todos los derechos reservados</p>
                </Col>
              </Row>
            </BottomSection>
          </WhiteSection>
        </div>
      )}
    </>
  );
};
