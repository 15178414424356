import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { SearchItem } from './searchItem';
import { UilMultiply } from '@iconscout/react-unicons';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SearchContainer = styled.div`
  background-color: white;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
  width: 660px;
  margin-left: auto;
  height: auto;
  margin-right: 4rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const WatchAllResults = styled.a`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #00ad90;
  bottom: 25px;
  height: 44px;
  margin-top: 28px;
  border: 2px solid #00ad90;
  padding: 10px 40px;
  border-radius: 22px;
  cursor: pointer;
  :hover {
    color: #fff;
    background: #00ad90;
  }
`;

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px',
  // border: '1px solid red',
};

const Title = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #000000;
  margin-bottom: 40px;
`;

const Column = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
`;

const Subt = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #8acca4;
  text-align: left;
`;

const closeStyle = {
  display: 'flex',
  margin: '0 0 -10px 80%',
  cursor: 'pointer',
};

export const Searcher = (props) => {
  const modalSearch = document.getElementById('modalSearch');
  const [items, setItems] = useState([]);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(`https://lpqsystem.com/searching-products?search=${props.search}`)
      .then((res) => {
        res.data.productos.map((item) => {
          Object.assign(item, {
            title: item.name,
            price: item.precio_promo,
            oldPrice: item.precio_normal,
          });
        });
        setItems(res.data.productos);
      });
  }, [props.search]);

  const handleCloseModalSearch = () => {
    modalSearch.style.display = 'none';
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 768;

  return (
    <SearchContainer className='keep-scrolling'>
      <Container className='mt-2 mb-3'>
        <Row>
          <Col xs={{ span: 2, offset: 10 }} md={{ span: 1, offset: 11 }}>
            <UilMultiply
              color='#00AD90'
              style={closeStyle}
              onClick={handleCloseModalSearch}
            />
          </Col>
        </Row>
        <Row
          style={{
            borderBottom:
              items && items.length > 0 ? '0.5px solid #8ACCA4' : '',
          }}
        >
          {props.search.length === 0 ? (
            <Col xs={12}>
              <Title>No hay nada que buscar</Title>
            </Col>
          ) : (
            items &&
            items.length > 0 && (
              <>
                <Column md={5} sm={6} xs={6} id='titles'>
                  <Subt style={{ color: '#00AD90' }}>
                    Sugerencias de la búsqueda
                  </Subt>
                </Column>
                {isMobile ? (
                  <Column xs={5} style={{ textAlign: 'center' }}>
                    <Subt>En línea</Subt>
                    <br />
                    <Subt>Sucursal</Subt>
                  </Column>
                ) : (
                  <>
                    <Column
                      md={3}
                      sm={6}
                      xs={6}
                      style={{ textAlign: 'center' }}
                    >
                      <Subt>En línea</Subt>
                    </Column>
                    <Column
                      md={3}
                      sm={6}
                      xs={6}
                      style={{ textAlign: 'center' }}
                    >
                      <Subt>Sucursal</Subt>
                    </Column>
                  </>
                )}
              </>
            )
          )}
        </Row>
        {props.search.length > 0
          ? items &&
            items.map((study, i) =>
              i < 5 ? (
                <SearchItem
                  handleClic={() => handleCloseModalSearch()}
                  key={i}
                  data={study}
                />
              ) : null
            )
          : null}
        {props.search.length > 0 &&
          (items && items.length > 0 ? (
            <div style={centerStyle}>
              <WatchAllResults href={`/buscador?search=${props.search}`}>
                Ver los {items.length} resultados
              </WatchAllResults>
            </div>
          ) : (
            <div style={centerStyle}>
              <p style={{ color: '#00AD90', fontSize: '22px' }}>
                <br />
                No hay resultados disponibles
              </p>
            </div>
          ))}
      </Container>
    </SearchContainer>
  );
};
