import React from "react";
import ReactApexChart from "react-apexcharts";

class CanvasP extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      series: [
        {
          name: "VG70",
          data: [props?.Vg70D,props?.Vg70E,props?.Vg70F,0,0],
          tension: 0.1,
        },
        {
          name: "Gluten",
          data: [props?.GlutenD,props?.GlutenE,props?.GlutenF,0,0],
          tension: 0.1
        },
        {
          name: "Lactosa",
          data: [props?.LactosaD,props?.LactosaE,props?.LactosaF,0,0],
          tension: 0.1
        },
        {
          name: "Cafeina",
          data: [props?.CafeinaD,props?.CafeinaE,props?.CafeinaF,0,0],
          tension: 0.1,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["rgba(212, 77, 173, 1)", "rgba(228, 157, 91, 1)", "rgba(160, 226, 24, 1)", "rgba(131, 85, 183, 1)"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: 'Pruebas',
          align: 'left'
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Diciembre","Enero","Febrero","Marzo","Abril"],
        },
        yaxis: {
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  }
  

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={230}
        />
      </div>
    );
  }
}
export default CanvasP;
