import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useSelector } from "react-redux";
import { Loader } from "../general/spinner";
import { OrderForm } from "./orderForm";
import { useForm } from "react-hook-form";

const StyledForDesktop = styled.div`
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1400px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

const NormalText = styled.p`
  font-family: "Assistant", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
`;
const StyledHr = styled.hr`
  padding: 0.5px 0 0.5px 0;
  background-color: rgba(2, 161, 181, 0.34);
  margin: 0;
`;
const StyledCheck = styled(Form.Check)`
  padding-left: 1.5em;
  display: block;
  .form-check-label {
    width: auto;
  }
  .form-check-input[type="checkbox"] {
    margin-left: -1.5em;
    float: left;
  }
  .form-check-input[type="checkbox"],
  .form-check-input[type="radio"] {
    margin-left: -1.5em;
  }
  label {
    padding-left: 1%;
  }
  > input {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #02a1b5;
    :checked {
      border: 1px solid #02a1b5;
      background-color: #02a1b5;
    }
  }
`;
const ContinueButton = styled(Button)`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: rgba(2, 161, 181, 0.81);
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: rgba(0, 139, 157, 0.81);
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const AddCouponText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  text-decoration: underline;
  color: #02a1b5;
  margin: 0;
  padding: 0;
`;
const StyledFloating = styled(FloatingLabel)`
  width: 100%;
  padding: 0;
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: "Assistant", sans-serif;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 200ms;
  }
  :active,
  :focus-within {
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 5px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;
const StyledInput = styled(Form.Control)`
  width: 100%;
  background: #ffffff;
  border: ${(props) =>
    props.error === 1 ? "1px solid #FF0505" : "1px solid #E6E6E6"};
  border-radius: 12px;
  max-height: 45px;
  height: 100%;
  padding: 0px 40px 0px 40px !important;
  z-index: 1;
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) =>
      props.error === 1 ? "1px solid #FF0505" : "1px solid #cccccc"};
    border-style: dashed;
    color: ${(props) => (props.disabled === 1 ? "#a6a6a6;" : "#000000")};
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? "#FF0505" : "#77CCD5")};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  ::placeholder {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;
const ApplyCuponBtn = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: "Assistant";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;
export const StepTwo = (props) => {
  const [items, setItems] = useState(props.items);
  const user = useSelector((state) => state.user?.data?.data);
  const [state, setState] = useState({ ...props.personalData, cp: "" });
  const [policies, setPolicies] = useState(false);
  const [viewCupon, setViewCupon] = useState(false);
  const [cupon, setCupon] = useState("");
  const [cuponApplied, setCuponApplied] = useState(false);
  const [cuponMessage, setCuponMessage] = useState("");
  const uid = useSelector((state) => state.auth.uid);
  var today = new Date(Date.now());
  if (today.getDay() === 0) {
    today.setDate(today.getDate());
  }
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      phone: "",
      shipping: "0",
      billing: {
        rfc: "",
        name: "",
        email: "",
        regimen: "",
        cp: "",
        state: "",
        city: "",
        colonia: "",
        street: "",
        usoFactura: "",
        comments: "",
      },
    },
  });

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleForm = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log(state);
  };

  const applyCupon = async () => {
    var formData = new FormData();
    formData.append("uid", uid);
    formData.append("cupon", cupon);
    // console.log(formData.get('cupon'));
    items.forEach((element) => {
      element.estudios.forEach((elem) => {
        formData.append("products[]", element.id);
        formData.append("studiesPrice[]", element.oldPrice);
        formData.append("studies[]", elem.id);
      });
    });
    let response = await axios.post(
      "https://lpqsystem.com/apply-cupon",
      formData
    );
    // console.log(response.data);
    if (response.data.ok && response.data.tipo !== -1) {
      setCuponApplied(true);
      if (response.data.tipo === 0) {
        setCuponMessage("Descuento de martes aplicado correctamente");
      } else if (response.data.tipo === 1) {
        setCuponMessage(
          "Descuento aplicado correctamente, canjeaste un cupón gratuito"
        );
      } else if (response.data.tipo === 2) {
        setCuponMessage(
          `Descuento aplicado correctamente, tienes disponible un saldo de ${response.data.saldoDisponible} en tu tarjeta de regalo`
        );
      } else if (response.data.tipo === 3) {
        setCuponMessage("Descuento aplicado correctamente");
      }
      props.applyCupon(cupon, response.data.paquetes, response.data.free);
    } else {
      setCuponApplied(false);
      setCuponMessage(
        "Hubo un error al canjear tu cupón. (Si persiste el error comunícate a la línea Labopat)"
      );
    }
  };

  useEffect(() => {
    if (
      props.discount !== 0 &&
      !cuponMessage.includes("Obvtuviste un descuento")
    ) {
      setCuponMessage(
        `${cuponMessage}. Obvtuviste un descuento de $ ${props.discount.toFixed(2)}`
      );
    }
  }, [props.discount, props.subTotalWeb, cuponMessage]);

  useEffect(() => {
    // console.log(props.items);
  }, [props.items]);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const onSubmit = (data, event) => {
    setState((prev) => ({
      ...prev,
      ...data,
    }));
    props.onStepTwo({
      personalData: data,
    });
    // props.continueClick();
  };

  return (
    <Container className="pt-5 pb-5">
      <StyledForDesktop style={screenWidth <= 768 ? { maxWidth: "100%" } : {}}>
        {loading ? <Loader show={loading} /> : null}
        {/* <Title>Resumen del pedido</Title> */}
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={9} xl={8}>
            <Row>
              <p
                style={{
                  fontSize: "12px",
                  color: "#A6A6A6",
                  fontWeight: "500",
                  padding: 0,
                }}
              >
                Resumen del pedido:
              </p>
            </Row>
            <table className="w-100">
              <tbody>
                {items.map((item, id) => (
                  <tr key={id}>
                    <th>
                      <p
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          padding: 0,
                          fontWeight: "400",
                        }}
                        className="mb-2"
                      >
                        {id + 1}.&nbsp; {item.title} {`(${item.howMany})`}
                      </p>
                    </th>
                    <th
                      className="ps-2"
                      style={{ textAlign: "right", verticalAlign: "middle" }}
                    >
                      <p
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          padding: 0,
                        }}
                        className="mb-2"
                      >
                        <b>
                          {cuponApplied
                            ? currency(item.price.toFixed(2))
                            : currency(item.price.toFixed(2))}{" "}
                          MXN
                        </b>
                      </p>
                    </th>
                  </tr>
                ))}
            
              </tbody>
            </table>
            <Row className="mb-4 justify-content-center">
              <p
                style={{ marginTop: "5px", marginBottom: "5px", padding: 0 }}
                className="w-auto"
              >
                Total: <b>{currency(props.subTotalWeb)} MXN</b>
              </p>
            </Row>
            {!viewCupon && (
              <Row className="mb-4 pb-2 justify-content-center">
                <AddCouponText
                  className="w-auto"
                  role={"button"}
                  onClick={() => setViewCupon(true)}
                >
                  Agregar cupón
                </AddCouponText>
              </Row>
            )}
            {viewCupon && (
              <>
                <Row className="mb-4 justify-content-center">
                  <Col xs={12} sm={12} md={6} className="p-0">
                    <StyledFloating
                      controlId="cupon"
                      label="Ingresa el cupón aquí"
                    >
                      <StyledInput
                        name="cupon"
                        type="text"
                        placeholder="Ingresa el cupón aquí"
                        value={cupon}
                        onChange={(e) => {
                          setCupon(e.target.value);
                        }}
                      />
                    </StyledFloating>
                  </Col>
                </Row>
                <Row className="mb-4 justify-content-center">
                  <ApplyCuponBtn
                    className="pt-2 pb-2"
                    disabled={cuponApplied || cupon.length === 0}
                    onClick={() => {
                      applyCupon();
                    }}
                  >
                    Aplicar cupón
                  </ApplyCuponBtn>
                </Row>
                <Row className="justify-content-center">
                  <p className="w-auto p-0 m-0">{cuponMessage}</p>
                </Row>
              </>
            )}
            <Row className="mb-4">
              <StyledHr />
            </Row>
          </Col>
        </Row>
        <Row>
          <form className="p-0" onSubmit={handleSubmit(onSubmit)}>
            <OrderForm
              register={register}
              user={user}
              personalData={props.personalData}
              setPersonalData={props.setPersonalData}
              state={state}
              setState={setState}
              handleForm={handleForm}
              handleSubmit={handleSubmit}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              continueClick={props.continueClick}
              subTotalWeb={props.subTotalWeb}
              setSubTotalWeb={props.setSubTotalWeb}
              setIsExpress={props.setIsExpress}
              items={props.items}
            />
            <Row className="justify-content-center">
              <Col xs={12} sm={12} md={11} lg={9} xl={8}>
                <Row className="mb-4" onChange={() => setPolicies(!policies)}>
                  <StyledCheck type="checkbox" name="policy">
                    <StyledCheck.Input type="checkbox" />
                    <StyledCheck.Label>
                      <NormalText>
                        *He leído y acepto las políticas de privacidad y
                        consentimiento informado (Para conocerlas dar click
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Freportes%2FAviso%20privacidad%20Pacientes%20(2).pdf?alt=media&token=bdd846bb-aa91-4db7-8a5b-03c848044522"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          aquí
                        </a>
                        ).
                      </NormalText>
                    </StyledCheck.Label>
                  </StyledCheck>
                </Row>
                <Row className="justify-content-center pb-4">
                  <ContinueButton
                    type="submit"
                    variant="primary"
                    disabled={!policies}
                  >
                    Continuar
                  </ContinueButton>
                </Row>
              </Col>
            </Row>
          </form>
        </Row>
      </StyledForDesktop>
    </Container>
  );
};