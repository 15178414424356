import { Container, Row, Col } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import {
  UilUser,
  UilClipboardNotes,
  UilCheckSquare,
  UilX,
} from "@iconscout/react-unicons";
import Form from "react-bootstrap/Form";

const HistorySection = styled.div`
  width: 22vw;
  height: 23.6vh;
  @media only screen and (max-width: 1366px) {
    height: 24vh;
  }
  @media only screen and (max-width: 576px) {
    margin-left: 0px;
    width: 300px;
  }
`;

const StyledHr = styled.hr`
  background-color: #77ccd5;
  height: 2px !important;
  width: 180px;
  margin: 10px;
  @media only screen and (max-width: 1366px) {
    width: 150px;
    margin: 0px;
  }
`;

const GrayText = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  //color: #8f8f8f;
  margin-bottom: 0;
  padding: 0;
  @media only screen and (max-width: 1366px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    font-size: 13px;
  }
`;

const StatusText = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d1d;
  margin-bottom: 0;
  padding: 0 0 0 8px;
  width: auto;
`;

const EmptyText = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: #121619;
  margin-bottom: 0;
  padding: 0;
  text-align: start;
  @media only screen and (max-width: 1366px) {
    text-align: start;
    font-size: 13px;
  }
`;

const EmptyIconContainer = styled.div`
  background-color: #f6f6f6;
  max-width: 140px;
  max-height: 140px;
  width: 140px;
  height: 140px;
  margin-right: 140px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1366px) {
    margin-right: 70px;
  }
`;

const Bullet = styled.p`
  font-size: 24px;
  color: ${(props) => props.color};
  width: auto;
  padding: 0;
  margin-bottom: 0;
  user-select: none;
`;

export const HistoryMed = () => {
  const statusList = [
    {
      name: "Mostrar todas las pruebas",
      color: "",
      code: 0,
    },
    {
      name: "Preparando kit",
      color: "#77CCD5",
      code: 10,
    },
    {
      name: "Kit enviado",
      color: "#71C388",
      code: 11,
    },
    {
      name: "Activado",
      color: "#E49D5B",
      code: 12,
    },
    {
      name: "Pendiente de recolección",
      color: "#71C388",
      code: 13,
    },
    {
      name: "Muestra recibida",
      color: "#E49D5B",
      code: 14,
    },
    {
      name: "Procesamiento de la prueba",
      color: "#E49D5B",
      code: 15,
    },
    {
      name: "Resultados listos",
      color: "#71C388",
      code: 16,
    },
  ];

  const { width } = useWindowDimensions();
  let isMobile = width <= 992;

  // Orders
  const uid = useSelector((state) => state?.auth?.uid);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState(0);

  const getTestHistoryByPhase = async (code) => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://clienteslabopat.com/user-sols-vg?uid=${uid}${
            code === undefined ? "" : `&fase=${code}`
          }`
        )
        .then((response) => {
          let orderByDate = response?.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setTests(orderByDate);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTestHistoryByPhase(phase);
  }, [phase]);

  const TestCard = (props) => {
    return (
      <Row>
        <Col
          xs={12}
          sm={12}
          md={12}
          style={{ marginBottom: "0px", padding: "0" }}
        >
          <Row className="p-0 align-items-start">
            <div
              className="pe-0 ps-0 d-flex flex-row align-items-center"
              style={{ maxWidth: "20px", paddingTop: "1px" }}
            >
              <UilUser size={16} color={"#1AC7C7"} className="w-auto p-0" />
            </div>
            <div
              className="p-0 d-flex flex-row align-items-center scroll.scrollTo(200)"
              style={{ maxWidth: "75%" }}
            >
              <GrayText style={{ color: "#1AC7C7" }} className="w-auto">
                <p>{props?.test?.nombre}</p>
              </GrayText>
            </div>
          </Row>
          <Row className="p-0 align-items-start">
            <div
              className="p-0 d-flex flex-row align-items-center"
              style={{ maxWidth: "89%" }}
            >
              <GrayText style={{ color: "#8F8F8F" }} className="w-auto">
                {props?.test?.laminilla}
              </GrayText>
            </div>
            <div
              className="p-0 d-flex flex-row align-items-center"
              style={{ maxWidth: "89%" }}
            >
              {props.test.idPago !== null  ? (
                <GrayText style={{ color: "#8F8F8F" }}>
                  {" "}
                  <UilCheckSquare style={{ color: "green" }} />{" "}
                  {props?.test?.casoLB}
                </GrayText>
              ) : (
                <GrayText style={{ color: "#8F8F8F" }}>
                  {" "}
                  <UilX style={{ color: "red" }} />
                  No pagado
                </GrayText>
              )}
            </div>
            <Row className="align-items-center">
              <Bullet
                color={
                  statusList.find((item) => item.code === props?.test?.fase)
                    ?.color
                }
              >
                &bull;
              </Bullet>
              <StatusText>
                {
                  statusList.find((item) => item.code === props?.test?.fase)
                    ?.name
                }
              </StatusText>
            </Row>
          </Row>
        </Col>
        <StyledHr />
      </Row>
    );
  };

  return (
    <>
      <HistorySection className="mb-4">
        <Container>
          {tests.length === 0 ? (
            <Row className="justify-content-center">
              <EmptyText className="mb-4">
                Sin historial en este momento
              </EmptyText>
              <EmptyIconContainer>
                <UilClipboardNotes size={100} color={"#A6A6A6"} />
              </EmptyIconContainer>
            </Row>
          ) : (
            tests
              .slice(0, 2)
              .map((test, index) => <TestCard test={test} key={index} />)
          )}
        </Container>
      </HistorySection>
      {loading ? <Loader show={loading} /> : null}
    </>
  );
};
