import React, { useState } from 'react';
import { Table, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ShopLink = styled(Link)`
    color: #03aea2;
    text-decoration: underline;
`;

const Td = styled.td`
    font-weight: 800;
`;
const Th = styled.th`
    font-weight: 800;
`;

const TableShop = styled(Table)`
    border: 1px solid transparent;
    table-layout: auto;
    word-wrap: break-word;
`;

export const Shopping = (props) => {
    const [data, setData] = useState(props.shopping)
    return (
        <TableShop striped borderless hover responsive='sm'>
        <thead>
            <tr style={{borderTop: "solid 1px #DDD"}}>
                <Th>#</Th>
                <Th>Paciente</Th>
                <Th>Fecha de la toma</Th>
                <Th>Estudios solicitados</Th>
                <Th>Estatus</Th>
                <Th>Acciones</Th>
            </tr>
        </thead>
        <tbody>
            {data.map((shop)=>(
                <tr key={shop.id}>
                    <Td><ShopLink to="#">{shop.id}</ShopLink></Td>
                    <Td>{shop.name}</Td>
                    <Td>{shop.date}</Td>
                    <Td style={{width: "30%"}}>{shop.studies.map((study)=>(`${study}, `))}</Td>
                    <Td>{shop.status}</Td>
                    <Td>
                        <DropdownButton
                            as={ButtonGroup}
                            key= "actions"
                            id= "shop-actions"
                            variant= "secondary"
                            title= ""
                        >
                            <Dropdown.Item eventKey="1">Descargar comprobante</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Descargar consentimiento informado</Dropdown.Item>
                        </DropdownButton>
                    </Td>
                </tr>
            ))}
        </tbody>
        </TableShop>
    );
}




