import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { UilPlayCircle } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { handleAddItemToCart } from '../../../../actions/shoppingCart';
import { toastSweet } from '../../../../helpers/sweetAlert2';

const MainDiv = styled.div`
  width: 100%;
  height: 460px;
  position: relative;
  overflow: hidden;background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FbannerCafeina.png?alt=media&token=a7b95962-0569-4184-b80b-fc66f572a195") center center/cover no-repeat;
  
  @media only screen and (max-width: 768px){
    background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FbannerCafeinaM.png?alt=media&token=74eeb08b-18bf-482e-a41b-30dee02e8a6f") center center/cover no-repeat;
    height: 400px;
  }
  #logo-transforma {
    margin-top: 36px;
    margin-bottom: 17px;
    width: 200px;
    @media only screen and (max-width: 992px){
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 20px;
      width: 200px;
    }
  }
  h1 {
    font-family: 'Assistant';
    font-weight: 800;
    font-size: 50px;
    line-height: 110%;
    color: #F2F7F9;
    @media only screen and (max-width: 768px){
      max-width: 233px;
      font-size: 32px;
      line-height: 34px;
      margin-left: 20px;
    }
  }
  p {
    font-family: 'Assistant';
    font-weight: 200;
    font-size: 22px;
    line-height: 120%;
    color: #FFFFFF;
    @media only screen and (max-width: 768px){
      display: none;
    }
  }
  #main-col {
    padding-left: 5px;
    padding-top: 15px;
  }
  #smiling {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 105%;
    max-width: 50%;
    @media only screen and (min-width: 1800px){
      right: 15%;
    }
    @media only screen and (min-width: 1400px) and (max-width: 1400px){
      right: 5%;
    }
    @media only screen and (max-width: 768px){
      right: -20%;
      max-width: 70%;
    }
  }
`;

const OrangeButton = styled.button`
  padding: 16px 30px;
  background: #24CAD3;
  border-radius: 102px;
  border: none;
  font-family: 'Assistant';
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
  font-weight: 800;
  margin-top: 6px;
  margin-bottom: 58px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #2fe3ed;
  }
  @media only screen and (max-width: 768px){
    margin-bottom: 31px;
  }
`;

const BlueWhiteText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0;
  padding: 8px;
  background: #24CAD3;
  height: 40px;
  @media only screen and (max-width: 768px){
    height: 30px;
    padding: 4px;
  }
`;

export const BannerCafeina = ({study, setShowCart}) => {
  const {width} = useWindowDimensions();
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  return (
    <>
      <MainDiv>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={9} lg={7} xl={6} id="main-col">
              <img id='logo-transforma' src='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/logos%2FlogoVitalgene.png?alt=media&token=811710ad-15a2-44f1-b9bf-b6016a11a4af' alt='programa-transforma'/>
              <h1>Prueba genética de intolerancia a la cafeína</h1>
              <p>¿Sabías que la cafeína está involucrada en el desarrollo de ansiedad e hipertensión?</p>
              <Row>
                <Col xs={12}>
                  <OrangeButton onClick={() => (window.location.href = '/cafeina/estudio/974')}>
                    Más información
                  </OrangeButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* <img id='smiling' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Ffeli.png?alt=media&token=7d5dcbc2-da9f-4adf-9c1e-2959cfedb5c0' alt='smile'/> */}
      </MainDiv>
      {/*<BlueWhiteText>Inicio: 17 de Abril 2023</BlueWhiteText>
      <Container>
        <div style={{width: "100%", height: "269px", left: "14px", top: "705px", background: "rgba(233, 233, 233, 0.8)", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "37px 0"}}>
          <UilPlayCircle color="#A6A6A6" size={55}/>
        </div>
      </Container>*/}
    </>
  );
};