import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilEar } from '@iconscout/react-unicons';
import {
  UilTrashAlt,
  UilTimes,
  UilPlus,
  UilMinus,
} from '@iconscout/react-unicons';
import { updateCartDB } from '../../helpers/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTotalPrice,
  setTotalPrice,
  removeItemFromCart,
  handleAddItemToCart,
} from '../../actions/shoppingCart';
import { useForm, Controller } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '@mui/material';
import { textAlign } from '@mui/system';
import { useEffect } from 'react';

const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494a;
  margin-bottom: 0px;
`;

const Price = styled.p`
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #45494a;
  margin-top: 7px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  line-height: 120%;
  color: #45494a;
  margin: 0;
  width: auto;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #77CCD5;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #77CCD5;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #77CCD5;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    background: #77CCD5;
  }
`;

const WhiteButton = styled(Button)`
  color: #77CCD5;
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
`;

const Column = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  text-align: left;
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
    opacity: 0;
    display: none;
  }
`;

const StyledHr = styled.hr`
  margin: -2px auto 3px auto;
`;

const StyledRow = styled(Row)`
  background-color: #f4f4f4;
  padding: 1.5rem;
  border-radius: 10px;
`;

const StyledSelect = styled.select`
  width: 60px;
  max-width: 60px;
  max-height: 22px;
  height: 100%;
  border-style: none;
  padding-left: 40%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative !important;
  ::-ms-expand {
    display: none;
  }
`;

const StyledExpand = styled.i`
  position: absolute;
  right: 15%;
  top: 28%;
  width: 6px;
  height: 6px;
  display: block;
  border-left: 1px solid #7a4dc7;
  border-bottom: 1px solid #7a4dc7;
  transform: rotate(-45deg);
  transition: all 0.25s ease;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #7a4dc7;
  border-radius: 3px;
  position: relative;
  max-width: 62px;
  text-align: center;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CheckboxIcon = styled.span`
  border-radius: 3px;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
  .Mui-focusVisible & {
    outline: '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
  }
  input:hover ~ & {
    background-color: #ebf1f5;
  }
  input:disabled ~ & {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
  }
`;

const CheckedIcon = styled(CheckboxIcon)`
  background-color: #7a4dc7;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0)
  );
  transition: 300ms;
  &:before {
    display: block;
    width: 16px;
    height: 16px;
    content: '';
  }
  input:hover ~ & {
    background-color: #9e78e1;
    transition: 300ms;
  }
`;

const StyledCheckbox = (props) => {
  return (
    <Checkbox
      style={{ paddingLeft: '0', paddingRight: '0' }}
      disableRipple
      checkedIcon={<CheckedIcon />}
      icon={<CheckboxIcon />}
      {...props}
    />
  );
};

export const ListItemCart = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [childs, setChilds] = useState(0);
  // const [storedChilds, setStoredChilds] = useState([]);

  let options = Array(props.item.howMany).fill(null);

  // Handle how many childs quantity
  const handleHowManyChilds = (studyId, value) => {
    localStorage.setItem(`childsFor-${studyId}`, value);
    setChilds(childs);
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    if (currentItem.howMany > 1) {
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      if (childs > newHowMany) {
        handleHowManyChilds(id, childs - 1);
        setChilds(childs - 1);
      }
    }

    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const handleDeleteItems = (id) => {
    setChilds(0);
    localStorage.removeItem(`childsFor-${id}`);
    localStorage.removeItem(`is-${id}-checked`);
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    if (currentItem.howMany > 1) {
      data = [...newCart];
    }

    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  // Increase quantity
  const handleIncreaseQuantity = (id) => {
    let currentItem = items.find((item) => item.id === id);
    dispatch(handleAddItemToCart(currentItem));
  };

  // Decrease quantity
  const handleDecreaseQuantity = (id) => {
    handleRemoveItemFromCart(id);
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const confirmDelente = () => {
    setShow(false);
    handleDeleteItems(props.item.id);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCheck = (event) => {
    if (event.target.checked) {
      localStorage.setItem(`is-${props.item.id}-checked`, event.target.checked);
      setChecked(event.target.checked);
    } else {
      localStorage.removeItem(`is-${props.item.id}-checked`);
      localStorage.removeItem(`childsFor-${props.item.id}`);
      setChecked(event.target.checked);
    }
  };

  useEffect(() => {
    let quantity = localStorage.getItem(`childsFor-${props.item.id}`);
    setChilds(Number(quantity));
    let isChecked =
      localStorage.getItem(`is-${props.item.id}-checked`) === 'true'
        ? true
        : false;
    setChecked(isChecked);
  }, [childs, props.item.howMany, checked]);

  return (
    <>
      <StyledHr />
      {/* {console.log(storedChilds)} */}
      <StyledRow className='row mb-2'>
        <Column xs={10} id='titles'>
          <Title>{props.item.title}</Title>
        </Column>
        <Column xs={2} className='d-flex justify-content-end mb-3'>
          <UilTrashAlt
            onClick={() => handleShow()}
            style={{ cursor: 'pointer', color: '#7A4DC7' }}
            size={25}
          />
        </Column>
        <hr
          style={{
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#C1D9D8',
            marginBottom: '0px',
            marginTop: '0px',
            border: '1px solid #C1D9D8',
          }}
        />
        {props.item.id !== 675 && props.item.id !== 966 &&
        <>
        <Column xs={12}>
          <Row>
            <Column
              xs={6}
              className='d-flex flex-column justify-content-center'
            >
              <Text style={{ fontWeight: '800' }}>Cantidad</Text>
            </Column>
            <Column
              xs={6}
              className='d-flex flex-column justify-content-center align-items-end'
            >
              <InputContainer>
                <UilMinus
                  onClick={() => {
                    props.item.howMany > 1 &&
                      handleDecreaseQuantity(props.item.id);
                  }}
                  size={18}
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '9%',
                  }}
                />
                <p className='m-0 quantity'>{props.item.howMany}</p>
                <UilPlus
                  onClick={() => {
                    props.item.howMany >= 1 &&
                      handleIncreaseQuantity(props.item.id);
                  }}
                  size={18}
                  style={{
                    position: 'absolute',
                    right: '0',
                    top: '9%',
                  }}
                />
              </InputContainer>
            </Column>
          </Row>
        </Column>
        <Column xs={12}>
          <Row>
            <Column
              xs={8}
              className='d-flex flex-column justify-content-center'
            >
              <Row>
                <Column xs={1}>
                  <StyledCheckbox
                    checked={checked}
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleHowManyChilds(props.item.id, 1);
                        setChilds(1);
                      } else if (childs > 0) {
                        localStorage.removeItem('howManyChilds');
                      }
                      handleCheck(event);
                    }}
                  />
                </Column>
                <Column
                  xs={11}
                  className='d-flex flex-column justify-content-center'
                >
                  <Text style={{ paddingLeft: '10px' }}>Solicito kit con vial para toma de muestra asistida </Text>
                </Column>
              </Row>
            </Column>
            {checked ? (
              <Column
                xs={4}
                className='d-flex flex-column justify-content-center align-items-end'
              >
                <InputContainer>
                  <StyledSelect
                    value={childs}
                    onChange={(e) => {
                      handleHowManyChilds(props.item.id, e.target.value);
                      setChilds(e.target.value);
                    }}
                  >
                    {options?.map((item, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </StyledSelect>
                  <StyledExpand />
                </InputContainer>
              </Column>
            ) : (
              <></>
            )}
          </Row>
        </Column>
        </>
        }
        <Column
          xs={6}
          style={{ textAlign: 'left' }}
          className='d-flex flex-column justify-content-center'
        >
          <Price className='p-0 m-0'>Subtotal: </Price>
        </Column>
        <Column
          xs={6}
          style={{ textAlign: 'right', color: '#7A4DC7', fontWeight: '800' }}
          className='d-flex flex-column justify-content-center'
        >
          {currency(props.item.price)} MXN
        </Column>
      </StyledRow>
      <StyledModal show={show} onHide={handleClose}>
        <div onClick={handleClose}>
          <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar estudio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Estás seguro de querer eliminar <span>{props.item.title}</span> del
            carrito?
          </p>
          <ContinueButton onClick={confirmDelente}>Eliminar</ContinueButton>
          <WhiteButton variant='light' onClick={handleClose}>
            Cancelar
          </WhiteButton>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
