import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {UilArrowLeft} from '@iconscout/react-unicons';

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #121619;
  margin: 0;
  padding: 0;
`;

const Question = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
  margin: 0;
  padding: 0;
`;

const RadiosContainer = styled.div`
  max-width: 740px;
`;

const StyledCheck = styled(Form.Check)`
  .form-check-input[type=checkbox] {
    border-color: #77ccd5;
  }
  .form-check-input[type=radio] {
    border-color: #77ccd5;
  }
  .form-check-input:checked {
    background-color: #ffffff;
    border-color: #77ccd5;
  }
  .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2377CCD5'/%3e%3c/svg%3e") !important;
  }
  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2377CCD5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const StyledInput = styled(Form.Control)`
  background: #ffffff;
  border-radius: 12px;
  border: ${(props) => (props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6')};
  ::placeholder {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
`;

const ErrorMessage = styled.p`
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #ff0505;
  padding: 0;
  margin: 0;
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`;

const BackBtn = styled.button`
  background: none;
  border: none;
  margin-bottom: 18px;
`;



export const StepTwo = (props) => {
  const [ethnicity, setEthnicity] = useState();
  const [diseases, setDiseases] = useState([]);
  const [symptom, setSymptom] = useState([]);
  const [familyDiseases, setFamilyDiseases] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: {
      ethnicity: 0,
      diseases: [],
      symptoms: [],
    },
  });
  
  const deleteCheck = (value, type) => {
    if (type === 1) {
      setDiseases(
        diseases.filter((item) => item !== value).sort((a, b) => a - b)
      );
    } else if (type === 2) {
      setSymptom(
        symptom.filter((item) => item !== value).sort((a, b) => a - b)
      );
    } else if (type === 3) {
      setFamilyDiseases(
        familyDiseases.filter((item) => item !== value).sort((a, b) => a - b)
      );
    }
  };

  const addCheck = (value, type) => {
    if (type === 1) {
      setDiseases((prev) => [...prev.sort((a, b) => a - b), value]);
    } else if (type === 2) {
      setSymptom((prev) => [...prev.sort((a, b) => a - b), value]);
    } else if (type === 3) {
      setFamilyDiseases((prev) => [...prev.sort((a, b) => a - b), value]);
    }
  };

  const onSubmit = (data, event) => {
    props.handleStepTwo({
      personalData: data,
    });
  };

  const checks = {
    ethnicity: [
      {
        name: 'Hispano',
      },
      {
        name: 'Asiático',
      },
      {
        name: 'Caucásico',
      },
      {
        name: 'Judío askenazí',
      },
      {
        name: 'Negro/africano',
      },
      {
        name: 'Nativo americano ',
      },
      {
        name: 'Otro',
      },
    ],
    diseases: [
      {
        name: 'Diabetes',
      },
      {
        name: 'Hipertensión',
      },
      {
        name: 'Colesterol elevado',
      },
      {
        name: 'Triglicéridos elevados',
      },
      {
        name: 'Otra',
      },
    ],
    symptom: [
      {
        name: 'Inflamación abdominal',
      },
      {
        name: 'Dolor abdominal',
      },
      {
        name: 'Diarrea crónica',
      },
      {
        name: 'Pérdida de peso',
      },
      {
        name: 'Fatiga',
      },
      {
        name: 'Estreñimiento',
      },
    ],
    familyDiseases: [
      {
        name: 'Diabetes',
      },
      {
        name: 'Hipertensión',
      },
      {
        name: 'Colesterol elevado',
      },
      {
        name: 'Triglicéridos elevados',
      },
      {
        name: 'Intolerancia lactosa',
      },
      {
        name: 'Celiaquía',
      },
    ],
  };

  return (
    <Container data-aos='slide-left' data-aos-duration='500'>
      <BackBtn variant='light' onClick={() => window.location.href="#"}>
          <UilArrowLeft color='#00AD90' size='26' />
          Regresar
      </BackBtn>
      <Row className='mb-5'>
        <Title>Nos gustaría conocerte un poco mejor...</Title>
      </Row>
      <Row>
        <Col xs={12} sm={10} md={10} lg={8} xl={7} className='p-0'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question className='w-auto'>1. Etnicidad: *</Question>
                {errors?.ethnicity?.type === 'required' ? (
                    <ErrorMessage className='w-auto ps-2'>Campo obligatorio</ErrorMessage>
                  ) : (
                    <></>
                  )}
              </Row>
              <RadiosContainer className='row'>
                {checks.ethnicity.map((radio, index) => (
                  <Col xs={6} sm={6} md={3} className='mb-1 p-0' key={index}>
                    <StyledCheck
                      inline
                      {...register('ethnicity', {
                        required: true
                      })}
                      label={radio.name}
                      name='ethnicity'
                      id={`ethnicity-${index}`}
                      type='radio'
                      value={index}
                      checked={ethnicity === index ? true : false}
                      onChange={() => {
                        setEthnicity(index);
                      }}
                    />
                  </Col>
                ))}
              </RadiosContainer>
              {ethnicity === 6 && (
                <Row className='pt-3'>
                  <StyledInput
                    className='pe-3 ps-3'
                    type='text'
                    placeholder='Escribe aquí *'
                    {...register('anotherEthnicity', {
                      required: true
                    })}
                    error={errors?.anotherEthnicity?.type === 'required' ? 1 : 0}
                  />
                </Row>
              )}
            </div>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question className='w-auto'>
                  2. ¿Tienes alguna enfermedad?
                </Question>
              </Row>
              <RadiosContainer className='row'>
                {checks.diseases.map((check, index) => (
                  <Col xs={6} sm={6} md={3} className='mb-1 p-0' key={index}>
                    <StyledCheck
                      inline
                      {...register('diseases')}
                      label={check.name}
                      name={`diseases`}
                      id={`diseases-${index}`}
                      type='checkbox'
                      value={index}
                      checked={diseases.includes(index) ? true : false}
                      onChange={() => {
                        diseases.includes(index)
                          ? deleteCheck(index, 1)
                          : addCheck(index, 1);
                      }}
                    />
                  </Col>
                ))}
              </RadiosContainer>
              {diseases.includes(4) && (
                <Row className='pt-3'>
                  <StyledInput
                    className='pe-3 ps-3'
                    type='text'
                    placeholder='Escribe aquí'
                    {...register('anotherDiseases')}
                  />
                </Row>
              )}
            </div>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question>
                  3. ¿Tienes alguno de los siguientes síntomas?
                </Question>
              </Row>
              <RadiosContainer className='row'>
                {checks.symptom.map((check, index) => (
                  <Col xs={6} sm={6} md={3} className='mb-1 p-0' key={index}>
                    <StyledCheck
                      inline
                      {...register('symptoms')}
                      label={check.name}
                      name={'symptoms'}
                      id={`symptoms-${index}`}
                      type='checkbox'
                      value={index}
                      checked={symptom.includes(index) ? true : false}
                      onChange={() => {
                        symptom.includes(index)
                          ? deleteCheck(index, 2)
                          : addCheck(index, 2);
                      }}
                    />
                  </Col>
                ))}
              </RadiosContainer>
            </div>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question>
                  4. ¿Tus familiares tienen alguna de las siguientes
                  enfermedades? (Familiares de línea directa - abuelo, padres,
                  hermanos)
                </Question>
              </Row>
              <RadiosContainer className='row'>
                {checks.familyDiseases.map((check, index) => (
                  <Col xs={6} sm={6} md={3} className='mb-1 p-0' key={index}>
                    <StyledCheck
                      inline
                      {...register('familyDiseases')}
                      label={check.name}
                      name={'familyDiseases'}
                      id={`familyDiseases-${index}`}
                      type='checkbox'
                      value={index}
                      checked={familyDiseases.includes(index) ? true : false}
                      onChange={() => {
                        familyDiseases.includes(index)
                          ? deleteCheck(index, 3)
                          : addCheck(index, 3);
                      }}
                    />
                  </Col>
                ))}
              </RadiosContainer>
            </div>
            <Row className='mb-4'>
              <Question>
                * Campo obligatorio
              </Question>
            </Row>
            <Row className='mb-5'>
              <ContinueButton type='submit' className='pt-2 pb-2' onClick={() => {window.scrollTo(0, 0);}}>
                Continuar
              </ContinueButton>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
