import { Redirect, Route } from "react-router-dom"
import PropTypes from 'prop-types';

export const PublicRoute = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            component={ (props) => (
                ( isAuthenticated )
                    ? ( <Redirect to={window.location.search.includes('from=checkout') ? '/checkout?from=checkout' : '/'} /> )
                    : ( <Component {...props} /> )
            )}
        />
    )
}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}