import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import loading from '../../assets/ecommerce/loading.gif';
import vg from '../../assets/logos/vitalgene.gif';

export const Loader = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div className='text-center'>
          <img src={vg} className='img-fluid' />
        </div>
      </Modal.Body>
    </Modal>
  );
};
