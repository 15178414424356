import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'antd/dist/antd.css';
import reportWebVitals from './reportWebVitals';
import './assets/css/faq.css';
import './assets/css/react-boostrap-custom.css';
import { store } from './store/store';
import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

// Axios configuration
const baseURL = 'http://localhost:3001';
axios.defaults.baseURL = baseURL;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
