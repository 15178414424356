import React, { useState } from "react";
import styled from "styled-components";
import { UilPadlock } from "@iconscout/react-unicons";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "../../../general/spinner";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Title = styled.h1`
  font-family: "Assistant", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #000;
  
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 115%;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
    line-height: 115%;
  }
`;

const Subtitle = styled.h2`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #000;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const MainContainer = styled(Container)`
  padding: 70px;
  transition: all 300ms;
  background: #f3f3f3;
  border-radius: 60px;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 992px) {
    padding: 50px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px;
    .hidden-mobile {
      display: none !important;
    }
    .col-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    border-radius: 0;
    padding: 20px;
  }
`;

const ExtraInfo = styled.div`
  height: 100%;
  border: 1px solid #f14cae;
  background: #f3f3f3;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  #prueba {
    width: 65%;
    margin-left: auto;
    margin-top: -100px;
    margin-right: auto;
  }
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
    border: 1px solid transparent;
  }
`;

const ExtraTitle = styled.h3`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 10px 0px 10px;
  background: #f14cae;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
  @media only screen and (max-width: 576px) {
    font-size: 18px;
    text-align: start;
  }
`;

const ExtraText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  color: #6a6a6a;
  margin: 0;
  @media only screen and (max-width: 576px) {
    font-size: 16px;
    text-align: start;
  }
`;

const ExtraRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const StripeGuaranty = styled(Row)`
  width: 100%;
  border-radius: 10px;
  background: none;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 0px;
  text-align: center;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
  #pay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #stripe-g {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #e0ecec;
    padding: 5px 20px;
    img {
      width: 55px;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 15px;
    }
  }
  #card-logos {
    width: 100%;
    max-width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px auto 0;
    img {
      width: 45.5px;
      margin-inline: 10px;
    }
  }
`;

export const StudyVg70 = () => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  let isMobile = width <= 992;
  const [limit, setLimit] = useState(false);
  const { studyId } = useParams();
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 598);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    dispatch(handleAddItemToCart(study));
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    getInfo();
  }, [studyId]);

  const footerObserver = new IntersectionObserver((entries) => {
    if (entries[0].intersectionRatio <= 0) {
      setLimit(false);
    } else {
      setLimit(true);
    }
  });

  useEffect(() => {
    footerObserver.observe(document.getElementById("footer"));
  }, []);

  return (
    <>
      <Loader show={isLoading} />
      
        <div style={{ background: "transparent" }}>
          <Container >
          <Title >*Estos paquetes son solo aplicables para los residentes de puebla</Title>
          </Container>
        </div>
     
      {!isMobile && (
        <div
          style={{
            background: "#f3f3f3",
            position: "relative",
          }}
        >
          <MainContainer>
            <Row className="justify-content-between" id="studyDetails">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} id="left-div">

                <StripeGuaranty>
                  <Col
                    xs={12}
                    lg={4}
                    id="pay"
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <p>
                      <UilPadlock size={25} />
                      &nbsp;&nbsp;Pago garantizado y seguro.
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    lg={4}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <div id="stripe-g">
                      <p>Procesado por&nbsp;&nbsp;</p>
                      <img
                        alt="Stripe"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    lg={4}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <div id="card-logos">
                      <img
                        alt="mastercard"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                      />
                      <img
                        alt="visa"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                      />
                      <img
                        alt="amex"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Subtitle>
                      *Hasta 3 MSI con tarjetas participantes.
                    </Subtitle>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Subtitle>Envíos a todo México</Subtitle>
                  </Col>
                </StripeGuaranty>
              </Col>
            </Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <ExtraInfo>
                <Col xs={12} lg={6}>
                  {study.indicaciones && (
                    <ExtraRow>
                      <ExtraTitle>Indicaciones</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                </Col>
                <Col xs={12} lg={4}>
                  {study.muestra && (
                    <ExtraRow>
                      <ExtraTitle>Tipo de muestra</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.muestra }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                  {study.tEntrega && (
                    <ExtraRow>
                      <ExtraTitle>Tiempo de entrega:</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                </Col>
              </ExtraInfo>
            </Col>
          </MainContainer>
        </div>
      )}
      {isMobile && (
        <div
          style={{
            background: "#F3F3F3",
            position: "relative",
          }}
        >
          <StripeGuaranty>
            <Col xs={12} lg={6} id="pay">
              <p>
                <UilPadlock size={25} />
                &nbsp;&nbsp;Pago garantizado y seguro.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <div id="stripe-g" style={{ background: "#e0ecec" }}>
                <p>Procesado por&nbsp;&nbsp;</p>
                <img
                  alt="Stripe"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                />
              </div>
            </Col>
            <Col xs={12} lg={{ span: 4, offset: 4 }}>
              <div id="card-logos">
                <img
                  alt="mastercard"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                />
                <img
                  alt="visa"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                />
                <img
                  alt="amex"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                />
              </div>
            </Col>
          </StripeGuaranty>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
            <Subtitle style={{ textAlign: "center" }}>
              Hasta 3 MSI con tarjetas participantes*.
            </Subtitle>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
            <Subtitle style={{ textAlign: "center" }}>
              Envíos a todo México
            </Subtitle>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5}>
            <ExtraInfo style={{ textAlign: "center", height: "410px" }}>
              {study.indicaciones && (
                <ExtraRow>
                  <ExtraTitle>Indicaciones</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                  ></ExtraText>
                </ExtraRow>
              )}
              {study.muestra && (
                <ExtraRow>
                  <ExtraTitle>Tipo de muestra</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.muestra }}
                  ></ExtraText>
                </ExtraRow>
              )}
              {study.tEntrega && (
                <ExtraRow>
                  <ExtraTitle>Tiempo de entrega:</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                  ></ExtraText>
                </ExtraRow>
              )}
            </ExtraInfo>
          </Col>
        </div>
      )}
    </>
  );
};
