import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import axios from "axios";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Contain = styled.div`
  width: 100%;
  height: 460px;
  position: relative;
  background: linear-gradient(95.67deg, #f30397 -13.24%, #00b8c3 49.29%);
  @media only screen and (max-width: 768px) {
    height: 590px;
    background: linear-gradient(0.84deg, #f30397 -32.07%, #00b8c3 51.62%);
  }
`;

const Slide = styled(Container)`
  width: 100%;
  height: 460px;
  position: relative;
  background: none;
  // overflow: hidden;
  h1 {
    color: #fff;
    font-family: Assistant;
    font-size: 48px;
    font-weight: 300;
    text-align: center;
  }
  h2 {
    color: var(--Grayscale-02, #fff);
    font-family: "IBM Plex Sans";
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Negro, #fff);
    font-family: "IBM Plex Sans";
    font-size: 22px;

    font-weight: 200;

    line-height: 130%; /* 26px */
  }
  span {
    font-size: 22px;
    font-weight: 200;
    font-style: normal;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
    #envios {
      font-weight: 300;
      text-align: center;
      font-style: normal;
      font-size: 18px;
    }
    #codigo {
      font-style: normal;
      font-size: 25px;
      text-align: center;
    }
  }
  #img-col {
    z-index: 1;
    height: 430px;
    margin-top: 30px;
    display: flex;
    left: -5%;
    align-items: end;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      position: relative;
    }
  }
  img {
    max-width: 600px;
    max-height: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0px;
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 155%;
      max-height: 105%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    padding: 0 0 0 3%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    padding: 0 0 0 3%;
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 48px;
    }
    p {
      font-size: 20px;
    }
    span {
      font-size: 18px;
    }
    img {
      max-width: 135%;
      max-height: 105%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    h1 {
      font-size: 33px;
    }
    p {
      font-size: 25px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    p {
      font-size: 25px;
    }
    #img-col {
      height: 520px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 550px;

    h1 {
      font-size: 24px;
      padding: 5px 5px 5px 20px;
      line-height: 15px;
    }
    h2 {
      font-size: 32px;
      padding: 0px 0px 0px 20px;
    }
    span {
      text-align: center;
      font-size: 16px;
    }
    img {
      bottom: -40px;
      max-width: 120%;
      max-height: 266px;
      padding-right: 40%;
    }
    #text-col {
      #codigo {
        font-style: normal;
        font-size: 17px;
        text-align: center;
        width: 154px;
      }
      #envios {
        font-family: Real Head Pro;
        width: 300px;
        font-size: 15px;
        font-weight: 200;
      }
      #descu {
        //text-align: center;
        font-size: 16px;
        padding: 5px 5px 5px 20px;

        font-weight: 400;
      }
      span {
        text-align: center;
        font-size: 16px;
      }
    }
  }
    @media only screen and (max-width: 576px) {
      img {
      bottom: -40px;
      max-width: 120%;
      max-height: 266px;
      padding-right: 35%;
    }
  }
`;

const Button1 = styled.div`
  color: #fff;

  font-family: "IBM Plex Sans";
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.3s ease;
  padding: 13px 0px;
  @media only screen and (min-width: 768px) and (max-width: 1400px) {
    font-size: 22px;
    padding: 10px 0px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    padding: 0px 0px 0px 20px;
  }
`;

const Button2 = styled.button`
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
  
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 30px;
  transition: all 0.3s ease;
  padding: 10px 30px;
  :hover {
    filter: brightness(1.05);
  }
  @media only screen and (min-width: 768px) and (max-width: 1400px) {
    font-size: 20px;
    padding: 10px 35px;
    width: 220px;
    height: 50px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 170px;
    height: 40px;
    padding: 0px 15px;
    margin-left: 10px;
  }
`;
function BannerDesc() {
  const [alertaVisible, setAlertaVisible] = useState(false);
  const { items } = useSelector((state) => state.shoppingCart);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { studyId } = useParams();
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 992;
  let isMobile = width <= 576;

  // Función para copiar texto al portapapeles y mostrar una alerta
  const copiarTexto = (texto) => {
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        console.log("Texto copiado al portapapeles: ", texto);
        mostrarAlerta();
      })
      .catch((err) => {
        console.error("Error al copiar el texto: ", err);
        alert("Error al copiar el texto. Por favor, inténtalo de nuevo.");
      });
  };

  // Función para mostrar la alerta y ocultarla después de un tiempo
  const mostrarAlerta = () => {
    setAlertaVisible(true);
    setTimeout(() => {
      setAlertaVisible(false);
    }, 500); // Oculta la alerta
  };

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 598);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <Contain>
      <Slide>
        <Row>
          {isMobile ? (
            <>
              <Col xs={12} md={6} xxl={7} id="text-col">
                <br />
                <h1>¡Conócete genéticamente!</h1>
                <h2>
                  Mejoramos nuestros <br />
                  precios para ti.
                </h2>
                <p id="descu">
                  Con solo una muestra de saliva te <br />
                  ayudamos a conocer el funcionamiento <br /> y las necesidades
                  de tu metabolismo.
                </p>
                <Row>
                  <Button1>
                    $4,950.00 <span style={{fontSize:"30px"}}>MXN</span>
                  </Button1>
                  <br /> <br />
                  <Col xs={6}>
                    <Button2 onClick={() => payStudy()}>Comprar ahora</Button2>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6} xxl={5} id="img-col">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fbanner2024mov.png?alt=media&token=cf301d3e-953a-4851-8a4a-3a3601dc8db6"
                  }
                  alt="phone"
                />
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6} xxl={5} id="img-col">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fbanner2024.png?alt=media&token=8079d1df-2339-4a33-b727-1f4b93284a38"
                  }
                  alt="phone"
                />
              </Col>
              <Col xs={12} md={6} xxl={7} id="text-col">
                <h1>¡Conócete genéticamente!</h1>
                <h2>
                  Mejoramos nuestros <br />
                  precios para ti.
                </h2>
                <p id="descu">
                  Con solo una muestra de saliva te ayudamos a conocer el <br />
                  funcionamiento y las necesidades de tu metabolismo.
                </p>
                <Row>
                  <Col md={4}>
                    <Button1>
                      $4,950.00 <span>MXN</span>
                    </Button1>
                  </Col>
                  <Col md={6}>
                    <Button2 onClick={() => payStudy()}>Comprar ahora</Button2>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Slide>
    </Contain>
  );
}

export default BannerDesc;
