import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import styled from "styled-components";

const symptoms = [
  {
    name: "Salud",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FVector.png?alt=media&token=ab1694dc-488f-42a3-bb60-d4ec2f113475",
  },
  {
    name: "Deporte",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FVector%20(1).png?alt=media&token=a446a163-995f-4760-87da-c823d7be565d",
  },
];

const symptoms1 = [
  {
    name: "Fertilidad",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FVector%20(2).png?alt=media&token=47fc0fc6-589d-4f7f-8876-d5a40fd7c1ad",
  },
];

const MySlider = styled(Slider)`
  margin-bottom: 20px;
  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -5px;
    li {
      width: 10px;
    }
    button {
      margin-right: 0;
      background: #ccc;
      width: 6px !important;
      height: 6px;
      border-radius: 60%;
      border: 1.5px solid #f14cae;
      background: #fff;
      ::before {
        display: none;
      }
    }
    .slick-active button {
      background: #f14cae;
    }
  }
`;

const BannerB2 = styled.div`
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  padding: 40px 0;
  #col-img1 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 609px;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;
const BannerB3 = styled.div`
  width: 100%;
  height: 206px;
  border-radius: 85px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Ffoto.png?alt=media&token=826cf0a7-7121-4bcd-a887-9b7936761602")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FfotoMOV.png?alt=media&token=f38e2d52-c773-4ebb-af2f-3d28d63bbc3d")
      center center/cover no-repeat;
    height: 206px;
    border-radius: 0px;
    overflow: hidden;
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Assistant;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  #test {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    margin-top: revert;
    font-weight: 300;
    line-height: 135%;
    @media only screen and (max-width: 768px) {
      font-size: 33px;
      margin-top: revert;
    }
    @media only screen and (max-width: 576px) {
      font-size: 30px;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 1400px) {
    font-size: 38px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  #subtitle {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 20px;
    width: 27vw;
    font-weight: 400;
    line-height: 120%;
    @media only screen and (max-width: 1400px) {
      font-size: 18px;
    }
  }
  #symp-desk1 {
    max-width: 450px;
    justify-content: center;
  }
  #desc {
    color: #000;
    border-radius: 15px;
    text-align: center;
    background: #e2feff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 444px;
    @media only screen and (max-width: 1400px) {
      font-size: 17px;
    }
  }
  .row {
    width: 100%;
  }
  #symp-desk {
    max-width: 450px;
  }
  .symp-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
  }
  .symp-name {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 13px;
    font-weight: 600;
  }
  img {
    width: 80%;
    max-width: 85px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 992px) {
    #subtitle,
    #desc {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 768px) {
    #symp-mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    #subtitle,
    #desc {
      font-size: 18px;
      width: 98vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    #subtitle,
    #desc {
      font-size: 16px;
      width: 76vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: -30px;
  font-size: 24px;
  margin-left: 30%;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #f14cae;
  border-radius: 40px;
  border: none;
  text-align: center;
  width: 505px;
  height: 60px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 250px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
  }
`;

export const Target = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isTablet = width <= 768;
  let isMobile = width <= 576;
  return (
    <>
      {!isMobile ? (
        <BannerB2>
          <Container>
            <Row>
              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fejer.png?alt=media&token=1c5d35e1-8b5c-4bd4-a37e-38b184599bbd"
                />
              </Col>

              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  Los resultados de nuestra prueba VG70 te ayudarán a
                  personalizar tu alimentación y rutinas de ejercicio para poder
                  alcanzar tus metas más efectivamente.
                </p>

                {/* Symptoms in desktop */}
                <Row id="symp-desk">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                  {symptoms1?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  También es importante mencionar que{" "}
                  <span style={{ fontWeight: "700" }}>
                    esta prueba se adapta a tus necesidades y objetivos
                  </span>
                  , esto quiere decir que los resultados pueden tener 3
                  diferentes enfoques: Deporte, Fertilidad y Salud.
                </p>
              </DescColumn>
            </Row>
            <Row>
              <BannerB3>
                <Title>
                  <p id="test">
                    ¿Esta prueba es para mí? <br />
                    <span style={{ textDecoration: "underline" }}>
                      Descúbrelo
                    </span>{" "}
                    con nuestro quiz.
                  </p>
                </Title>
              </BannerB3>
            </Row>
            <Row>
              <Desc>
                <a href="/quizVg70" target="_blank" rel="noopener noreferrer">
                  Comenzar quiz
                </a>
              </Desc>
            </Row>
          </Container>
        </BannerB2>
      ) : (
        <BannerB2>
          <Container>
            <Row>
              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  ¡Personaliza tu alimentación y rutinas de ejercicio para
                  alcanzar tus metas! <br />
                  <br /> Con solo una muestra de saliva te ayudamos a conocer el
                  funcionamiento y las necesidades de tu metabolismo.
                </p>

                {/* Symptoms slider in mobile */}
                <Row id="symp-mobile">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>
                <Row id="symp-mobile">
                  {symptoms1?.map((symp) => (
                    <Col xs={12} md={12} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  También es importante mencionar que{" "}
                  <span style={{ fontWeight: "700" }}>
                    esta prueba se adapta a tus necesidades y objetivos
                  </span>
                  , esto quiere decir que los resultados pueden tener 3
                  diferentes enfoques: Deporte, Fertilidad y Salud.
                </p>
              </DescColumn>

              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fejer.png?alt=media&token=1c5d35e1-8b5c-4bd4-a37e-38b184599bbd"
                />
              </Col>
            </Row>
          </Container>
          <Row>
            <BannerB3>
              <Title>
                <p id="test">
                  ¿Esta prueba es para mí? <br />
                  <span style={{ textDecoration: "underline" }}>
                    Descúbrelo
                  </span>{" "}
                  con nuestro quiz.
                </p>
              </Title>
            </BannerB3>
          </Row>
          <Row>
            <Desc>
              <a href="/quizVg70" target="_blank" rel="noopener noreferrer">
                Comenzar quiz
              </a>
            </Desc>
          </Row>
        </BannerB2>
      )}
    </>
  );
};
