import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilArrowLeft } from '@iconscout/react-unicons';
import covid from "../../assets/covid/covid.png";

const Title = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 115%;
  color: #00AD90;
`;

const BackBtn = styled(Button)`
  background: none;
  border: none;
  margin-bottom: 18px;
`;

const RegularP = styled.p`
  font-size: 15px;
`;

export const Banner = () => {
  return (
    <div style={{
      background: "rgba(0, 173, 144, 0.1)", 
      paddingTop: "15px"
      }}>
      <Container>
        <BackBtn variant='light' onClick={() => window.history.back()}>
          <UilArrowLeft color='#00AD90' size='26' />
          Regresar
        </BackBtn>
        <Row>
          <Col xs={2} lg={{span: 1, offset: 3}}>
            <img src={covid} alt="covid" width={52}/>
          </Col>
          <Col xs={10} lg={{span: 6, offset: 0}}>
            <Title>COVID-19</Title>
            <RegularP>Conoce las pruebas para detección e inmunidad de COVID-19 que tenemos para ti y tu familia.</RegularP>
          </Col>
        </Row>
      </Container>
    </div>
  )
};