import styled from "styled-components";

export const SideNavContainer = styled.div`
  @media only screen and (min-width: 1401px) {
    flex: 0.185;
  }
  @media only screen and (max-width: 1400px) {
    flex: 0.24;
  }
  @media only screen and (max-width: 1200px) {
    flex: 0.28;
  }
  @media only screen and (max-width: 992px) {
    flex: 0.39;
  }
  @media only screen and (max-width: 768px) {
    flex: "none";
  }
`;
