import React, { useState, useEffect } from 'react';
import { BackButton } from '../components/general/backButton';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { StudyL } from '../components/studies/studyL';
import { SearchSlider } from './categories/SearchSlider';
import ReactPaginate from 'react-paginate';
import {
  UilFileSearchAlt,
  UilAngleDown,
  UilListUl,
  UilApps,
} from '@iconscout/react-unicons';
import styled from 'styled-components';
import { HelmetTitle } from '../helpers/HelmetTitle';
import { TestCard } from '../components/studies/testCard';
import axios from 'axios';

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  text-align: center;
  color: #00AD90;
`;
const FilterButton = styled(Button)`
  background-color: white;
  border: none;
`;
const ResultsLabel = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #000000;
`;

const ShowMoreButton = styled.button`
  width: 297px;
  height: 48px;
  background: #6FBEA0;
  border: none;
  border-radius: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  :hover {
    background: #81d6b6;
  }
`;

export const Buscador = ({ itemsPerPage = 4 }) => {
  const [items, setItems] = useState();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var search = urlParams.get('search');
  const [itemsToShow, setItemsToShow] = useState(12);
  useEffect(()=>{
    axios.get(`https://lpqsystem.com/searching-products?search=${search}`)
      .then(res => {
        res.data.productos.map((item) => {
          Object.assign(item, {title: item.name, price: item.precio_promo, oldPrice: item.precio_normal});
        })
        setItems(res.data.productos);
      })  
  },[search]);

  const [width, setWidth] = useState(window.innerWidth);
  const [showList, setShowList] = useState(false);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 991;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Container
      className='mt-4 min-vh-100'
      style={{ backgroundColor: 'rgba(230,0,89,0)' }}
    >
      <BackButton />
      <Row className='justify-content-center mt-3'>
        <Col md={6} className='text-center'>
          <UilFileSearchAlt color='#00AD90' className='mb-1' size='60' />
          <br />
          <Title>Buscador</Title>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <ResultsLabel>{items?.length} resultados para "{search && search}"</ResultsLabel>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <SearchSlider />
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <ResultsLabel>Lista completa de estudios</ResultsLabel>
        </Col>
      </Row> */}

      <Row className='mt-3'>
        <Col md={10} xs={8} sm={8} />
        <Col md={2} xs={4} sm={4} className='float-right'>
          <FilterButton variant='light' onClick={() => setShowList(!showList)}>
            {showList ? (
              <UilListUl size='30' color='#00AD90' />
            ) : (
              <UilApps size='30' color='#00AD90' />
            )}
          </FilterButton>
        </Col>
      </Row>
      <Row>
        {items &&
        items.map((item, index) => (
          <>
            {index < itemsToShow ? (
              showList ? (
                <StudyL key={index} data={item} />
              ) : (
                <TestCard key={index} data={item} xs={12} sm={6} md={6} lg={4} xl={3} xxl={3} />
              )
            ) : (
              null
            )}
          </>
        ))}
      </Row>
      <p style={{textAlign: 'center', fontSize: '18px', marginTop: '40px'}}>
        Mostrando {itemsToShow > items?.length ? items?.length : itemsToShow} de {items?.length} estudios
      </p> 
      <div className='d-flex justify-content-center'>
        {itemsToShow < items?.length &&
          <ShowMoreButton onClick={()=>setItemsToShow(itemsToShow+6)}>Mostrar más</ShowMoreButton>
        }
      </div>
      <br/>
      <br/>
      <br/>
    </Container>
  );
};
