import React, { useEffect, useState } from 'react';
import styled from 'react-bootstrap';
import Slider from 'react-slick';
import { AboutSlide } from './slide';
import SlideImgOne from '../../assets/aboutUs/slides/slideOne.png';
import SlideImgTwo from '../../assets/aboutUs/slides/slideTwo.png';
import SlideImgThree from '../../assets/aboutUs/slides/slideThree.png';
import SlideImgFour from '../../assets/aboutUs/slides/slideFour.png';
import SlideImgFive from '../../assets/aboutUs/slides/slideFive.png';
import SlideImgSix from '../../assets/aboutUs/slides/slideSix.png';
import SlideImgSeven from '../../assets/aboutUs/slides/slideSeven.png';

const Slides = [
  {
    id: 1,
    title: 'Brindamos',
    desc: 'un servicio cómodo, eficiente y de confianza',
    img: SlideImgOne,
  },
  {
    id: 2,
    title: 'Construimos',
    desc: 'soluciones que beneficien a los pacientes',
    img: SlideImgTwo,
  },
  {
    id: 3,
    title: 'Somos',
    desc: 'el impulso para crear un cambio en la salud de las personas',
    img: SlideImgThree,
  },
  {
    id: 4,
    title: 'Impulsamos',
    desc: 'el diagnóstico a otro nivel',
    img: SlideImgFour,
  },
  {
    id: 5,
    title: 'Buscamos',
    desc: 'crecimiento y mejora continua',
    img: SlideImgFive,
  },
  {
    id: 6,
    title: 'Estamos',
    desc: 'en constante volución en nuestro saber y hacer',
    img: SlideImgSix,
  },
  {
    id: 7,
    title: 'Nosotros',
    desc: 'hacemos que suceda',
    img: SlideImgSeven,
  }
];

export const AboutSlider = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return(
    <Slider
      dots={false}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
    >
      {Slides.map((slide, index) => (
        <AboutSlide data={slide} key={index} />
      ))}
    </Slider>
  );
};