import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Modalvg } from "./Modalvg";
import { Modalmov } from "./Modalmov";

const Results =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2Fvariante.png?alt=media&token=540e1afc-eb0d-48d5-bd53-5b6567ed5f46";
const Alterations =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2Fresultado.png?alt=media&token=86f1915a-cf34-4640-86e6-87388a771456";
const Biomarkers =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2Fimplicacion.png?alt=media&token=8c249fa1-0114-4dc0-ac2b-507152c348a3";

const Title = styled.h1`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000;
  width: 480px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  p {
    font-weight: 500;
    font-size: 20px;
  }
  @media only screen and (max-width: 576px) {
    width: 323px;
    font-size: 30px;
  }
`;

const AxionaDocuments = styled.div`
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  .first {
    width: 490px;
    /* position: absolute; */
    /* left: -20px; */
    /* right: 0; */
    margin-left: 415px;
    margin-right: auto;
    transition: all 300ms;
    @media only screen and (max-width: 1366px) {
      margin-left: 345px;
    }
  }
  .second {
    transition: all 300ms;
    width: 410px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .third {
    transition: all 300ms;
    width: 410px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .mask {
    width: 410px;
  }
  @media only screen and (max-width: 992px) {
    .second {
      top: 5%;
    }
    .third {
      top: 5%;
    }
  }
  @media only screen and (max-width: 768px) {
    .first {
      margin-left: 0px;
      max-width: 380px;
      height: auto;
    }
    .second {
      max-width: 320px;
      height: auto;
    }
    .third {
      max-width: 320px;
      height: auto;
    }
    .mask {
      max-width: 320px;
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 400px) {
    .first {
      max-width: 100%;
      height: auto;
    }
    .second {
      max-width: 85%;
      height: auto;
    }
    .third {
      max-width: 85%;
      height: auto;
    }
    .mask {
      max-width: 85%;
      width: auto;
      height: auto;
    }
  }
`;

const DocumentImg = styled.img`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 140px;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.selected === 1 ? "#24CAD3" : "#24CAD3")};
  border-radius: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  box-shadow: 0px 8px 8px 0px #00000026;
  color: #ffffff;
  margin: 0;
  width: auto;
  outline: none;
  border: none;
  transition: 300ms;
  margin-bottom: 57px;
  :hover {
    transition: 300ms;
    color: #ffffff;
    background-color: #24CAD3;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StepText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  text-align: left;
`;

const DocumentInfo = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353433;
  max-width: 460px;
  margin-bottom: 0;
`;

export const Beneficios = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [documentSelected, setDocumentSelected] = useState(0);
  const [modalSelected, setModalSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  let isDesktop = width <= 1366;
  let isTablet = width <= 992;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Container
        className={`pt-${isMobile ? "4" : "5"} `}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row className="mb-5">
          <Title>
            ¿Qué información ofrece el reporte de resultados de{" "}
            <span style={{ fontWeight: "700" }}>Intolerancia a la cafeína</span>?
            <p>Seleccione la sección de su interés para más información.</p>
          </Title>
        </Row>
        {width <= 992 ? (
          <>
            <Row
              // data-aos="fade-up"
              className="mb-3"
            >
              <StyledButton
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 1 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(1);
                  setModalSelected(1);
                  setShowModal(true);
                }}
              >
                Variantes encontradas
              </StyledButton>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-3"
            >
              <StyledButton
                style={{
                  maxWidth: "300px",
                }}
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 2 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(2);
                  setModalSelected(2);
                  setShowModal(true);
                }}
              >
                Resultado
              </StyledButton>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-4"
            >
              <StyledButton
                style={{
                  maxWidth: "235px",
                }}
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 3 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(3);
                  setModalSelected(3);
                  setShowModal(true);
                }}
              >
                Implicación clínica
              </StyledButton>
            </Row>
          </>
        ) : null}
        {width <= 992 ? (
          <>
            <Row className="mb-4 pb-2">
              <AxionaDocuments>
                <DocumentImg
                  src={
                    documentSelected === 3
                      ? Biomarkers
                      : documentSelected === 2
                      ? Alterations
                      : Results
                  }
                  alt="Documento de ejemplo"
                  className="first"
                />
              </AxionaDocuments>
            </Row>

            <Row className="justify-content-center position-relative mb-4 pb-2">
              <DocumentInfo
              // data-aos="fade-up"
              >
                Este documento se ha creado únicamente con fines explicativos y
                la totalidad de los datos no corresponden a un sujeto
                particular.
              </DocumentInfo>
            </Row>
          </>
        ) : (
          <Row className="mb-5">
            <AxionaDocuments className="pt-4 pb-4">
              <DocumentImg
                src={
                  documentSelected === 3
                    ? Biomarkers
                    : documentSelected === 2
                    ? Alterations
                    : Results
                }
                alt="Documento de ejemplo"
                className="first"
              />
              <ButtonsContainer>
                <Col xs={12}>
                  <Row className="h-100 justify-content-between">
                    <Col md={5}>
                      <div className="h-100 d-flex flex-column justify-content-start">
                        <Row data-aos="fade-up">
                          <StyledButton
                            style={{ marginTop: "-100px", marginLeft: "100px" }}
                            className="pt-2 pe-4 pb-2 ps-4"
                            selected={documentSelected === 1 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(1)}
                            onClick={() => {
                              setModalSelected(1);
                              setShowModal(true);
                            }}
                          >
                            Variantes encontradas
                          </StyledButton>
                        </Row>
                        <Row data-aos="fade-up">
                          <StepText
                            style={{ marginTop: "80px", marginLeft: "100px" }}
                            className="ps-4 mb-2"
                          ></StepText>
                          <StyledButton
                            style={{
                              maxWidth: "250px",
                              marginLeft: "100px",
                            }}
                            className="pt-2 pe-4 pb-2 ps-4"
                            selected={documentSelected === 2 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(2)}
                            onClick={() => {
                              setModalSelected(2);
                              setShowModal(true);
                            }}
                          >
                            Resultado
                          </StyledButton>
                        </Row>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="h-100 d-flex flex-column justify-content-start">
                        <Row className="justify-content-end" data-aos="fade-up">
                          {!isDesktop && (
                            <StepText
                              style={{
                                marginTop: "-30px",
                                marginRight: "-80px",
                                width: "100px",
                              }}
                              className="ps-4 mb-2"
                            ></StepText>
                          )}
                          {isDesktop && (
                            <StepText
                              style={{
                                marginTop: "-30px",
                                marginRight: "-80px",
                              }}
                              className="ps-4 mb-2"
                            ></StepText>
                          )}
                          <StyledButton
                            style={{
                              maxWidth: "260px",
                              marginRight: "80px",
                            }}
                            className="pt-2 pe-4 pb-2 ps-4 w-100"
                            selected={documentSelected === 3 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(3)}
                            onClick={() => {
                              setModalSelected(3);
                              setShowModal(true);
                            }}
                          >
                            Implicación clínica
                          </StyledButton>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </ButtonsContainer>
            </AxionaDocuments>
            <Row className="mt-4 justify-content-center position-relative mb-3">
              <DocumentInfo data-aos="fade-up">
                Este documento se ha creado únicamente con fines explicativos y
                la totalidad de los datos no corresponden a un sujeto
                particular.
              </DocumentInfo>
            </Row>
          </Row>
        )}
        {!isMobile ? (
          <Modalvg
            show={showModal}
            setShowModal={setShowModal}
            modalSelected={modalSelected}
          />
        ) : (
          <Modalmov
            show={showModal}
            setShowModal={setShowModal}
            modalSelected={modalSelected}
          />
        )}
      </Container>
    </>
  );
};
