import React, {useState} from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledFloating = styled(FloatingLabel)`
  .form-select~label {
    transform-origin: -8em -5em !important;
    background-color: #ffffff;
    color: #A6A6A6;
    opacity: 1;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding: 0px 9px;
  }
  width: 100%;
  padding: 0;
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'Assistant', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 200ms;
  }
  :active,
  :focus-within {
    .form-select~label {
      padding: 0px 9px;
      background-color: #ffffff;
    }
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 5px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  width: 100%;
  background: #ffffff;
  border: ${(props) => (props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6')};
  border-radius: 12px;
  max-height: 45px;
  height: 100%;
  padding: 0px 40px 0px 40px !important;
  z-index: 1;
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) => props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
    border-style: dashed;
    color: ${(props) => (props.disabled === 1 ? '#a6a6a6;' : '#000000')};
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  ::placeholder {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
  border: ${(props) =>
    props.error === 1 ? '1px solid #FF0505' : '1px solid #E9E9E9'};
  border-radius: 12px;
  font-family: 'Assistant', sans-serif;
  max-height: 45px;
  padding: 0 0 0 40px !important;
  box-shadow: 0px 4px 4px #f6f6f6;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2377CCD5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) =>
      props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
    border-style: dashed;
    color: #b8b8b8;
  }
`;

const StyledCheck = styled(Form.Check)`
  .form-check-input[type='radio'] {
    border-color: #77ccd5;
  }
  .form-check-input:checked {
    background-color: #ffffff;
    border-color: #77ccd5;
  }
  .form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2377CCD5'/%3e%3c/svg%3e") !important;
  }
`;

const LabelText = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  width: 328px;
  height: 44px;
  background: #ffffff;
  border-radius: 12px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 11px;
  border: 1px solid #E6E6E6;
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #ffffff;
    font-family: 'Assistant', sans-serif;
  }
`;

const SelectCol = styled(Col)`
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;

export const SomeoneElseForm = (props) => {
  const [solData, setSolData] = useState({
    name: '',
    fathersLastname: '',
    mothersLastname: '',
    phone: '',
    email: '',
    phone: '',
    day: '',
    month: '',
    year: '',
    birthday: '',
    cp: '',
    state: '',
    city: '',
    colonia: '',
    street: '',
    street2: '',
    street3: '',
    indications: '',
    next: '',
    nint: '',
    gender: ''
  });
  const [phone, setPhone] = useState();
  const { width } = useWindowDimensions();
  const years = [];
  for (var i = 2022; i >= 1930; i--) {
    years.push(i.toString());
  }
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const days = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];

  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const handleForm = (e) => {
    const { name, value } = e.target;
    setSolData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (data) => {
    props.handleAddressForm({
      data: data,
    })
  }

  const states = [
    {
      name: 'Aguascalientes',
    },
    {
      name: 'Baja California',
    },
    {
      name: 'Baja California Sur',
    },
    {
      name: 'Campeche',
    },
    {
      name: 'Chiapas',
    },
    {
      name: 'Chihuahua',
    },
    {
      name: 'Coahuila',
    },
    {
      name: 'Colima',
    },
    {
      name: 'CDMX',
    },
    {
      name: 'Estado de México',
    },
    {
      name: 'Guanajuato',
    },
    {
      name: 'Guerrero',
    },
    {
      name: 'Hidalgo',
    },
    {
      name: 'Jalisco',
    },
    {
      name: 'Michoacán',
    },
    {
      name: 'Morelos',
    },
    {
      name: 'Nayarit',
    },
    {
      name: 'Nuevo León',
    },
    {
      name: 'Oaxaca',
    },
    {
      name: 'Puebla',
    },
    {
      name: 'Querétaro',
    },
    {
      name: 'Quintana Roo',
    },
    {
      name: 'San Luis Potosí',
    },
    {
      name: 'Sinaloa',
    },
    {
      name: 'Sonora',
    },
    {
      name: 'Tabasco',
    },
    {
      name: 'Tamaulipas',
    },
    {
      name: 'Tlaxcala',
    },
    {
      name: 'Veracruz',
    },
    {
      name: 'Yucatán',
    },
    {
      name: 'Zacatecas',
    },
  ];

  return(
    <Form className='w-100 p-0' onSubmit={handleSubmit(onSubmit)}>
      <Row className='mb-5'>
        <Row className='mb-4 pb-2 p-0'>
          <StyledFloating
            controlId='name'
            label='Nombre y apellidos *'
            >
            <StyledInput
              type='text'
              name='name'
              placeholder='Nombre *'
              error={errors?.name?.type === 'required' ? 1 : 0}
              {...register('name', {
                required: 'Campo obligatorio'
              })}
              value={solData?.name}
              onChange={(e) => handleForm(e)}
            />
          </StyledFloating>
        </Row>
        <Row className='mb-4 pb-2 p-0'>
          <StyledFloating
            controlId='email'
            label='Email *'
          >
            <StyledInput
              type='text'
              name='email'
              placeholder='Email *'
              error={errors?.email?.type === 'required' ? 1 : 0}
              {...register('email', {
                required: 'Campo obligatorio'
              })}
              value={solData?.email}
              onChange={(e) => handleForm(e)}
            />
          </StyledFloating>
        </Row>
        <Row className='mb-4 pb-2 p-0'>
          <Form.Label className='p-0 mb-4'>
            <LabelText>Teléfono</LabelText>
          </Form.Label>
          <StyledPhoneInput
            className='w-100 m-0'
            type='tel'
            international
            defaultCountry='MX'
            placeholder='Teléfono celular'
            name='phone'
            id='phone'
            {...register('phone')}
            value={phone}
            onChange={setPhone}
          />
        </Row>
        <Row className='pb-2 p-0'>
          <Form.Label className='p-0 mb-4'>
            <LabelText>Fecha de nacimiento</LabelText>
          </Form.Label>
          <SelectCol xs={12} sm={12} md={4} className='ps-0 mb-4'>
            <StyledFloating
              controlId='day'
              label='Día *'
              >
              <StyledSelect
                name='day'
                error={errors?.day?.type === 'required' ? 1 : 0}
                {...register('day', {
                  required: 'Campo obligatorio'
                })}
                onChange={(e) => handleForm(e)}
              >
                <option value={''} hidden>
                  --
                </option>
                {days.map((item, i) => (
                  <option key={`${item}-${i}`} value={item}>
                    {item}
                  </option>
                ))}
              </StyledSelect>
            </StyledFloating>
          </SelectCol>
          <SelectCol xs={12} sm={12} md={4} className='ps-0 mb-4'>
            <StyledFloating
              controlId='month'
              label='Mes *'
              >
              <StyledSelect
                name='month'
                error={errors?.month?.type === 'required' ? 1 : 0}
                {...register('month', {
                  required: 'Campo obligatorio'
                })}
                onChange={(e) => handleForm(e)}
              >
                <option value={''} hidden>
                  --
                </option>
                {months.map((item, i) => (
                  <option key={`${item}-${i}`} value={item}>
                    {item}
                  </option>
                ))}
              </StyledSelect>
            </StyledFloating>
          </SelectCol>
          <SelectCol xs={12} sm={12} md={4} className='ps-0 mb-4'>
            <StyledFloating
              controlId='year'
              label='Año *'
              error={errors?.year === 'required' ? 1 : 0}
            >
              <StyledSelect
                name='year'
                error={errors?.year?.type === 'required' ? 1 : 0}
                {...register('year', {
                  required: 'Campo obligatorio'
                })}
                onChange={(e) => handleForm(e)}
              >
                <option value={''} hidden>
                  --
                </option>
                {years.map((item, i) => (
                  <option key={`${item}-${i}`} value={item}>
                    {item}
                  </option>
                ))}
              </StyledSelect>
            </StyledFloating>
          </SelectCol>
        </Row>
        <div className='mb-4 pb-2 p-0'>
          <Form.Label className='p-0 mb-4'>
            <LabelText>
              Selecciona el sexo
            </LabelText>
          </Form.Label>
          <Row
            {...register('gender')}
            onChange={(e) => {
              handleForm(e);
            }}
          >
            {errors?.gender?.type === 'required' ? (
              <LabelText className='pb-2' style={{color: '#FF0505'}}>Por favor, selecciona un sexo</LabelText>
            ) : (
              <></>
            )}
            <Col xs={12} sm={12} md={3}>
              <StyledCheck
                inline
                label='Masculino'
                name='gender'
                id='gender-masculino'
                type='radio'
                value={0}
              />
            </Col>
            <Col xs={12} sm={12} md={3}>
              <StyledCheck
                inline
                label='Femenino'
                name='gender'
                id='gender-femenino'
                type='radio'
                value={1}
              />
            </Col>
          </Row>
        </div>
        <Row className='mb-4 p-0'>
          <LabelText className='p-0'>
            Dirección de envío
          </LabelText>
        </Row>
        <Row className='w-100 mb-4 pt-2 pb-2 ps-0 pe-0'>
          <StyledFloating
            controlId='street'
            label='Calle *'
          >
            <StyledInput
              type='text'
              name='street'
              placeholder='Calle *'
              error={errors?.street?.type === 'required' ? 1 : 0}
              {...register('street', {
                required: 'Campo obligatorio'
              })}
              value={solData?.street}
              onChange={(e) => handleForm(e)}
            />
          </StyledFloating>
        </Row>
        <Row className='w-100 mb-4 pb-2 ps-0 pe-0'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='next'
              label='Número exterior *'
            >
              <StyledInput 
                type='text'
                name='next' 
                placeholder='Número exterior *'
                error={errors?.next?.type === 'required' ? 1 : 0}
                {...register('next', {
                  required: 'Campo obligatorio'
                })}
                value={solData?.next}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='nint'
              label='Número interior (opcional)'
            >
              <StyledInput
                name='nint'
                type='text'
                placeholder='Número interior (opcional)'
                {...register('nint')}
                value={solData?.nint}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
        </Row>
        <Row className='w-100 mb-4 pt-2 pb-2 ps-0 pe-0'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={`${width <= 1200 ? 'p-0 mb-4 pb-2' : 'ps-0 pb-2'}`}
          >
            <StyledFloating
              controlId='cp'
              label='Código postal *'
            >
              <StyledInput
                type='text'
                name='cp'
                placeholder='Código postal *'
                error={errors?.cp?.type === 'required' ? 1 : 0}
                {...register('cp', {
                  required: 'Campo obligatorio'
                })}
                value={solData?.cp}
                onChange={(e) => {
                  handleForm(e);
                }}
              />
            </StyledFloating>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='colonia'
              label='Colonia *'
            >
              <StyledInput
                name='colonia'
                type='text'
                placeholder='Colonia *'
                error={errors?.colonia?.type === 'required' ? 1 : 0}
                {...register('colonia', {
                  required: 'Campo obligatorio'
                })}
                value={solData?.colonia}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
        </Row>
        <Row className='w-100 pt-2 mb-4 pb-2 ps-0 pe-0'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='state'
              label='Estado *'
            >
              <StyledSelect 
                name='state'
                error={errors?.state?.type === 'required' ? 1 : 0}
                {...register('state', {
                  required: 'Campo obligatorio'
                })}
                onChange={(e) => handleForm(e)}
              >
                <option
                  value={''}
                  hidden>
                    Selecciona un estado
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state.name}>
                    {state.name}  
                  </option>
                ))}
              </StyledSelect>
            </StyledFloating>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='city'
              label='Ciudad *'
            >
              <StyledInput
                name='city'
                type='text'
                error={errors?.city?.type === 'required' ? 1 : 0}
                placeholder='Ciudad *'
                {...register('city', {
                  required: 'Campo obligatorio'
                })}
                value={solData?.city}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
        </Row>
        <Row className='mb-4 p-0'>
          <LabelText className='p-0'>
            Entre calles (Opcional)
          </LabelText>
        </Row>
        <Row className='w-100 pt-2 mb-4 pb-2 ps-0 pe-0'>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='street2'
              label='Calle 1'
            >
              <StyledInput
                type='text'
                name='street2'
                placeholder='Calle 1'
                {...register('street2')}
                value={solData?.street2}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledFloating
              controlId='street3'
              label='Calle 2'
            >
              <StyledInput
                name='street3'
                type='text'
                placeholder='Calle 2'
                {...register('street3')}
                value={solData?.street3}
                onChange={(e) => handleForm(e)}
              />
            </StyledFloating>
          </Col>
        </Row>
        <Row className='mb-4 p-0'>
          <LabelText className='p-0'>
            Indicaciones adicionales (Opcional)
          </LabelText>
        </Row>
        <Row className='w-100 pt-2 mb-4 pb-2 ps-0 pe-0'>
          <Col
            xs={12}
            className={width <= 1200 ? 'p-0' : 'mb-0 p-0'}
            style={{ paddingLeft: '0' }}
          >
            <StyledInput
              style={{ height: '100px', maxHeight: '250px' }}
              className='w-100'
              as='textarea'
              name='floatingIndications'
              type='text'
              placeholder='Escribe las indicaciones aquí'
              {...register('indications')}
              value={solData?.indications}
              onChange={(e) => handleForm(e)}
            />
          </Col>
        </Row>
        <Row className='w-100 ps-0 pe-0'>
          <ContinueButton
            type='submit'
            className='pt-2 pb-2'
            disabled={props.isLoading ? true : false}
          >
            {props.isLoading ? 'Cargando...' : 'Continuar'}
          </ContinueButton>
        </Row>
      </Row>
    </Form>
  );
}
