import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UilHeart } from '@iconscout/react-unicons';
import { AiFillHeart } from 'react-icons/ai';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { toastSweet } from '../../helpers/sweetAlert2';

const StudyContainer = styled.div`
  justify-content: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px #f6f6f6;
  width: 100%;
  border-radius: 16px;
  padding: 0 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  > svg:hover {
    cursor: pointer;
  }
  @media only screen and {
    max-width: 1200px;
  }
   {
    padding: 18px 12px;
  }
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px auto;
  margin-top: -12px;
  margin-bottom: -12px;
  @media only screen and {
    min-width: 768;
  }
   {
    margin-left: 0px;
  }
`;

const Favorite = styled(UilHeart)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 17.33px;
`;

const FavoriteFilled = styled(AiFillHeart)`
  color: #ff004d;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 17.33px;
`;

const Title = styled.h3`
  text-align: left;
  margin-top: 25px;
  > a {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */
    letter-spacing: -0.03em;
    color: #00ad90;
    :hover {
      color: #00ad90;
      text-decoration: underline;
    }
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  background-color: #f9ad62;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 26px;
  margin-bottom: 8px;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;

const PayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f9ad62;
  border: none;
  border-radius: 20px;
  padding: 8px 26px;
  margin-top: 8px;
  border: 2px solid #f9ad62;
  background: #fff;
  :hover {
    transition: all 0.4s ease;
    background: #ffecd9;
  }
`;

const Prices = styled(Row)`
  background: linear-gradient(180deg, #85b4d2 -35.38%, #8acca4 166.15%);
  border-radius: 5px;
  width: 100%;
  color: #fff;
  margin: 8px auto 18px auto;
  padding-top: 9px;
  p {
    margin-bottom: 7px;
  }
  #linea {
    margin-top: 2px;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
  }
  #sucursal {
    margin-top: 2px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
  #price-online {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    margin-left: -20px;
  }
  #price-sucursal {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: right;
  }
`;

const Detail = styled(Row)`
  padding: 16px 18px;
  > div h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #00ad90;
    margin-bottom: 6px;
  }
  > div p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #45494a;
    margin-bottom: 10px;
  }
`;

export const StudyL = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(false);
  const isLogged = useSelector((state) => state.auth.uid);
  const [viewDetail, setViewDetail] = useState(false);
  const { title, description, price, id } = props.data;
  const history = useHistory();
  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 1200;

  const addStudy = () => {
    const exists = items.filter((item) => item.id === props.data.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(props.data));
    }
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === props.data.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(props.data));
      history.push('/checkout');
    }
  };

  const toggleFav = () => {
    if (isLogged) {
      setFavorite(!favorite);
      // TODO: Add to favorites in user profile
    } else {
      history.push('/registro');
    }
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para ` +
      props.data.title;
  };

  return (
    <>
      <div className='col-12'>
        <StudyContainer data-aos='fade-up'>
          {props.data.tag ? (
            <Tag
              style={{
                backgroundColor: props.data.tag.background,
                color: props.data.tag.color,
              }}
            >
              {props.data.tag.content}
            </Tag>
          ) : null}
          {/* {favorite ? <FavoriteFilled onClick={()=>{toggleFav()}}/> : <Favorite onClick={()=>{toggleFav()}}/> } */}
          <Row>
            {isMobile ? (
              <>
                <Col xs={8} style={{ paddingRight: '0px' }}>
                  <Title>
                    <a href={`/estudio/${props.data.id}`}>{props.data.title}</a>
                  </Title>
                  <Prices>
                    <Col xs={5} style={{ paddingRight: '0px' }}>
                      {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
                        <p id='linea'>En línea </p>
                      ) : (
                        <></>
                      )}
                      <p id='sucursal'>En sucursal</p>
                    </Col>
                    <Col
                      xs={7}
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'right !important',
                      }}
                    >
                      {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
                        <p id='price-online'>
                          {currency(props.data.price)} MXN
                        </p>
                      ) : (
                        <></>
                      )}
                      <p id='price-sucursal'>
                        {currency(props.data.oldPrice)} MXN
                      </p>
                    </Col>
                  </Prices>
                </Col>
                <Col
                  xs={4}
                  className='d-flex justify-content-center align-items-center'
                  style={{ flexDirection: 'column' }}
                >
                  {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
                    <>
                      <AddButton onClick={() => addStudy()}>Agregar</AddButton>
                      <PayButton onClick={() => payStudy()}>
                        Pagar
                      </PayButton>{' '}
                    </>
                  ) : (
                    <AddButton onClick={() => callToSchedule()}>
                      Llamar para agendar
                    </AddButton>
                  )}
                </Col>
                <Col xs={12}>
                  <p
                    style={{
                      color: '#C4C4C4',
                      textAlign: 'center',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onClick={() => setViewDetail(!viewDetail)}
                  >
                    <span style={{ textDecoration: 'underline' }}>
                      Ver detalles
                    </span>{' '}
                    {viewDetail ? (
                      <UilAngleUp size='28' />
                    ) : (
                      <UilAngleDown size='28' />
                    )}
                  </p>
                </Col>
              </>
            ) : (
              <>
                <Col lg={12} xl={3}>
                  <Title>
                    <a href={`/estudio/${props.data.id}`}>{props.data.title}</a>
                  </Title>
                  <p
                    style={{
                      color: '#C4C4C4',
                      textAlign: 'left',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onClick={() => setViewDetail(!viewDetail)}
                  >
                    <span style={{ textDecoration: 'underline' }}>
                      Ver detalles
                    </span>{' '}
                    {viewDetail ? (
                      <UilAngleUp size='28' />
                    ) : (
                      <UilAngleDown size='28' />
                    )}
                  </p>
                </Col>
                <Col
                  lg={12}
                  xl={7}
                  className='d-flex justify-content-center align-items-center'
                >
                  <Prices style={{ marginTop: isMobile && '-20px' }}>
                    <Col xs={6} style={{ paddingRight: '0px' }}>
                      <p id='linea'>Pago en línea</p>
                      <p id='sucursal'>Pago en sucursal</p>
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: '0px',
                        textAlign: 'right !important',
                      }}
                    >
                      <p id='price-online'>{currency(props.data.price)} MXN</p>
                      <p id='price-sucursal'>
                        {currency(props.data.oldPrice)} MXN
                      </p>
                    </Col>
                  </Prices>
                </Col>
                <Col
                  xl={2}
                  className='d-flex justify-content-center align-items-center'
                  style={{ flexDirection: 'column' }}
                >
                  {props.data.categoria !== 10 && props.data.categoria !== 8 ? (
                    <>
                      <AddButton onClick={() => addStudy()}>Agregar</AddButton>
                      <PayButton onClick={() => payStudy()}>Pagar</PayButton>
                    </>
                  ) : (
                    <AddButton
                      onClick={() => callToSchedule()}
                      style={{ marginTop: '5px' }}
                    >
                      Llamar para agendar
                    </AddButton>
                  )}
                </Col>
              </>
            )}
          </Row>
          {viewDetail && (
            <Detail>
              {props.data.tEntrega && (
                <Col 
                  sm={12} 
                  md={6} 
                  lg={4}
                  xl={3}
                >
                  <h4>Tiempo de entrega</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.data.tEntrega }}
                  ></div>
                </Col>
              )}
              {props.data.muestra && (
                <Col 
                  sm={12} 
                  md={6} 
                  lg={4}
                  xl={3}
                >
                  <h4>Tipo de muestra</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.data.muestra }}
                  ></div>
                </Col>
              )}
              {props.data.indicaciones && (
                <Col
                  sm={12}
                  md={6} 
                  lg={4}
                  xl={3}
                >
                  <h4>Indicaciones</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.data.indicaciones,
                    }}
                  ></div>
                </Col>
              )}
              {props.data.info && (
                <Col
                  sm={12}
                  md={6} 
                  lg={4}
                  xl={3}
                >
                  <h4>Información</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.data.info,
                    }}
                  ></div>
                </Col>
              )}
            </Detail>
          )}
        </StudyContainer>
      </div>
    </>
  );
};
