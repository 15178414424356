import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { toastSweet } from '../../helpers/sweetAlert2';

const Card = styled.div`
  margin: 0 auto 74px;
  width: 92%;
  max-width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-top: 52px;
  padding-bottom: 26px;
  div {
    width: 90%;
    margin: 0 auto;
    max-width: 300px;
    margin-bottom: 28px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #00AD90;
  }
  h3 {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 46px;
    color: #24CAD3;
    padding-left: 32px;
  }
  li {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #45494A;
  }
  a {
    width: 100% !important;
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F9AD62;
    text-decoration: underline;
  }
`;

const Background = styled.div`
  background: white;
  width: 100%;
  padding-top: 26px;
  @media only screen and (min-width: 768px) {
    padding-top: 80px;
  }
`;

const BlueDiv = styled.div`
  background: #FA5067;
  border-radius: 16px;
  padding: 53px 10px 42px;
  width: 94%;
  margin: 0 auto 39px;
  max-width: 90%;
  p {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 13px;
  }
  #last-price {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
  }
  #price {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
  }
`;

const OrangeButton = styled.button`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  height: 48px;
  background: #FA5067;
  border-radius: 24px;
  border: none;
  padding: 0;
  margin-top: 6px;
  margin-bottom: 10px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #2cdce6;
    border: 2px solid #2cdce6;
  }
`;

const WhiteButton = styled.button`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #24CAD3;
  width: 100%;
  height: 48px;
  background: none;
  border: 2px solid #24CAD3;
  border-radius: 24px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #2cdce6;
    color: #FFF;
    border-color: #2cdce6;
  }
`;

const GrayBg = styled.div`
  width: 100%;
  background: #24CAD3;
  padding: 50px 22px;
  h1 {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* or 117% */
    text-align: center;
    color: #FFFFFF;
    @media only screen and (max-width: 568px){
      max-width: 328px;
    }
  }
  button {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100% !important;
    max-width: 398px;
    margin: 0;
    font-weight: 800;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    @media only screen and (min-width: 768px){
      max-width: 470px;
      font-size: 24px;
      line-height: 36px;      
    }
  }
`;

export const StartNow = ({study, setShowCart}) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(study));
      history.push('/checkout');
    }
  };


  return (
    <Background>
      <BlueDiv>
        <p>Inscripción*: $500 MXN</p>
        {/*<p id='last-price'>$6,500.00 MXN</p>
        <p id='price'>$6,000.00 MXN</p>*/}
        <p>+ 3 pagos mensuales de $2,000 MXN</p>
        <p>*Al inscribirte recibirás nuestra cuponera</p>
      </BlueDiv>
      <Card>
        <div>
          <h3>Incluye:</h3>
          <ul>
            <li>Kit de bienvenida</li>
            <li>Check up: análisis de laboratorio</li>
            <li>4 consultas de nutrición con plan de alimentación</li>
            <li>4 consultas de acondicionamiento</li>
            <li>4 consultas de psicología</li>
            <li>3 mediciones de antropometría a través de la báscula InBody para conocer tu composición corporal</li>
            <li>Registro saludable</li>
            <li>Videos educativos</li>
            <li>12 clases/talleres a lo largo de los tres meses (1 por semana)</li>
            <li>Material para talleres</li>
            <li>Diploma por haber concluido el programa de manera satisfactoria</li>
          </ul>
          <OrangeButton onClick={() => addStudy()}>
            Aparta tu lugar
          </OrangeButton>
          {/*<WhiteButton  onClick={() => payStudy()}>
            Pagar ahora
          </WhiteButton>*/}
        </div>
      </Card>
      {/*<GrayBg>
        <h1>¿Quieres conocer tu estado de salud actual?</h1>
        <div className='d-flex w-100 justify-content-center'>
          <OrangeButton onClick={(e) => {
             e.preventDefault();
             window.location.href='https://www.proprofs.com/quiz-school/ugc/story.php?title=test-de-hbitos-nutricios';
          }}>
              Tomar cuestionario de hábitos</OrangeButton>
        </div>
      </GrayBg>*/}
    </Background>
  );
};
