import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UilQrcodeScan } from '@iconscout/react-unicons';
import { QrReader } from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { addItemToCart, addPersonalData, getTotalPrice, handleAddItemToCart, removeItemFromCart, resetCart, setTotalPrice } from '../../actions/shoppingCart';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { updateCartDB } from '../../helpers/api';
import { ListItemCart } from '../shopping-cart/listItemCart';

const Title = styled.h1`
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    color: #fff;
    width: 180px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
  }
  .green {
    background: #00AC90;
  }
  .red {
    background: #B53737;
  }
`;

const Total = styled.h5`
  color: #45494a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #c1d9d8;
  position: relative;
  span {
    position: absolute;
    right: 0px;
    color: #5731B2;
    text-align: right;
  }
`;

const OrangeButton = styled.button`
  width: 80%;
  height: 48px;
  background: linear-gradient(180deg, #5731B2 -1.87%, #77CCD5 100%);
  border-radius: 30px !important;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 16px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid #5731B2;
    color: #5731B2;
  }
  :disabled {
    background: #c4c4c4;
    color: #fff;
    border: 2px solid #c4c4c4;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  height: 48px;
  background: none;
  color: #77CCD5 !important;
  border: 2px solid #77CCD5 !important;
  border-radius: 30px !important;
  font-size: 16px;
  margin-bottom: 16px;
  transition: all 0.4s ease;
  :hover {
    color: #fff !important;
    transition: all 0.4s ease;
    background: #77CCD5;
  }
`;

const Bottom = styled.div`
  background: #fff;
  border-top: 1px solid #c1d9d8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  p {
    text-align: center;
    margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  span {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #00ad90;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  input {
    width: 20px;
    height: 20px;
    border: 2px solid #00ad90;
  }
  label {
    margin-bottom: -20px;
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  padding-right: 3px;
  margin-right: -5px;
  max-height: 40vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const CompleteAppointment = (props) => {
  const { items, totalPrice, personalData } = useSelector((state) => state.shoppingCart);
  const [error, setError] = useState();
  const [pay, setPay] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [pagoSucursal, setPagoSucursal] = useState(false);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var order = urlParams.get("order");
  var isOrder = urlParams.get('doctor');
  // order id to test 162803

  const getData = async (id) => {
    try {
      const appointment = await axios.get(`https://covid19-cc1fe.uc.r.appspot.com/get-order-detail?id=${id}`);
      if (appointment?.data) {
        console.log('RES: ', appointment.data);
        dispatch(resetCart());
        appointment.data.estudios.map((item)=>{
          dispatch(handleAddItemToCart({...item, title: item.name, price: item.normal_price , oldPrice: item.normal_price}));  
        })
        dispatch(addPersonalData(appointment.data.solicitud));
      } else {
        setError('No se encontró no se encontraron datos de tu solicitud');
      }
    } catch (error) {
      setError('Hubo un error al acceder a tu solicitud');
    }
  };

  useEffect(()=>{
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    getData(order);
  },[]);

  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log('IF');
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log('DATA: ', data);
    }

    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  return (
    <MainDiv>
      <Container style={{paddingLeft: '20px', paddingRight: '20px'}}>
      <div style={{ position: 'relative', paddingBottom: '20px' }}>
        <Title>Te damos la bienvenida a nuestro laboratorio</Title>
        <p>{personalData?.doctorTexto}ha iniciado una solicitud para realizarte los estudios:</p>
        <ListDiv>
          {items.map((item) => (
            <ListItemCart
              key={item.id}
              item={item}
              onDelete={handleRemoveItemFromCart}
            />
          ))}
        </ListDiv>
        <Total>
          Total a pagar: <span>{currency(totalPrice)} MXN</span>
        </Total>
        <br />
      </div>
      </Container>
      <Bottom>
        <OrangeButton
          onClick={() => {
            window.location.href = `/checkoutVG?order=${order}`;
            props.onClose();
          }}
          disabled={items?.length === 0}
        >
          Pagar en línea
        </OrangeButton>
        {/*<WhiteButton
          onClick={() => {
            window.location.href = `/checkoutVG?sucursal=1&order=${order}`;             
            props.onClose();
          }}
        >
          Pagar en sucursal
        </WhiteButton>*/}
      </Bottom>
    </MainDiv>
  );
}