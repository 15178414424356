import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  startFacebookLogin,
  startGoogleLogin,
  startLoginWithPhoneNumber,
  startRegisterWithEmailPasswordName,
  verifiedPhone,
} from '../../actions/auth';
import facebookIcon from '../../assets/social-media-icons/facebook-icon.png';
import googleIcon from '../../assets/social-media-icons/google-icon.png';
import { UilEye, UilEyeSlash, UilCheckCircle } from '@iconscout/react-unicons';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';

const DataForm = styled(Form)`
  font-family: 'Assistant', sans-serif;
  width: 100%;
  max-width: 360px;
  .form-floating {
    position: relative;
    .icon-see-password,
    .icon-right-email {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    .icon-see-password {
      cursor: pointer;
    }
  }
  .not-coincidence {
    outline: none;
    -webkit-box-shadow: 0px 0px 5px 5px #ff000080;
    box-shadow: 0px 0px 5px 5px #ff000080;
  }
`;

const StyledFloating = styled(FloatingLabel)`
  margin-top: 28px;
  .form-select ~ label {
    transform-origin: -8em -5em !important;
    background-color: #ffffff;
    color: #a6a6a6;
    opacity: 1;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding: 0px 9px;
  }
  width: 100%;
  padding: 0;
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'Assistant', sans-serif;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 200ms;
  }
  :active,
  :focus-within {
    .form-select ~ label {
      padding: 0px 9px;
      background-color: #ffffff;
    }
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 5px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  width: 100%;
  background: #ffffff;
  border: ${(props) =>
    props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6'};
  border-radius: 12px;
  max-height: 45px;
  height: 100%;
  padding: 0px 40px 0px 40px !important;
  z-index: 1;
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) =>
      props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
    border-style: dashed;
    color: ${(props) => (props.disabled === 1 ? '#a6a6a6;' : '#000000')};
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  ::placeholder {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

const LogContainer = styled.div`
  width: 100%;
  margin-left: 5%;
  max-width: 490px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 40px 0;
  margin: 0 auto;
  h1 {
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #3b3b3b;
  }
  h2 {
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    a {
      font-family: 'Assistant', sans-serif;
      color: #77CCD5;
      text-decoration: underline;
    }
  }
  hr {
    width: 100%;
    border: 1px solid #c1d9d8;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .phone-row {
    width: 100%;
    max-width: 358px;
    margin-bottom: 24px;
  }
`;

const GreenButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  height: 40px;
  background: #77CCD5;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const SignInOptions = styled(Row)`
  flex-direction: row !important;
  width: 100%;
  max-width: 483px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  p {
    margin-bottom: 24px;
  }
`;

const Circle = styled.div`
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  box-shadow: 0px 4px 4px #f6f6f6;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`;

const InputStyled = styled(Form.Check)`
  display: flex;
  margin-top: 26px;
  > input {
    width: 20px !important;
    height: 20px !important;
    border: 1px solid #77CCD5 !important;
    border-radius: 0px !important;
    :checked {
      border: 1px solid #77CCD5 !important;
      background-color: #77CCD5 !important;
    }
    :focus {
      outline: none;
      -webkit-box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 2px;
      box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 2px;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #3b3b3b;
    margin-top: 3px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  a {
    color: #77CCD5;
  }
`;

export const RegisterForm = () => {
  const [terms, setTerms] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(false);

  const dispatch = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    dispatch(verifiedPhone(phone, false, 'Dispatch', ''));
  }, [dispatch, phone]);

  useEffect(() => {
    if (showCaptcha) {
      if (document.getElementById('recaptcha-container') && phone?.length > 6) {
        console.log('Entra al segundo if');
        try {
          let appVerifier = window.recaptchaVerifier;
          appVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'normal',
              callback: () => {
                if (phone.length > 10) {
                  dispatch(
                    startLoginWithPhoneNumber(phone, appVerifier, () => {
                      setShowCaptcha(false);
                      setHideCaptcha(false);
                    })
                  );
                  setHideCaptcha(true);
                  // setCaptcha(true);
                } else {
                  alert('NO HAY NÚMERO');
                }
              },
              'expired-callback': () => {
                setShowCaptcha(false);
                setHideCaptcha(false);
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
              },
            },
            auth
          );
          appVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
          });
          console.log('OK RECAPTCHA');
        } catch (error) {
          // TODO: handle error when reCAPTCHA doesn't work
          console.log(String(error).split('.')[0]);
        }
      }
    }
  }, [showCaptcha, auth, dispatch, phone]);

  const checkPattern = () => {
    return /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i.test(email);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    try {
      dispatch(
        startRegisterWithEmailPasswordName(
          data.email,
          data.password,
          '',
          '',
          '',
          ''
        )
      );
    } catch (error) {
      setTerms(false);
      console.log(error);
    }
  };

  const handleGoogleRegister = () => {
    dispatch(startGoogleLogin(phone));
  };

  const handleFacebookRegister = () => {
    dispatch(startFacebookLogin(phone));
  };

  return (
    <LogContainer>
      <DataForm onSubmit={handleSubmit(onSubmit)}>
        <h1>Crea tu cuenta</h1>
        <h2>
          ¿Ya tienes cuenta? <Link to='/iniciar-sesion'>Inicia sesión</Link>
        </h2>
        <StyledFloating controlId='email' label='Correo electrónico'>
          <StyledInput
            {...register('email', {
              required: true,
              pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
            })}
            type='email'
            placeholder='pruebas@labopat.mx'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name='email'
          />
          {checkPattern() && (
            <UilCheckCircle color='#77CCD5' className='icon-right-email' />
          )}
        </StyledFloating>
        <StyledFloating controlId='password' label='Contraseña'>
          <StyledInput
            {...register('password', {
              required: true,
            })}
            type={showPassword ? 'text' : 'password'}
            placeholder='********'
            value={password}
            name='password'
            onChange={(e) => setPassword(e.target.value)}
          />
          {showPassword ? (
            <UilEye
              color='#77CCD5'
              className='icon-see-password'
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <UilEyeSlash
              color='#77CCD5'
              className='icon-see-password'
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </StyledFloating>
        <StyledFloating controlId='passwordRepeat' label='Confirmar contraseña'>
          <StyledInput
            {...register('passwordRepeat', {
              required: true,
            })}
            type={showPasswordRepeat ? 'text' : 'password'}
            placeholder='********'
            value={passwordRepeat}
            name='passwordRepeat'
            onChange={(e) => setPasswordRepeat(e.target.value)}
            className={
              passwordRepeat.length >= password.length &&
              password !== passwordRepeat
                ? 'not-coincidence'
                : ''
            }
          />
          {showPasswordRepeat ? (
            <UilEye
              color='#77CCD5'
              className='icon-see-password'
              onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
            />
          ) : (
            <UilEyeSlash
              color='#77CCD5'
              className='icon-see-password'
              onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
            />
          )}
        </StyledFloating>
        <InputStyled
          {...register('terms', {
            required: true,
          })}
          key='check-terms'
          id='check-terms'
          type='checkbox'
          name='terms'
          label={
            <p>
              Acepto{' '}
              <a
                href='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Te%CC%81rminos%20y%20condiciones%202.pdf?alt=media&token=e828a534-fb14-440f-9f58-2a7059c204c2&_gl=1*1gfdva1*_ga*MTE4MzY3NDk3OS4xNTk5NjY4Mzg5*_ga_CW55HF8NVT*MTY4NjA3NDM1Ni4xOS4xLjE2ODYwNzU1MzQuMC4wLjA.'
                target='_blank'
                rel='noreferrer'
              >
                términos y condiciones
              </a>
            </p>
          }
          checked={terms}
          onChange={() => setTerms(!terms)}
        />

        <GreenButton
          variant='primary'
          type='submit'
          disabled={
            password.length < 1 ||
            email.length < 1 ||
            password !== passwordRepeat
          }
        >
          Crear cuenta
        </GreenButton>
      </DataForm>
      <hr />
      <SignInOptions>
        <Col xs={{ span: 4, offset: 2 }}>
          <Circle onClick={handleFacebookRegister}>
            <img
              src={facebookIcon}
              style={{ width: '20px', height: '20px' }}
              alt='facebook-icon'
            />
          </Circle>
          <p>Facebook</p>
        </Col>
        <Col xs={{ span: 4 }}>
          <Circle onClick={handleGoogleRegister}>
            <img
              src={googleIcon}
              style={{ width: '20px', height: '20px' }}
              alt='google-icon'
            />
          </Circle>
          <p>Gmail</p>
        </Col>
      </SignInOptions>
      <p style={{ color: '#3B3B3B', fontSize: '16px', fontWeight: '400' }}>
        O usa tu número celular para registrarte
      </p>
      <Row className='phone-row'>
        <StyledPhoneInput
          international
          type='tel'
          defaultCountry='MX'
          placeholder='Teléfono celular'
          id='phoneNumber'
          value={phone}
          onChange={setPhone}
        />
      </Row>
      <p>
        Enviaremos un código por mensaje de texto al número que proporcionaste.
      </p>
      {!showCaptcha && (
        <GreenButton
          variant='primary'
          onClick={() => {
            setShowCaptcha(true);
          }}
          disabled={phone === undefined || phone?.length < 12}
        >
          Enviar código
        </GreenButton>
      )}
      {showCaptcha && (
        <Row style={{ display: hideCaptcha ? 'none' : 'block' }}>
          <div id='recaptcha-container' className='recaptcha-container' />
        </Row>
      )}
      <div id='div-for-code'></div>
    </LogContainer>
  );
};
