import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";


const Banner = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Finvitacion.png?alt=media&token=413f2c7c-5141-4f55-8e11-8fa9afbeaa2a")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FQuizGlutenMov.png?alt=media&token=fc6a153f-6b33-4960-b6b9-03d5cf5793c5")
      center center/cover no-repeat;
  }
`;

const ContainerDiv = styled.section`
  position: relative;
  width: 100%;
  height: 97vh;
`;

export const Invitacion = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 428;
  return (
    <>
      <HelmetTitle title="Quiz Gluten" name="description" content="content" />
      {!isMobile ? (
        <Banner>
          <ContainerDiv></ContainerDiv>
        </Banner>
      ) : (
        <Banner>
          <ContainerDiv></ContainerDiv>
        </Banner>
      )}
    </>
  );
};
