import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { Start } from './start';
import { StepOne } from './stepOne';
import { StepTwo } from './stepTwo';
import { StepThree } from './stepThree';
import { Congratulations } from './congratulations';
import { useSelector } from 'react-redux';
import { db } from '../../firebase/firebase-config';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { toastSweet } from '../../helpers/sweetAlert2';
import axios from 'axios';
import { Loader } from '../general/spinner';

const KitSection = styled.div`
  min-height: 82vh;
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const HeaderTitle = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #77ccd5;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const StyledHr = styled.hr`
  background-color: #4f90ba;
  height: 1px !important;
  margin: 0;
`;

const StepCount = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #121619;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const StyledContainer = styled(Container)`
  @media only screen and (max-width: 768px) {
    margin-top: 3rem;
    padding-top: 1.5rem;
  }
`;

export const Kit = () => {
  const uid = useSelector((state) => state?.user?.data?.uid);
  const [step, setStep] = useState(0);
  const [belongs, setBelongs] = useState(0);
  const [personalData, setPersonalData] = useState();
  const [solData, setSolData] = useState();
  const [codeUsed, setCodeUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);

  const nextStep = () => {
    window.scrollTo(0, 0)
    setStep(step + 1);
  };

  const getKitInfo = async (data) => {
    try {
      let kitInfo = await axios.get(`https://clienteslabopat.com/get-kit-info?kit=${data?.kitCode}`).then((response) => {
        if(response?.data?.ok) {
          setSolData(response?.data?.data);
          setPersonalData(data);
          setIsLoading(false);
          setCodeUsed(false);
          setValidated(true);
        } else {
          setIsLoading(false);
          toastSweet('warning', 'Ha ocurrido un error', 2000);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const activateKit = async () => {
    try {
      const collectionRef = collection(db, 'kits', `${uid}`, 'userKits');
      await setDoc(doc(collectionRef, `${personalData?.kitCode}`), {
        ...personalData, otraPersona: belongs
      });
    } catch (error) {
      console.log(error);
    }
  };

  const verifyKitCode = async (data) => {
    setIsLoading(true);
    console.log(data);
    const KitRef = doc(db, 'kits', `${uid}`, 'userKits', `${data?.kitCode}`);
    const kitSnap = await getDoc(KitRef);
    if(kitSnap.exists()) {
      setIsLoading(false);
      setCodeUsed(true);
      setValidated(false);
      toastSweet('warning', 'El código ya ha sido utilizado')
    } else {
      getKitInfo(data);
    }
  };

  const updateMyKitInfo = async (data) => {
    var formData = new FormData();
    formData.append('id', solData?.id);
    formData.append('name', data.name);
    formData.append('street', data.street);
    formData.append('street2', data?.street2 ? data.street2 : '');
    formData.append('street3', data?.street3 ? data?.street3 : '');
    formData.append('indications', data?.indications ? data?.indications : '');
    formData.append('next', data.next);
    formData.append('nint', data?.nint ? data?.nint : '');
    formData.append('colonia', data.colonia);
    formData.append('city', data.city);
    formData.append('state', data.state);
    formData.append('cp', data.cp);
    formData.append('otraPersona', belongs);
    try {
      setIsLoading(true);
      let request = await axios.post('https://clienteslabopat.com/update-kit-info', formData).then((res) => {
        if(res?.data?.ok) {
          setShowAddressModal(false);
          getKitInfo({kitCode: solData?.idVial}).then(() => {
            setIsLoading(false);
            toastSweet('success', 'Datos actualizados', 2500);
          });
        } else {
          toastSweet('error', 'No se encontró la solicitud', 2500);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const updateOtherKitInfo = async (data) => {
    var formData = new FormData();
    formData.append('id', solData?.id);
    formData.append('name', data.name);
    formData.append('street', data?.street2 ? data.street : '');
    formData.append('street2', data?.street2 ? data.street2 : '');
    formData.append('street3', data?.street3 ? data?.street3 : '');
    formData.append('indications', data?.indications ? data?.indications : '');
    formData.append('next', data.next);
    formData.append('nint', data?.nint ? data?.nint : '');
    formData.append('colonia', data.colonia);
    formData.append('city', data.city);
    formData.append('state', data.state);
    formData.append('cp', data.cp);
    formData.append('otherEmail', data.email);
    formData.append('otherPhone', data?.phone ? data?.phone : '');
    formData.append('fnacimiento', `${data.year}-${data.month}-${data.day}`);
    formData.append('genero', data?.gender ? data?.gender : 'NE');
    formData.append('otraPersona', belongs);
    try {
      setIsLoading(true);
      let request = await axios.post('https://clienteslabopat.com/update-kit-info', formData).then((res) => {
        if(res?.data?.ok) {
          getKitInfo({kitCode: solData?.idVial}).then(() => {
            setIsLoading(false);
            toastSweet('success', 'Datos actualizados', 2500);
            nextStep();
          });
        } else {
          toastSweet('error', 'No se encontró la solicitud', 2500);
        }
      })
    } catch (error) {
      
    }
  }

  const handleStepOne = async (values) => {
    verifyKitCode(values?.personalData);
  };

  const handleAddressForm = async (values) => {
    if(belongs === 0) {
      updateMyKitInfo(values?.data);
    } else if (belongs === 1 ) {
      updateOtherKitInfo(values?.data);
    }
  };

  const handleStepTwo = async (values) => {
    const mergedState = { ...personalData, ...values.personalData };
    setPersonalData(mergedState);
    nextStep();
  };

  const handleStepThree = async (values) => {
    const mergedState = { ...personalData, ...values.personalData };
    setPersonalData(mergedState);
  };
  
  const handleTestModal = async (values) => {
    nextStep();
    activateKit();
    try {
      let kitInfo = await axios.get(`https://clienteslabopat.com/update-test-vg?fase=12&id=${solData?.id}`).then((response) => {
        if(response?.data?.ok) {
          console.log(response?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
      
  };

  return (
    <>
      <Loader show={isLoading} />
      <StyledContainer>
        <Row className='mb-3'>
          <HeaderSection>
            <HeaderTitle>Activar kit</HeaderTitle>
          </HeaderSection>
        </Row>
        <Row className='mb-5'>
          <div className='p-0'>
            <StyledHr />
          </div>
        </Row>
        {step >= 1 && step < 4 && (
          <Row className='mb-2'>
            <StepCount>{step} de 3</StepCount>
          </Row>
        )}
      </StyledContainer>
      <KitSection>
        {step === 1 ? (
          <StepOne
            isLoading={isLoading}
            codeUsed={codeUsed}
            handleStepOne={handleStepOne}
            personalData={personalData}
            solData={solData}
            handleAddressForm={handleAddressForm}
            validated={validated}
            setValidated={setValidated}
            nextStep={nextStep}
            showAddressModal={showAddressModal}
            setShowAddressModal={setShowAddressModal}
            belongs={belongs}
            setBelongs={setBelongs}
          />
        ) : step === 2 ? (
          <StepTwo handleStepTwo={handleStepTwo} personalData={personalData} />
        ) : step === 3 ? (
          <StepThree
            handleStepThree={handleStepThree}
            personalData={personalData}
            handleTestModal={handleTestModal}
          />
        ) : step === 4 ? (
          <Congratulations />
        ) : (
          <Start continue={nextStep} />
        )}
      </KitSection>
    </>
  );
};
