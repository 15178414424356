import React from "react";
import { Lactosa } from "./lactosa";
import Vimeo from '@u-wave/react-vimeo';
import { Banners } from './banners';
import { StudyLactosa } from "./StudyLactosa";
import { Beneficios } from "./beneficios";
import { Target } from "./target";
import Carroussel from "./carrousel";
import { Reporte } from "./reporte";
import Testimonials from "./testimonials";

export const stuLactosa = () => {
    return (
      <>
        <Lactosa/>
        <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FVideo%203.png?alt=media&token=10eb9d04-cd91-4a54-8ac4-0d74f62f50e1" alt="leche" style={{width: '100%'}}/>
        {/*<Vimeo color='ffbc45' video={'https://player.vimeo.com/video/851738680?h=e7c0323fe5'} responsive={true} autoplay loop muted/>
        <Banners/>*/}
        <Target/>
        <Testimonials/>
        <Beneficios/>
        <Carroussel/>
        <Reporte/>
        <StudyLactosa/>
      </>
    );
  };