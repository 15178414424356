import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UilArrowCircleLeft, UilArrowCircleRight } from '@iconscout/react-unicons';

const Infos = [
  {
    id: 0,
    title: "Talleres teóricos y prácticos",
    image: "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/transforma%2FAdobeStock_121830274%20(1)%201%20(1).png?alt=media&token=1cd3d5f8-f902-48ae-992f-d8e95cdde20d",
  },
  {
    id: 1,
    title: "Consultas  de nutrición",
    image: "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/transforma%2FAdobeStock_121830274%20(1)%202.png?alt=media&token=8d3bb22e-2ab1-402a-82a5-93a7f2a881d8",
  },
  {
    id: 2,
    title: "Consultas de activación física",
    image: "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/transforma%2FAdobeStock_121830274%20(1)%203.png?alt=media&token=7bb2b105-d984-40ce-acec-7d2e1dbaef0e",
  },
  /*{
    id: 3,
    title: "Estudios de laboratorio",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FSlide4.png?alt=media&token=8eff9d24-98d3-49f1-b3bc-fc4a5e36ccc1",
  },*/
];

const Title = styled.h1`
  margin-bottom: 70px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #45494A;
  padding-top: 85px;
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 27px;
  }
`;

const Card = styled.div`
  width: 304px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 3px rgba(8, 43, 45, 0.05);
  img {
    width: 100%;
  }
  div {
    background: #FFFFFF;
    width: 100%;
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      max-width: 227px;
      font-family: 'Assistant';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #24CAD3;
      margin: 0;
    }
  }
`;

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const LogosImage = styled.img`
  width: 100%;
`;

export const SliderProgram = () => {
  const {width} = useWindowDimensions();

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleLeft
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleRight
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
    <div style={{background: "rgba(36, 202, 211, 0.07)"}}>
      <Container>
        <Title>Nuestro programa ofrece:</Title>
        <StyledSlider
          width="100%"
          dots={false}
          infinite={true}
          slidesToShow={width > 992 ? 3 : width > 768 ? 2 : 1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={4000}
          cssEase={'linear'}
          speed={600}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
        >
        {Infos.map((info)=>
          <div className='d-flex justify-content-center' key={info.id}>
            <Card>
              <img src={info.image} alt={info.title}/>
              <div>
                <h1>{info.title}</h1>
              </div>
            </Card>
          </div>
        )}
        </StyledSlider>
        <br/>
      </Container>
    </div>
    <Container>
      <Title>Conoce a nuestros aliados</Title>
      <br/><br/>
      <LogosImage alt='logo' src={width > 768 ? "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/transforma%2FCaptura%20de%20Pantalla%202023-04-14%20a%20la(s)%2010.39.43.png?alt=media&token=d5f51b2e-3f50-43c1-81ef-3b595574272b" : "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/transforma%2FCaptura%20de%20Pantalla%202023-04-14%20a%20la(s)%2010.35.30.png?alt=media&token=974c88a1-5a4a-45b1-911a-a2c5fdedd901"}/>
    </Container>
    </>
  );
};
