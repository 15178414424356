import React from "react";
import styled from "styled-components";
import { Modal, ModalBody } from "react-bootstrap";

const Results =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fp1mov.png?alt=media&token=5b1c8142-33b5-4cb3-9705-094035bb4697";
const Alterations =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fp2mov.png?alt=media&token=28755bcb-78c3-4e8e-acb2-365c0fc1d2c3";
const Biomarkers =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fp3mov.png?alt=media&token=1ff86b73-97a5-4d0e-8e44-bd70ab28d5d7";
const Therapies =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fp4mov.png?alt=media&token=770844dc-9f42-4bc6-9af3-d5f650321ff0";

const DocumentImg = styled.img``;

const StyledModal = styled(Modal)`
  .large {
    max-width: 960px;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 100%;
      margin: 0;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: hidden;
    @media only screen and (max-width: 768px) {
        margin-top: 300px;
      overflow-x: scroll;
      border-radius: 40px 40px 40px 40px !important;
      ::-webkit-scrollbar {
        background-color: #f9f9f9 !important;
        height: 0px !important;
        -webkit-overflow-scrolling: auto !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
  }
`;

export const Modalmov = (props) => {
  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="results-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Results}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="alterations-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Alterations}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="biomarkers-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Biomarkers}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 4 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="therapies-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Therapies}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
