import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const symptoms = [
  {
    name: "Distención abdominal",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F1.png?alt=media&token=7199b068-6cfd-4982-8df5-f9f30bcf547e",
  },
  {
    name: "Estreñimiento",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F2.png?alt=media&token=336efccb-4aef-4744-91eb-72192a76ef12",
  },
  {
    name: "Vómito",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F3.png?alt=media&token=4b00f1dc-0bcf-44b8-b524-bc599503cbb9",
  },
  {
    name: "Diarrea",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F4.png?alt=media&token=f2358e56-a37a-4a31-a0c2-08bc8ed0d660",
  },
  {
    name: "Dolor estomacal",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F5.png?alt=media&token=5a5dcf01-0658-468c-9c7e-32a1613258ca",
  },
  {
    name: "Flatulencias",
    image:
      "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2F6.png?alt=media&token=93bd60fd-aca1-44ac-8b02-162233fe9c33",
  },
];

const BannerB2 = styled.div`
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  padding: 40px 0;
  #col-img1 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 640px;
      @media only screen and (max-width: 1400px) {
        width: 620px;
      }
      @media only screen and (max-width: 768px) {
        width: 90%;
        max-width: 300px;
        margin-bottom: 0px;
      }
    }
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;
const BannerB3 = styled.div`
  width: 100%;
  height: 206px;
  border-radius: 85px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2Ffondo.png?alt=media&token=cb4998b1-a9f6-4b09-b2f9-ed357fdf062e")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FFondomov.png?alt=media&token=d1c59356-32ab-440c-a6ca-b1065f77e966")
      center center/cover no-repeat;
    height: 206px;
    border-radius: 0px;
    overflow: hidden;
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Assistant;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  #test {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    margin-top: revert;
    font-weight: 300;
    line-height: 135%;
    @media only screen and (max-width: 768px) {
      font-size: 33px;
      margin-top: revert;
    }
    @media only screen and (max-width: 576px) {
      font-size: 30px;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 1400px) {
    font-size: 38px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  #subtitle {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 20px;
    width: 27vw;
    font-weight: 400;
    line-height: 120%;
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }
  #symp-desk1 {
    max-width: 450px;
    justify-content: center;
  }
  #desc {
    color: #000;
    border-radius: 15px;
    text-align: center;
    background: #fff4ea;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 9px;
    line-height: normal;
    width: 470px;
    @media only screen and (max-width: 1400px) {
      font-size: 17px;
    }
  }
  .row {
    width: 100%;
  }
  #symp-desk {
    max-width: 450px;
  }
  .symp-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
  }
  .symp-name {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 13px;
    font-weight: 600;
  }
  img {
    width: 80%;
    max-width: 72px;
    margin: 0 auto;
    @media only screen and (max-width: 1400px) {
      max-width: 65px;
    }
  }
  @media only screen and (max-width: 992px) {
    #subtitle,
    #desc {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 768px) {
    #symp-mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    #subtitle,
    #desc {
      font-size: 18px;
      width: 98vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    #subtitle,
    #desc {
      font-size: 16px;
      width: 76vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: -30px;
  font-size: 24px;
  margin-left: 30%;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #24cad3;
  border-radius: 40px;
  border: none;
  text-align: center;
  width: 505px;
  height: 60px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 1400px) {
    margin-left: 28%;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 250px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
  }
`;

export const Target = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isTablet = width <= 768;
  let isMobile = width <= 576;
  return (
    <>
      {!isMobile ? (
        <BannerB2>
          <Container>
            <Row>
              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FChico%20cereal%201.png?alt=media&token=b2fed5d4-c499-4b87-a6fb-e22657a96b10"
                />
              </Col>

              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  Personas que presenten uno o más de los siguientes síntomas:
                </p>

                {/* Symptoms in desktop */}
                <Row id="symp-desk">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  <span style={{ fontWeight: "600" }}>
                    Personas que tengan algún familiar de primer grado
                  </span>{" "}
                  (como padres, hermanos o hijos) que sufra de enfermedad
                  celíaca o alguna otra afección relacionada con el consumo de
                  gluten.
                </p>
              </DescColumn>
            </Row>
            <Row>
              <BannerB3>
                <Title>
                  <p id="test">
                    ¿Esta prueba es para mí? <br />
                    <span style={{ textDecoration: "underline" }}>
                      Descúbrelo
                    </span>{" "}
                    con nuestro quiz.
                  </p>
                </Title>
              </BannerB3>
            </Row>
            <Row>
              <Desc>
                <a href="/quizGluten" target="_blank" rel="noopener noreferrer">
                  Comenzar quiz
                </a>
              </Desc>
            </Row>
          </Container>
        </BannerB2>
      ) : (
        <BannerB2>
          <Container>
            <Row>
              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  Personas que presenten uno o más de los siguientes síntomas:
                </p>

                {/* Symptoms slider in mobile */}
                <Row id="symp-mobile">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  <span style={{ fontWeight: "600" }}>
                    Personas que tengan algún familiar de primer grado
                  </span>{" "}
                  (como padres, hermanos o hijos) que sufra de enfermedad
                  celíaca o alguna otra afección relacionada con el consumo de
                  gluten.
                </p>
              </DescColumn>

              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FChico%20cereal%201.png?alt=media&token=b2fed5d4-c499-4b87-a6fb-e22657a96b10"
                />
              </Col>
            </Row>
          </Container>
          <Row>
            <BannerB3>
              <Title>
                <p id="test">
                  ¿Esta prueba es para mí? <br />
                  <span style={{ textDecoration: "underline" }}>
                    Descúbrelo
                  </span>{" "}
                  con nuestro quiz.
                </p>
              </Title>
            </BannerB3>
          </Row>
          <Row>
            <Desc>
              <a href="/quizGluten" target="_blank" rel="noopener noreferrer">
                Comenzar quiz
              </a>
            </Desc>
          </Row>
        </BannerB2>
      )}
    </>
  );
};
