import React, { useState } from "react";
import "./Slide.css";
import styled from "styled-components";

const Title = styled.h1`
  color: rgb(80, 81, 83);
  font-style: italic;
  text-align: start;
  font-size: 100px;
  font-weight: 900;
  margin-left: -71px;
  margin-left: -200px;
  margin-top: -273px;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
    p {
      margin-top: -70px;
      font-size: 17px;
    }
  }
`;

const Slide = (props) => {
  const { title, subtitle, img, img2 } = props.slide;
  const { className } = props;

  return (
    <>
      <div className={className}>
        {img2 && (
          <div className="img2">
            <img src={img2} alt="" />
          </div>
        )}
        <div className="slidecont">
          <div className="slidetitles">
            <Title>
              {title}
              <br />
              {subtitle}
            </Title>
          </div>
        </div>
        <img src={img} alt="" />
      </div>
    </>
  );
};
export default Slide;
