import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'react-bootstrap';
//assets
import { Menu } from '../menu/mobile';
import { UilBars, UilSearchAlt, UilTimes } from '@iconscout/react-unicons';
import { SearchModal } from '../login/modals/SearchModal';
import useAnalyticsEventTracker from '../../analytics/useAnalyticsEventTracker';
import Logo from '../../assets/navbar/logo.svg';

const Mobile = styled(Row)`
  display: none;
  background: #ffffff;
  padding-top: 1rem !important;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker('Navbar');

  useEffect(() => {
    const modalSearch = document.getElementById('modalSearch');
    const handleOpenModalSearch = () => {
      modalSearch.style.display = 'block';
    };
    if (props.search?.length > 0) {
      handleOpenModalSearch();
    }
  }, [props.search]);

  return (
    <Mobile style={{ display: 'block' }}>
      <Row style={{ paddingBottom: !showSearchInput ? '10px' : '0px' }}>
        <Col
          xs={8}
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
          }}
        >
          <a href='/'>
            <img src={Logo} alt='vitalgene logo' style={{ width: '164px' }} />
          </a>
        </Col>
        <Col
          xs={2}
          sm={{ span: 1, offset: 2 }}
          md={{ span: 1, offset: 2 }}
        >
          <Button
            variant='link'
            size='sm'
            onClick={() => props.showSearch()}
            style={{
              background: '#F1F8F9',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
            }}
          >
            <UilSearchAlt
              size='25'
              color='#1AC7C7'
              style={{ marginLeft: '-2px', marginTop: '-1px' }}
            />
          </Button>
        </Col>
        <Col
          xs={2}
          sm={1}
          md={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.showSearcher ?(
            <Button
              variant='link'
              size='sm'
              onClick={() => props.hideSearch()}
              style={{
                background: '#1AC7C7',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
              }}
            >
              <UilTimes
                size='25'
                color='#fff'
                style={{ marginLeft: '-2px', marginTop: '-1px' }}
              />
            </Button>
          ):(
            <Button
              variant='link'
              size='sm'
              onClick={() => {
                setShowMenu(true);
                gaEventTracker('abrir-buscador');
              }}
              style={{
                background: '#F1F8F9',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
              }}
            >
              <UilBars
                size='25'
                color='#1AC7C7'
                style={{ marginLeft: '-2px', marginTop: '-1px' }}
              />
            </Button>
          )}
        </Col>
      </Row>
      <Menu show={showMenu} setShowMenu={setShowMenu} hide={() => setShowMenu(false)} menu={props.menu} />
      {/* {props.showSearcher && <Searcher search={props.search} />} */}
      <SearchModal search={props.search} />
    </Mobile>
  );
};
