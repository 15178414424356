import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../../../assets/home/hiwbanner.png';
import TextureMobile from '../../../assets/home/textureShippingMobile.png';
import Step1 from '../../../assets/checkout/step1.svg';
import Step2 from '../../../assets/checkout/step2.svg';
import Step3 from '../../../assets/checkout/step3.svg';

const ShippingSection = styled.div`
  padding: 0;
  background-image: url(${Banner});
  background-color: #F1F8F9;
  background-repeat: no-repeat;
  background-size: cover;
  height: 167px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 768px) {
    background-image: url(${TextureMobile});
    background-size: cover;
    height: 360px;
  }
  @media only screen and (max-width: 576px) {
    height: 240px;
  }
`;

const ShippingText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    max-width: 285px;
  }
`;

const HowItWorksTitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 375;
  font-size: 48px;
  line-height: 67px;
  color: #45494A;
  margin: 0;
  width: auto;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const HowItWorksContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0rem;
  }
`;

const StepsCard = styled.div`
  background-color: #FFFFFF;
  padding: 6% 6% 12% 6%;
  border-radius: 16px;
  min-height: 355px;
  .cover {
    max-width: 140px;
    max-height: 140px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const StepTitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 375;
  font-size: 24px;
  line-height: 33px;
  color: #505152;
  margin: 0;
  width: auto;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const StepDesc = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 375;
  font-size: 16px;
  line-height: 22px;
  color: #505152;
  text-align: center;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const HowItWorks = () => {
  return(
    <>
      <ShippingSection className='mb-0 mb-lg-4' id='como-funciona'>
        <Container>
          <Row className='justify-content-center'>
            <ShippingText>
              Envíos a toda la República Mexicana, desde la comodidad de tu casa
            </ShippingText>
          </Row>
        </Container>
      </ShippingSection>
      <HowItWorksContainer>
        <Row className='justify-content-center mb-5'>
          <HowItWorksTitle>
            ¿Cómo funciona?
          </HowItWorksTitle>
        </Row>
        <Row className='justify-content-center mb-4'>
          <Col xs={12} sm={6} md={4} lg={4} className='mb-4'>
            <StepsCard className='h-100'>
              <Row className='justify-content-center mb-4'>
                <img src={Step1} alt='Imagen paso 1' className='img-fluid cover' />
              </Row>
              <Row className='justify-content-center mb-4'>
                <StepTitle>
                  1. Solicita tu prueba
                </StepTitle>
              </Row>
              <Row>
                <StepDesc>
                  Recibe tu kit a domicilio, actívalo y prepárate para hacer tu toma de saliva.{' '}
                  <b>Los kits llegan de 3-5 días hábiles.</b> Envíos express disponibles.
                </StepDesc>
              </Row>
            </StepsCard>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} className='mb-4'>
            <StepsCard className='h-100'>
              <Row className='justify-content-center mb-4'>
                <img src={Step2} alt='Imagen paso 2' className='img-fluid cover' />
              </Row>
              <Row className='justify-content-center mb-4'>
                <StepTitle>
                  2. Recibe tu kit
                </StepTitle>
              </Row>
              <Row>
                <StepDesc>
                  Una vez activado tu kit,{' '}
                    <b>
                      realiza tu toma y recibe tu guía de paquetería{' '}
                    </b>
                  para enviarlo a nuestro laboratorio.
                </StepDesc>
              </Row>
            </StepsCard>
          </Col>
          <Col xs={12} sm={6} md={4} lg={4} className='mb-4'>
            <StepsCard className='h-100'>
              <Row className='justify-content-center mb-4'>
                <img src={Step3} alt='Imagen paso 3' className='img-fluid cover' />
              </Row>
              <Row className='justify-content-center mb-4'>
                <StepTitle>
                  3. Consulta tu resultado
                </StepTitle>
              </Row>
              <Row>
                <StepDesc>
                  Cuando tus resultados estén listos,{' '}
                  <b>
                    te notificaremos por email{' '}
                  </b>
                  y podrás consultarlos ingresando a tu expediente digital.{' '}
                  <b>
                    ¿Listo para descubrir tu ADN?
                  </b>
                </StepDesc>
              </Row>
            </StepsCard>
          </Col>
        </Row>
      </HowItWorksContainer>
    </>
  );
}