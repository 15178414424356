import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import searchIcon from '../../assets/ecommerce/search.png';
import { UilSearchAlt, UilTimes } from '@iconscout/react-unicons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Search } from './search';
import axios from 'axios';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { StudySearch } from '../packages/studySearch';
import { handleAddItemToCart } from '../../actions/shoppingCart';

const SearcherDiv = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  background: #fff;
  padding-bottom: 20px;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const Sub = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #45494a;
`;

const SearchResults = styled.div`
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
`;

const ListDiv = styled(Row)`
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 3px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  max-width: 396px;
  height: 48px;
  background: none;
  color: #1AC7C7;
  border: 2px solid #1AC7C7;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  :hover {
    color: #fff;
    transition: all 0.3s ease;
    background: #1AC7C7;
  }
`;

const CloseButton = styled(Button)`
  background: #1AC7C7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 50px;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const Searcher = (props) => {
  const [searching, setSearching] = useState('');
  const [searchItems, setSearchItems] = useState();
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    searching !== '' &&
      axios
        .get(`https://lpqsystem.com/searching-products-vg?search=${searching}`)
        .then((res) => {
          res.data.productos.map((item) => {
            Object.assign(item, {
              title: item.name,
              price: item.precio_promo,
              oldPrice: item.precio_normal,
            });
          });
          setSearchItems(res.data.productos);
        });
  }, [searching]);

  const handleAddItem = (item) => {
    dispatch(handleAddItemToCart(item));
  };

  return (
    <SearcherDiv>
      <Container style={{ position: 'relative' }}>
        <CloseButton
          variant='link'
          size='sm'
          onClick={() => props.hideSearch()}
        >
          <UilTimes
            size='25'
            color='#fff'
            style={{ marginLeft: '-2px', marginTop: '-1px' }}
          />
        </CloseButton>
        <Title>¿Qué prueba buscas?</Title>
        <Sub>Escribe la prueba que necesitas</Sub>
        <div
          style={{ maxWidth: '548px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <Search
            setSearching={setSearching}
            hideSearch={() => null}
            showSearch={() => null}
          />
        </div>
        {searching !== '' ? (
          <div style={{ position: 'relative' }} id='search-results'>
            <SearchResults>
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: '5px',
                  marginTop: '10px',
                }}
              >
                Sugerencias de la busqueda:
              </p>
              {searchItems?.length !== 0 ? (
                <ListDiv>
                  {searchItems?.map((item, index) => (
                    <StudySearch
                      key={index}
                      data={item}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={4}
                      addItem={handleAddItem}
                      studies={items}
                    />
                  ))}
                </ListDiv>
              ) : (
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '25px',
                    marginTop: '25px',
                    fontSize: '20px',
                  }}
                >
                  No hay resultados
                </p>
              )}
            </SearchResults>
          </div>
        ) : (
          <br />
        )}
        {/* {searching !== '' && searchItems?.length !== 0 && (
          <div className='d-flex justify-content-center'>
            <WhiteButton
              onClick={() => {
                history.push(`/buscador?search=${searching}`);
                props.hideSearch();
              }}
            >
              Mostrar los {searchItems?.length} resultados
            </WhiteButton>
          </div>
        )} */}
      </Container>
    </SearcherDiv>
  );
};
