import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

const BannerBackground = styled.div`
  width: 100%;
  height: 270px;
  overflow: hidden;
  background: #f14cae;
  box-shadow: 0px 16px 9px 0px #25060e33;
  @media only screen and (max-width: 768px) {
    background: #f14cae;
    height: 247px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
    }
    img {
      width: 650px;
      position: absolute;
      bottom: 90px;
      left: 214px;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 86%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 285px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 46px;
  line-height: 120%;
  color: #ffffff;
  margin: 0 auto;
  p {
    line-height: 120%;
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
    font-size: 20px;
    margin-bottom: 20px !important;
  }
`;

const Desc = styled.p`
  width: 53vw;
  margin: 0 auto;
  height: 135px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 74vw;
    margin: 10px;
  }
  @media only screen and (max-width: 576px) {
    margin-left: auto;
    font-size: 13px;
    width: 71vw;
    margin-right: auto;
  }
`;

export const BannerVg70 = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <BannerBackground>
        <Container>
          <Row>
            <DescColumn
              xs={12}
              md={12}
              className={
                isTablet
                  ? "justify-content-start mt-5"
                  : "justify-content-center"
              }
            >
              <Container data-aos="fade-up">
                <Title className={isMobile ? "mb-2 ms-auto me-auto" : "mb-4"}>
                  ¿Qué es la prueba VG 70?
                </Title>
                <Desc>
                  Prueba nutrigenética que analiza 70 variantes en 63 genes que
                  están{" "}
                  <span style={{ fontWeight: "700" }}>
                    asociados con el metabolismo de nutrimentos, intolerancia
                    alimentaria, salud cardiometabólica, control de peso,
                    composición corporal, ejercicio y salud física.
                  </span>
                </Desc>
              </Container>
            </DescColumn>
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
