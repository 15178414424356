import React from "react";
import styled from "styled-components";
import { Modal, ModalBody } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { Genetic } from "./Genetic";
import { Recomen } from "./recomen";
import { Desglose } from "./desglose";
import { Result } from "./result";

const StyledModal = styled(Modal)`
  .large {
    max-width: 850px;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 100%;
      margin: 0;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: hidden;
    @media only screen and (max-width: 768px) {
      overflow-x: scroll;
      border-radius: 40px 40px 40px 40px !important;
      ::-webkit-scrollbar {
        background-color: #f9f9f9 !important;
        height: 0px !important;
        -webkit-overflow-scrolling: auto !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    max-width: 920px;
    width: 820px;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #a42874;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

export const Modalvg = (props) => {
  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="results-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <Result show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="alterations-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <Desglose show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="biomarkers-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <Recomen show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 4 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="therapies-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <Genetic show={props.show} setShowModal={props.setShowModal} />
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
