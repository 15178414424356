import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Mask from '../../../assets/kit/container.png';
import Instructor1 from '../../../assets/home/instructor1.png'
import Instructor2 from '../../../assets/home/instructor2.png'

const InstructorsRow = styled(Row)`
margin-bottom: 1.5rem;
  @media only screen and (max-width: 992px) {
    margin-bottom: 1.5rem;
  }
`;

const PictureContainer = styled.div`
  padding: 0;
  width: auto;
  position: relative;
  justify-content: center;
  max-height: 244px;
  @media only screen and (max-width: 1200px) {
    max-height: 244px;
  }
  @media only screen and (max-width: 992px) {
    max-height: 244px;
  }
`;

const MaskImage = styled.img`
  max-width: 244px;
  max-height: 244px;
  pointer-events: none;
  user-select: none;
`;

const GreenContainer = styled.div`
  position: absolute;
  max-width: 232px;
  max-height: 232px;
  height: auto;
  background-color: #BBF483;
  border-radius: 50%;
  top: -.5em;
  left: ${props => props.type === 1 ? '1.24em' : '-.45em'};
  padding: 0;
  img {
    padding: 0;
    max-width: 244px;
    max-height: 244px;
    pointer-events: none;
    user-select: none;
  }
  @media only screen and (max-width: 991px) {
    max-width: 232px;
    max-height: 232px;
    top: -.5em;
    left: ${props => props.type === 1 ? '1.24em' : '-.45em'};
  }
`;

const BlueContainer = styled.div`
  position: absolute;
  max-width: 244px;
  max-height: 244px;
  height: auto;
  background-color: #77CCD5;
  border-radius: 50%;
  bottom: -.55em;
  right: ${props => props.type === 1 ? '.8em' : '-.8em'};
  img {
    max-width: 244px;
    max-height: 244px;
    padding: 0;
    pointer-events: none;
    user-select: none;
  }
  @media only screen and (max-width: 992px) {
    max-width: 205px;
    max-height: 205px;
    bottom: 2.35em;
    right: ${props => props.type === 1 ? '.7em' : '-.7em'};
  }
  @media only screen and (max-width: 991px) {
    max-width: 244px;
    max-height: 244px;
    bottom: -.55em;
    right: ${props => props.type === 1 ? '.8em' : '-.8em'};
  }
`;

const InstructorPicture = styled.div`
  max-width: 244px;
  max-height: 244px;
  background-color: #f1f8f9;
  border-radius: 50%;
  background-image: ${props => props.type === 1 ? `url(${Instructor2})` : `url(${Instructor1})`};
  background-size: 100%;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
  position: relative;
  position: absolute;
  top: 0;
  padding: 0;
  img {
    padding: 0;
    pointer-events: none;
    user-select: none;
  }
`;

const InstructorName = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #3B3B3B;
  margin: 0;
  @media only screen and (max-width: 576px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Instructors = () => {
  const instructors = [
    {
     name: 'LNCA. Ana Dolores Lerch Rojo' 
    },
    {
     name: 'LNCA. Fernando Iván López de la Huerta' 
    },
  ];
  return(
    <Row className='justify-content-center'>
      {instructors.map((instructor, index) => (
        <Col xs={12} sm={12} md={12} lg={5} xl={4} key={index} className='p-0'>
          <InstructorsRow className='justify-content-center'>
            <PictureContainer>
              <MaskImage src={Mask} alt='Mask' className='img-fluid p-0' />
              <GreenContainer type={index}>
                <img src={Mask} className='img-fluid p-0' />
              </GreenContainer>
              <BlueContainer type={index}>
                <img src={Mask} className='img-fluid p-0' />
              </BlueContainer>
              <InstructorPicture type={index}>
                <img src={Mask} alt='Mask' className='img-fluid p-0' />
              </InstructorPicture>
            </PictureContainer>
          </InstructorsRow>
          <Row className='justify-content-center mb-5'>
            <InstructorName>
              {instructor.name}
            </InstructorName>
          </Row>
        </Col>
      ))}
    </Row>
  );
}