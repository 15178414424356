import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Container } from 'react-bootstrap';
import {
  UilAngleDown,
  UilSearchAlt,
} from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import { SearchModal } from '../login/modals/SearchModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useAnalyticsEventTracker from '../../analytics/useAnalyticsEventTracker';
import Logo from '../../assets/navbar/logo.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const DesktopItem = styled(Col)`
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 15px 10px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  > a {
    text-decoration: none;
    color: #000 !important;
  }
  .arrow {
    position: absolute;
    right: 6%;
    top: 1rem;
    z-index: 1;
    @media only screen and (max-width: 1200px) {
      right: 0;
    }
  }
`;

const NavButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.showmenu === 1 ? '#1AC7C7' : '#515253'};
  transition: 300ms;
  &:hover {
    transition: 300ms;
    color: #1AC7C7;
  }
`;

const MobileLogo = styled.img`
  width: 50px;
  margin: auto;
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ShowMenu = styled.div`
  background-color: white;
  border: none;
`;

const StyledAngleDown = styled(UilAngleDown)`
  color: ${props => props.selected === 1 ? '#1AC7C7' : '#515253'};
  transform: ${props => props.selected === 1 ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: all 300ms;
`;

export const Desktop = (props) => {
  const [showSearchInput, setShowSearchInput] = useState(true);
  const history = useHistory();
  const modal = document.getElementById('myModal');
  const {width} = useWindowDimensions();
  const [navHeight, setNavHeight] = useState(0);
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker('Navbar');

  useEffect(() => {
    //map((item) => { return item.count })
    var total = 0;
    props.cart.items.forEach((element) => {
      total += element.count;
    });
  }, [props.cart]);

  useEffect(() => {
    const modalSearch = document.getElementById('modalSearch');
    const handleOpenModalSearch = () => {
      modal.style.display = 'none';
      modalSearch.style.display = 'block';
    };
    if (props.search?.length > 0) {
      handleOpenModalSearch();
    }
  }, [props.search]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  useEffect(() => {
    history.listen((location) => {
      listenToScroll();
      // console.log(location);
    });
  }, [history]);

  let navbar = document.getElementById('topNavBar')?.getBoundingClientRect()?.height;
  useEffect(() => {
    // navbar !== null && ;
    navbar !== undefined && setNavHeight(navbar);
  }, [navbar])


  const listenToScroll = () => {
    setShowSearchInput(true);
    let searchInput = document.getElementById('search-in-home');
    let rectSearch = searchInput?.getBoundingClientRect();
    let searchYPositionInput = rectSearch?.y;

    !searchInput && setShowSearchInput(true);

    let navbar = document.getElementById('topNavBar');
    let rectNav = navbar?.getBoundingClientRect().height;

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (window.location.pathname === '/') {
      if (winScroll > searchYPositionInput + rectNav) {
        //showSearchInput &&      // to limit setting state only the first time
        setShowSearchInput(true);
      } else {
        setShowSearchInput(false);
      }
    }
  };

  return (
    <div style={{ background: '#FFFFFF' }}>
      <Container style={width <= 992 ? {maxWidth: '100%'} : {}} className='d-flex flex-row w-100 pe-3 ps-3'>
        <DesktopItem
          md={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start'
          }}
        >
          <Link to='/' replace onClick={() => {window.location.replace('#inicio'); window.scrollTo(-10, 0);}}>
            <MobileLogo
              alt='Logo Vitalgene'
              src={Logo}
              style={{ width: '100%', maxWidth: '164px', height: 'auto' }}
            />{' '}
            <DesktopLogo
              alt='Logo Vitalgene'
              src={Logo}
              style={{ width: '100%', maxWidth: '164px', height: 'auto' }}
            />{' '}
          </Link>
        </DesktopItem>
        <Col xs={9} className='row justify-content-around'>
          <DesktopItem
            md={3}
            className='my-auto'
            style={{
              position: 'relative',
              zIndex: '3',
            }}
          >
            <ShowMenu
              onClick={props.showTheMenu}
              style={{
                background: 'none',
                padding: '0',
                width: '100%',
                zIndex: '2',
              }}
            >
              <NavButton id='botonMenu' style={{ width: '100%', textAlign: 'center', }} showmenu={props.showMenu ? 1 : 0} onClick={() => {window.location.replace('#studi'); window.scrollTo(0, document.getElementById('studi').offsetTop-navHeight)}}>
                Pruebas genéticas
              </NavButton>
            </ShowMenu>
            <StyledAngleDown
              size='24'
              className='arrow'
              selected={props.showMenu ? 1 : 0}
            />
          </DesktopItem>
          <DesktopItem
            md={2}
            className='my-auto'
            style={{ padding: '0px' }}
          >
            <NavButton onClick={() => {window.location.replace('/#como-funciona'); window.scrollTo(0, document.getElementById('como-funciona').offsetTop-navHeight)}}>
              ¿Cómo funciona?
            </NavButton>
          </DesktopItem>
          <DesktopItem md={2} className='my-auto'>
            <NavButton onClick={() => {window.location.replace('/#descubre'); window.scrollTo(0, document.getElementById('descubre').offsetTop-navHeight)}}>
              Descubre Vitalgene
            </NavButton>
          </DesktopItem>

        </Col>
        <DesktopItem md={1} className='d-flex flex-column justify-content-center align-items-end'>
          <Button
            variant='link'
            size='sm'
            onClick={() => {
              gaEventTracker('abrir-buscador');
              props.showSearch();
            }}
            style={{
              background: '#F1F8F9',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
            }}
          >
            <UilSearchAlt
              size='25'
              color='#1AC7C7'
              style={{ marginLeft: '-2px', marginTop: '-1px' }}
            />
          </Button>
        </DesktopItem>
        <SearchModal search={props.search} />
      </Container>
    </div>
  );
};
