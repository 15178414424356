import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';

const smallText = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    color: '#A6A6A6',
    margin: '0 0 2px 5px',
};

const inputStyle = {
    width: '245px',
    height: '48px',
    left: '40px',
    top: '315px',
    background: '#F6F6F6',
    borderRadius: '12px',
};

const buttonStyle = {
    width: '239px',
    height: '48px',
    left: '89px',
    top: '304px',
    border: 'none',
    background: '#00AD90',
    borderRadius: '102px',
    fontFamily: 'Assistant',
    fontWeight: '800',
};

export const CreatePasswordModal = () => {
    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={smallText}>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Ingresa tu contraseña"
                        style={inputStyle}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={smallText}>Repetir contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Repite tu contraseña"
                        style={inputStyle}
                    />
                </Form.Group>
            </Form>
            <Button
                variant="primary"
                style={buttonStyle}
            // onClick={() => history.push('/checkout')}
            >
                Crear cuenta
            </Button>
        </>
    )
}
