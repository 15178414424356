export const slides = [
  {
    title: "ALTO",
    subtitle: "RENDIMIENTO",
    link: '/shop/bags',
    img: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%201.png?alt=media&token=48f43a51-4a89-4edd-97cf-8168193578c8&_gl=1*1dhsn10*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODA5ODgxMC4xMDIuMS4xNjk4MDk4ODI3LjQzLjAuMA..',
    position: 1,
    img2: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%202.png?alt=media&token=c3334721-9eca-40a1-bc37-fe8020a483ef&_gl=1*10mjgbd*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODA5ODgxMC4xMDIuMS4xNjk4MDk5Mjc4LjU3LjAuMA..',
    class: 'leftimg',
    btntext: 'Shop Now'
  },
  {
    title: "ESPECIALIZACIÓN",
    link: '/shop/bags',
    img: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%201.1%20.png?alt=media&token=4e94bcb9-d07c-434d-b5c0-f97bead641b2&_gl=1*1xh6yx7*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODc4MTAzMi4xMjguMS4xNjk4NzgxMTIwLjMyLjAuMA..',
    position: 2,
    img2: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%203.png?alt=media&token=6f785541-e355-4547-88b9-704acae6c019&_gl=1*m4cph8*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODA5ODgxMC4xMDIuMS4xNjk4MDk5NDQ4LjUzLjAuMA..',
    class: 'leftimg',
    btntext: 'Shop Now'
  },
  {
    title: "INICIACIÓN",
    link: '/shop/bags',
    img: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%201.2.png?alt=media&token=125227c5-a371-416e-bd6f-084feb957034&_gl=1*xldqoo*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODc4MTAzMi4xMjguMS4xNjk4NzgxMTQzLjkuMC4w',
    position: 3,
    img2: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%203%204.png?alt=media&token=511771d5-c377-4d55-8ce8-bab8a3c133cb&_gl=1*1e1ene7*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODA5ODgxMC4xMDIuMS4xNjk4MDk5NTQyLjUzLjAuMA..',
    class: 'leftimg',
    btntext: 'Shop Now'
  },

]