import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const Background = styled.div`
  background: #fff;
  @media only screen and (max-width: 768px) {
    background: #fff;
  }
`;

const ContainerDiv = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const SpaceHolder = styled.div`
  position: relative;
  width: 100%;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const Horizontal = styled.div`
  position: absolute;
  height: 100%;
  margin-top: -12vh;
  will-change: transform;
`;

const Cards = styled.section`
  position: relative;
  height: 100%;
  padding: 0 0 0 70px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  // margin-bottom: -500px;
`;

const SampleCard = styled.div`
  position: relative;
  height: 100vh;
  width: 90vw;
  background-color: none;
  // background-color: #111f301A;
  margin-right: 75px;
  padding-top: 300px;
  flex-shrink: 0;
  @media only screen and (max-width: 768px) {
    padding-top: 270px;
  }
  .text-col {
    display: flex;
    position: absolute;
    padding-left: 100px;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
    h1 {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 45px;
      line-height: 80%;
      color: #f14cae;
      margin-right: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 38px;
        margin-top: 5px;
        margin-right: 18px;
      }
    }
    p {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 30px;
      line-height: 50px;
      color: #000;
      span {
        font-weight: 700;
      }
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 30px;
        max-width: 245px;
      }
    }
  }
  img {
    max-width: 50%;
    max-height: 85vh;
    height: auto;
    position: relative;

    @media only screen and (max-width: 768px) {
      max-width: 120%;
      max-height: 60vh;
      left: 40%;
      transform: translate(-50%, 0);
    }
  }
  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
  }
  #create {
    h4 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff !important;
      max-width: 237px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  #create-bottom {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #ffffff;
    position: absolute;
    bottom: 70px;
    left: 80px;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const RedDiv = styled.div`
  position: sticky;
  top: 150px;
  left: 150px;
  background: none;
  // background: #FF00001A;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 27px;
  line-height: 130%;
  color: #ffffff;
  @media only screen and (max-width: 768px) {
    top: 120px;
    left: 50px;
  }
  p {
    font-style: italic;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 23px;
      margin-bottom: 0;
    }
  }
  h3 {
    color: #ffffff;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

export const HorizontalScrollSection = () => {
  // Ref for space holder
  const spaceHolderRef = useRef(null);

  // Ref for width
  const horizontalRef = useRef(null);

  useEffect(() => {
    // Get height dynamically
    const calcDynamicHeight = () => {
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      const objectWidth = horizontalRef.current.scrollWidth;
      return objectWidth - vw + vh;
    };

    // When scroll (vertical) make horizontal scroll
    const handleScroll = () => {
      const sticky = document.querySelector(".sticky");
      horizontalRef.current.style.transform = `translateX(-${sticky.offsetTop}px)`;
    };

    // When resize change the height ofthe space holder
    const handleResize = () => {
      spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;
    };

    //  Listeners for scroll and resize
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Change space holder height
    spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;

    // Remove listeners
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Background>
      <ContainerDiv>
        <SpaceHolder ref={spaceHolderRef}>
          <Sticky className="sticky">
            {/* Fixed div with title */}
            {/* Horizontal scroll div */}
            <Horizontal ref={horizontalRef}>
              {/* Step cards */}
              <Cards>
                <SampleCard data-aos="fade-up">
                  <Row>
                    <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F1.png?alt=media&token=ab556a78-a37f-421a-80e0-37773a440c06"
                        alt="paso 1"
                      />
                    </Col>
                  </Row>
                </SampleCard>
                <SampleCard data-aos="fade-up">
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F2.png?alt=media&token=07fa259c-c506-413a-9a89-d67386679076"
                      alt="paso 2"
                    />
                  </Col>
                </SampleCard>
                <SampleCard>
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F3.png?alt=media&token=273ec8c4-a619-4ae7-8b97-9978c4827355"
                      alt="paso 3"
                    />
                  </Col>
                </SampleCard>
                <SampleCard>
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F4.png?alt=media&token=b00b457d-f40d-4299-a3d5-d13c14eb006b"
                      alt="paso 4"
                    />
                  </Col>
                </SampleCard>
                <SampleCard>
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F5.png?alt=media&token=a10883b7-2b29-46b5-aee0-00d941bcc642"
                      alt="paso 5"
                    />
                  </Col>
                </SampleCard>
                <SampleCard>
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F6.png?alt=media&token=68288b7d-b372-47de-881e-8b33f2cd918c"
                      alt="paso 6"
                    />
                  </Col>
                </SampleCard>
                <SampleCard>
                  <Col xs={12} md={10} lg={10} xl={12} className="text-col">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2F7.png?alt=media&token=0895d708-d4f2-4ee2-a46d-b4acc899d79b"
                      alt="paso 7"
                    />
                  </Col>
                </SampleCard>
              </Cards>
            </Horizontal>
          </Sticky>
        </SpaceHolder>
      </ContainerDiv>
    </Background>
  );
};
