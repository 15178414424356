import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";
import "./sliderstyle.css";

const SeminarsBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage6.png?alt=media&token=4398832a-dd3c-4eca-9c48-094efa85fa45";
const SessionsBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage7.png?alt=media&token=bc26caea-36cc-4553-8769-06f0669c98c4";
const PracticesBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage8.png?alt=media&token=54279788-8248-47e0-83a7-b97d13060f00";
const BiblioBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage9.png?alt=media&token=f371bad9-6a8d-48f0-aa9e-b6d7dd675576";
const ConferencesBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage10.png?alt=media&token=ccb78e4e-b45c-45af-b6ce-3f79eb421cff";
const Conferen =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage11.png?alt=media&token=e1e49b53-9ab1-459a-a182-7152240e9c8e";
const Renfe =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage12.png?alt=media&token=db4189f1-30a0-4efc-abcc-53c8ffc3cbf1";
const Ulti =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage13.png?alt=media&token=975bbd1f-6f5b-4da3-9dbe-7ddc5f8e7ba6";


const BannerBackground = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px) {
    background: #fff;
    height: 340px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 335px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
    }
    img {
      width: 385px;
      position: absolute;
      bottom: 0px;
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 80%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 270px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: #000;
  text-align: star;
  //max-width: 600px;
  p {
    font-size: 29px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const IlustrationsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  @media only screen and (max-width: 1400px) {
    max-width: 100%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    padding-bottom: 45px;
  }
  .list-card {
    float: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 140px;
    height: 385px;
    opacity: 0.7;

    transition: 300ms;

    &:hover,
    &:first-child ~ .list-card:last-child {
      width: 100%;
      max-width: 200px;
      opacity: 1;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 185px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 175px;
      }
    }

    &:hover ~ .list-card:last-child {
      max-width: 192px;
      opacity: 0.7;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 152px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 110px;
      }
    }

    &:nth-child(1) > .cover {
      background: url(${SeminarsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(2) > .cover {
      background: url(${SessionsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(3) > .cover {
      background: url(${PracticesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(4) > .cover {
      background: url(${BiblioBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(5) > .cover {
      background: url(${ConferencesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(6) > .cover {
      background: url(${Conferen}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(7) > .cover {
      background: url(${Renfe}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(8) > .cover {
        background: url(${Ulti}) top left no-repeat;
        background-size: cover;
        height: 302px;
      }

    @media only screen and (max-width: 1400px) {
      max-width: 152px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 110px;
    }
    @media only screen and (max-width: 992px) {
      flex-direction: row-reverse;
      width: 100%;
      max-width: 100%;
      height: 124px;
      &:hover {
        max-width: 100%;
        width: 100%;
        height: 320px;
        opacity: 1;
      }
      &:first-child ~ .list-card:last-child {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(1) > .cover {
        background: url(${SeminarsBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(2) > .cover {
        background: url(${SessionsBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(3) > .cover {
        background: url(${PracticesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(4) > .cover {
        background: url(${BiblioBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(5) > .cover {
        background: url(${ConferencesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(6) > .cover {
        background: url(${Conferen}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(7) > .cover {
        background: url(${Renfe}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(7) > .cover {
        background: url(${Ulti}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 100px;
      &:hover {
        height: 220px;
      }
    }
    @media only screen and (max-width: 576px) {
      height: 72px;
      &:hover {
        height: 191px;
      }
    }
  }
`;

const CardTitle = styled.div`
  background-color: #ffffff;
  height: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
  padding-right: 0px;
  p {
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 24px;
    color: #474a4b;
    margin-bottom: 0;
    overflow: hidden;
  }
  @media only screen and (max-width: 1400px) {
    padding-left: 14px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 1200px) {
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    max-width: 55%;
    width: 100%;
    height: auto;
    padding-top: 14px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

export const Nutriologos = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { studyId } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 166);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getInfo();
  }, [studyId]);

  return (
    <>
      <BannerBackground>
        <Container>
          <HelmetTitle
            title="Aliados vitales"
            name="description"
            content="content"
          />
          <Row>
            <DescColumn
              xs={12}
              md={12}
              className={
                isTablet
                  ? "justify-content-start mt-3"
                  : "justify-content-center"
              }
            >
              <Title>
                <p>Beneficios para los nutriólogos</p>
              </Title>
            </DescColumn>
          </Row>
          <Row>
            <IlustrationsList>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Exclusividad de catálogo de pruebas genéticas para la
                  nutrición en tu ciudad.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>
                  Comunidad Alianzas Vitales en todo el país.
                  </p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Capacitación continua.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Soporte para la interpretación de resultados por parte de
                  nuestro equipo.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Recomendación en nuestra página.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Precio preferencial en nuestras pruebas genéticas.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Webinars gratuitos en la página de Vitalgene.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Comunidad de nutriólogos, médicos y genetistas.</p>
                </CardTitle>
              </div>
            </IlustrationsList>
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
