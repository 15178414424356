import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";
import "./sliderstyle.css";

const SeminarsBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage1.png?alt=media&token=35376834-8f40-4a17-bb30-2b4d97aab0cf";
const SessionsBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage2.png?alt=media&token=deed50b0-b53f-4b8f-a467-6a91ba4ff38e";
const PracticesBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage3.png?alt=media&token=9a6a2893-beca-44d0-bc4c-34a5d45c7b85";
const BiblioBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage4.png?alt=media&token=da97432f-9adf-4755-a970-5a656bd602aa";
const ConferencesBg =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Fimage5.png?alt=media&token=d5333f5c-c773-4c06-bdec-b63e2ee91a3d";

const BannerBackground = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    background: #fff;
    height: 340px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 335px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
    }
    img {
      width: 385px;
      position: absolute;
      bottom: 0px;
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 80%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 270px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: #000;
  text-align: star;
  //max-width: 600px;
  p {
    font-size: 29px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const IlustrationsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  @media only screen and (max-width: 1400px) {
    max-width: 100%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    padding-bottom: 45px;
  }
  .list-card {
    float: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 200px;
    height: 385px;
    opacity: 0.7;

    transition: 300ms;

    &:hover,
    &:first-child ~ .list-card:last-child {
      width: 100%;
      max-width: 250px;
      opacity: 1;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 185px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 175px;
      }
    }

    &:hover ~ .list-card:last-child {
      max-width: 192px;
      opacity: 0.7;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 152px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 110px;
      }
    }

    &:nth-child(1) > .cover {
      background: url(${SeminarsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(2) > .cover {
      background: url(${SessionsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(3) > .cover {
      background: url(${PracticesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(4) > .cover {
      background: url(${BiblioBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(5) > .cover {
      background: url(${ConferencesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }

    @media only screen and (max-width: 1400px) {
      max-width: 152px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 110px;
    }
    @media only screen and (max-width: 992px) {
      flex-direction: row-reverse;
      width: 100%;
      max-width: 100%;
      height: 124px;
      &:hover {
        max-width: 100%;
        width: 100%;
        height: 320px;
        opacity: 1;
      }
      &:first-child ~ .list-card:last-child {
        max-width: 100%;
        width: 100%;
      }

      &:nth-child(1) > .cover {
        background: url(${SeminarsBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(2) > .cover {
        background: url(${SessionsBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(3) > .cover {
        background: url(${PracticesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(4) > .cover {
        background: url(${BiblioBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(5) > .cover {
        background: url(${ConferencesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 100px;
      &:hover {
        height: 220px;
      }
    }
    @media only screen and (max-width: 576px) {
      height: 72px;
      &:hover {
        height: 191px;
      }
    }
  }
`;

const CardTitle = styled.div`
  background-color: #ffffff;
  height: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
  padding-right: 0px;
  p {
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #474a4b;
    margin-bottom: 0;
    overflow: hidden;
  }
  @media only screen and (max-width: 1400px) {
    padding-left: 14px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 1200px) {
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    max-width: 55%;
    width: 100%;
    height: auto;
    padding-top: 14px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

export const Aliados = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { studyId } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 166);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getInfo();
  }, [studyId]);

  return (
    <>
      <BannerBackground>
        <Container>
          <HelmetTitle
            title="Aliados vitales"
            name="description"
            content="content"
          />
          <Row>
            <DescColumn
              xs={12}
              md={12}
              className={
                isTablet
                  ? "justify-content-start mt-3"
                  : "justify-content-center"
              }
            >
              <Title>
                <p>Beneficios de nuestras pruebas genéticas en tu consulta</p>
              </Title>
            </DescColumn>
          </Row>
          <Row>
            <IlustrationsList>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Atención más completa a los pacientes (A-G)</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>
                    Herramientas genéticas para personalizar los planes de
                    alimentación de tus pacientes.
                  </p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Eficientiza el logro de metas de tus pacientes.</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Material educativo y compartible para tus pacientes</p>
                </CardTitle>
              </div>
              <div className="list-card">
                <div className="cover"></div>
                <CardTitle>
                  <p>Aumento de tu cartera de pacientes.</p>
                </CardTitle>
              </div>
            </IlustrationsList>
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
