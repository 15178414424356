import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { UilAngleDown, UilAngleUp, UilTimes } from '@iconscout/react-unicons';

const ButtonMenu = styled.button`
  text-align: inherit;
  color: black;
  width: 105%;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: none;
`;

const Description = styled.p`
  font-family: 'Assitant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3b3b3b;
  margin-top: 15px;
  padding-left: 8px;
`;

const LinkMenu = styled.a`
  text-align: inherit;
  color: black;
  background-color: white;
  border: none;
  @media only screen and (min-width: 586px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    > div {
      justify-content: center;
      > img {
        margin-right: 10px;
      }
    }
  }
  .icon {
    max-width: 52px;
    max-height: 52px;
  }
`;

const Title = styled.span`
  font-family: 'Assitant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  color: #000000;
`;

const MenuTitle = styled.p`
  color: #2E363E;
  font-family: 'Assitant', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: left;
  @media only screen and (max-width: 900px) {
    width: 220px;
  }
  @media only screen and (max-width: 390px) {
    max-width: 190px;
  }
`;
const LinkContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
`;

export const MobileSubmenu = (props) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const opening = () => {
    setShowSubMenu(!showSubMenu);
    props.opening();
  };
  return (
    <Row style={{ width: '100%', marginLeft: 0 }}>
      <Col
        md={12}
        style={{ padingLeft: 0, padingRight: 0 }}
        className='d-flex flex-row-reverse bd-highlight'
      >
        <Button variant='link' onClick={props.onHide}>
          <UilTimes size='32' color='#1AC7C7' className=' bd-highlight' />
        </Button>
      </Col>
      <Col md={12} style={{ padingLeft: 0, padingRight: 0 }}>
        <ButtonMenu variant='light' onClick={() => opening()}>
          <Row className='justify-content-center'>
            <Col sm={10} xs={10} className='text-center'>
              <Row className='justify-content-center'>
                <Title className='w-auto p-0'>{props.menu.name}</Title>
                {showSubMenu ? (
                  <UilAngleUp size='32' color='#bd43b7' className='w-auto p-0' />
                ) : (
                  <UilAngleDown size='32' color='#bd43b7' className='w-auto p-0' />
                )}
              </Row>
            </Col>
          </Row>
        </ButtonMenu>
      </Col>

      <Col md={12} className='mt-3'>
        {showSubMenu ? (
          <>
            {props.menu.submenu.map((item, index) => (
              <LinkContainer key={index}>
                <LinkMenu href={item.link}>
                  <Row className='align-items-center justify-content-center'>
                    <div className='p-0 d-flex flex-column align-items-start me-3' style={{width: '52px'}}>
                      <img
                        src={item.icon}
                        className='img-fluid icon'
                        alt='category-icon'
                        width={item.width}
                      />
                    </div>
                    <div className='p-0 d-flex flex-column justify-content-center w-auto'>
                      <MenuTitle>{item.nameA} <br/> {item.nameB}</MenuTitle>
                    </div>
                  </Row>
                </LinkMenu>
              </LinkContainer>
            ))}
          </>
        ) : (<></>)}
      </Col>
    </Row>
  );
};
