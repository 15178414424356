import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Form, Modal, Container } from 'react-bootstrap';
import { UilAngleDown, UilAngleUp, UilTimes } from '@iconscout/react-unicons';

const Title = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 28px;
    margin-bottom: 4px;

    > svg {
        margin-left: 10px;
    }
`;

const ButtonMenu = styled.button`
    text-align: left;
    color: black;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    border: none;
`;

const CustomCheck = styled(Form)`
    > div input.form-check-input {
        width: 20px;
        height: 20px;
        border: 2px solid #0A7A71;
        border-radius: 0px;
    }

    > div input.form-check-input:checked {
        background-color: #0A7A71;
    }

    > div label {
        margin-top: 2px;
        margin-bottom: 20px;
        font-size: 16px;
        color: #717171;
        margin-left: 18px;
        vertical-align: center;
    }
`;

const FilterCount = styled.span`
    display: inline-block;
    font-size: 12px;
    background-color: #F9AD62;
    color: white;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 13px;
    margin-left: 10px;
`;

const FilterModal = styled(Modal)`
    > .modal-dialog .modal-content {
        width: 85vw;
        height: 100vh;
        margin-left: 15vw;
    }
`;

const ButtonClean = styled(Button)`
    font-size: 16px;
    text-decoration: none;
    color: #0A7A71;
    :hover {
        color: #0A7A71;
    }
`;

const ButtonApply = styled(Button)`
    border: none;
    font-size: 16px;
    background-color: #0A7A71;
    width: 80%;
    max-width: 140px;
    height: 40px;
    border-radius: 20px;
    :hover {
        border: none;
        background-color: #0A7A71;
    }
`;

const Buttons = styled.div`
    position: absolute;
    width: 90%;
    padding-left: 0px;
    bottom: 24px;
    > .row div {
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const FilterMobile = (props) => {
    const [showMenu1, setShowMenu1] = useState(false);
    const [showMenu2, setShowMenu2] = useState(false);
    const [filterCount1, setFilterCount1] = useState(0);
    const [filterCount2, setFilterCount2] = useState(0);
    const [filterState, setFilterState] = useState(props.filters);

    const checkClick = (event, id) => {
        let checked = event.target.checked;
        setFilterState(
            filterState.map(data => {
            if (id === data.id) {
                data.select = checked;
            }
            return data;
            })
        );

        if(filterState[id-1].select){
            if(filterState[id-1].group === 1){
                setFilterCount1(filterCount1+1);
            }
            if(filterState[id-1].group === 2){
                setFilterCount2(filterCount2+1);
            }
        }

        if(!filterState[id-1].select){
            if(filterState[id-1].group === 1){
                setFilterCount1(filterCount1-1);
            }
            if(filterState[id-1].group === 2){
                setFilterCount2(filterCount2-1);
            }
        }
    }

    const cleanFilters = () =>{
        setFilterState(
            filterState.map(filter => {
              return {
                select: false,
                id: filter.id,
                name: filter.name
              };
            })
        );
        setFilterCount1(0);
        setFilterCount2(0);
    }

    const onSubmit = () => {
        props.onFilter(filterState);
        props.hide();
    }

    return(
        <FilterModal show={props.show} fullscreen={true} onHide={props.hide}>
            <Modal.Body style={{position: "relative"}}>
                <Container>
                    <Col
                        md={12}
                        style={{ padingLeft: 0, padingRight: 0 }}
                        className='d-flex flex-row-reverse bd-highlight'
                    >
                        <Button variant='link' onClick={props.hide}>
                        <UilTimes size='32' color='#0A7A71' className=' bd-highlight' />
                        </Button>
                    </Col>
                    <Title>Filtrar por</Title>
                    <hr/>
                    <ButtonMenu
                    variant='light'
                    onClick={() => setShowMenu1(!showMenu1)}
                    >
                        <Row >
                            <Col sm={12} xs={12} style={{paddingLeft: '0px'}} >
                                <Title> Tipo de estudio {filterCount1 > 0 ? <FilterCount>{filterCount1}</FilterCount> : null}
                                {showMenu1 ? (
                                    <UilAngleUp size='32' color='#0A7A71' />
                                ) : (
                                    <UilAngleDown size='32' color='#0A7A71' />
                                )}
                                </Title>
                            </Col>
                        </Row>
                    </ButtonMenu>
                    <hr/>
                    {showMenu1 ? (
                        <CustomCheck>
                            <Form.Check
                                inline
                                label="Filtro 1"
                                name={filterState[0].name}
                                type="checkbox"
                                id={filterState[0].name}
                                onChange={event => checkClick(event, 1)}
                                checked={filterState[0].select}
                                /><br/>
                            <Form.Check
                                inline
                                label="Filtro 2"
                                name={filterState[1].name}
                                type="checkbox"
                                id={filterState[1].name}
                                onChange={event => checkClick(event, 2)}
                                checked={filterState[1].select}
                                /><br/>
                            <Form.Check
                                inline
                                label="Filtro 3"
                                name={filterState[2].name}
                                type="checkbox"
                                id={filterState[2].name}
                                onChange={event => checkClick(event, 3)}
                                checked={filterState[2].select}
                                />
                        </CustomCheck>
                    ) : (
                        null
                    )}
                    <ButtonMenu
                    variant='light'
                    onClick={() => setShowMenu2(!showMenu2)}
                    >
                        <Row >
                            <Col sm={12} xs={12} style={{paddingLeft: '0px'}} >
                                <Title> Tipo de filtro 1 {filterCount2 > 0 ? <FilterCount>{filterCount2}</FilterCount> : null}
                                {showMenu2 ? (
                                    <UilAngleUp size='32' color='#0A7A71' />
                                ) : (
                                    <UilAngleDown size='32' color='#0A7A71' />
                                )}
                                </Title>
                            </Col>
                        </Row>
                    </ButtonMenu>
                    <hr/>
                    {showMenu2 ? (
                        <CustomCheck>
                            <Form.Check
                                inline
                                label="Filtro 1"
                                name={filterState[3].name}
                                type="checkbox"
                                id={filterState[3].name}
                                onChange={event => checkClick(event, 4)}
                                checked={filterState[3].select}
                                /><br/>
                            <Form.Check
                                inline
                                label="Filtro 2"
                                name={filterState[4].name}
                                type="checkbox"
                                id={filterState[4].name}
                                onChange={event => checkClick(event, 5)}
                                checked={filterState[4].select}
                                /><br/>
                            <Form.Check
                                inline
                                label="Filtro 3"
                                name={filterState[5].name}
                                type="checkbox"
                                id={filterState[5].name}
                                onChange={event => checkClick(event, 6)}
                                checked={filterState[5].select}
                                />
                        </CustomCheck>
                    ) : (
                        null
                    )}
                </Container>
                
                <Buttons className="container">
                    <hr style={{border: "1px solid #C1D9D8"}}/>
                    <Row>
                        <div className="col-6 text-center">
                            <ButtonClean variant='link' onClick={cleanFilters}>
                                Limpiar filtros
                            </ButtonClean>
                        </div>
                        <div className="col-6 text-center">
                            <ButtonApply onClick={onSubmit}>
                                Aplicar
                            </ButtonApply>
                        </div>

                    </Row>
                </Buttons>
            </Modal.Body>
        </FilterModal>
    );
}