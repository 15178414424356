import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import BannerBgMobile from '../../../assets/home/bannerMobile.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Slider from 'react-slick';
//import { BannerGluten} from './gluten/bannerGluten';
import BannerVg70 from './banners/BannerVg70'; 
import { useSelector } from 'react-redux';
import { BannerCafeina } from './banners/bannerCafeina';
//import Vimeo from '@u-wave/react-vimeo';
import axios from 'axios';

const BannerDesk = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FBannerHome.png?alt=media&token=5febbc0c-7ade-4d73-af09-bc1e2f05365d';

const BannerTablet = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FMobile.png?alt=media&token=3a5d9703-45ce-47d2-abad-e18ae9c1fdae';

const PromoBannerDesk = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FBannerPromo.png?alt=media&token=766f7fcf-73fd-4882-99a7-efaa1205e8ef';

const PromoBannerTablet = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FBannerPromoTablet.png?alt=media&token=05e3888d-8e6c-4f7c-8ba6-3718a86d0e48';

const PromoBannerMobile = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/banners%2FBannerPromoMobile.png?alt=media&token=98582193-3ee0-4b2d-9d89-aeca9ed5f0f8';

const Title = styled.h1`
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 86px;
  line-height: 95px;
  text-align: left;
  letter-spacing: -0.04em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-bottom: 40px;
  max-width: 610px;
  @media only screen and (max-width: 1200px) {
    font-size: 64px;
    line-height: 82px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 80px;
    line-height: 95px;
    text-align: center;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 64px;
    line-height: 72px;
    text-align: center;
  }
  @media only screen and (max-width: 576px) {
    font-size: 48px;
    line-height: 63px;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 16px;
  }
`;

const StyledRow = styled(Row)`
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`;

const DescColumn = styled(Col)`
margin-left: 289px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 1366px) {
    margin-left: 130px;
  }
  @media only screen and (max-width: 992px) {
    justify-content: flex-start;
    align-items: center;
    padding-top: 30px;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    padding-top: 80px;
    align-items: center;
    text-align: center;
  }
  @media only screen and (max-width: 576px) {
    padding-top: 24px;
    margin-left: 0px;
  }
`;

const DescText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  margin: 0;
  z-index: 2;
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 20px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    display: flex !important;
    align-items: center;
    align-content: center;
    padding-left: var(--bs-gutter-x,.75rem);
    padding-right: var(--bs-gutter-x,.75rem);
    @media only screen and (max-width: 992px) {
      justify-content: center;
    }
    @media only screen and (max-width: 768px) {
      position: unset;
      justify-content: center;
      padding: 1rem 0 0 0 !important;
    }
  }
  .slick-dots li {
    height: 12px;
    width: 24px;
    display: flex;
    align-items: center;
    align-content: center;
  }
  .slick-dots li button {
    height: 20px;
    display: flex;
    align-items: center;
  }
  .slick-dots li button:before {
    font-size: 10px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 300ms !important;
    color: transparent;
  }
  .slick-dots li.slick-active button:before {
    color: #FFFFFF;
    background: #FFFFFF;
    border-radius: 81px;
    height: 11px;
    width: 28px;
    transition: all 300ms !important;
  }
`;

const Slide = styled.div`
  position: relative;
  background: ${props=>props.load ? 'none' : '#F1F8F9'};
  height: 460px;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    height: 590px;
    
  }
`;

const SlideImg = styled.img`
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    height: 590px;
  }
`;

const DotsDiv = styled.div`
  position: absolute;
  bottom: 45px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 346px) {
    display: none;
  }
`;

const DotsContainer = styled(Container)`
  @media only screen and (max-width: 992px) {
    margin: 0;
    padding: 0;
  }
`;

const Spinner = styled.div`
  height: 87.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #1AC7C7;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1AC7C7 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Contain = styled.div`
  top: 0px;
  align-items: center;
  justify-content: center;
  margin: auto;
  /* height: 100vh; */
    @media only screen and (max-width: 768px) {
    height: 590px;
  }
`;

export const Banner = (props) => {
  const [load, setLoaded] = useState(false);
  const { width } = useWindowDimensions();
  const [slide, setSlide] = useState(0);
  let isTablet = width <= 992;
  let isMobile = width <= 576;

  const onLoad = () => {
    setLoaded(true);
  }

  const [study, setStudy] = useState();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-study-test/675/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
    } catch (error) {
      console.log(error);
      window.location.href = '/not-found';
    }
  };

  useEffect(()=>{
    getInfo();
  },[])

  const CustomDots = (props) => {
    return(
      <DotsContainer>
        <DotsDiv slide={slide}>
          <ul className='slick-dots' key={slide}>
            {props.dots}
          </ul>
        </DotsDiv>
      </DotsContainer>
    );
  }

  const settings = {
    appendDots: dots => (
      <CustomDots dots={dots} />
    ),
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    pauseOnHover: false,
    initialSlide: 0,
    dotClass: 'slick-dots',
    
  };

  return (
    <>
      <StyledSlider {...settings} beforeChange={(current, next) => setSlide(next)}>
        {/*<BannerVg70/>*/}
        <Slide id={'firstBannerSlide'} key={slide} load={load}>
          <SlideImg
            src={isMobile ? `${BannerBgMobile}` : isTablet ? BannerTablet : BannerDesk}
            className='img-fluid'
            onLoad={onLoad}
          />
          <Contain>
            {load ? (
              <StyledRow>
                <DescColumn xs={12} sm={12} md={7} lg={6}>
                  <Title>
                    Conoce tu <br/>
                    predisposición<br/>
                    genética...
                  </Title>
                  <DescText>
                    y toma mejores decisiones para tu salud
                  </DescText>
                </DescColumn>
              </StyledRow>
            ) : (
              <Spinner>
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
              </Spinner>
            )}
          </Contain>
        </Slide>
        
      </StyledSlider>
    </>
  );
};
