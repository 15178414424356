import Carousel from "react-spring-3d-carousel";
import { useState } from "react";
import { config } from "react-spring";
import styled from "styled-components";
import Foto from "../../../../assets/packs/perfi.png";
import Comillas from "../../../../assets/packs/“1.png";

const StyledDiv = styled.div`
  width: 90%;
  max-width: 900px;
  height: 450px;
  margin: 61px auto;
  padding: 70px 0;
  @media only screen and (max-width: 768px) {
    width: 220px;
    height: 94vw;
    max-height: 498px;
    transform: rotate(90deg);
    margin: -20px auto 150px;
  }
  @media only screen and (max-width: 420px) {
    width: 220px;
    height: 94vw;
    max-height: 498px;
    transform: rotate(90deg);
    margin: -20px auto 150px;
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Assistant;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: -60px;
  p {
    width: 380px;
    font-size: 21px;
    font-style: normal;
    margin-left: auto;
    font-weight: 400;
    margin-right: auto;
  }
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const TCard = styled.div`
  cursor: pointer;
  position: relative;
  width: 555px;
  height: 260px;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(180deg, #542F71 0%, #FF5C6D 100%);
  border-radius: 10px;
  transform: rotateY(0) translateZ(35vw);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  #perfil {
    width: 90px;
    position: absolute;
    bottom: 5px;
    left: 16px;
    @media only screen and (max-width: 768px) {
      width: 70px;
      bottom: -10px;
      left: 40px;
    }
  }
  #com {
    width: 120px;
    position: absolute;
    bottom: 200px;
    left: 16px;
    @media only screen and (max-width: 768px) {
      bottom: 155px;
      left: -26px;
    }
    @media only screen and (max-width: 420px) {
      bottom: 126px;
      left: -26px;
    }
  }
  h1 {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    max-width: 300px;
    position: absolute;
    bottom: 30px;
    left: 115px;
    margin-bottom: 0;
  }
  p {
    color: #515253;
    text-align: start;
    font-family: Assistant;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-top: -15px;
    @media only screen and (max-width: 768px) {
      margin-top: -50px;
    }
  }
  @media only screen and (max-width: 768px) {
    transform: rotate(-90deg);
    width: 270px;
    height: 220px;
    margin: 0px -150px;
    margin-left: -153px;
    img {
      max-height: 120px !important;
      height: 120px;
      width: 120px;
      bottom: -10px;
      left: -50px;
    }
    h1 {
      font-size: 13px;
      bottom: 35px;
      right: 12px;
    }
    p {
      font-size: 10px;
      line-height: 11px;
    }
  }
  @media only screen and (max-width: 420px) {
    width: 300px;
    height: 190px;
  }
  @media only screen and (max-width: 372px) {
    width: 250px;
  }
`;

// This is used coause there is a gap after the section idk why :c
const MarginDiv = styled.div`
  margin: -60px;
  @media only screen and (max-width: 768px) {
    margin: -150px;
  }
`;

export const CardTestimonials = (props) => {
  return (
    <TCard>
      <img id="com" src={Comillas} alt="Avatar" />
      <img id="perfil" src={Foto} alt="Avatar" />
      <h1>{props.name}</h1>
      <p>{props.comment}</p>
    </TCard>
  );
};

export default function Testimonials() {
  let cardsArray = [
    {
      key: 1,
      content: (
        <CardTestimonials
          avatar=""
          name="Anonimo"
          comment='Comentario positivo de la marca"'
        />
      ),
    },
    {
      key: 2,
      content: (
        <CardTestimonials
          avatar=""
          name="Anonimo"
          comment='Comentario positivo de la marca"'
        />
      ),
    },
    {},
  ];

  const table = cardsArray.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  return (
    <div>
      <Title>
        Testimonios
        <p>
          Esto es lo que dicen las personas que ya se han realizado la prueba.
        </p>
      </Title>
      <StyledDiv>
        <Carousel
          slides={cards}
          goToSlide={goToSlide}
          offsetRadius={3}
          showNavigation={false}
          animationConfig={config.gentle}
        />
      </StyledDiv>
      <MarginDiv />
    </div>
  );
}
