import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import searchIcon from '../../assets/ecommerce/search.png';
import { UilSearchAlt, UilTimesCircle } from '@iconscout/react-unicons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FloatingLabel, Form } from 'react-bootstrap';

const SearchInput = styled(Form.Control)`
  background: #ffffff !important;
  border: 1px solid #E9E9E9;
  border-radius: 10px !important;
  padding: 0 0 0 40px !important;
  max-height: 45px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #A6A6A6;
    margin-left: 40px;
  }
  :focus {
    outline: none;
    -webkit-box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
`;

const MyFloating = styled(FloatingLabel)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  .form-control {
    border-radius: 12px;
    font-family: 'Assistant', sans-serif;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -9em;
    background: #ffffff;
    border-radius: 8px;
    padding: 8px 9px 5px 9px;
    margin: 5px 0 0 35px;
    height: auto;
    opacity: 1 !important;
    font-family: 'Assistant', sans-serif;
    transition: 300ms;
  }
  :active,
  :focus-within {
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 35px;
      padding: 5px 9px 5px 9px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

export const Search = (props) => {
  const [search, setSearch] = useState('');
  const history = useHistory();
  const show = () => {
    if (search.length > 0) {
      props.showSearch();
    }
  };

  const hide = () => {
    if (search.length <= 0) {
      props.hideSearch();
    }
  };

  useEffect(() => {
    props.setSearching(search);
    if (search.length > 0) {
      props.showSearch();
    } else if (search.length === 0) {
      props.hideSearch();
    }
  }, [search]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && search !== '') {
      history.push(`/buscador?search=${search}`)
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <UilSearchAlt style={{ zIndex: 50, color: '#A6A6A6', position: 'absolute', left: '10px', top: '10px' }}/>
      {search !== "" &&
        <UilTimesCircle style={{ zIndex: 50, color: '#A6A6A6', position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
          onClick={()=> setSearch("")}
        />
      }
      <MyFloating controlId='floatingName' label='Busca tu prueba'>
        <SearchInput
          type="text"
          placeholder='Busca tu prueba'
          onFocus={show}
          onBlur={hide}
          value={search}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearch(e.target.value)}
        />
      </MyFloating>
    </div>
  );
};
