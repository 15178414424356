import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { UilPlayCircle } from '@iconscout/react-unicons';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { toastSweet } from '../../helpers/sweetAlert2';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import Vimeo from '@u-wave/react-vimeo';
import { BannerTransforma } from '../content/home/banners/bannerTransforma2';

const MainDiv = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FbannerTransformaVitalgene.png?alt=media&token=9bf04587-df73-47de-8a85-4ff15ccf4d61");
  background-size: 100% 100%;
  @media only screen and (max-width: 768px){
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FbannerTransformaMobileVitalgene.png?alt=media&token=31ee4b8c-e95e-4e59-9215-8f6de3ebaa65");
    background-size: 100% 100%;
  }
  #logo-transforma {
    margin-top: 36px;
    margin-bottom: 17px;
    width: 524px;
    @media only screen and (max-width: 768px){
      margin-top: 27px;
      margin-bottom: 29px;
      width: 267px;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 64px;
    line-height: 70px;
    color: #F2F7F9;
    max-width: 494px;
    @media only screen and (max-width: 768px){
      max-width: 233px;
      font-size: 32px;
      line-height: 34px;
    }
  }
  p {
    max-width: 474px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F2F7F9;
    margin-bottom: 31px;
    @media only screen and (max-width: 768px){
      max-width: 221px;
      margin-top: 15px;
      font-size: 14px;
      line-height: 130%;
    }
  }
  #main-col {
    padding-left: 2px;
  }
  #smiling {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 105%;
    max-width: 50%;
    @media only screen and (min-width: 1800px){
      right: 15%;
    }
    @media only screen and (min-width: 1400px) and (max-width: 1400px){
      right: 5%;
    }
    @media only screen and (max-width: 768px){
      right: -20%;
      max-width: 70%;
    }
  }
`;

const OrangeButton = styled.button`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  height: 48px;
  background: #FA5067;
  border-radius: 24px;
  border: none;
  padding: 0;
  margin-top: 6px;
  margin-bottom: 10px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #F77C8D;
    border: 2px solid #2cdce6;
  }
`;

const GrayBar = styled.div`
  padding: 17px 0;
  background: rgba(0, 173, 144, 0.15);
  #left-col {
    border-right: 1px solid #24CAD3;
  }
  .col-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-family: 'Assistant';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #45494A;
      margin-bottom: 0px;
      @media only screen and (max-width: 768px){
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
      }
      span {
        font-weight: 700;
        margin-right: 14px;
        @media only screen and (max-width: 768px){
          font-size: 10px;
        }
      }
    }
  }
  #timer {
    width: 25px;
  }
  #location {
    width: 20px;
  }
  img {
    margin-right: 14px;
    @media only screen and (max-width: 768px){
      margin-right: 10px;
    }
  }
`;

const BlueWhiteText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 46px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0;
  padding: 19px;
  background: #24CAD3;
  @media only screen and (max-width: 768px){
    padding: 14px;
  }
`;

const GrayBg = styled.div`
  width: 100%;
  background: #24CAD3;
  padding: 50px 22px;
  h1 {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* or 117% */
    text-align: center;
    color: #FFFFFF;
    @media only screen and (max-width: 568px){
      max-width: 328px;
    }
  }
  button {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100% !important;
    max-width: 398px;
    margin: 0;
    font-weight: 800;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    @media only screen and (min-width: 768px){
      max-width: 470px;
      font-size: 24px;
      line-height: 36px;      
    }
  }
`;

export const Banner = ({study, setShowCart}) => {
  const {width} = useWindowDimensions();
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  return (
    <>
      <BannerTransforma study={study} setShowCart={setShowCart}/>
      <GrayBar>
        <Container>
          <Row>
            <Col xs={6} id="left-col">
              <img id='timer' alt='timer' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FtimeVG.png?alt=media&token=234fae11-14f5-495c-8622-782ba9133a70'/>
              <p><span>Duración:</span>{width < 768 && <br/>}3 meses</p>
            </Col>
            <Col xs={6}>
              <img id='location' alt='location' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FubiVG.png?alt=media&token=0627dfce-97ac-4397-aa07-7dc20ddd5589'/>
              <p><span>Ubicación:</span>{width < 768 && <br/>}Puebla</p>
            </Col>
          </Row>
        </Container>
      </GrayBar>
      <BlueWhiteText>Próximas fechas de inicio: 3, 17 y 31 de Julio</BlueWhiteText>
      <Vimeo color='ffbc45' video={'https://player.vimeo.com/video/803588742?h=f98c90aa81&autoplay=1&loop=1&byline=0&portrait=0'} responsive={true} autoplay loop muted/>
      <GrayBg>
        <div className='d-flex w-100 justify-content-center'>
        <h1>¿Quieres conocer tu estado de salud actual?</h1>
        </div>
        <div className='d-flex w-100 justify-content-center'>
        <OrangeButton>
            <a style={{color:'#FFF'}} 
            target='_blank'
            rel='noopener noreferrer' 
            href='https://www.proprofs.com/quiz-school/ugc/story.php?title=test-de-hbitos-nutricios'>
              Tomar cuestionario de hábitos
            </a>
          </OrangeButton>
        </div>
      </GrayBg>
    </>
  );
};
