import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { handleAddItemToCart } from "../../../actions/shoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VG70Box =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG70Box.png?alt=media&token=3b33240a-6380-4e85-9fdb-e369a995079d";
const VG45Box =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG45Box.png?alt=media&token=d268cba9-b484-4452-a3fd-0679194c2655";
const CeliaquiaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCeliaquiaBoxFull.png?alt=media&token=d2113dd7-eb93-49f2-9642-368f2e18e534";
const LactosaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FLactosaBoxFull.png?alt=media&token=62cd4ce0-9ea6-4065-9f81-e161e445bd1a";
const CafeinaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCafeinaBoxFull.png?alt=media&token=2874b510-71fa-49dc-8edf-4b5be251895e";

const CardContainer = styled.div`
  background-color: #ffffff;
  border-top: 1px solid #e2eaf2;
  border-left: 1px solid #e2eaf2;
  border-right: 1px solid #e2eaf2;
  border-radius: 20px 20px 0 0;
  text-align: center;
  min-height: 345px;
  padding: 16px 16px 0 16px;
`;

const StudyCover = styled.img`
  max-height: 230px;
  margin-top: -128px;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const StudyTitle = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: ${(props) =>
    props.type === 598
      ? "#D44DAD"
      : props.type === 519 || props.type === 974
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#90CB15"};
  max-width: 253px;
  margin: 0;
  padding: 0;
`;

const StudyPrice = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  color: #515151;
  margin: 0;
  text-align: center;
  width: auto;
  padding: 0;
`;

const Currency = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: #515151;
  margin: 0;
  width: auto;
  padding: 0;
`;

const StudyDetail = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #515151;
  margin: 0;
  text-align: left;
`;

const StyledHr = styled.hr`
  background-color: #bfc6cd;
  height: 0.5px;
  max-width: 550px;
  margin: 0;
`;

const PayButton = styled.button`
  width: auto;
  border: none;
  border-color: ${(props) =>
    props.type === 598
      ? "#D44DAD"
      : props.type === 598
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#A0E218"};
  border-radius: 102px;
  padding: 10px;
  width: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: ${(props) =>
    props.type === 598
      ? "#D44DAD"
      : props.type === 519 || props.type === 974
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#A0E218"};
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: ${(props) =>
      props.type === 598
        ? "#C52288"
        : props.type === 519 || props.type === 974
        ? "#5c48db"
        : props.type === 166
        ? "#EF6534"
        : "#62e218"};
    color: #ffffff;
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 220px;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 380px;
  }
`;

const MoreInfo = styled.button`
  width: auto;
  border: 1px solid transparent;
  border-radius: 102px;
  padding: 10px;
  width: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: ${(props) =>
    props.type === 598
      ? "#D44DAD"
      : props.type === 519 || props.type === 974
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#90CB15"};
  background-color: #ffffff;
  max-width: 281.75px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: #ffffff;
    color: ${(props) =>
      props.type === 598
        ? "#C52288"
        : props.type === 519 || props.type === 974
        ? "#5c48db"
        : props.type === 166
        ? "#EF6534"
        : "#62e218"};
    border: ${(props) =>
      props.type === 598
        ? "1px solid #C52288"
        : props.type === 519 || props.type === 974
        ? "1px solid #5c48db"
        : props.type === 166
        ? "1px solid #EF6534"
        : "1px solid #62e218"};
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 220px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 380px;
  }
`;

const AddToCart = styled.button`
  width: auto;
  border: ${(props) =>
    props.type === 598
      ? "1px solid #D44DAD"
      : props.type === 519 || props.type === 974
      ? "1px solid #8355B7"
      : props.type === 166
      ? "1px solid #E49D5B"
      : "1px solid #A0E218"};
  border-radius: 102px;
  padding: 10px;
  width: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: ${(props) =>
    props.type === 598
      ? "#D44DAD"
      : props.type === 519 || props.type === 974
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#90CB15"};
  background-color: #ffffff;
  max-width: 281.75px;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: #ffffff;
    color: ${(props) =>
      props.type === 598
        ? "#C52288"
        : props.type === 519 || props.type === 974
        ? "#5c48db"
        : props.type === 166
        ? "#EF6534"
        : "#62e218"};
    border: ${(props) =>
      props.type === 598
        ? "1px solid #C52288"
        : props.type === 519 || props.type === 974
        ? "1px solid #5c48db"
        : props.type === 166
        ? "1px solid #EF6534"
        : "1px solid #62e218"};
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 220px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 380px;
  }
`;

const MoreInfoRow = styled(Row)`
  border-left: 1px solid #e2eaf2;
  border-right: 1px solid #e2eaf2;
  border-bottom: 1px solid #e2eaf2;
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  padding: 0 16px 16px 16px;
`;

const studyContent = {
  vg70: [
    {
      detail:
        "Versión más completa de nuestras pruebas genéticas, incluye todos los genes de la prueba Vg45 y más",
    },
    {
      detail:
        "Incluye apartado de genetic insights (lo último en investigación)",
    },
    {
      detail:
        "Logra personalizar tu alimentación y realiza cambios en tu estilo de vida",
    },
  ],
  vg45: [
    {
      detail: "Personaliza tu alimentación (básica)",
    },
    {
      detail:
        "¿Qué vitaminas y minerales necesitas vigilar en tu alimentación?",
    },
    {
      detail: "¿Qué alimentos implican un riesgo para tu corazón?",
    },
    {
      detail:
        "¿Ejercicio de potencia o resistencia? ¿A cuál tienes una mejor respuesta?",
    },
  ],
  celiaquia: [
    {
      detail: "Análisis de variantes HLA- DQ 2.5 y 8",
    },
    {
      detail:
        "Determinación del riesgo genético a desarrollar intolerancia al gluten",
    },
    {
      detail: "Entrega de resultados de 3-5 días hábiles*",
    },
  ],
  lactosa: [
    {
      detail: "Análisis del gen MCM6, regulador de la producción de la lactasa",
    },
    {
      detail:
        "Las personas que poseen la variante de riesgo CC o CT tienen una capacidad reducida de procesar la lactosa, creando una intolerancia",
    },
    {
      detail: "Entrega de resultados de 3-5 días hábiles*",
    },
  ],
  cafeina: [
    {
      detail: "Análisis del gen ADORA2 y CYP1A2 por PCR en tiempo real.",
    },
    {
      detail:
        "Las personas que presentan el polimorfismo AC o CT respectivamente tienen un metabolismo menor de la cafeína y por ende pueden presentar ansiedad o enfermedades cardiovasculares.",
    },
    {
      detail: "Entrega de resultados de 3-5 días hábiles*",
    },
  ],
};

export const StudyCard = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const getStudyInfo = async (studyId) => {
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + studyId);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const PayStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      setTimeout(() => {
        history.push("/checkout");
        window.scrollTo(0, 0);
      }, 300);
    }
  };

  const addStudy = () => {
    dispatch(handleAddItemToCart(study));
  };

  useEffect(() => {
    getStudyInfo(props.studyId);
  }, [props.studyId]);

  const Title = () => {
    return (
      <>
        {study.id === 598 ? (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              <span
                onClick={() => (window.location.href = "/vg70/estudio/598")}
                type="button"
              >
                Nutrición personalizada y condición física <br />
                <b>70 variantes</b>
              </span>
            </StudyTitle>
          </Row>
        ) : study.id === 519 ? (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              <span
                onClick={() => (window.location.href = "/estudio/519")}
                type="button"
              >
                Nutrición personalizada y condición física <br />
                <b>45 variantes</b>
              </span>
            </StudyTitle>
          </Row>
        ) : study.id === 166 ? (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              <span
                onClick={() => (window.location.href = "/gluten/estudio/166")}
                type="button"
              >
                Prueba genética de riesgo para <b>intolerancia al gluten</b>
              </span>
            </StudyTitle>
          </Row>
        ) : study.id === 974 ? (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              <span
                onClick={() => (window.location.href = "/cafeina/estudio/974")}
                type="button"
              >
                Prueba genética de <b>intolerancia a la cafeína</b>
              </span>
            </StudyTitle>
          </Row>
        ) : (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              <span
                onClick={() => (window.location.href = "/lactosa/estudio/590")}
                type="button"
              >
                Prueba genética de riesgo de <b>intolerancia a la lactosa</b>
              </span>
            </StudyTitle>
          </Row>
        )}
      </>
    );
  };

  const Price = () => {
    return (
      <Row className="justify-content-center align-items-end mb-4">
        <StudyPrice>{currency(study.normal_price)}</StudyPrice>
        <Currency className="ps-1">MXN</Currency>
      </Row>
    );
  };

  const MoreInfoButton = ({ type, children, eventKey }) => {
    const onClick = useAccordionButton(eventKey);

    return (
      <MoreInfo className="mb-2" type={type} onClick={onClick}>
        {children}
      </MoreInfo>
    );
  };

  const Details = (props) => {
    return (
      <div className="p-0">
        <Accordion>
          <Row className="justify-content-center">
            <MoreInfoButton type={study.id} eventKey={study.id}>
              Más información
            </MoreInfoButton>
          </Row>
          <Accordion.Collapse eventKey={study.id}>
            <div className="p-0">
              {props.study.map((item, index) => (
                <Row className="justify-content-center flex-wrap" key={index}>
                  <StudyDetail
                    className={`mt-2 mb-${
                      index === props.study.length - 1
                        ? study.id === 2
                          ? "2"
                          : study.id === 3
                          ? "2"
                          : "3"
                        : "2"
                    }`}
                  >
                    {item.detail}
                  </StudyDetail>
                  {index === props.study.length - 1 ? (
                    <>
                      {study.id === 166 ? (
                        <StudyDetail
                          className="mt-2 mb-3"
                          style={{ color: "#A6A6A6" }}
                        >
                          *Una vez que la muestra esté en nuestro laboratorio
                        </StudyDetail>
                      ) : (
                        study.id === 590 && (
                          <StudyDetail
                            className="mt-2 mb-3"
                            style={{ color: "#A6A6A6" }}
                          >
                            *Una vez que la muestra esté en nuestro laboratorio
                          </StudyDetail>
                        )
                      )}
                      <AddToCart type={study.id} onClick={() => addStudy()}>
                        Añadir al carrito
                      </AddToCart>
                    </>
                  ) : (
                    <div className="p-0">
                      <StyledHr />
                    </div>
                  )}
                </Row>
              ))}
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  };

  return (
    <>
      <CardContainer className="mt-5 mb-0 d-flex flex-column justify-content-between">
        <Row className="justify-content-center mb-2">
          <div className="p-0" style={{ maxWidth: "100%" }}>
            <StudyCover
              src={
                study.id === 598
                  ? VG70Box
                  : study.id === 519
                  ? VG45Box
                  : study.id === 166
                  ? CeliaquiaBox
                  : study.id === 974
                  ? CafeinaBox
                  : LactosaBox
              }
              alt="Imagen de caja"
              className="img-fluid"
            />
          </div>
        </Row>
        <div className="p-0">
          <Title />
          <Price />
          <p>(Envío incluido)</p>
          <Row className="justify-content-center mb-2">
            <PayButton type={study.id} onClick={() => PayStudy()}>
              Pagar ahora
            </PayButton>
          </Row>
        </div>
      </CardContainer>
      <MoreInfoRow className="justify-content-center w-100">
        <Details
          study={
            study.id === 598
              ? studyContent.vg70
              : study.id === 519
              ? studyContent.vg45
              : study.id === 166
              ? studyContent.celiaquia
              : study.id === 974
              ? studyContent.cafeina
              : studyContent.lactosa
          }
        >
          Más información
        </Details>
      </MoreInfoRow>
    </>
  );
};
