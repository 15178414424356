import React from 'react';
import styled from 'styled-components';
import { Row, Col, Modal } from 'react-bootstrap';
import Mask from '../../assets/kit/container.png';
import TestGif from '../../assets/kit/muestra.gif';
import { UilWhatsapp } from '@iconscout/react-unicons';

const StyledModal = styled(Modal)`
  display: flex !important;
  align-items: center;
  .modal-dialog {
    max-width: 590px;
    width: 100%;
  }
  .btn-close {
    max-width: 32px;
    max-height: 32px;
    width: 100%;
    height: 100%;
    background-color: #77ccd5;
    border-radius: 50%;
    padding: 8px;
    border: none;
    position: absolute;
    top: -1.6em;
    right: 3em;
    opacity: 1;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  }
  @media only screen and (max-width: 768px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
`;

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #121619;
  margin: 0;
  padding: 0;
`;

const NormalText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #121619;
  margin: 0;
  padding: 0;
`;

const CirclesContainer = styled.div`
  max-width: 260px;
  max-height: 260px;
  position: relative;
`;

const TestContainer = styled.div`
  max-width: 245px;
  max-height: 245px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url(${TestGif});
  background-size: 95%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  position: absolute;
  top: 0;
`;

const PinkContainer = styled.div`
  position: absolute;
  max-width: 233px;
  max-height: 233px;
  background-color: #d44dad;
  border-radius: 50%;
  top: -0.4em;
  left: 0.3em;
`;

const BlueContainer = styled.div`
  position: absolute;
  max-width: 246px;
  max-height: 246px;
  background-color: #77ccd5;
  border-radius: 50%;
  bottom: -1.2em;
  right: -0.5em;
`;

const ChatBg = styled.div`
  background-color: #26a30b;
  width: auto;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  padding: 8px;
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 272px;
  width: 100%;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;

export const StartTestModal = (props) => {
  return (
    <>
      <StyledModal
        size='lg'
        show={props.show}
        onHide={() => props.setShow(false)}
      >
        <StyledModal.Body className='p-5 position-relative'>
          <button className='btn-close' onClick={() => props.setShow(false)} />
          <Row className='mb-4'>
            <Title>Es momento de realizar tu toma</Title>
          </Row>
          <Row className='mb-4'>
            <NormalText>
              Sigue las instrucciones que vienen en tu kit genético{' '}
            </NormalText>
          </Row>
          <Row className='mb-4 pt-3 pb-3'>
            <CirclesContainer data-aos='zoom-in' data-aos-duration='600'>
              <img src={Mask} className='img-fluid p-0' />
              <PinkContainer 
                data-aos='fade-up-left'
                data-aos-duration='400'
              >
                <img src={Mask} className='img-fluid p-0' />
              </PinkContainer>
              <BlueContainer
                className='img-fluid p-0'
                data-aos='fade-down-right'
                data-aos-duration='400'
              >
                <img src={Mask} className='img-fluid p-0' />
              </BlueContainer>
              <TestContainer>
                <img src={Mask} className='img-fluid p-0' />
              </TestContainer>
            </CirclesContainer>
          </Row>
          <Row className='mb-2'>
            <NormalText>
              <strong>¿Necesitas asesoramiento? -</strong> Llámanos al 22 22 08 68 33 
            </NormalText>
          </Row>
          <Row className='mb-2'>
            <NormalText>
              o escríbenos
            </NormalText>
          </Row>
          <Row className='align-items-center mb-4'>
            <a
              href='https://wa.me/525615077271'
              target='_blank'
              rel='noreferrer'
              className='w-100 d-flex flex-row align-items-center'
            >
              <ChatBg>
                <UilWhatsapp size={24} color={'#ffffff'} />
              </ChatBg>
              <NormalText className='ps-2 w-auto'>
                <strong>
                  Chat de Whatsapp
                </strong>
              </NormalText>
            </a>
          </Row>
          <Row className='pt-2 w-100 justify-content-center'>
            <ContinueButton className='pt-2 pb-2' onClick={() => {props.setShow(false); props.handleTestModal();}}>
              Ya realicé mi muestra
            </ContinueButton>
          </Row>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
};
