import React, { useState } from 'react';
import { Tabs, Tab, } from "react-bootstrap";
import styled from "styled-components";
import { PersonalData } from '../components/profile/personalData';
import { Shopping } from '../components/profile/shopping';
import { ProfileMenu } from '../components/login/ProfileMenu';

const Label = styled.label`
    border: 1px solid transparent;
    background-color: #03aea2;
    display: inline-block;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 800;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    color: #fff;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

const TabProfile = styled(Tab)`
    color: ##495057;
    font-weight = 800 !important;
`;

export const Profile = () => {
    const [info, setInfo] = useState([
        {
            name: 'Pedro Sanchez Tellez',
            phone: '7483237581',
            email: 'test-pedroœgmail.com',
            address: {
                calle: 'Buena vista',
                nExt: '12',
                nInt: 'B',
                colonia: 'America',
                municipio: 'Huejotzingo',
                estado: 'CDMX',
                cPostal: '74160',
                pais: 'Mexico',
            },
            billing: {
                rfc: 'PFAJSKASJSA',
                razonSocial: 'PedroSanchez',
                usoFactura: true,
                calle: 'Buena vista',
                nExt: '12',
                nInt: 'B',
                colonia: 'America',
                municipio: 'Huejotzingo',
                estado: 'CDMX',
                cPostal: '74160',
                pais: 'Mexico',
            }

        }
    ]);
    const [shopping, setShopping] = useState([
        {
            id: 'DM21-0020570-1',
            name: 'Pedro Sanchez Tellez',
            date: '2021-05-15 16:40:00',
            studies: ['Detección (COVID-19) por PCR en tiempo real', 'Velocidad de sedimentación Globular'],
            status: 'Resultado listo',
        },
        {
            id: 'DM21-0024950-1',
            name: 'Pedro Sanchez Tellez',
            date: '2021-05-15 16:40:00',
            studies: ['Detección (COVID-19) por PCR en tiempo real', 'Velocidad de sedimentación Globular'],
            status: 'Resultado listo',
        },
        {
            id: 'DM21-1473570-1',
            name: 'Pedro Sanchez Tellez',
            date: '2021-05-15 16:40:00',
            studies: ['Detección (COVID-19) por PCR en tiempo real', 'Velocidad de sedimentación Globular'],
            status: 'Resultado listo',
        },
        {
            id: 'DM21-0131570-1',
            name: 'Pedro Sanchez Tellez',
            date: '2021-05-15 16:40:00',
            studies: ['Detección (COVID-19) por PCR en tiempo real', 'Velocidad de sedimentación Globular'],
            status: 'Resultado listo',
        },
        {
            id: 'DM21-6793800-1',
            name: 'Pedro Sanchez Tellez',
            date: '2021-05-15 16:40:00',
            studies: ['Detección (COVID-19) por PCR en tiempo real', 'Velocidad de sedimentación Globular'],
            status: 'Resultado listo',
        },
    ]);

    return (
        <>
            {/* <div className='min-vh-100'>
                <div className="mt-3 row">
                    <div className="col-md-4">
                        <Label>Mi perfil</Label>
                    </div>
                </div>
                <div className="mt-4 container">
                    <Tabs defaultActiveKey="personalData" id="profile-tab" className="mb-4 font-weight-bold">
                        <TabProfile eventKey="personalData" title="Datos">
                            <PersonalData info={info}/>
                        </TabProfile>
                        {console.log(info)}
                        <TabProfile eventKey="shopping" title="Mis compras">
                            <Shopping shopping={shopping}/>
                        </TabProfile>
                    </Tabs>
                </div>
            </div> */}
            <ProfileMenu />
        </>
    );
}
