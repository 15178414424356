/* eslint-disable no-unused-vars */
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
} from 'react-bootstrap';
import { UilBookMedical, UilCameraPlus, UilFacebookF, UilInstagram, UilGlobe } from '@iconscout/react-unicons';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useForm } from '../../hooks/useForm';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { updateInfoUser } from '../../actions/user';
import { useSelector } from 'react-redux';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import {
  getStorage,
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { db, app } from '../../firebase/firebase-config';
import { addMember } from '../../mailchimp/maichimp';

const days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
const months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const especialidades = [
  { value: "Seleccione una opción" },
  { value: "MEDICINA GENERAL" },
  { value: "ALERGOLOGÍA" },
  { value: "ANATOMÍA PATOLÓGICA" },
  { value: "ANESTESIOLOGÍA" },
  { value: "AUDIOLOGÍA, OTONEUROLOGÍA Y FONIATRÍA" },
  { value: "BARIATRA" },
  { value: "CALIDAD DE LA ATENCIÓN CLÍNICA" },
  { value: "CIRUGÍA GENERAL" },
  { value: "EPIDEMIOLOGÍA" },
  { value: "GASTROENTEROLOGÍA" },
  { value: "GENÉTICA MÉDICA" },
  { value: "GERIATRÍA" },
  { value: "GINECOLOGÍA Y OBSTETRICIA" },
  { value: "IMAGENOLOGÍA DIAGNÓSTICA Y TERAPÉUTICA" },
  { value: "MEDICINA DE LA ACTIVIDAD FISICA Y DEPORTIVA" },
  { value: "MEDICINA DE REHABILITACIÓN" },
  { value: "MEDICINA DE URGENCIAS" },
  { value: "MEDICINA DEL TRABAJO Y AMBIENTAL" },
  { value: "MEDICINA FAMILIAR" },
  { value: "MEDICINA INTERNA" },
  { value: "MEDICINA LEGAL" },
  { value: "MEDICINA NUCLEAR E IMAGENOLOGÍA MOLECULAR" },
  { value: "MEDICINA PREVENTIVA" },
  { value: "NEUMOLOGÍA" },
  { value: "NUTRICIÓN" },
  { value: "OFTALMOLOGÍA" },
  { value: "OTORRINOLARINGOLOGÍA Y CIRUGIA DE CABEZA Y CUELLO" },
  { value: "ONCOLOGÍA CLÍNICA" },
  { value: "PATOLOGÍA CLÍNICA" },
  { value: "PEDIATRÍA" },
  { value: "PSIQUIATRÍA" },
  { value: "RADIO ONCOLOGÍA" },
  { value: "TRAUMATOLOGÍA Y ORTOPEDIA" },
  { value: "OTRA" },
];

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;

  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 40px;
  line-height: 130%;
  color: #474A4B;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
  }
`;

const NormalText = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  line-height: 26.73px;
  color: #45494a;
  text-align: left;
  margin: 0;
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 0;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${(props) => (props.selected ? '#474A4B' : '#697077')};
  padding-left: 0;
  padding-right: 3%;
  padding-bottom: 4px;
  margin-bottom: -2px;
  transition: 200ms;
  padding-bottom: 20px;
  :hover {
    color: ${(props) => (props.$isDoctor ? '#5731B2' : '#77CCD5')};
    transition: 200ms;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: -5px;
  background:  ${(props) =>
    props.selected && props.$isDoctor
      ? 'linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%)'
      : props.selected && 'linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%)'};
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #C1D9D8;
`;

const StyledInput = styled(Form.Control)`
  background: rgba(77, 153, 229, 0.1);
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 20px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :focus{
    filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
    box-shadow: none;
    border-color: #00000000;
    border-radius: 10px;
    background: #FFFFFF;
  }
`;

const BorderForPhoneInput = styled.div`
  display: flex;
  padding: 2px;
  width: 332px;
  height: 48px;
  margin: 6px 11px;
  border-radius: 10px;
  :focus-within {
    background: linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%);
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 328px;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #ecf5fc;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  :focus-within, input:focus{
    background: #FFF;
  }
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #ecf5fc;
  }
`;

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 2px;
  border-radius: 12px;
  transition: all ease .5s;
  position: relative;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 1;
  }
  .form-control {
    border: none;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    max-height: 45px;
    padding: 0 0 0 20px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  .form-select {
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    max-height: 45px;
    padding: 0 0 0 40px !important;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'IBM Plex Sans';
    transition: 300ms;
  }
  :active,
  :focus-within {
    background: linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%);
    label {
      margin: 5px 0 0 35px;
      padding: 4px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const Birthday = styled(Row)`
  text-align: center;
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    cursor: pointer;
    :focus {
      box-shadow: none;
      filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
      border-radius: 10px;
      background: #FFFFFF;
      border-radius: 10px;
    }
  }
  .border-div {
    border-radius: 12px;
    padding: 12px 2px 2px;
  }
  .border-div:focus-within {
    background: linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%);
  }
  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: -52px;
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
`;

const InputStyled = styled(Form.Check)`
  > input {
    width: 18px !important;
    border: ${(props) =>
      props.isdoctor === 1
        ? '1px solid #5731B2 !important'
        : '1px solid #77CCD5 !important'};
    :checked {
      border: ${(props) =>
        props.isdoctor === 1
          ? '1px solid #5731B2 !important'
          : '1px solid #77CCD5 !important'};
      background-color: ${(props) =>
        props.isdoctor === 1 ? '#5731B2 !important' : '#77CCD5 !important'};
    }
  }
`;

const Delimitador = styled.hr`
  width: 100%;
  padding: 0.5px 0;
  background: ${(props) => (props.isDoctor ? '#5731B2' : '#77CCD5')};
`;

const UploadImage = styled.div`
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #e0ecec;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const LogoDocContainer = styled.div`
  border-radius: 50%;
  box-shadow: 0px 4px 4px #d6d6d6;
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  position: relative;
  overflow: hidden;
  background-image: ${(props) => `url('${props.bg}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
`;

export const EditPersonalData = () => {
  const [tab, setTab] = useState(0);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isNewUser = urlParams.get('new');
  const years = [];
  for (var i = 2024; i >= 1930; i--) {
    years.push(i.toString());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonStyle = {
    width: '214px',
    height: '48px',
    border: 'none',
    borderRadius: '102px',
    fontweight: '800',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const text1Style = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
  };

  const rowContent = {
    width: '100%',
  };

  const smallText = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    textAlign: 'left',
    color: '#A6A6A6',
    margin: '0 0 2px 0',
  };

  // const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width < 630;

  // Go back arrow
  const goBackLabel = {
    ...text1Style,
    width: '40px',
    margin: width < 680 ? '0px -29px' : '0px -15px',
  };

  const subtitle = (text) => (
    <Row
      style={{
        ...rowContent,
        paddingLeft: isMobile ? '7%' : '4.1%',
        margin: '10px 0',
      }}
    >
      <br />
      <p style={{ ...goBackLabel, textAlign: 'left', width: '100%' }}>{text}</p>
    </Row>
  );

  const colBirthDay = (array, title, defaultValue, id) => (
    <Col xs={4} style={{ paddingLeft: '0px' }}>
      <Form.Label>{title}</Form.Label>
      <div className='border-div'>
        <Form.Select
          defaultValue={defaultValue}
          key={id}
          id={id}
          name={id}
          onChange={handleInputChange}
        >
          <option disabled hidden>
            {defaultValue}
          </option>
          {array.map((item, i) => (
            <option key={`${item}-${i}`} value={item}>
              {item}
            </option>
          ))}
          {/* studies/studiesDetail */}
        </Form.Select>
      </div>
    </Col>
  );

  const user = useSelector((state) => state.user?.data?.data);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [addSecondEmail, setAddSecondEmail] = useState(false);
  const uidProfile = useSelector((state) => state.auth.uid);
  const [addPhoneContact, setAddPhoneContact] = useState(false);
  const [addInnerNumber, setAddInnerNumber] = useState(false);
  const [addOfficeNumberOrName, setAddOfficeNumberOrName] = useState(false);
  const [addExtensionNumber, setAddExtensionNumber] = useState(false);
  const [newsLetterCheck, setChecked] = useState(
    user?.newsLetterCheck ? user.newsLetterCheck : false
  );
  const [imDr, setImDr] = useState((user?.profile?.type == 1) ? true : false);
  const [gender, setRadioValue] = useState(
    user?.gender ? user.gender : 'Masculino'
  );
  const [picture, setPicture] = useState(
    user?.profile?.logo ? user.profile.logo : ''
  );
  const [prevPicture, setPrevPicture] = useState('');
  const myRef = useRef();

  const addWhat = (title) => {
    switch (title) {
      case 'Agregar otro correo':
        setAddSecondEmail(!addSecondEmail);
        break;
      case 'Agregar otro teléfono de contacto':
        setAddPhoneContact(!addPhoneContact);
        break;
      case 'Añadir número interior':
        setAddInnerNumber(!addInnerNumber);
        break;
      case 'Añadir número o nombre del consultorio':
        setAddOfficeNumberOrName(!addOfficeNumberOrName);
        break;
      case 'Añadir número de extensión':
        setAddExtensionNumber(!addExtensionNumber);
        break;
      default:
        console.log('Nada que agregar');
    }
  };

  const checkInput = (id, label, value) => (
    <InputStyled
      key={id}
      id={id}
      type='radio'
      name={id}
      label={label}
      value={value}
      style={{ margin: '10px 8% 10px 0' }}
      checked={gender === value}
      isdoctor={isDoctor ? 1 : 0}
      onChange={(e) => setRadioValue(e.currentTarget.value)}
    />
  );

  const addAnotherSomething = (title) => (
    <Row
      key={title}
      onClick={() => addWhat(title)}
      className='mb-4 pb-2'
      style={{ cursor: 'pointer', maxWidth: '380px' }}
    >
      <UilBookMedical
        className='w-auto'
        size='26'
        color={isDoctor ? '#5731B2' : '#77CCD5'}
      />
      <p className='w-auto p-0 m-0'>{title}</p>
    </Row>
  );

  const newsletterCheckBox = (
    <InputStyled
      key='check-newsletter'
      id='check-newsletter'
      type='checkbox'
      name='radio'
      label='Quiero recibir noticias y promociones exclusivas'
      value={newsLetterCheck}
      style={{ margin: '10px 0' }}
      checked={newsLetterCheck}
      isdoctor={isDoctor ? 1 : 0}
      onChange={() => setChecked(!newsLetterCheck)}
    />
  );

  const [phone, setPhone] = useState(user?.phone ? user.phone : '');
  const [phone2, setPhone2] = useState(user?.phone2 ? user.phone2 : '');
  const [phoneNumberConsultingRoom, setPhoneConsultRoom] = useState(
    user?.phoneNumberConsultingRoom ? user.phoneNumberConsultingRoom : ''
  );
  const [phoneNumberEmergency, setPhoneEmergency] = useState(
    user?.phoneNumberEmergency ? user.phoneNumberEmergency : ''
  );
  const initialForm = {
    idMedicoLabopat: user?.profile?.idMedicoLabopat
      ? user?.profile?.idMedicoLabopat
      : 0,
    idPacienteLabopat: user?.profile?.idPacienteLabopat
      ? user?.profile?.idPacienteLabopat
      : 0,
    newUser: user?.newUser ? user?.newUser : false,
    name: user?.name ? user.name : '',
    fathersLastname: user?.fathersLastname ? user.fathersLastname : '',
    mothersLastname: user?.mothersLastname ? user.mothersLastname : '',
    email: user?.email ? user.email : '',
    secondEmail: user?.secondEmail ? user.secondEmail : '',
    street: user?.street ? user.street : '',
    next: user?.externalNumber ? user.externalNumber : '',
    cp: user?.cp ? user.cp : '',
    colonia: user?.colonia ? user.colonia : '',
    facebook: user?.facebook ? user.facebook : '',
    instagram: user?.instagram ? user.instagram : '',
    web: user?.web ? user.web : '',
    day: user?.birthday ? user.birthday.split('-')[2] : '',
    month: user?.birthday ? user.birthday.split('-')[1] : '',
    year: user?.birthday ? user.birthday.split('-')[0] : '',
    especialidad: user?.profile?.especialidad ? user?.profile.especialidad : '',
    next: user?.next ? user.next : '',
    nint: user?.nint ? user.nint : '',
    extensionNumber: user?.extensionNumber ? user.extensionNumber : '',
    officeNameOrNumber: user?.officeNameOrNumber ? user.officeNameOrNumber : '',
    num_consejo: user?.profile?.num_consejo ? user?.profile.num_consejo : '',
   
    cedula_especialidad: user?.profile?.cedula_especialidad
      ? user?.profile?.cedula_especialidad
      : '',
    cedula_profesional: user?.profile?.cedula_profesional
      ? user?.profile?.cedula_profesional
      : '',
    universidad: user?.profile?.universidad
      ? user?.profile?.universidad
      : '',
    universidadB: user?.profile?.universidadB
      ? user?.profile?.universidadB
      : '.',
  };

  const [formValues, handleInputChange] = useForm(initialForm);
  const {
    num_consejo,
    cedula_especialidad,
    cedula_profesional,
    universidad,
    universidadB,
    name,
    fathersLastname,
    mothersLastname,
    email,
    secondEmail,
    street,
    cp,
    colonia,
    facebook,
    instagram,
    web,
    day,
    month,
    year,
    next,
    nint,
    officeNameOrNumber,
    extensionNumber,
    especialidad,
    newUser,
    idPacienteLabopat,
    idMedicoLabopat,
  } = formValues;

  const dispatch = useDispatch();

  const createMedicald = async (data) => {
    return await axios.post('https://lpqsystem.com/nuevoMe', data);
  };
  const createPatientId = async (data) => {
    console.log('paciente', data);
    var form = new FormData();
    form.append('nombre', data.info.nombre);
    form.append('app', data.info.app);
    form.append('apm', data.info.apm);
    form.append('tel', data.info.tel);
    form.append('email', data.info.email);
    form.append('sexo', 'Femenino');
    form.append('fnac', data.info.fnac);
    form.append('edad', 45);
    form.append('uid', data.uid);
    console.log('***********...');

    return await axios.post('https://lpqsystem.com/crea-paciente', form);
    /*
    return await axios({
      method: 'post',
      headers: { 'content-type': 'application/json' },
      url: 'http://lpqsystem.com:3010/lpq/insert-paciente-principal',
      data: data,
    });*/
  };

  const handleUpdateProfileData = async () => {
    if(newsLetterCheck){
      await addMember(formValues.email, (isDoctor || imDr));
    }
    var idLpqMed = 0;
    var idLpqPac = 0;
    var promises = [];
    console.log('idLpq: ', formValues.idMedicoLabopat);
    if (imDr && formValues.idMedicoLabopat === 0) {
      var form = new FormData();
      form.append('nombre', formValues.name);
      form.append('app', formValues.fathersLastname);
      form.append('apm', formValues.mothersLastname);
      form.append('calle', formValues.street);
      form.append('noext', formValues.next);
      form.append('noint', formValues.nint);
      form.append('colonia', formValues.colonia);
      form.append('facebook', formValues.facebook);
      form.append('instagram', formValues.instagram);
      form.append('web', formValues.web);
      form.append('ciudad', '');
      form.append('estado', '');
      form.append('cp', formValues.cp);
      form.append('tel', '');
      form.append('email', formValues.email);
      const prom1 = createMedicald(form);
      promises.push(prom1);
      /*
      if (request.data.ok) {
        idLpqMed = request.data.id;
      } else {
        idLpqMed = 12;
      }*/
    } else {
      idLpqMed = formValues.idMedicoLabopat;
    }
    console.log('user: ', formValues.idPacienteLabopat);
    if (
      formValues.idPacienteLabopat === 0 ||
      formValues.idPacienteLabopat === -1
    ) {
      console.log('------');
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      let lpqData = {
        info: {
          nombre: formValues.name,
          app: formValues.fathersLastname,
          apm: formValues.mothersLastname,
          calle: formValues.calle,
          noext: formValues.noext,
          noint: formValues.noint,
          colonia: formValues.colonia,
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          web: formValues.web,
          ciudad: formValues.ciudad,
          estado: formValues.estado,
          cp: formValues.cp,
          tel: formValues.tel,
          email: formValues.email,
          fnac: birthday,
          gender: formValues.gender,
        },
        uid: uidProfile,
      };
      const prom2 = createPatientId(lpqData);
      promises.push(prom2);
    } else {
      // checar mandar update a LPQ system
      console.log('ya tengo id');
      idLpqPac = formValues.idPacienteLabopat;
    }

    if (promises.length > 0) {
      let a = await Promise.all(promises);
      console.log(a);
      a.forEach((element) => {
        console.log(element.config.url);
        if (element.config.url === 'https://lpqsystem.com/nuevoMe') {
          if (element.data.ok) {
            idLpqMed = element.data.id;
          } else {
            idLpqMed = 12;
          }
        } else if (
          element.config.url === 'https://lpqsystem.com/crea-paciente'
        ) {
          if (element.data.ok) {
            idLpqPac = element.data.id;
          } else {
            idLpqPac = 0;
          }
        }
      });
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      var profile = user?.profile ? user?.profile : { type: imDr ? 1 : 0 };
      profile.idMedicoLabopat = idLpqMed;
      profile.idPacienteLabopat = idLpqPac;
      profile.type = imDr ? 1 : 0;
      profile.first_time = profile?.first_time ? false : true;
      profile.cedula_especialidad = cedula_especialidad;
      profile.cedula_profesional = cedula_profesional;
      profile.universidad = universidad;
      profile.universidadB = universidadB;
      profile.num_consejo = num_consejo;
      profile.especialidad = especialidad;
      profile.first_time = false;
      profile.verifed = true;
      profile.logo = picture && picture.length > 20 ? picture : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdrLogos%2F0DedaultLogo.png?alt=media&token=719fdda6-4221-4c31-a9cd-82243eea531b";
      console.log('profile2: ', profile);

      const newObj = {
        ...formValues,
        birthday,
        phone,
        phoneNumberConsultingRoom,
        phoneNumberEmergency,
        gender,
        newsLetterCheck,
        phone2,
        profile,
      };
      delete newObj.day;
      delete newObj.month;
      delete newObj.year;
      console.log('f nac', newObj);

      dispatch(updateInfoUser(newObj));
      history.push('/mi-perfil');
    } else {
      const birthday = `${formValues.year}-${formValues.month}-${formValues.day}`;
      var profile = user?.profile ? user?.profile : { type: imDr ? 1 : 0 };
      profile.idMedicoLabopat = idLpqMed;
      profile.idPacienteLabopat = idLpqPac;
      profile.type = imDr ? 1 : 0;
      profile.first_time = profile?.first_time ? false : true;
      profile.cedula_especialidad = cedula_especialidad;
      profile.cedula_profesional = cedula_profesional;
      profile.universidad = universidad;
      profile.universidadB = universidadB;
      profile.num_consejo = num_consejo;
      profile.especialidad = especialidad;
      profile.first_time = false;
      profile.verifed = true;
      profile.logo = picture && picture.length > 20 ? picture : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdrLogos%2F0DedaultLogo.png?alt=media&token=719fdda6-4221-4c31-a9cd-82243eea531b";
      console.log('profile: ', profile);

      const newObj = {
        ...formValues,
        birthday,
        phone,
        phoneNumberConsultingRoom,
        phoneNumberEmergency,
        gender,
        newsLetterCheck,
        phone2,
        profile,
      };
      delete newObj.day;
      delete newObj.month;
      delete newObj.year;
      console.log(newObj);

      dispatch(updateInfoUser(newObj));
      history.push('/mi-perfil');
    }
  };

  const handleInputFile = () => {
    document.getElementById('inputFile').click();
  };

  const editInputFile = () => {
    setPrevPicture(picture);
    setPicture('');
    setTimeout(() => {
      document.getElementById('inputFile').click();
    }, 10);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      dispatch(
        uploadFile(file, `/ecommerce/drLogos/${file.name}${Date.now()}`)
      );
    }
  };

  const uploadFile = (file, path) => {
    const storageRef = getStorage(app, 'gs://covid19-cc1fe.appspot.com/');
    const fileRef = ref(storageRef, path);
    uploadBytes(fileRef, file)
      .then((res) => {
        console.log(res);
        getDownloadURL(fileRef)
          .then((url) => {
            if (prevPicture !== '') {
              const refToDelete = ref(storageRef, prevPicture);
              deleteObject(refToDelete)
                .then(() => {
                  console.log('File deleted successfully');
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(url);
            setPicture(url);
          })
          .catch((error) => {
            console.log('Error en URL');
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container
      className={isMobile ? 'mt-5 pt-4' : ''}
      style={{ minHeight: '100vh' }}
    >
      <HelmetTitle title='Mi perfil' name='description' content='content' />
      <Row className='mb-5'>
        <HeaderSection>
          <HeaderTitle className='mb-5'>
            {isNewUser && isNewUser === '1'
              ? 'Completa tú perfil'
              : 'Mi perfil'}
          </HeaderTitle>
          <StyledNav>
            <Col xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: '0' }}>
              <TabTitle
                onClick={() => setTab(0)}
                selected={tab === 0 ? true : false}
                $isDoctor={isDoctor}
              >
                Datos generales
              </TabTitle>
            </Col>
            {/* <Col xs={6} md={3} lg={3} xl={2} style={{paddingLeft: '0'}}>
              <TabTitle
                onClick={() => setTab(0)} selected={tab === 1 ? true : false}>
                Peniente...
              </TabTitle>
            </Col> */}
          </StyledNav>
          <Row>
            <TabIndicatorCol xs={6} md={3} lg={3} xl={2} style={{ paddingLeft: '0' }}
              selected={tab === 0 ? true : false}
              $isDoctor={isDoctor}
            />
          </Row>
        </HeaderSection>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Row className='w-100 mb-4 pb-2'>
            <p>
              {isNewUser && isNewUser === '1'
                ? 'Al completar tu perfil médico, vamos a poder generar estadísticas y guardar tu historial médico.'
                : ''}
            </p>
            <StyledLabel controlId='name' label='Nombre'>
              <StyledInput
                type='text'
                placeholder='Nombre *'
                value={name}
                name='name'
                autoComplete='off'
                list='autocompleteOff'
                onChange={handleInputChange}
                required
              />
            </StyledLabel>
          </Row>
          <Row className='w-100 mb-4 pb-2'>
            <StyledLabel controlId='fathersLastname' label='Apellido paterno'>
              <StyledInput
                type='text'
                placeholder='Apellido paterno *'
                value={fathersLastname}
                name='fathersLastname'
                autoComplete='off'
                list='autocompleteOff'
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          <Row className='w-100 mb-4 pb-2'>
            <StyledLabel controlId='mothersLastname' label='Apellido materno'>
              <StyledInput
                type='text'
                placeholder='Apellido materno *'
                value={mothersLastname}
                name='mothersLastname'
                autoComplete='off'
                list='autocompleteOff'
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          <Row className='w-100 mb-4 pb-2'>
            <StyledLabel controlId='email' label='Correo electrónico'>
              <StyledInput
                type='email'
                placeholder='Correo electrónico*'
                value={email}
                name='email'
                autoComplete='off'
                list='autocompleteOff'
                onChange={handleInputChange}
              />
            </StyledLabel>
          </Row>
          {(addSecondEmail || secondEmail.length > 0) && (
            <Row className='w-100 mb-4 pb-2'>
              <StyledLabel controlId='secondEmail' label='Correo adicional'>
                <StyledInput
                  type='email'
                  placeholder='Correo adicional'
                  value={secondEmail}
                  name='secondEmail'
                  autoComplete='off'
                  list='autocompleteOff'
                  onChange={handleInputChange}
                />
              </StyledLabel>
            </Row>
          )}
          {!addSecondEmail &&
            !(secondEmail.length > 0) &&
            addAnotherSomething('Agregar otro correo')}
          <Row className='mb-4 pb-2'>
            {/* {phoneInputComp('phoneNumber')} */}
            <Form.Label style={{ ...smallText, paddingLeft: '1.8%' }}>
              Teléfono celular
            </Form.Label>
            <BorderForPhoneInput>
              <StyledPhoneInput
                type='tel'
                international
                defaultCountry='MX'
                placeholder='Teléfono celular *'
                value={phone}
                name='phoneNumber'
                id='phoneNumber'
                autoComplete='off'
                list='autocompleteOff'
                onChange={setPhone}
              />
            </BorderForPhoneInput>
          </Row>
          {(addPhoneContact || phone2.length > 0) && (
            <Row className='mb-4 pb-2'>
              {/* {phoneInputComp('phoneNumber')} */}
              <Form.Label style={{ ...smallText, paddingLeft: '1.8%' }}>
                Contacto adicional
              </Form.Label>
              <StyledPhoneInput
                type='tel'
                international
                defaultCountry='MX'
                placeholder='Contacto adicional'
                value={phone2}
                name='secondPhoneNumber'
                id='secondPhoneNumber'
                autoComplete='off'
                list='autocompleteOff'
                onChange={setPhone2}
              />
            </Row>
          )}
          {!addPhoneContact &&
            !(phone2.length > 0) &&
            addAnotherSomething('Agregar otro teléfono de contacto')}

          {subtitle('Fecha de nacimiento')}
          <Row className='mb-3'>
            <div
              style={{
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
               <Birthday>
                {colBirthDay(days, 'Día', day?.length > 0 ? day : 'DD', 'day')}
                {colBirthDay(
                  months,
                  'Mes',
                  month?.length > 0 ? month : 'MM',
                  'month'
                )}
                {colBirthDay(
                  years,
                  'Año',
                  year?.length > 0 ? year : 'AAAA',
                  'year'
                )}
              </Birthday> 
            </div>
          </Row>
          {subtitle('Selecciona tu sexo')}
          <Row style={{ margin: '7px 0 0 0', padding: '3%' }}>
            <ButtonGroup className='mb-2' style={{ background: '#FFFFFF' }}>
              {checkInput('radio-masculino', 'Masculino', 'Masculino')}
              {checkInput('radio-femenino', 'Femenino', 'Femenino')}
            </ButtonGroup>
          </Row>
          <Row className='mb-4 pb-2' style={{ paddingLeft: '3%' }}>
            <InputStyled
              key='check-soyMedico'
              id='check-newsletter'
              type='checkbox'
              name='soyMedico'
              label='Soy especialista'
              value={imDr}
              style={{ margin: '10px 0' }}
              checked={imDr}
              isdoctor={isDoctor ? 1 : 0}
              onChange={() => setImDr(!imDr)}
            />
          </Row>
          {imDr ? (
            <>
              <Row className='mb-4'>
                {/* <HeaderSection> */}
                  <StyledNav style={{paddingLeft: '0'}}>
                    <Col xs={10} md={8} lg={8} xl={8} style={{ paddingLeft: '0' }}>
                      <TabTitle
                        selected={true}
                        $isDoctor={isDoctor}
                      >
                        Datos de perfil de especialista
                      </TabTitle>
                    </Col>
                  </StyledNav>
                  <Row style={{paddingLeft: '0'}}>
                    <TabIndicatorCol xs={10} md={8} lg={8} xl={8} style={{ paddingLeft: '0' }}
                      selected={true}
                      $isDoctor={isDoctor}
                    />
                  </Row>
                {/* </HeaderSection> */}
              </Row>
              {subtitle(' Datos médicos y dirección de consultorio')}
              <Row className='mb-4 mt-4 pb-2 pt-1'>
                <StyledLabel
                  controlId='cedula_profesional'
                  label='Cédula profesional'
                >
                  <StyledInput
                    type='text'
                    placeholder='Cédula profesional'
                    value={cedula_profesional}
                    name='cedula_profesional'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className='mb-4 mt-4 pb-2 pt-1'>
                <StyledLabel
                  controlId='universidad'
                  label='Institución educativa'
                >
                  <StyledInput
                    type='text'
                    placeholder='Institución educativa'
                    value={universidad}
                    name='universidad'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className='mb-4 pb-2'>
                <StyledLabel controlId='especialidad' label='Especialidad'>
                  <StyledSelect
                    name='especialidad'
                    value={especialidad}
                    onChange={(e) => {
                      handleInputChange(e);
                      console.log(e.target.value);
                    }}
                  >
                    <option disabled>Especialidad</option>
                    {especialidades.map((especialidad, index) => (
                      <option key={index} value={especialidad.value}>
                        {especialidad.value}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledLabel>
              </Row>
              <Row className='mb-4 pb-2'>
                <StyledLabel
                  controlId='cedula_especialidad'
                  label='Cédula de especialidad (opcional)'
                >
                  <StyledInput
                    type='text'
                    placeholder='Cédula de especialidad (opcional)'
                    value={cedula_especialidad}
                    name='cedula_especialidad'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>

              <Row className='mb-4 pb-2'>
                <StyledLabel controlId='street' label='Calle'>
                  <StyledInput
                    type='text'
                    placeholder='Calle'
                    value={street}
                    name='street'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className='mb-4 pb-2'>
                <StyledLabel controlId='next' label='No. exterior'>
                  <StyledInput
                    type='text'
                    placeholder='No. exterior'
                    value={next}
                    name='next'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              {(addInnerNumber || nint.length > 0) && (
                <Row className='mb-4 pb-2'>
                  <StyledLabel controlId='nint' label='No. interior'>
                    <StyledInput
                      type='text'
                      placeholder='No. interior'
                      value={nint}
                      name='nint'
                      autoComplete='off'
                      list='autocompleteOff'
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                </Row>
              )}
              {!addInnerNumber &&
                !(nint.length > 0) &&
                addAnotherSomething('Añadir número interior')}
              <Row className='mb-4 pb2'>
                <StyledLabel controlId='cp' label='Código postal'>
                  <StyledInput
                    type='text'
                    placeholder='Código postal'
                    value={cp}
                    name='cp'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Row className='mb-4 pb2'>
                <StyledLabel controlId='colonia' label='Colonia'>
                  <StyledInput
                    type='text'
                    placeholder='Colonia'
                    value={colonia}
                    name='colonia'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={handleInputChange}
                  />
                </StyledLabel>
              </Row>
              <Delimitador isDoctor={isDoctor} />
              <Form.Label style={{ ...smallText, paddingLeft: '1.8%' }}>
                  Teléfono del consultorio
                </Form.Label>
                <BorderForPhoneInput>
                  <StyledPhoneInput
                    type='tel'
                    international
                    defaultCountry='MX'
                    placeholder='Teléfono del consultorio'
                    value={phoneNumberConsultingRoom}
                    name='phoneNumberConsultingRoom'
                    id='phoneNumberConsultingRoom'
                    autoComplete='off'
                    list='autocompleteOff'
                    onChange={setPhoneConsultRoom}
                  />
                </BorderForPhoneInput>

              <Row>
                <h5 style={{ fontSize: '16px', marginTop: '22px', marginBottom: '20px' }}>Redes</h5>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId='facebook' label='Facebook'>
                    <UilFacebookF className='input-icon' color='#AAA'/>
                    <StyledInput
                      style={{paddingLeft: '30px'}}
                      type='text'
                      placeholder='Facebook'
                      value={facebook}
                      name='facebook'
                      autoComplete='off'
                      list='autocompleteOff'
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {facebook !== undefined && facebook !== '' ? null : <p className="text-muted mt-1">&nbsp;&nbsp;Nombre del perfil</p>}
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId='instagram' label='Instagram'>
                    <UilInstagram className='input-icon' color='#AAA'/>
                    <StyledInput
                      style={{paddingLeft: '30px'}}
                      type='text'
                      placeholder='Instagram'
                      value={instagram}
                      name='instagram'
                      autoComplete='off'
                      list='autocompleteOff'
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {instagram !== undefined && instagram !== '' ? null : <p className="text-muted mt-1">&nbsp;&nbsp;@your_user</p>}
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <StyledLabel controlId='web' label='Página web'>
                    <UilGlobe className='input-icon' color='#AAA'/>
                    <StyledInput
                      style={{paddingLeft: '30px'}}
                      type='text'
                      placeholder='Página web'
                      value={web}
                      name='web'
                      autoComplete='off'
                      list='autocompleteOff'
                      onChange={handleInputChange}
                    />
                  </StyledLabel>
                  {web !== undefined && web !== '' ? null : <p className="text-muted mt-1">&nbsp;&nbsp;mipagina.com</p>}
                </Col>
              </Row>
             
            </>
          ) : null}
          {/* Campo de checkbox ofertas solo para paciente */}
          <Row style={{ paddingLeft: '3%' }} className='mb-2'>
            {newsletterCheckBox}
          </Row>
          <Row
            className='mb-5'
            style={
              isMobile ? { justifyContent: 'center' } : { paddingLeft: '3%' }
            }
          >
            <Button
              variant='primary'
              style={
                isDoctor
                  ? { ...buttonStyle, background: 'linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%)', borderRadius: '10px'}
                  : { ...buttonStyle, background: 'linear-gradient(90deg, #5731B2 -1.87%, #77CCD5 100%)', borderRadius: '10px'}
              }
              onClick={handleUpdateProfileData}
            >
              <b>Guardar cambios</b>
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
