import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledA = styled.a`
  display: ${props => props.display};
  position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
`;

export const FloatingWhatsapp = () => {
  const [display, setDisplay] = useState('block');
  const location = useLocation();
  useEffect(() => {
    location.pathname.includes('/estudio/') ? setDisplay('none') : setDisplay('block');
  }, []);
  return(
    <>
      <StyledA href="https://wa.me/525615077271" target="_blank" rel='noreferrer' display={display}>
        <i class="fa fa-whatsapp my-float"></i>
      </StyledA>
    </>
  );
}