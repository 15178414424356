import React, { useState, useEffect } from 'react';
import { Banner } from '../../components/categories/Banner';
import { useParams } from 'react-router';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Highlights } from './Highlights';
import { Studies } from './Studies';
import axios from 'axios';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { Doubts } from '../../components/doubts/doubts';

export const Main = (props) => {
  const { service } = useParams();
  const [pageInfo, setPageInfo] = useState({
    description: '',
    descripcion_larga: '',
    icono: '',
    id: 0,
    imagen_desktop: '',
    imagen_mobile: '',
    nombre: '',
  });

  const getInfo = async () => {
    try {
      let request = axios.get('https://lpqsystem.com/get-category/' + service);
      let response = await request;
      setPageInfo(response.data);
      console.log('cat info.:  ', response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    console.log('page info: ', pageInfo);
  }, [pageInfo]);

  return (
    <div style={{ paddingRight: 0, paddingLeft: 0 }}>
      <HelmetTitle title='Incio' name='description' content='content' />
      <Banner
        data={{
          icono: pageInfo?.icono,
          nombre: pageInfo?.nombre,
          descripcion: pageInfo?.descripcion,
        }}
      />
      <Highlights data={{ id: pageInfo.id, service: service }} />
      <Studies className='mt-4' data={{ id: pageInfo.id, service: service }} />
      <Doubts />
    </div>
  );
};
