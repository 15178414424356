import { Modal } from "react-bootstrap";
import styled from 'styled-components';
import { useState } from 'react';
import { CodeVerificationModal } from "./CodeVerificationModal";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const StyledModal = styled(Modal)`
    .modal-dialog {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }
`;

const titleModal = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '19px',
    textAlign: 'left',
    color: '#000000',
    marginTop: '4px',
    paddingRight: '17px',
};

const normalText = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'left',
    color: '#000000',
    margin: '0 21px 0 15px',
};

export const ModalCode = ({ show, classSize, resolve }) => {
    const [showVal, setShow] = useState(show);

    const handleClose = () => setShow(!showVal);

    const { width } = useWindowDimensions();

    return (
        <CodeVerificationModal resolve={resolve} handleClose={handleClose} />
    );
}