import React, {useEffect} from 'react';
import { UilFileCheck, UilUsersAlt, UilFileMedicalAlt, UilEstate, UilTelegramAlt } from '@iconscout/react-unicons'

export const Sections = {

  docSections: [
    {
      name: 'Inicio',
      icon: <UilEstate size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/dashboard'
    },
    {
      name: 'Historial de pruebas',
      icon: <UilFileMedicalAlt size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/historial-pruebas'
    },
    {
      name: 'Mi perfil',
      icon: <UilUsersAlt size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/mi-perfil'
    }
  ],
  userSections: [
    {
      name: 'Historial de pruebas',
      icon: <UilFileMedicalAlt size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/historial-pruebas'
    },
    {
      name: 'Mi perfil',
      icon: <UilUsersAlt size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/mi-perfil'
    },
    {
      name: 'Activa tu kit',
      icon: <UilFileCheck size={24} color={'#77CCD5'} className='w-auto icon' />,
      route: '/activar-kit'
    }
  ],
  studiesAndServices: [
    {
      name: 'COVID-19',
      route: '/covid-19'
    },
    {
      name: 'Toma de biopsia',
      route: '/toma-biopsia'
    },
    {
      name: 'Nutrición',
      route: '/nutricion'
    },
    {
      name: 'Laboratorio clínico',
      route: '/laboratorio-clinico'
    },
    {
      name: 'Servicios genéticos',
      route: '/servicios-geneticos'
    },
    {
      name: 'Patología quirúrgica',
      route: '/patologia-quirurgica'
    },
    {
      name: 'Ultrasonido',
      route: '/ultrasonido'
    },
    {
      name: 'Biología molecular',
      route: '/molecular'
    },
  ]
}