import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 32px;
  color: transparent;
  position: absolute;
  top: 8.6%;
  right: 5.8%;
  width: auto;
  padding: 0px;
  z-index: 1;
  margin: 0px;
  cursor: pointer;
  @media only screen and (max-width: 1400px) {
    font-size: 29px;
    color: transparent;
    position: absolute;
    top: 7%;
    right: 8%;
  }
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #00b9c4;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 100px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 30px;
      line-height: 24px;
      text-align: center;
      color: #00b9c4;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

export const Result = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  let isDesktop = width <= 1366;
  return (
    <>
      <StyledRow className="">
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
      </StyledRow>
      <div className="position-relative">
        <StyledRowDown className="">
          <Col md={12} className="text-col">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FVariantes%20encontradas.png?alt=media&token=c7ffb0a5-9559-44e7-8ea0-f0ef8c529980"
              alt=""
              style={{ width: "46vw" }}
            />
          </Col>
        </StyledRowDown>
      </div>
    </>
  );
};
