import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { CodeVerificationModal } from '../login/modals/CodeVerificationModal';
import { CreatePasswordModal } from '../login/modals/CreatePasswordModal';
import { ModalTemplate } from '../login/modals/ModalTemplate';
import { RegisterEmailModal } from '../login/modals/RegisterEmailModal';
import { useHistory } from 'react-router-dom';
import { Navigate } from "react-router"
import { Indications } from '../profile-menu/Indications';

export const ShoppingTest = () => {
    const lorem = `
        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        Earum provident dolorem deleniti dolore quis corporis ut 
        voluptas quo iste voluptatem.
    `;

    // const cards = [
    //     {
    //         id: 1,
    //         title: 'Prueba 1',
    //         description: lorem,
    //         price: 300
    //     },
    //     {
    //         id: 2,
    //         title: 'Prueba 2',
    //         description: lorem,
    //         price: 400
    //     },
    //     {
    //         id: 3,
    //         title: 'Prueba 3',
    //         description: lorem,
    //         price: 250
    //     },
    //     {
    //         id: 4,
    //         title: 'Prueba 4',
    //         description: lorem,
    //         price: 100
    //     },
    // ];

    const { width } = useWindowDimensions();
    const history = useHistory();
    const [showCreatePassword, setShowCreatePassword] = useState(false);
    const [showCodeVerification, setShowCodeVerification] = useState(false);
    const [showEmailRegister, setShowEmailRegister] = useState(false);

    const { isLogged } = useSelector(state => state.auth);
    useEffect(() => {
        if (isLogged) {
            setShowEmailRegister(false);
        }
    }, [isLogged]);

    const handleIndication = (list) => {
        // return (<Navigate to={<Indications list={list}} />)
    };

    const indicaciones = [
        {
            title: 'Indicaciones:',
            description: 'Presentarse con orden médica y la cual deberá de indicar específicamente las proyecciones de placas a tomar.'
        },
        {
            title: 'Ayuno:',
            description: '12 horas preferentemente, aunque no es una limitante para la realización del estudio / No es necesario'
        },
        {
            title: 'Duración del estudio:',
            description: 'Aproximadamente de 20 a 45 min'
        },
        {
            title: 'Entrega de resultados:',
            description: 'Los resultados se entregar después de 24hrs de la realización del estudio.'
        },
        {
            title: 'El día de la muestra no olvides llevar:',
            description: 'Identificación oficial; INE, Pasaporte y/o Credencial Escolar (en menores de edad)'
        },
    ];

    return (
        <center style={{ padding: '5%', paddingBottom: '25px' }}>
            <HelmetTitle
                title="Carrito pruebas"
                name="description"
                content="content"
            />
            <ModalTemplate
                show={showCreatePassword}
                handleClose={() => setShowCreatePassword(false)}
                content={<CreatePasswordModal />}
                title="Crea una contraseña para continuar"
                subtitle="Incluye al menos 1 número y una letra mayúscula."
                classSize={`modal-change-password${width < 680 ? '-responsive' : ''}`}
            />

            <ModalTemplate
                show={showCodeVerification}
                handleClose={() => setShowCodeVerification(false)}
                content={<CodeVerificationModal />}
                title="Ingresa el código que te enviamos"
                subtitle="Te enviamos un código a tu celular para verificar tu cuenta."
                classSize={`modal-verification-code${width < 680 ? '-responsive' : ''}`}
            />

            <ModalTemplate
                show={showEmailRegister}
                handleClose={() => setShowEmailRegister(false)}
                content={<RegisterEmailModal />}
                title="Ingresa tus datos para crear una cuenta"
                subtitle=""
                classSize={`modal-change-password${width < 680 ? '-responsive' : ''}`}
            />

            <Button variant="primary" onClick={() => history.push({
                pathname: '/indicaciones',
                state: { list: indicaciones }
            })}>
                Indicaciones
            </Button>

            <br /><br />

            <Button variant="primary" onClick={() => setShowCreatePassword(true)}>
                Crear contraseña
            </Button>

            <br /><br />

            <Button variant="primary" onClick={() => setShowCodeVerification(true)}>
                Código de verificación
            </Button>

            {/* <Button
                variant="primary"
                onClick={handleAddCart}
            >
                Agregar carrito
            </Button> */}
            {/* <br /> */}
            {/* <Row xs={1} md={4} className="g-4">
                {
                    cards.map((card) => (
                        <Col key={card.id}>
                            <CardTest
                                title={card.title}
                                description={card.description}
                                price={card.price}
                                id={card.id}
                            />
                        </Col>
                    ))
                }
            </Row> */}
        </center>
    )
}
