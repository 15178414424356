import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useParams } from "react-router-dom";
import axios from "axios";

const Slide0 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Faxionahem%2Fgenes-slide.png?alt=media&token=3ac73ff7-354e-47fc-aa5c-e7e1f2363769";

const BannerBackground = styled.div`
  width: 100%;

  height: 586px;
  overflow: hidden;
  box-shadow: 0px 16px 9px 0px #25060e33;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FFondo%201%201.png?alt=media&token=23551ae3-3a3c-460b-b6c3-220d631fcec4&_gl=1*5wwnab*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODQzNzc1NS4xMTkuMS4xNjk4NDM4MDQyLjQ3LjAuMA..")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2Ffondoc.png?alt=media&token=d3701ec0-ea1b-4b37-9716-957c6e7e6685"centerno-repeat);
    height: 340px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
      width: 130%;
    }
    img {
      width: 545px;
      position: absolute;
      bottom: 90px;
      left: 115px;
      transform: translate(-36%, 0);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 130%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 460px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: #505153;
  text-align: star;
  max-width: 683px;
  margin: 0;
  margin-right: auto;
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #fff;
  padding: 5px 1rem 5px 1rem;
  
  width: 265px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #505153;
  border: none;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.13));
  text-align: center;
  max-width: 100%;
  margin-right: auto;
  transition: all 0.2s ease;
  :hover {
    background-color: #505153;
  }
  @media only screen and (max-width: 768px) {
    height: 40px;
    width: 151px;
    font-size: 16px;
    margin: 20px auto;
  }
`;

export const Sport = () => {
  const { items } = useSelector((state) => state.shoppingCart);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { studyId } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 166);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getInfo();
  }, [studyId]);

  return (
    <>
    {!isMobile && (
        <div style={{backgroundColor: "#FAC400"}}>
      <BannerBackground>
      <Container>
        <Row>
          <DescColumn
            xs={12}
            md={7}
            className={
              isTablet ? "justify-content-start mt-3" : "justify-content-center"
            }
          >
            <Title className={isTablet ? "mb-2 ms-auto me-auto" : "mb-4"}>
                ¿Te gustaría potencializar al máximo tu rendimiento físico?
            </Title>
            {!isTablet ? (
              <Desc
                onClick={() => payStudy()}
                className={isMobile ? "ms-auto me-auto" : "mb-4"}
              >
                Comprar ahora
              </Desc>
            ) : null}
          </DescColumn>
          <Col xs={6} md={4} id="col-img">
            <img
              alt="sport"
              src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FLP_caja_sport%204.png?alt=media&token=8f8004a8-5dd8-4bdd-8af8-95264fc7af5e&_gl=1*11qsn8v*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODQzNzc1NS4xMTkuMS4xNjk4NDM4MTQ2LjQwLjAuMA.."
            />
          </Col>
          {isTablet ? (
            <Desc
              onClick={() => payStudy()}
              className={isMobile ? "ms-auto me-auto" : "mb-4"}
            >
              Comprar ahora
            </Desc>
          ) : null}
        </Row>
      </Container>
    </BannerBackground>
    </div>
    )}
    {isMobile && (
      <BannerBackground>
      <Container>
        <Row>
          <DescColumn
            xs={12}
            md={7}
            className={
              isTablet ? "justify-content-start mt-3" : "justify-content-center"
            }
          >
            <Title className={isTablet ? "mb-2 ms-auto me-auto" : "mb-4"} style={{ fontSize:'20px', textAlign: 'center'}}>
                ¿Te gustaría potencializar al máximo tu rendimiento físico?
            </Title>
            {!isTablet ? (
              <Desc
                onClick={() => payStudy()}
                className={isMobile ? "ms-auto me-auto" : "mb-4"}
              >
                Comprar ahora
              </Desc>
            ) : null}
          </DescColumn>
          <Col xs={12} md={5} id="col-img">
            <img
              style={{width: '94%'}}
              alt="sport"
              src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FLP_caja_sport%204.png?alt=media&token=8f8004a8-5dd8-4bdd-8af8-95264fc7af5e&_gl=1*11qsn8v*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODQzNzc1NS4xMTkuMS4xNjk4NDM4MTQ2LjQwLjAuMA.."
            />
          </Col>
          {isTablet ? (
            <Desc
              onClick={() => payStudy()}
              className={isMobile ? "ms-auto me-auto" : "mb-4"}
            >
              Comprar ahora
            </Desc>
          ) : null}
        </Row>
      </Container>
    </BannerBackground>
    )}
    </>
  );
};
