import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import Testimonial1 from '../../../assets/home/testimonial1.png';
import Testimonial2 from '../../../assets/home/testimonial2.png';
import Mask from '../../../assets/kit/container.png';
import ExampleVideo from '../../../assets/home/exampleVideo.png';
import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons'
import Vimeo from '@u-wave/react-vimeo';

const SliderSection = styled.div`
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px){
    padding: 0;
    margin-bottom: 0;
  }
`;

const StyledSlider = styled(Slider)`
  border-radius: 20px !important;
.slick-list {
  padding-top: 4rem !important;
  width: 100% !important;
}
.slick-prev {
    background-color: #77CCD5;
    left: 31.2rem;
    top: 4rem;
    z-index: 1;
    @media only screen and (max-width: 1400px){
      left: 27rem;
    }
    @media only screen and (max-width: 1280px){
      left: 20.2rem;
    }
    @media only screen and (max-width: 992px){
      top: 15rem;
      left: 4rem;
    }
    @media only screen and (max-width: 768px){
      display: block;
      left: 2rem;
    }
    @media only screen and (max-width: 480px){
      display: block;
      left: 0rem;
    }
  }
  .slick-next {
    background-color: #77CCD5;
    right: 8rem;
    top: 4rem;
    @media only screen and (max-width: 991px){
      top: 15rem;
      right: 4rem;
    }
    @media only screen and (max-width: 768px){
      display: block;
      right: 2rem;
    }
    @media only screen and (max-width: 480px){
      display: block;
      right: 0rem;
    }
  }
`;

const Slide = styled(Row)`
  display: flex !important;
  background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 90px;
  padding-right: 90px;
  border-radius: 20px;
  @media only screen and (max-width: 992px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0;
    border-radius: 20px 20px 0px 0px !important;
  }
  @media only screen and (max-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const SlideTitle = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3B3B3B;
  margin: 0;
  width: auto;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const PictureContainer = styled.div`
  padding: 0;
  width: auto;
  margin-top: -6em;
  position: relative;
  justify-content: center;
  max-height: 244px;
  @media only screen and (max-width: 1200px) {
    max-height: 183px;
  }
  @media only screen and (max-width: 992px) {
    max-height: 244px;
    margin-bottom: 2rem;
  }
`;

const TestimonialPicture = styled.div`
  max-width: 244px;
  max-height: 244px;
  height: auto;
  background-color: #f1f8f9;
  border-radius: 50%;
  background-image: ${props => props.type === 1 ? `url(${Testimonial2})` : `url(${Testimonial1})`};
  background-size: 100%;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
  position: relative;
  position: absolute;
  top: 0;
  padding: 0;
  img {
    padding: 0;
  }
  @media only screen and (max-width: 768px) {
    max-width: 214px;
    max-height: 214px;
  }
`;

const MaskImage = styled.img`
  max-width: 244px;
  max-height: 244px;
  @media only screen and (max-width: 768px) {
    max-width: 214px;
    max-height: 214px;
  }
`;

const PinkContainer = styled.div`
  position: absolute;
  max-width: 232px;
  max-height: 232px;
  height: auto;
  background-color: #d44dad;
  border-radius: 50%;
  top: -.5em;
  left: -.45em;
  padding: 0;
  img {
    padding: 0;
    max-width: 244px;
    max-height: 244px;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 176px;
    max-height: 176px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 190px;
    max-height: 190px;
    left: -.4em;
  }
  @media only screen and (max-width: 991px) {
    max-width: 232px;
    max-height: 232px;
    top: -.5em;
    left: -.45em;
  }
  @media only screen and (max-width: 768px) {
    max-width: 212px;
    max-height: 212px;
    top: -.5em;
    left: -.45em;
  }
`;

const BlueContainer = styled.div`
  position: absolute;
  max-width: 244px;
  max-height: 244px;
  height: auto;
  background-color: #77ccd5;
  border-radius: 50%;
  bottom: -.55em;
  right: -.8em;
  img {
    max-width: 244px;
    max-height: 244px;
    padding: 0;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 183px;
    max-height: 183px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 205px;
    max-height: 205px;
    bottom: 2.35em;
    right: -.7em;
  }
  @media only screen and (max-width: 991px) {
    max-width: 244px;
    max-height: 244px;
    bottom: -.55em;
    right: -.8em;
  }
  @media only screen and (max-width: 768px) {
    max-width: 214px;
    max-height: 214px;
    bottom: -.55em;
    right: -.7em;
  }
`;

const SlideQuote = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #D44DAD;
  margin: 0;
  padding: 0 1rem 0 1rem;
  position: relative;
  max-width: 510px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const LeftDoubleQuote = styled.span`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: 0.01em;
  color: #3B3B3B;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -12px;
  left: -2px;
  @media only screen and (max-width: 768px) {
    left: -8px;
  }
`;

const RightDoubleQuote = styled.span`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: 0.01em;
  color: #3B3B3B;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -12px;
  right: -2px;
  @media only screen and (max-width: 768px) {
    right: -8px;
  }
`;

const VideContainer = styled(Row)`
  padding: 0;
  justify-content: center;
  position: relative;
`;

const PrevArrowBtn = styled.button`
  width: 46px;
  height: 46px;
  background-color: #77CCD5;
  border-radius: 50%;
  ::before {
    display: none;
  }
  :hover {
    background-color: #77CCD5;
  }
  @media only screen and (max-width: 1400px){
    width: 34px;
    height: 34px;
  }
  @media only screen and (max-width: 1200px){
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 991px){
    width: 36px;
    height: 36px;
  }
`;

const NextArrowBtn = styled.button`
  width: 46px;
  height: 46px;
  background-color: #77CCD5;
  border-radius: 50%;
  ::before {
    display: none;
  }
  :hover {
    background-color: #77CCD5;
  }
  @media only screen and (max-width: 1400px){
    width: 34px;
    height: 34px;
  }
  @media only screen and (max-width: 1200px){
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 991px){
    width: 36px;
    height: 36px;
  }
`;

export const TestimonialSlider = () => {
  const PrevArrow = (props) => {
    const {className, style, onClick} = props;
    return(
      <PrevArrowBtn
        type='button'
        className={className}
        style={{...style}}
        onClick={onClick}
      >
        <UilArrowLeft
          size={26}
          color={'#ffffff'}
          className='w-auto'
        />
      </PrevArrowBtn>
    );
  };

  const NextArrow = (props) => {
    const {className, style, onClick} = props;
    return(
      <NextArrowBtn
        type='button'
        className={className}
        style={{...style}}
        onClick={onClick}
      >
        <UilArrowRight
          size={26}
          color={'#ffffff'}
          className='w-auto'
        />
      </NextArrowBtn>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  const Testimonials = [
    {
      name: 'Sofía Reinoso,',
      proffesion: 'atleta olímpica de canotaje slalom',
      desc: 'Es una prueba muy completa, estoy muy emocionada por haber conocido qué son mis genes y cómo puedo mejorar mi vida diaria.',
      videoUrl: 'https://player.vimeo.com/video/751748534?h=caf85ebfd2'
    },
    {
      name: 'Pamela Argaez,',
      proffesion: 'corredora de alto rendimiento',
      desc: 'Conocer mis resultados me ha dado mayor campo para poder cuidarme y obtener una  mejor calidad deportiva',
      videoUrl: 'https://player.vimeo.com/video/751745119?h=b11eea25f3'
    },
  ]

  return(
    <SliderSection>
      <StyledSlider {...settings}>
        {Testimonials.map((person, index) => (
          <Slide key={index}>
            <Col xs={12} sm={12} md={12} lg={3} xl={4} className='p-0'>
              <Row className='justify-content-center'>
                <PictureContainer>
                  <MaskImage src={Mask} alt='Mask' className='img-fluid p-0' />
                  <PinkContainer>
                    <img src={Mask} className='img-fluid p-0' />
                  </PinkContainer>
                  <BlueContainer className='p-0'>
                    <img src={Mask} className='img-fluid p-0' />
                  </BlueContainer>
                  <TestimonialPicture type={index}>
                    <img src={Mask} alt='Mask' className='img-fluid p-0' />
                  </TestimonialPicture>
                </PictureContainer>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={9} xl={8} className='p-0'>
              <Row className='mb-4 justify-content-center'>
                <SlideTitle>
                  <b>
                    {person.name}{' '}
                  </b>
                  {person.proffesion}
                </SlideTitle>
              </Row>
              <Row className='justify-content-center'>
                <SlideQuote>
                  {person.desc}
                  <LeftDoubleQuote>
                    “
                  </LeftDoubleQuote>
                  <RightDoubleQuote>
                    ”
                  </RightDoubleQuote>
                </SlideQuote>
              </Row>
            </Col>
            <Col xs={12} className='mt-4 mt-lg-5 p-0'>
              <VideContainer className='p-0'>
                <Vimeo
                  className='p-0'
                  color='ffbc45'
                  video={person.videoUrl}
                  responsive={true}
                  autopause
                />
              </VideContainer>
            </Col>
          </Slide>
        ))}
      </StyledSlider>
    </SliderSection>
  );
}