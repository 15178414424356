import React from "react";
import { OurServices } from "./ourServices";
import { Doubts } from "../doubts/doubts";
import { AboutSlider } from "./Slider";


export const AboutUs = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <AboutSlider />
      <OurServices />
      <Doubts />
    </div>
  )
};