import React, { useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UilFileMedicalAlt } from "@iconscout/react-unicons";
import { useState } from "react";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Carousel } from "./carousel";
import CanvasP from "./canvasP";
import { HistoryMed } from "./historyMed";
import axios from "axios";

const Sections1 = [
  {
    title: "Resumen mensual de pruebas",
    olnyDoc: true,
  },
];

const Sections2 = [
  {
    title: "Historial de pruebas",
    icon: <UilFileMedicalAlt color={"#8355B7"} className="w-auto icon" />,
    path: "/historial-pruebas",
    olnyDoc: true,
  },
];

const Sections3 = [
  {
    title: "Panel Deportivo",
    desc: "Proximamente",
    olnyDoc: true,
  },
];

const Sections4 = [
  {
    olnyDoc: true,
  },
];

const Title = styled.h1`
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #02a1b5;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 18px;
`;

const Card = styled.div`
  background: #ffffff;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  border-radius: 10px;
  padding: 10px;
  margin: 12px auto;
  cursor: pointer;
  transition: all ease 0.4s;
  h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    color: #000;
  }
  p {
    margin-bottom: 0px;
  }
  :hover {
    background: #ffffff;
    border: 2px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(45deg, #fff, #fff),
      linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
    box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
    transition: 0.4s;
  }
  @media only screen and (max-width: 1366px) {
    height: 276px;
  }
`;

const CardImg = styled.div`
  width: 100%;
  margin-top: -150px;
  height: 150px;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/cardsVG%2Fmask.png?alt=media&token=b324d782-7e68-43bf-ab72-019367f4fb4e")
    center center/cover no-repeat;
  border-radius: 25px;
  padding: 10px;
  transition: all ease 0.4s;
  h1 {
    font-size: 45px;
    font-weight: 400;
    margin-left: 58px;
    line-height: 50px;
  }
  p {
    margin-left: 60px;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 1400px) {
    margin-top: -150px;
  }
  @media only screen and (max-width: 1366px) {
    margin-top: -156px;
  }
  @media only screen and (max-width: 576px) {
    h1 {
      font-size: 26px;
    }
    margin: 12px 0px;
  }
`;

const Desc = styled.a`
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: none;
  border-radius: 10px;
  border: 2px solid rgba(245, 41, 173, 1);
  text-align: center;
  width: 200px;
  margin-left: 300px;
  margin-top: -10px;
  :hover {
    color: #fff;
    background: none;
    border: 2px solid rgba(245, 41, 173, 1);
    transition: 0.4s;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    margin-left: 60px;
    width: 149px;
    font-size: 12px;
  }
`;

const DivHr = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  padding-top: 1px;
  @media only screen and (max-width: 992px) {
    background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  }
`;

export const Dashboard = (props) => {
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const user = useSelector((state) => state?.user?.data.data);

  const [showSearcher, setShowSearcher] = useState(false);

  const { width } = useWindowDimensions();
  const location = useLocation();
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  const [loading, setLoading] = useState(false);
  let isDesk = width <= 1400;
  let isMobile = width <= 576;
  const number = "+525615077271";
  const uid = useSelector((state) => state?.auth?.uid);

  const getTestHistoryByPhase = async () => {
    setLoading(true);
    try {
      await axios
        .get(`https://clienteslabopat.com/canvas?uid=${uid}`)
        .then((response) => {
          console.log(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTestHistoryByPhase();
  }, []);

  return (
    <>
      <Container
        style={{ minHeight: "80vh", paddingTop: "25px" }}
        className={isMobile ? "mt-5 pt-4" : ""}
      >
        <HelmetTitle title="Dashboard" name="description" content="content" />
        <Title>
          ¡Hola! {user?.name} {user?.fathersLastname}{" "}
        </Title>
        <DivHr />
        <Row>
          {Sections1.map((section) =>
            isDoctor || (!isDoctor && !section.olnyDoc) ? (
              <Col xs={12} lg={5}>
                <Card>
                  <h1>
                    {section.icon}&nbsp;{section.title}
                  </h1>{" "}
                  {isMobile ? (
                    <iframe
                      title="canvas"
                      width="400"
                      height="300"
                      style={{ marginLeft: "-20px" }}
                      src={`https://clienteslabopat.com/canvas?uid=${uid}`}
                    ></iframe>
                  ) : (
                    <iframe
                      title="canvas"
                      width="490"
                      height="240"
                      src={`https://clienteslabopat.com/canvas?uid=${uid}`}
                    ></iframe>
                  )}
                </Card>
              </Col>
            ) : null
          )}
          {Sections2.map((section) =>
            isDoctor || (!isDoctor && !section.olnyDoc) ? (
              <Col xs={12} lg={3}>
                <Card
                  onClick={() =>
                    section.path === "historial-pruebas"
                      ? setShowSearcher(true)
                      : (window.location.href = section.path)
                  }
                >
                  <h1>
                    {section.icon}&nbsp;{section.title}
                  </h1>
                  <HistoryMed />
                </Card>
              </Col>
            ) : null
          )}
          {Sections4.map((section) =>
            isDoctor || (!isDoctor && !section.olnyDoc) ? (
              <Col xs={12} lg={4}>
                <Carousel />
              </Col>
            ) : null
          )}
          {Sections3.map((section) =>
            isDoctor || (!isDoctor && !section.olnyDoc) ? (
              <Col xs={12} lg={8}>
                <CardImg id="subimg">
                  <p style={{ color: "#ffffff", fontSize: "15px" }}>
                    {section.desc}
                  </p>

                  <h1 style={{ color: "#ffffff" }}>{section.title}</h1>

                  <Desc
                    href={`https://wa.me/${number}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Solicita información
                  </Desc>
                </CardImg>
              </Col>
            ) : null
          )}
          <br />
        </Row>
      </Container>
    </>
  );
};
