import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ThanksScreen } from './thanksScreen';
import { Pay } from './pay';
import { Results } from './results';
import { Scan } from './scan';
import { Thanks } from './thanks';
import { Welcome } from './welcome';
import { QRreader } from './QRreader';
import { useSelector } from 'react-redux';

const Items = [
  {
    id: 1,
    title: 'Química sanguínea de 24 elementos',
    price: 1040,
    category: 'Laboratorio',
    place: ['Labopat 1 Q1', 'Labopat 2 Q1', 'Labopat 3 Q1', 'Labopat 4 Q1'],
    needDate: true,
  },
  {
    id: 2,
    title: 'Exámen general de orina',
    price: 1050.3,
    category: 'Laboratorio',
    place: ['Labopat 1 EO', 'Labopat 2 EO', 'Labopat 3 EO', 'Labopat 4 EO'],
    needDate: false,
  },
  {
    id: 3,
    title: 'Química sanguínea de 24 elementos',
    price: 1060,
    category: 'Laboratorio',
    place: ['Labopat 1 Q2', 'Labopat 2 Q2', 'Labopat 3 Q2', 'Labopat 4 Q2'],
    needDate: true,
  },
  {
    id: 4,
    title: 'Química sanguínea de 24 elementos',
    price: 1060,
    category: 'Laboratorio',
    place: ['Labopat 1 Q3', 'Labopat 2 Q3', 'Labopat 3 Q3', 'Labopat 4 Q3'],
    needDate: true,
  },
];

const Background = styled.div`
  background: #e0ecec;
  @media only screen and (min-width: 768px) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  > .container {
    background: #fff;
    max-width: 1120px;
    @media only screen and (min-width: 768px) {
      border-radius: 16px;
      padding-top: 24px;
    }
  }
`;

export const CheckIn = () => {
  const { currentCheckin } = useSelector((state) => state);
  const [scanned, setScanned] = useState(false);
  const [payedNow, setPayedNow] = useState(false);
  const [data, setData] = useState({
    pay: currentCheckin?.solicitud?.fase !== 0,
    sampleTaked: false,
  });

  const handleScan = (data) => {
    setData(data);
    setScanned(true);
  };

  const handlePay = () => {
    console.log('payyy');
    setData({
      ...data,
      pay: true,
    });
    setPayedNow(true);
  };

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    if (!currentCheckin) {
      setScanned(false);
    }
  }, [currentCheckin]);
  {
    /*<Scan onScan={handleScan} />*/
  }
  return (
    <Background>
      <Container
        style={{ paddingLeft: '16px', paddingRight: '16px', margin: 'auto' }}
      >
        {!scanned ? (
          <Scan onScan={handleScan} />
        ) : !data.pay ? (
          <Pay onPay={handlePay} />
        ) : payedNow ? (
          <Thanks
            isDoctor={false}
            pay={'web'}
            total={1000}
            otherPerson={false}
            firstTimeDoctor={false}
            onContinue={() => {
              setPayedNow(false);
            }}
          />
        ) : data.sampleTaked ? (
          <Results payedNow={payedNow} />
        ) : (
          <Welcome payedNow={payedNow} />
        )}
      </Container>
    </Background>
  );
};
