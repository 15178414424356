import React from "react";
import { Glutense } from "./glutense";
import { StudyGlutense } from "./StudyGlutense";
import { Beneficios } from "./beneficios";
import { Target } from "./target";
import { Reporte } from "./reporte";
import Testimonials from "./testimonials";

export const stuGlutense = () => {
  return (
    <>
      <Glutense />
      <Target />
      <Testimonials />
      <Beneficios/>
      <Reporte />
      <StudyGlutense />
    </>
  );
};
