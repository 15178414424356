import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
  UilDownloadAlt,
  UilCheck,
  UilInvoice,
  UilInfoCircle,
} from '@iconscout/react-unicons';
// import Dra from '../../assets/checkout/dra.png';
import { useSelector, useDispatch } from 'react-redux';
// import { resetCart } from '../../actions/shoppingCart';
import { useEffect } from 'react';

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  text-transform: capitalize;
  margin-top: 32px;
  text-transform: capitalize;
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  > span {
    font-weight: 800;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  > button {
    font-size: 16px;
    background: none;
    border: none;
    margin-top: 20px;
    margin-bottom: 37px;
    > svg {
      color: #00ad90;
    }
  }
`;

const StyledDiv = styled.div`
  margin-bottom: 24px;
  background: #dbf1ff;
  padding: 16px 20px;
  border-radius: 12px;
  > p {
    text-align: left;
    margin-bottom: 0px;
    > a {
      color: #00ad90;
      text-decoration: underline;
    }
  }
`;

const PlaceP = styled.p`
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const AddressP = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #717171;
`;

const DateP = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
`;

const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 328px;
  padding: 16px;
  background-color: #f1f6f6;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 24px;
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    position: absolute;
    bottom: 65px;
    margin-top: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
  > hr {
    margin-left: auto;
    margin-right: auto;
    width: 296px;
  }
  > .row#info div {
    display: flex;
    justify-content: left;
    padding: 0px;
    > svg {
      color: #3b3b3b;
    }
  }

  > .row#info div#patient {
    padding-left: 10px;
  }
`;

const Done = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 86px;
  height: 86px;
  background: linear-gradient(180deg, #1ecbcb 0%, #4a6cc1 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  > svg {
    color: #fff;
    width: 36px;
  }
`;

const InfoIcon = styled(UilInfoCircle)`
  color: #2a97dd;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
`;

const Require = styled.div`
  background-image: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdraBg.png?alt=media&token=02562a38-0d64-482a-b6f2-b0083081bca6');
  background-repeat: no-repeat;
  background-position: bottom right;
  min-height: 210px;
  padding-bottom: 31px;
  padding-right: 35%;
  position: relative;
  > ul {
    margin-bottom: 0px;
  }
  > img {
    z-index: 1;
    width: 175px;
    position: absolute;
    bottom: 0;
    right: 5px;
  }
`;

const Reward = styled.div`
  background: linear-gradient(180deg, #1ecbcb 0%, #4a6cc1 100%);
  padding: 32px 16px;
  border-radius: 12px;
  > div {
    background: #fff;
    border-radius: 12px;
    padding: 40px 24px;
    text-align: center;
    > h4 {
      font-weight: 900;
      margin-bottom: 16px;
    }
    > button {
      border: none;
      color: #fff;
      margin-top: 10px;
      font-weight: 800;
      font-size: 16px;
      width: 214px;
      height: 48px;
      background: #00ad90;
      border-radius: 102px;
    }
  }
`;

const DocButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  > #whiteButton {
    text-align: center;
    border: none;
    width: 241px;
    background: #fff;
    color: #00ad90;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
`;

const GreenButton = styled.button`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 0px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #006d67;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const Indications = [
  {
    id: 1,
    indications: [
      "Diríjase a la ventanilla",
      "Acuda al módulo indicado por la o el recepcionista",
      "Se tomarán las pruebas",
      "Los resultados serán enviados a su correo electrónico"
    ]
  },
  {
    id: 3,
    indications: [
      "Diríjase a la ventanilla",
      "Realice el pago de su solicitud",
      "Acuda al módulo indicado por la o el recepcionista",
      "Se tomarán las pruebas",
      "Los resultados serán enviados a su correo electrónico"
    ]
  }
];

export const ThanksScreen = (props) => {
  const { currentCheckin } = useSelector((state) => state);
  const isDoctor = props.isDoctor;
  const gender = 'M'; // TODO: Add field to state
  const cardPayment = props.pay === 'web';
  // const dispatch = useDispatch();
  // const user = useSelector((state) => state.user?.data?.data);
  const cartId = localStorage.getItem('cartId');
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  console.log(currentCheckin);
  return (
    <StyledForDesktop>
      <Done>
        <UilCheck size='36' />
      </Done>
      <Title>
        Gracias, {isDoctor ? (gender === 'M' ? 'Dr.' : 'Dra.') : null}{' '}
        {currentCheckin?.name}
      </Title>
      <RegularP>¡Tu compra se completó exitosamente!</RegularP>
      <RegularP>
        No. de la orden: <span>{currentCheckin?.drive.folio}</span>
      </RegularP>
      <RegularP style={{ textAlign: 'left' }}>
        Indicaciones:
      </RegularP>
      <Require>
        <ul>
          {Indications.find(e => e.id === currentCheckin?.drive.tipopago)?.indications.map((ind)=>(
            <>
            <hr />
            <li>
              {ind}
            </li>
            </>
          ))}
        </ul>
      </Require>
      <br />
    </StyledForDesktop>
  );
};
