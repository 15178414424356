import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { UilCheck } from '@iconscout/react-unicons';
import Mask from '../../assets/kit/container.png';
import ShippingGif from '../../assets/kit/shipping.gif';

const Header = styled(Row)`
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const Title = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #121619;
  margin: 0;
  max-width: 700px;
  @media only screen and (max-width: 768px) {
    padding: 0 !important;
    text-align: center;
  }
`;

const CheckBg = styled.div`
  max-width: 66px;
  max-height: 66px;
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 1px solid #77CCD5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CirclesContainer = styled.div`
  max-width: 260px;
  max-height: 260px;
  position: relative;
`;

const ShippingContainer = styled.div`
  max-width: 260px;
  max-height: 260px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url(${ShippingGif});
  background-size: 90%;
  background-position: 50% 1em;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
  position: relative;
  position: absolute;
  top: 0;
`;

const PinkContainer = styled.div`
  position: absolute;
  max-width: 95%;
  max-height: 95%;
  background-color: #d44dad;
  border-radius: 50%;
  top: -0.5em;
  left: -0.5em;
`;

const BlueContainer = styled.div`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  background-color: #77ccd5;
  border-radius: 50%;
  bottom: -0.6em;
  right: -0.8em;
`;

const Description = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  max-width: 462px;
`;

export const Congratulations = () => {
  return(
    <Container data-aos='slide-left' data-aos-duration='500'>
      <Header className='mb-5'>
        <CheckBg className='w-auto p-3 mb-5'>
          <UilCheck size={30} color={'#77CCD5'} className='w-auto p-0' />
        </CheckBg>
        <Title className='ps-4'>
          <b>¡FELICIDADES!</b> Tu kit ha sido activado,
          estás a un paso de comenzar a cuidarte mejor
        </Title>
      </Header>
      <Row className='mb-5'>
        <Col xs={12} sm={12} md={3} className='mb-4 p-0 pt-4 d-flex flex-column align-items-center'>
          <CirclesContainer data-aos='zoom-in' data-aos-duration='600'>
            <img src={Mask} className='img-fluid p-0' />
            <PinkContainer
              data-aos='fade-up-left'
              data-aos-duration='400'
            >
              <img src={Mask} className='img-fluid p-0' />
            </PinkContainer>
            <BlueContainer
              className='img-fluid p-0'
              data-aos='fade-down-right'
              data-aos-duration='400'
            >
              <img src={Mask} className='img-fluid p-0' />
            </BlueContainer>
            <ShippingContainer>
              <img src={Mask} className='img-fluid p-0' />
            </ShippingContainer>
          </CirclesContainer>
        </Col>
        <Col xs={12} sm={12} md={8} className='d-flex flex-column justify-content-center'>
          <Description>
            <b>
              De 1-3 días recibirás en tu correo una guía de paquetería{' '}
            </b>
            para que puedas enviarnos tu muestra para analizar.
          </Description>
        </Col>
      </Row>
    </Container>
  );
}