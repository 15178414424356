import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import {
  UilInfoCircle,
  UilEditAlt,
  UilBookMedical,
  UilTimes,
  UilMinusCircle,
} from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { PersonalForm } from './personalForm';

const StyledForDesktop = styled.div`
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 24px;
  }
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #5731b2;
    text-decoration: underline;
  }
`;

const UserInfo = styled.div`
  position: relative;
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > #name {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 4px;
  }
  > p {
    margin-bottom: 0px;
  }
  > p:nth-child(6) {
    margin-bottom: 0px;
  }
  > svg {
    color: #5731b2;
    position: absolute;
    top: 12px;
    right: 16px;
  }
`;

const DataForm = styled.form`
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'Assistant', sans-serif;
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'Assistant', sans-serif;
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #5731b2;
  }

  > div input.form-check-input:checked {
    background-color: #5731b2;
  }
    > div
    .form-check
    label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const Red = [
  {
    id: 1,
    name: 'Mario Lopez',
    record: '12124623',
    email: 'mario.lbpat@gmail.com',
    age: '42',
    phone: '+522222221123',
  },
  {
    id: 2,
    name: 'Perla Rodriguez',
    record: '11234623',
    email: 'perla.lbpat@gmail.com',
    age: '22',
    phone: '+522221111123',
  },
  {
    id: 3,
    name: 'Carlos Castillo',
    record: '14564623',
    email: 'carlos.lbpat@gmail.com',
    age: '19',
    phone: '+522228133323',
  },
  {
    id: 4,
    name: 'Pedro Ramirez',
    record: '67824623',
    email: 'pedro.lbpat@gmail.com',
    age: '27',
    phone: '+522244471123',
  },
];

const ContinueButton = styled(Button)`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #5731b2;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #5731b2;
  }
  :hover {
    background: #5731b2;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const WhiteButton = styled(Button)`
  color:#5731b2;
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: -10px;
  margin-bottom: 0px;
  :hover{
    color: #5731b2;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #C1D9D8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #5731b2;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #5731b2;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

export const StepThree = (props) => {
  const logged = useSelector((state) => state.auth.uid);
  const user = useSelector((state) => state.user?.data?.data);
  const [personalData, setPersonalData] = useState(props.personalData);
  const [otherPersonCheck, setOtherPersonCheck] = useState(false);
  const [otherPerson, setOtherPerson] = useState();
  const [showAddNetwork, setShowAddNetwork] = useState(false);
  const [phone, setPhone] = useState();
  // const [selectedFile, setSelectedFile] = useState(null);
  const isDoctor = props.isDoctor;
  const pay = props.pay;
  const [state, setState] = useState(user);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  /*
  {
    name: '',
    fathersLastname: '',
    mothersLastname: '',
    phone: '',
    email: '',
  }
   */

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  //console.log(errors);
  const onSubmit = (data, event) => {
    //TODO: Add onStepThree
    //console.log(props.pay);
    //return;
    //console.log(data);
    handleClose();
    props.onStepThree({
      personalData: data,
      otherPersonCheck: otherPersonCheck,
      otherPerson: otherPerson,
      newNetwork: showAddNetwork,
    });

    /**
     * revisar que persona es y crear un solo objeto persona indicando si es red, nueva red o para mi
     */

    //setOtherPerson(Red[e.target.value - 1]);
    //setPersonalData(Red[e.target.value - 1]);
    //console.log('other person: ', otherPerson);
    //console.log('other person check: ', otherPersonCheck);
    //console.log('personal data: ', personalData);
    //console.log(data);
    //console.log(data.target.name.value);
    window.scroll({
      top: -10,
      behavior: 'auto',
    });
  };
  //console.log(errors);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(state);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setDataOtherUser = (e) => {
    setShowAddNetwork(false);
    setOtherPerson(Red[e.target.value - 1]);
    setPersonalData(Red[e.target.value - 1]);
  };

  const buttonAddNetwork = () => {
    eraseDataOtherUser();
    setShowAddNetwork(true);
  };

  const eraseDataOtherUser = () => {
    setOtherPerson(undefined);
    setPersonalData({
      name: '',
      secondName: '',
      lastName: '',
      phone: '',
      email: '',
      birthday: '',
    });
  };

  return (
    <StyledForDesktop>
      <DataForm onSubmit={handleSubmit(handleShow)}>
        {logged && pay !== 'orderToPatient' ? (
          <>
            {/* ------ Logged user ----- */}
            <Title>Confirma los datos de tu expediente digital</Title>
            <br />
            {/*<OtherCheck className='mb-3' controlId='formBasicCheckbox'>
              <Form.Check
                type='checkbox'
                label='Los estudios no son para mi'
                checked={otherPersonCheck}
                {...register('otherPerson', {
                  required: false,
                })}
                onChange={() => {
                  setOtherPersonCheck(!otherPersonCheck);
                }}
              />
            </OtherCheck>*/}
            {otherPersonCheck ? (
              <>
                {/* ------ Logged user for other person----- */}
                {/*<RegularP>
                  Elige y confirma los datos de la persona que se va a realizar
                  los estudios
                </RegularP>
                <StyledSelect
                  onChange={(e) => setDataOtherUser(e)}
                  defaultValue='Elije una persona de tu red'
                  value={!otherPerson ? 'Elije una persona de tu red' : null}
                >
                  <option value=''>Selecciona</option>
                  {Red.map((person, index) => (
                    <option value={person.id} key={index}>
                      {person.name}
                    </option>
                  ))}
                </StyledSelect>*/}
                <br />
                {/*<RegularP
                  onClick={() => buttonAddNetwork()}
                  style={{ cursor: 'pointer' }}
                >
                  <UilBookMedical style={{ color: '#00AD90' }} /> &nbsp; Añadir
                  a mi red
                </RegularP>*/}

                <>
                  <RegularP>
                    Escribe los datos de la persona que vas a añadir a tu red.
                  </RegularP>

                  <PersonalForm
                    register={register}
                    state={state}
                    user={user}
                    handleChange={handleChange}
                    control={control}
                  />
                </>

                {otherPerson ? (
                  <>
                    <UserInfo>
                      <p id='name'>{otherPerson.name}</p>
                      <p>
                        Expediente{' '}
                        {otherPerson.age
                          ? otherPerson.age
                          : 'Expediente no registrado'}
                      </p>
                      <p>
                        {otherPerson.age
                          ? `${otherPerson.age} años`
                          : 'Edad no registrada'}
                      </p>
                      <br />
                      <p>
                        {otherPerson.phone
                          ? otherPerson.phone
                          : 'Teléfono no registrado'}
                      </p>
                      <p>
                        {otherPerson.email
                          ? otherPerson.email
                          : 'Email no registrado'}
                      </p>
                      <UilMinusCircle
                        style={{ cursor: 'pointer' }}
                        onClick={() => eraseDataOtherUser()}
                      />
                    </UserInfo>
                  </>
                ) : null}
              </>
            ) : null}

            {!otherPersonCheck && user.record ? (
              <>
                <RegularP>Confirma tus datos</RegularP>
                <UserInfo>
                  <p id='name'>{user.name}</p>
                  <p>
                    {user.record
                      ? `Expediente ${user.record}`
                      : 'Expediente no registrado'}
                  </p>
                  <p>{user.age ? `${user.age} años` : 'Edad no registrada'}</p>
                  <br />
                  <p>{user.phone ? user.phone : 'Teléfono no registrado'}</p>
                  <p>{user.email ? user.email : 'Email no registrado'}</p>
                  <UilEditAlt />
                </UserInfo>
                <hr />
              </>
            ) : !otherPersonCheck ? (
              <PersonalForm
                register={register}
                state={state}
                user={user}
                handleChange={handleChange}
                control={control}
              />
            ) : null}
          </>
        ) : !logged ? (
          <>
            {/* ------ Not logged user ----- */}
            <Title>Ingresa tus datos para crear tu expediente digital.</Title>
            <br />
            <PersonalForm
              register={register}
              state={state}
              user={user}
              handleChange={handleChange}
              control={control}
            />
          </>
        ) : null}
        {logged && isDoctor && pay === 'orderToPatient' ? (
          <>
            {/* ------ Logged Doctor ----- */}
            <Title>Escribe los datos de tu paciente</Title>
            <PersonalForm
              register={register}
              state={state}
              user={{ doctor: true }}
              handleChange={handleChange}
              orderToPatient={true}
              control={control}
            />
          </>
        ) : null}

        <Row className='justify-content-center'>
          <ContinueButton
            variant='primary'
            type='submit'
            disabled={(personalData.name = '')}
            // onClick={handleSubmit(handleShow)}
          >
            Continuar
          </ContinueButton>
          <StyledModal
            show={show}
            onHide={handleClose}
          >
            <div onClick={handleClose}>
              <UilTimes color="#fff" style={{cursor: 'pointer'}}/>
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Verifica tus datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Antes de continuar revisa que todos tus datos sean correctos</p>
              {/*<p className='text-left'>
                Nombre: {props.personalData.name}{' '}
                {props.personalData.fathersLastname}{' '}
                {props.personalData.mothersLastname}
              </p>
              <p>Tel.: {props.personalData.phone}</p>
              <p>Email.: {props.personalData.email}</p>
              <p>
                Fecha nacimiento: {props.personalData.day}/
                {props.personalData.month}/{props.personalData.year}
              </p>
              <p>Sexo: {props.personalData.gender}</p>*/}
              <ContinueButton onClick={handleSubmit(onSubmit)}>
                Confirmar
              </ContinueButton>
              <WhiteButton variant="light" onClick={handleClose}>Ver datos</WhiteButton>
            </Modal.Body>
          </StyledModal>
        </Row>
      </DataForm>
    </StyledForDesktop>
  );
};
