import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { TestimonialSlider } from './testimonialSlider';

const StyledContainer = styled(Container)`
`;

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2E363E;
  margin: 0;
  @media only screen and (max-width: 768px) {
    padding: 0;
    font-size: 32px;
    line-height: 42px;
  }
`;

export const Testimonials = () => {
  return(
    <StyledContainer id='descubre'>
      <Row className='mb-3 mb-sm-4 mb-md-5'>
        <Title>
          Te invitamos a ver los videos de testimoniales:
        </Title>
      </Row>
      <Row>
        <TestimonialSlider />
      </Row>
    </StyledContainer>
  );
}