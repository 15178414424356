import { types } from "../types/types";

const initialState = { data: {
	data: {
		userType: '',
		name: '',
		birthday: '',
		phone: ''
	}
}};

export const userReducer = (state=initialState, action) => {

	switch (action.type) {
		case types.userInfo:
			return {
				...state,
				data: action.payload
			};

		case types.isNew:
			return {
				...state,
				isNew: action.payload
			};

		case types.cleanSession:
			return initialState;
	
		default:
			return state;
	}

}