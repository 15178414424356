import React, { useState } from 'react';
import styled from 'styled-components';
import { UilHeart } from '@iconscout/react-unicons';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { AiFillHeart } from 'react-icons/ai';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StudyContainer = styled.div`
  text-align: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px #f6f6f6;
  width: 100%;
  max-width: 250px;
  height: 290px;
  border-radius: 16px;
  padding-top: 1px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px auto;
  margin-top: -12px;
  margin-bottom: -12px;
`;

const Favorite = styled(UilHeart)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const FavoriteFilled = styled(AiFillHeart)`
  color: #ff004d;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Title = styled.a`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  :hover {
    color: #00AD90;
    text-decoration: underline;
  }
`;

const Price = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #00AD90;
  margin-top: 16px;
  margin-bottom: 0px;
`;

const OldPrice = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: line-through;
  color: #c4c4c4;
  margin-bottom: 0px;
`;

const AddButton = styled.button`
  width: 140px;
  height: 40px;
  background-color: #00AD90;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 26px;
  margin-top: 16px;
`;

const EndRequest = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00AD90;
  margin-top: 13px;
  margin-bottom: 13px;
`;

const Top = styled.div`
    display: flex;
    height: 65px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-top: 25px;
`;

const Middle = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 120px;
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%);
`;

export const StudyG = (props) => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.shoppingCart);
  const isLogged = useSelector((state) => state.auth.uid);

  const [favorite, setFavorite] = useState(false);

  const toggleFav = () => {
    if(isLogged){
      setFavorite(!favorite);
      // TODO: Add to favorites in user profile
    }else{
      history.push('/registro');
    }
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const payStudy = () => {
    dispatch(handleAddItemToCart(props.data));
    history.push('/checkout');
  };

  const { title, description, price, id } = props.data;
  const history = useHistory();

  return (
    <Col xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <StudyContainer>
        {props.data.tag ? (
          <Tag
            style={{
              backgroundColor: props.data.tag.background,
              color: props.data.tag.color,
            }}
          >
            {props.data.tag.content}
          </Tag>
        ) : null}
        {/* {favorite ? (
          <FavoriteFilled
            onClick={() => {
              toggleFav();
            }}
          />
        ) : (
          <Favorite
            onClick={() => {
              toggleFav();
            }}
          />
        )} */}
        <Top>
          <Title href={`/estudio/${props.data.id}`}>{props.data.title}</Title>
        </Top>
        <Middle>
          <Price>{currency(props.data.price)} MXN</Price>
          <OldPrice>{currency(props.data.oldPrice)} MXN</OldPrice>
        </Middle>
        <Bottom>
          <AddButton
            onClick={() => dispatch(handleAddItemToCart(props.data))}
          >
            <UilClipboardAlt /> Agregar
          </AddButton>
          <button style={{background: '#fff', border: 'none'}} onClick={ () => payStudy() }>
            <EndRequest>Pagar estudio</EndRequest>
          </button>
        </Bottom>
      </StudyContainer>
    </Col>
  );
};
