import { Container, Row, Col } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import {
  UilUser,
  UilClipboardNotes,
  UilCheck,
  UilX,
  UilCheckSquare,
} from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const StyledContainer = styled(Container)`
  @media only screen and (max-width: 768px) {
    margin-top: 3rem;
    padding-top: 1.5rem;
  }
`;

const HistorySection = styled.div`
  min-height: 82vh;
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const HeaderTitle = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #77ccd5;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const StyledHr = styled.hr`
  background-color: #77ccd5;
  height: 1.5px !important;
  margin: 0;
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0849468);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
`;

const GrayText = styled.span`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  //color: #8f8f8f;
  margin-bottom: 0;
  padding: 0;
`;

const StatusText = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1d1d1d;
  margin-bottom: 0;
  padding: 0 0 0 8px;
  width: auto;
`;

const EmptyText = styled.p`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #121619;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
`;

const EmptyIconContainer = styled.div`
  background-color: #f6f6f6;
  max-width: 140px;
  max-height: 140px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const Bullet = styled.p`
  font-size: 24px;
  color: ${(props) => props.color};
  width: auto;
  padding: 0;
  margin-bottom: 0;
  user-select: none;
`;

export const MedicalRecord = () => {
  const statusList = [
    {
      name: "Mostrar todas las pruebas",
      color: "",
      code: 0,
    },
    {
      name: "Preparando kit",
      color: "#77CCD5",
      code: 10,
    },
    {
      name: "Kit enviado",
      color: "#71C388",
      code: 11,
    },
    {
      name: "Activado",
      color: "#E49D5B",
      code: 12,
    },
    {
      name: "Pendiente de recolección",
      color: "#71C388",
      code: 13,
    },
    {
      name: "Muestra recibida",
      color: "#E49D5B",
      code: 14,
    },
    {
      name: "Procesamiento de la prueba",
      color: "#E49D5B",
      code: 15,
    },
    {
      name: "Resultados listos",
      color: "#71C388",
      code: 16,
    },
  ];

  const { width } = useWindowDimensions();

  // Orders
  const uid = useSelector((state) => state?.auth?.uid);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState(0);
  let isMobile = width <= 992;
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [search, setSearch] = useState("");
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [modalPatient, setModalPatient] = useState();
  const history = useHistory();

  useEffect(() => {
    // Search patients
  }, [search]);

  useEffect(() => {
    dd();
  }, []);

  const dd = async () => {
    try {
      //setLoading(true);
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://clienteslabopat.com/user-sols-vg?uid=${uid}`
      );
      setPatients(requestPending.data);
      setFilteredPatients(requestPending.data);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };

  const ded = async (patient) => {
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://clienteslabopat.com/user-sols-vg?uid=${uid}`
      );
      setPatient(requestPending.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTestHistoryByPhase = async (code) => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://clienteslabopat.com/user-sols-vg?uid=${uid}${
            code === undefined ? "" : `&fase=${code}`
          }`
        )
        .then((response) => {
          let orderByDate = response?.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setTests(orderByDate);
          setLoading(false);
          console.log(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (date) => {
    date.split("-").join("/");
    const formatDate = date.split(" ")[0];
    return formatDate;
  };

  const formatHours = (date) => {
    date.split("-").join("/");
    const formatHours = date.split(" ")[1];
    const result = formatHours.split(":")[0] + ":" + formatHours.split(":")[1];
    return result;
  };

  useEffect(() => {
    getTestHistoryByPhase(phase);
  }, [phase]);

  const TestCard = (props) => {
    return (
      <Row>
        <Card>
          <Row>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={3}
              style={
                width <= 992
                  ? { marginBottom: "16px", padding: "0" }
                  : { paddingLeft: "0" }
              }
            >
              <Row className="p-0 align-items-start" style={{cursor: "pointer"}}>
                <div
                  className="pe-0 ps-0 d-flex flex-row align-items-center"
                  style={{ maxWidth: "20px", paddingTop: "1px" }}
                >
                  <UilUser size={16} color={"#8F8F8F"} className="w-auto p-0" />
                </div>
                <div
                  className="p-0 d-flex flex-row align-items-center"
                  style={{ maxWidth: "75%" }}
                >
                  <GrayText
                    onClick={() => history.push(`/trackorder/${props?.test?.id}`)}
                    style={{ color: "#8F8F8F" }}
                    className="w-auto"
                  >
                    {props?.test?.nombre}
                  </GrayText>
                </div>
              </Row>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={3}
              style={
                width <= 992
                  ? { marginBottom: "16px", padding: "0" }
                  : { paddingLeft: "0" }
              }
            >
              <Row className="p-0 align-items-start">
                <tr>
                  <th>
                    <div
                      className="p-0 d-flex flex-row align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <GrayText style={{ color: "#8F8F8F" }} className="w-auto">
                        {props?.test?.laminilla}
                      </GrayText>
                    </div>
                  </th>
                </tr>
              </Row>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={2}
              style={
                width <= 992
                  ? { marginBottom: "16px", padding: "0" }
                  : { textAlign: "initial" }
              }
            >
              {width > 992 && (
                <>
                  {props.test.idPago !== null ?  (
                    <GrayText style={{ color: "#8F8F8F" }}>
                      {" "}
                      <UilCheckSquare style={{ color: "green" }} />{" "}
                      {props.test.casoLB}
                    </GrayText>
                  ) : (
                    <GrayText style={{ color: "#8F8F8F" }}>
                      {" "}
                      <UilX style={{ color: "red" }} />
                      No pagado
                    </GrayText>
                  )}
                </>
              )}
            </Col>
            {width <= 992 && (
              <>
                <Row className="p-0" style={{ marginBottom: "16px" }}>
                  <div className="p-0">
                    <StyledHr />
                  </div>
                </Row>
                <Row className="p-0" style={{ marginBottom: "16px" }}>
                  {/*<Col xs={12} sm={12} className="p-0">
                    <GrayText style={{ color: "#8F8F8F" }}>
                      Folio {props?.test?.driveThru}
                    </GrayText>
                  </Col>*/}
                  <Col xs={12} sm={12} className="p-0">
                    {props.test.idPago !== null  ? (
                      <GrayText style={{ color: "#8F8F8F" }}>
                        {" "}
                        <UilCheckSquare style={{ color: "green" }} />{" "}
                        {props?.test?.casoLB}
                      </GrayText>
                    ) : (
                      <GrayText style={{ color: "#8F8F8F" }}>
                        {" "}
                        <UilX style={{ color: "red" }} />
                        No pagado
                      </GrayText>
                    )}
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    className="p-0 d-flex flex-column justify-content-center"
                  >
                    <GrayText style={{ color: "#8F8F8F" }}>
                      {formatDate(props?.test?.created_at)}
                      {" a las "}
                      {formatHours(props?.test?.created_at)}
                    </GrayText>
                  </Col>
                </Row>
              </>
            )}
            <Col
              sm={12}
              md={12}
              lg={4}
              className="p-0 d-flex flex-column justify-content-center"
            >
              <Row className="p-0">
                {width > 992 && (
                  <Col
                    md={6}
                    lg={4}
                    className="ps-0 d-flex flex-column justify-content-center"
                  >
                    <GrayText style={{ color: "#8F8F8F" }}>
                      {formatDate(props?.test?.created_at)}
                      {" a las "}
                      {formatHours(props?.test?.created_at)}
                    </GrayText>
                  </Col>
                )}
                {width > 992 && (
                  <Col
                    md={6}
                    lg={4}
                    className="ps-0 d-flex flex-column justify-content-center"
                  >
                    <GrayText style={{ color: "#8F8F8F" }}>
                      {formatDate(props?.test?.updated_at)}
                      {" a las "}
                      {formatHours(props?.test?.updated_at)}
                    </GrayText>
                  </Col>
                )}
                {width > 992 && (
                  <Col
                    lg={4}
                    className="p-0 d-flex flex-column justify-content-center"
                  >
                    <Row className="align-items-center">
                      <div
                        className="d-flex flex-row align-items-center p-0"
                        style={{ maxWidth: "5px" }}
                      >
                        <Bullet
                          color={
                            statusList.find(
                              (item) => item.code === props?.test?.fase
                            )?.color
                          }
                        >
                          &bull;
                        </Bullet>
                      </div>
                      <div
                        className="d-flex flex-row align-items-center p-0"
                        style={{ maxWidth: "94%" }}
                      >
                        {props?.test?.estudio === 2064 ||
                        props?.test?.estudio === 1801 ? (
                          <StatusText>Inscripción pagada</StatusText>
                        ) : (
                          <StatusText>
                            {
                              statusList.find(
                                (item) => item.code === props?.test?.fase
                              )?.name
                            }
                          </StatusText>
                        )}
                      </div>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            {width < 992 && (
              <Col
                xs={12}
                className="p-0 d-flex flex-column justify-content-center"
              >
                <Row className="align-items-center">
                  <Bullet
                    color={
                      statusList.find((item) => item.code === props?.test?.fase)
                        ?.color
                    }
                  >
                    &bull;
                  </Bullet>
                  <StatusText>
                    {
                      statusList.find((item) => item.code === props?.test?.fase)
                        ?.name
                    }
                  </StatusText>
                </Row>
              </Col>
            )}
          </Row>
        </Card>
      </Row>
    );
  };

  return (
    <>
      <StyledContainer>
        <Row className="mb-3">
          <HeaderSection>
            <HeaderTitle>Pruebas genéticas</HeaderTitle>
          </HeaderSection>
        </Row>
        <Row className="mb-4">
          <div className="p-0">
            <StyledHr />
          </div>
        </Row>
      </StyledContainer>
      {!isMobile && (
        <HistorySection className="mb-5">
          <Container>
            <Row>
              <Row>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={3}
                  style={
                    width <= 992
                      ? { marginBottom: "16px", padding: "0" }
                      : { paddingLeft: "0" }
                  }
                >
                  <Row className="p-0 align-items-start">
                    <div
                      className="pe-0 ps-0 d-flex flex-row align-items-center"
                      style={{ maxWidth: "20px", paddingTop: "1px" }}
                    ></div>
                    <div
                      className="p-0 d-flex flex-row align-items-center"
                      style={{ maxWidth: "75%" }}
                    >
                      <GrayText
                        style={{ color: "rgba(119, 204, 213, 1)" }}
                        className="w-auto"
                      >
                        Nombre del paciente
                      </GrayText>
                    </div>
                  </Row>
                </Col>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={3}
                  style={
                    width <= 992
                      ? { marginBottom: "16px", padding: "0" }
                      : { paddingLeft: "0" }
                  }
                >
                  <Row className="p-0 align-items-start">
                    <tr>
                      <th>
                        <div
                          className="p-0 d-flex flex-row align-items-center"
                          style={{ maxWidth: "75%" }}
                        >
                          <GrayText
                            style={{ color: "rgba(119, 204, 213, 1)" }}
                            className="w-auto"
                          >
                            Prueba
                          </GrayText>
                        </div>
                      </th>
                    </tr>
                  </Row>
                </Col>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={2}
                  style={
                    width <= 992
                      ? { marginBottom: "16px", padding: "0" }
                      : { paddingLeft: "0" }
                  }
                >
                  {width > 992 && (
                    <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                      {" "}
                      Pago
                    </GrayText>
                  )}
                </Col>
                {width <= 992 && (
                  <>
                    <Row className="p-0" style={{ marginBottom: "16px" }}>
                      <div className="p-0">
                        <StyledHr />
                      </div>
                    </Row>
                    <Row className="p-0" style={{ marginBottom: "16px" }}>
                      <Col
                        xs={6}
                        sm={6}
                        className="p-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Envio de muestra
                        </GrayText>
                      </Col>
                    </Row>
                  </>
                )}
                <Col
                  sm={12}
                  md={12}
                  lg={4}
                  className="p-0 d-flex flex-column justify-content-center"
                >
                  <Row className="p-0">
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Envio de muestra
                        </GrayText>
                      </Col>
                    )}
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Fecha de resultados
                        </GrayText>
                      </Col>
                    )}
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Estatus de prueba
                        </GrayText>
                      </Col>
                    )}
                  </Row>
                </Col>
                {width < 992 && (
                  <Col
                    md={6}
                    lg={4}
                    className="ps-0 d-flex flex-column justify-content-center"
                  >
                    <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                      Estatus de prueba
                    </GrayText>
                  </Col>
                )}
              </Row>
            </Row>
            <br />
            {tests.length === 0 ? (
              <Row className="justify-content-center">
                <EmptyText className="mb-4">
                  No hay pruebas con este status
                </EmptyText>
                <EmptyIconContainer>
                  <UilClipboardNotes size={100} color={"#A6A6A6"} />
                </EmptyIconContainer>
              </Row>
            ) : (
              tests.map((test, index) => <TestCard test={test} key={index} />)
            )}
          </Container>
        </HistorySection>
      )}
      {isMobile && (
        <HistorySection className="mb-5">
          <Container>
            <Row>
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={4}
                  className="p-0 d-flex flex-column justify-content-center"
                >
                  <Row className="p-0">
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Envio de muestra
                        </GrayText>
                      </Col>
                    )}
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Fecha de resultados
                        </GrayText>
                      </Col>
                    )}
                    {width > 992 && (
                      <Col
                        md={6}
                        lg={4}
                        className="ps-0 d-flex flex-column justify-content-center"
                      >
                        <GrayText style={{ color: "rgba(119, 204, 213, 1)" }}>
                          Estatus de prueba
                        </GrayText>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Row>
            <br />
            {tests.length === 0 ? (
              <Row className="justify-content-center">
                <EmptyText className="mb-4">
                  No hay pruebas con este status
                </EmptyText>
                <EmptyIconContainer>
                  <UilClipboardNotes size={100} color={"#A6A6A6"} />
                </EmptyIconContainer>
              </Row>
            ) : (
              tests.map((test, index) => <TestCard test={test} key={index} />)
            )}
          </Container>
        </HistorySection>
      )}
      {loading ? <Loader show={loading} /> : null}
    </>
  );
};
