import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Dropdown } from 'react-bootstrap';
import {
  UilAngleDown,
  UilAngleUp,
  UilListUl,
  UilApps,
  UilFilter,
} from '@iconscout/react-unicons';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { StudyG } from '../studies/studyG';
import { StudyL } from '../studies/studyL';
import { FilterDesk } from '../filter/filterDesk';
import { FilterMobile } from '../filter/filterMobile';
import axios from 'axios';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { TestCard } from '../studies/testCard';

const FilterD = styled(Col)`
  @media only screen and (max-width: 991px) {
    display: none !important;
  }
`;

const FilterButton = styled(Button)`
  background-color: white;
  border: none;
  @media only screen and (min-width: 992px) {
    width: 189px;
    height: 81px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 16px;
  }

  > svg {
    @media only screen and (min-width: 992px) {
      margin-right: 10px;
    }
  }
`;

const OrderButton = styled(Dropdown.Toggle)`
  background-color: white;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: 992px) {
    width: 189px;
    height: 81px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 16px;
  }

  ::after {
    display: none;
  }
`;

const OrderMenu = styled(Dropdown.Menu)`
  margin-top: 20px;
  width: 262px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  box-shadow: 0px 15px 30px rgba(164, 164, 164, 0.5);

  > a {
    color: #717171;
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  > a::hover {
    color: #000000;
  }

  > .dropdown-item {
    width: 90%;
  }
  > .dropdown-item:active {
    background-color: ##f1f6f6;
  }
`;

const DropdownOrder = styled(Dropdown)`
  z-index: 1;
  > .dropdown-menu:before {
    content: '';
    background-color: transparent;
    border-right: 8px solid transparent;
    position: absolute;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #fff;
    border-top: 8px solid transparent;
    top: -15px;
    left: 144px;
  }
`;

const Description = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  color: #717171;
`;

const StudiesPaginate = styled.div`
  > ul li.active {
    z-index: 1 !important;
  }
`;

function Items({ currentItems, showList }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <>
            {showList ? (
              <StudyL key={index} data={item} />
            ) : (
              <TestCard
                key={index}
                data={item}
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                xxl={3}
              />
            )}
          </>
        ))}
    </>
  );
}

export const Studies = (props) => {
  // For mobile filter
  const [showMenu, setShowMenu] = useState(false);
  const [order, setOrder] = useState(['Ordenar por', 0]);
  const [items, setItems] = useState([]);

  //Change order button content
  const handleChange = (e, index) => {
    setOrder([e, index]);
  };

  const [filters, setFilters] = useState([
    { id: 1, select: false, name: 'filter1a', group: 1 },
    { id: 2, select: false, name: 'filter1b', group: 1 },
    { id: 3, select: false, name: 'filter1c', group: 1 },
    { id: 4, select: false, name: 'filter2a', group: 2 },
    { id: 5, select: false, name: 'filter2b', group: 2 },
    { id: 6, select: false, name: 'filter2c', group: 2 },
  ]);

  // Make filters request
  // Add axios request
  const getFilteredInfo = () => {
    const toFilter = [];
    filters.map((f) => {
      if (f.select) {
        toFilter.push(f.name);
      }
    });
    console.log(toFilter);
    console.log(order);
    const endOffset = itemOffset + itemsPerPage;
    getStudies(5, endOffset);
  };

  useEffect(getFilteredInfo, [filters, order]);

  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 991;

  const [showList, setShowList] = useState(false);

  // Set items per page based on the width & list
  const itemsPerPage = showList
    ? 5
    : width > 1199
    ? 12
    : width > 991
    ? 12
    : width > 767
    ? 8
    : 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const getStudies = async (id, endOffset) => {
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    try {
      let request = axios.get(
        'https://lpqsystem.com/get-studies/' + order[1] + '/' + id
      ); //orden/service
      let response = await request;
      setItems(response.data);
      setCurrentItems(response.data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(response.data.length / itemsPerPage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    getStudies(5, endOffset);
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <Container>
      <HelmetTitle title='Estudios' name='description' content='content' />
      <Row>
        <Col className='text-center mt-5'>
          <h4 style={{ fontWeight: 900, marginTop: '30px', color: '#00AD90' }}>
            Todos los estudios
          </h4>
        </Col>
      </Row>
      <Row className='mt-1 mb-3'>
        <Col className='text-center'>
          <Description>
            Busca el estudio que necesitas realizarte, <br />
            utiliza los filtros para que sea más facil
          </Description>
        </Col>
      </Row>

      <Row>
        {/* <FilterD md={3} xl={3}>
          <FilterDesk filters={filters} onFilter={handleFilter} />
        </FilterD> */}
        <Col
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Row>
            {/* <Col xl={6} lg={4} md={4} xs={4} sm={4} className='text-center'>
              {isMobile ? (
                <FilterButton
                  variant='light'
                  style={{ fontSize: '13px', padding: 2, width: '100%' }}
                  onClick={() => setShowMenu(true)}
                >
                  Filtrar
                  <UilFilter size='26' color='#00AD90' />
                </FilterButton>
              ) : null}
            </Col> */}
            <Col xs={1}></Col>
            <Col
              xs={7}
              sm={7}
              md={{ span: 3, offset: 5 }}
              className='text-center'
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <DropdownOrder>
                <OrderButton
                  variant='light'
                  style={{ fontSize: '13px', padding: 2, width: '100%' }}
                  value={order[0]}
                >
                  <UilFilter size='26' color='#00AD90' />
                  {order[0]}
                  <UilAngleDown size='32' color='#00AD90' />
                </OrderButton>

                <OrderMenu>
                  <Dropdown.Item
                    onClick={() => {
                      handleChange(
                        width < 768 ? 'A-Z' : 'Alfabéticamente A-Z',
                        1
                      );
                    }}
                    href='#'
                  >
                    Alfabeticamente (De A-Z)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleChange(
                        width < 768 ? 'Z-A' : 'Alfabéticamente Z-A',
                        2
                      );
                    }}
                    href='#'
                  >
                    Alfabeticamente (De Z-A)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleChange('Promociones', 3);
                    }}
                    href='#'
                  >
                    Promociones
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleChange('Populares', 4);
                    }}
                    href='#'
                  >
                    Mas populares
                  </Dropdown.Item>
                </OrderMenu>
              </DropdownOrder>
            </Col>
            <Col
              md={3}
              xs={3}
              sm={3}
              style={{
                paddingRight: '0px',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FilterButton
                className='view'
                variant='light'
                onClick={() => setShowList(!showList)}
              >
                {showList ? (
                  <>
                    <UilListUl size='30' color='#00AD90' />{' '}
                    {isMobile ? null : 'En lista'}
                  </>
                ) : (
                  <>
                    <UilApps size='30' color='#00AD90' />{' '}
                    {isMobile ? null : 'Cuadrícula'}
                  </>
                )}
              </FilterButton>
            </Col>
          </Row>
          {/* <FilterMobile
            show={showMenu}
            hide={() => setShowMenu(false)}
            onFilter={handleFilter}
            filters={filters}
          /> */}
          <Row>
            <Items currentItems={currentItems} showList={showList} />
          </Row>
          {pageCount > 1 ? (
            <StudiesPaginate>
              <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination justify-content-center'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                activeClassName='active'
                previousLabel='< '
                renderOnZeroPageCount={null}
              />
            </StudiesPaginate>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};
