import React, { useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { Pruebas } from '../pruebas/Pruebas';
import { PruebaTable } from '../pruebas/PruebaTable';
import { CheckIn } from './CheckIn';
import { CheckinDetail } from './CheckinDetail';

const MainContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  button {
    background: #fff;
    border: 1px solid #00ad90;
    color: #00ad90;
    border-radius: 16px;
    margin: 0 10px;
    padding: 5px 18px;
  }
  .active {
    background: #00ad90 !important;
    color: #fff !important;
  }
`;

export const MainCheckin = () => {
  let { path, url } = useRouteMatch();
  const [current, setCurrent] = useState('checkin');
  const history = useHistory();

  return (
    <div>
      <MainContainer>
        <button
          onClick={() => {
            history.push('/checkin');
            setCurrent('checkin');
          }}
          className={current === 'checkin' ? 'active' : ''}
        >
          Checkin
        </button>
      </MainContainer>
      <br />
      <Switch>
        <Route exact path={path} component={CheckIn} />
        {/*<Route path={`${path}/test`} component={Pruebas} /> 
                <Route path={`${path}/pacientes`} component={PruebaTable} /> 
                <Route path={`${path}/checkin-detalle/:checkinId`} component={CheckinDetail} />*/}
      </Switch>
    </div>
  );
};
