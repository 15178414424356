import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PhoneInput from "react-phone-number-input";

const SectionTitle = styled.p`
  font-family: "Assistant", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: #02a1b5;
  margin: 0;
  padding: 0;
`;

const NormalText = styled.p`
  font-family: "Assistant", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
`;

const StyledHr = styled.hr`
  padding: 0.5px 0 0.5px 0;
  background-color: rgba(2, 161, 181, 0.34);
  margin: 0;
`;

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 0;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  .form-control {
    font-family: "Assistant", sans-serif;
    max-height: 45px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
    :disabled {
      background-color: #f6f6f6;
      border: ${(props) =>
        props.error === 1 ? "1px solid #FF0505" : "1px solid #cccccc"};
      border-style: dashed;
    }
  }
  .form-select {
    border: ${(props) =>
      props.error === 1 ? "1px solid #FF0505" : "1px solid #E9E9E9"};
    border-radius: 12px;
    font-family: "Assistant", sans-serif;
    max-height: 45px;
    padding: 0 0 0 40px !important;
    box-shadow: 0px 4px 4px #f6f6f6;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237a4dc7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :disabled {
      background-color: #f6f6f6;
      border: ${(props) =>
        props.error === 1 ? "1px solid #FF0505" : "1px solid #cccccc"};
      border-style: dashed;
      color: #b8b8b8;
    }
  }
  label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: ${(props) => (props.disabled ? "#b8b8b8" : "#828282")};
    transform-origin: -8em -10.5em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: "Assistant", sans-serif;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 300ms;
  }
  :active,
  :focus-within {
    label {
      background-color: ${(props) => (props.disabled ? "#F6F6F6" : "#ffffff")};
      margin: 5px 0 0 35px;
      padding: 5px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  background: #f7f7f7 !important;
  border: ${(props) => (props.error === 1 ? "1px solid #FF0505" : "none")};
  border-radius: 12px !important;
  padding: 0 40px 0 40px !important;
  height: 50px;
  z-index: 1;
  :disabled {
    color: ${(props) => (props.disabled === 1 ? "#a6a6a6;" : "red")};
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? "#FF0505" : "#77CCD5")};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  ::placeholder {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
`;

const StyledCheck = styled(Form.Check)`
  padding-left: 1.5em;
  display: block;
  .form-check-label {
    width: auto;
  }
  .form-check-input[type="checkbox"] {
    margin-left: -1.5em;
    float: left;
  }
  .form-check-input[type="checkbox"],
  .form-check-input[type="radio"] {
    margin-left: -1.5em;
  }
  label {
    padding-left: 1%;
  }
  > input {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #02a1b5;
    :checked {
      border: 1px solid #02a1b5;
      background-color: #02a1b5;
    }
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 328px;
  height: 44px;
  left: 0px;
  top: 0px;
  background: #f7f7f7;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 11px;
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #f7f7f7;
  }
`;

const ErrorMessage = styled.p`
  width: auto;
  margin: 0;
  font-family: "Assistant", sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #ff0505;
  padding: 0;
`;

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

const Birthday = styled(Row)`
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    :focus {
      box-shadow: none;
      filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
      border-radius: 10px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
  .border-div {
    border-radius: 12px;
    padding: 12px 2px 2px;
  }
  .border-div:focus-within {
    background: linear-gradient(90deg, #5731b2 -1.87%, #77ccd5 100%);
  }

  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

let days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
let months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const years = [];
for (var i = 2024; i >= 1918; i--) {
  years.push(i.toString());
}

export const OrderForm = (props) => {
  const [phone, setPhone] = useState(props.personalData?.phone);
  const personalData = useSelector((state) => state.shoppingCart.personalData);
  const [cpState, setCpState] = useState(true);
  const [billing, setBilling] = useState(false);
  const [needBilling, setNeedBilling] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [persona, setPersona] = useState(1);
  const originalTotal = useSelector((state) => state.shoppingCart.totalPrice);
  const { width } = useWindowDimensions();

  const states = [
    {
      name: "Aguascalientes",
    },
    {
      name: "Baja California",
    },
    {
      name: "Baja California Sur",
    },
    {
      name: "Campeche",
    },
    {
      name: "Chiapas",
    },
    {
      name: "Chihuahua",
    },
    {
      name: "Coahuila",
    },
    {
      name: "Colima",
    },
    {
      name: "CDMX",
    },
    {
      name: "Estado de México",
    },
    {
      name: "Guanajuato",
    },
    {
      name: "Guerrero",
    },
    {
      name: "Hidalgo",
    },
    {
      name: "Jalisco",
    },
    {
      name: "Michoacán",
    },
    {
      name: "Morelos",
    },
    {
      name: "Nayarit",
    },
    {
      name: "Nuevo León",
    },
    {
      name: "Oaxaca",
    },
    {
      name: "Puebla",
    },
    {
      name: "Querétaro",
    },
    {
      name: "Quintana Roo",
    },
    {
      name: "San Luis Potosí",
    },
    {
      name: "Sinaloa",
    },
    {
      name: "Sonora",
    },
    {
      name: "Tabasco",
    },
    {
      name: "Tamaulipas",
    },
    {
      name: "Tlaxcala",
    },
    {
      name: "Veracruz",
    },
    {
      name: "Yucatán",
    },
    {
      name: "Zacatecas",
    },
  ];

  const [cfdi, setcfdi] = useState([
    //{ id: 1,clave: "G01",nombre: "Adquisición de mercancías.",tipo: 0 },
    //{ id: 2,clave: "G02",nombre: "Devoluciones, descuentos o bonificaciones.",tipo: 0 },
    { id: 3, clave: "G03", nombre: "Gastos en general.", tipo: 0 },
    /*{ id: 4,clave: "I01",nombre: "Construcciones.",tipo: 0 },
      { id: 5,clave: "I02",nombre: "Mobiliario y equipo de oficina por inversiones.",tipo: 0 },
      { id: 6,clave: "I03",nombre: "Equipo de transporte.",tipo: 0 },
      { id: 7,clave: "I04",nombre: "Equipo de computo y accesorios.",tipo: 0 },
      { id: 8,clave: "I05",nombre: "Dados, troqueles, moldes, matrices y herramental.",tipo: 0 },
      { id: 9,clave: "I06",nombre: "Comunicaciones telefónicas.",tipo: 0 },
      { id: 10,clave: "I07",nombre: "Comunicaciones satelitales.",tipo: 0 },
      { id: 11,clave: "I08",nombre: "Otra maquinaria y equipo.",tipo: 0 },*/
    {
      id: 12,
      clave: "D01",
      nombre: "Honorarios médicos, dentales y gastos hospitalarios.",
      tipo: 1,
    },
    {
      id: 13,
      clave: "D02",
      nombre: "Gastos médicos por incapacidad o discapacidad.",
      tipo: 1,
    },
    { id: 14, clave: "D03", nombre: "Gastos funerales.", tipo: 1 },
    /*{ id: 15,clave: "D04",nombre: "Donativos.",tipo: 1 },
      { id: 16,clave: "D05",nombre: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",tipo: 1 },
      { id: 17,clave: "D06",nombre: "Aportaciones voluntarias al SAR.",tipo: 1 },*/
    {
      id: 18,
      clave: "D07",
      nombre: "Primas por seguros de gastos médicos.",
      tipo: 1,
    },
    /*{ id: 19,clave: "D08",nombre: "Gastos de transportación escolar obligatoria.",tipo: 1 },
      { id: 20,clave: "D09",nombre: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",tipo: 1 },
      { id: 21,clave: "D10",nombre: "Pagos por servicios educativos (colegiaturas).",tipo: 1 },*/
    { id: 22, clave: "S01", nombre: "Sin efectos fiscales.", tipo: 0 },
    /*{ id: 23,clave: "CP01",nombre: "Pagos",tipo: 0 },
      { id: 24,clave: "CN01",nombre: "Nómina",tipo: 1 },*/
  ]);

  const [regimen, setRegimen] = useState([
    { id: 1, nombre: "General de Ley Personas Morales", clave: "601", tipo: 2 },
    {
      id: 2,
      nombre: "Personas Morales con Fines no Lucrativos",
      clave: "603",
      tipo: 2,
    },
    {
      id: 3,
      nombre: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      clave: "605",
      tipo: 1,
    },
    { id: 4, nombre: "Arrendamiento", clave: "606", tipo: 1 },
    {
      id: 5,
      nombre: "Régimen de Enajenación o Adquisición de Bienes",
      clave: "607",
      tipo: 1,
    },
    { id: 6, nombre: "Demás ingresos", clave: "608", tipo: 1 },
    {
      id: 7,
      nombre:
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
      clave: "610",
      tipo: 0,
    },
    {
      id: 8,
      nombre: "Ingresos por Dividendos (socios y accionistas)",
      clave: "611",
      tipo: 1,
    },
    {
      id: 9,
      nombre: "Personas Físicas con Actividades Empresariales y Profesionales",
      clave: "612",
      tipo: 1,
    },
    { id: 10, nombre: "Ingresos por intereses", clave: "614", tipo: 1 },
    {
      id: 11,
      nombre: "Régimen de los ingresos por obtención de premios",
      clave: "615",
      tipo: 1,
    },
    { id: 12, nombre: "Sin obligaciones fiscales", clave: "616", tipo: 1 },
    {
      id: 13,
      nombre:
        "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      clave: "620",
      tipo: 2,
    },
    { id: 14, nombre: "Incorporación Fiscal", clave: "621", tipo: 1 },
    {
      id: 15,
      nombre: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      clave: "622",
      tipo: 2,
    },
    {
      id: 16,
      nombre: "Opcional para Grupos de Sociedades",
      clave: "623",
      tipo: 2,
    },
    { id: 17, nombre: "Coordinados", clave: "624", tipo: 2 },
    {
      id: 18,
      nombre:
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      clave: "625",
      tipo: 1,
    },
    {
      id: 19,
      nombre: "Régimen Simplificado de Confianza",
      clave: "626",
      tipo: 0,
    },
  ]);

  const fillBilling = () => {
    props.setValue("billing", {
      cp: props.state.cp,
      state: props.state.state,
      city: props.state.city,
      colonia: props.state.colonia,
      street: props.state.street,
    });
  };

  useEffect(() => {
    props.state.cp?.length < 5 ? setCpState(true) : setCpState(false);
  }, [props.state.cp]);

  useEffect(() => {
    console.log(props.errors);
  }, [props.errors]);

  useEffect(() => {
    props.personalData?.phone && setPhone(props.personalData?.phone);
    props.personalData?.phone &&
      props.setValue("phone", props.personalData?.phone);
  }, []);

  useEffect(() => {
    props.setSubTotalWeb((prev) =>
      props.items.some((e) => e.id === 675 || e.id === 966) ? prev : prev
    );
  }, [props.isExpress]);

  return (
    <Row className="justify-content-center">
      <Col xs={12} sm={12} md={11} lg={9} xl={8}>
        <Row className="mb-4 pb-2">
          <SectionTitle>
            1. Información{" "}
            {props.items.some((e) => e.id === 675 || e.id === 966)
              ? "personal"
              : "de envío"}
          </SectionTitle>
        </Row>
        <Row className="mb-4 pb-2">
          <Col md={6} lg={6}>
            <StyledLabel
              controlId="floatingName"
              label="Nombre *"
              error={props.errors?.name ? 1 : 0}
            >
              <StyledInput
                name="floatingName"
                type="text"
                {...props.register("name", {
                  required: "Campo obligatorio",
                })}
                placeholder="Nombre*"
                defaultValue={props.personalData?.name}
                onChange={props.handleForm}
              />
            </StyledLabel>
            {props.errors?.name ? (
              <ErrorMessage className="pt-2 ps-2">
                {props.errors.name?.message}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </Col>
          <Col md={6} lg={6}>
            <StyledLabel
              controlId="floatingName"
              label="Apellido paterno *"
              error={props.errors?.name ? 1 : 0}
            >
              <StyledInput
                name="floatingName"
                type="text"
                {...props.register("fathersLastname", {
                  required: "Campo obligatorio",
                })}
                placeholder="Apellido paterno *"
                defaultValue={props.personalData?.fathersLastname}
                onChange={props.handleForm}
              />
            </StyledLabel>
            {props.errors?.fathersLastname ? (
              <ErrorMessage className="pt-2 ps-2">
                {props.errors.fathersLastname?.message}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="mb-4 pb-2">
          <Col md={6} lg={6}>
            <StyledLabel
              controlId="floatingName"
              label="Apellido materno *"
              error={props.errors?.name ? 1 : 0}
            >
              <StyledInput
                name="floatingName"
                type="text"
                {...props.register("mothersLastname", {
                  required: "Campo obligatorio",
                })}
                placeholder="Apellido materno *"
                defaultValue={props.personalData?.mothersLastname}
                onChange={props.handleForm}
              />
            </StyledLabel>
            {props.errors?.mothersLastname ? (
              <ErrorMessage className="pt-2 ps-2">
                {props.errors.mothersLastname?.message}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <RegularP>Fecha de nacimiento</RegularP>
        <Birthday>
          <Col xs={4} style={{ paddingLeft: "0px" }}>
            <Form.Label>Día</Form.Label>
            <div className="border-div">
              <Form.Select
                {...props.register("day", {
                  required: true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                defaultValue={
                  props.orderToPatient
                    ? "DD"
                    : props.state.birthday && props.state.birthday !== ""
                    ? props.state.birthday.substring(0, 2)
                    : personalData
                    ? personalData.fnacimiento.substring(8, 10)
                    : "DD"
                }
                onChange={props.handleChange}
              >
                <option disabled hidden>
                  DD
                </option>
                {days.map((day, index) => (
                  <option value={day} key={index}>
                    {day}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
          <Col xs={4} style={{ paddingLeft: "0px" }}>
            <Form.Label>Mes</Form.Label>
            <div className="border-div">
              <Form.Select
                {...props.register("month", {
                  required: true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                defaultValue={
                  props.orderToPatient
                    ? "MM"
                    : props.state.birthday && props.state.birthday !== ""
                    ? props.state.birthday.substring(3, 5)
                    : personalData
                    ? personalData.fnacimiento.substring(5, 7)
                    : "MM"
                }
                onChange={props.handleChange}
              >
                <option disabled hidden>
                  MM
                </option>
                {months.map((month, index) => (
                  <option value={month} key={index}>
                    {month}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
          <Col xs={4} style={{ paddingRight: "0px" }}>
            <Form.Label>Año</Form.Label>
            <div className="border-div">
              <Form.Select
                className="form-control"
                {...props.register("year", {
                  required: true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                defaultValue={
                  props.orderToPatient
                    ? "AAAA"
                    : props.state.birthday && props.state.birthday !== ""
                    ? props.state.birthday.substring(6, 11)
                    : personalData
                    ? personalData.fnacimiento.substring(0, 4)
                    : "AAAA"
                }
                onChange={props.handleChange}
              >
                <option disabled hidden>
                  AAAA
                </option>
                {years.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Birthday>
        <br />

        {!props.items.some((e) => e.id === 675 || e.id === 966) && (
          <>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingCp"
                  label="Código postal *"
                  error={props.errors?.cp ? 1 : 0}
                >
                  <StyledInput
                    name="floatingCp"
                    type="number"
                    {...props.register("cp", {
                      required: "Campo obligatorio",
                    })}
                    placeholder="Código postal *"
                    value={props.state.cp}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                  />
                </StyledLabel>
                {props.errors?.cp ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.cp?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingState"
                  label="Estado *"
                  error={props.errors?.state ? 1 : 0}
                >
                  <StyledSelect
                    name="floatingState"
                    {...props.register("state", {
                      required: "Selecciona un estado",
                    })}
                    value={props.state.state}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                    disabled={cpState}
                  >
                    <option value={""}>Estado</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledLabel>
                {props.errors?.state ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.state?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2 p-0"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingCity"
                  label="Ciudad *"
                  disabled={cpState}
                  error={props.errors?.city ? 1 : 0}
                >
                  <StyledInput
                    name="floatingCity"
                    type="text"
                    {...props.register("city", {
                      required: "Campo obligatorio",
                    })}
                    placeholder="Ciudad *"
                    defaultValue={""}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                    disabled={cpState}
                  />
                </StyledLabel>
                {props.errors?.city ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.city?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingColonia"
                  label="Colonia *"
                  disabled={cpState}
                  error={props.errors?.colonia ? 1 : 0}
                >
                  <StyledInput
                    name="floatingColonia"
                    type="text"
                    {...props.register("colonia", {
                      required: "Campo obligatorio",
                    })}
                    placeholder="Colonia *"
                    defaultValue={""}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                    disabled={cpState}
                  />
                </StyledLabel>
                {props.errors?.colonia ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.colonia?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingStreet"
                  label="Calle *"
                  error={props.errors?.street ? 1 : 0}
                >
                  <StyledInput
                    name="floatingStreet"
                    type="text"
                    {...props.register("street", {
                      required: "Campo obligatorio",
                    })}
                    placeholder="Calle *"
                    defaultValue={""}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                  />
                </StyledLabel>
                {props.errors?.street ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.street?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2 p-0"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingNext"
                  label="Número exterior *"
                  error={props.errors?.next ? 1 : 0}
                >
                  <StyledInput
                    name="floatingNext"
                    type="text"
                    {...props.register("next", {
                      required: "Campo obligatorio",
                    })}
                    placeholder="Número exterior *"
                    defaultValue={""}
                    onChange={(e) => {
                      props.handleForm(e);
                    }}
                  />
                </StyledLabel>
                {props.errors?.next ? (
                  <ErrorMessage className="pt-2 ps-2">
                    {props.errors.next?.message}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel
                  controlId="floatingNint"
                  label="Número interior (Opcional)"
                >
                  <StyledInput
                    name="floatingNint"
                    type="text"
                    {...props.register("nint")}
                    placeholder="Número interior (Opcional)"
                    defaultValue={""}
                    onChange={props.handleForm}
                  />
                </StyledLabel>
              </Col>
            </Row>
            <Row>
              <NormalText style={{ fontSize: "14px" }} className="mb-4 pb-2">
                ¿Entre qué calles está? (Opcional)
              </NormalText>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel controlId="floatingStreet2" label="Calle 1">
                  <StyledInput
                    name="floatingStreet2"
                    type="text"
                    {...props.register("street2")}
                    placeholder="Calle 1"
                    defaultValue={""}
                    onChange={props.handleForm}
                  />
                </StyledLabel>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2 p-0"}
                style={{ paddingLeft: "0" }}
              >
                <StyledLabel controlId="floatingStreet3" label="Calle 2">
                  <StyledInput
                    name="floatingStreet3"
                    type="text"
                    {...props.register("street3")}
                    placeholder="Calle 2"
                    defaultValue={""}
                    onChange={props.handleForm}
                  />
                </StyledLabel>
              </Col>
            </Row>
            <Row>
              <NormalText style={{ fontSize: "14px" }} className="mb-4 pb-2">
                Indicaciones adicionales
              </NormalText>
            </Row>
            <Row>
              <Col
                xs={12}
                className={width <= 1200 ? "p-0 mb-4 pb-2" : "mb-4 pb-2 p-0"}
                style={{ paddingLeft: "0" }}
              >
                <StyledInput
                  className="w-100"
                  as="textarea"
                  name="floatingIndications"
                  type="text"
                  {...props.register("indications")}
                  placeholder="Escribe las indicaciones aquí"
                  defaultValue={""}
                  onChange={props.handleForm}
                  style={{ height: "100px", padding: "0.6em 2em" }}
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mb-4">
          <StyledHr />
        </Row>
        {!props.orderToPatient && (
          <>
            <Row className="mb-4 pb-2">
              <SectionTitle>2. Datos de facturación</SectionTitle>
            </Row>
            <Row className="mb-4 pb-2">
              <NormalText className="m-0 p-0 mb-4">
                Necesito factura *
              </NormalText>
              <StyledCheck type="radio">
                <Form.Check.Input
                  type="radio"
                  name="needBilling"
                  {...props.register("needBilling")}
                  checked={needBilling === 1 ? true : false}
                  onChange={() => {
                    setNeedBilling(1);
                  }}
                  value={1}
                />
                <Form.Label>
                  <NormalText>Sí</NormalText>
                </Form.Label>
              </StyledCheck>
              <StyledHr className="mb-2" />
              <StyledCheck type="radio">
                <Form.Check.Input
                  type="radio"
                  name="needBilling"
                  {...props.register("needBilling")}
                  checked={needBilling === 0 ? true : false}
                  onChange={() => {
                    setNeedBilling(0);
                  }}
                  value={0}
                />
                <Form.Label>
                  <NormalText>No</NormalText>
                </Form.Label>
              </StyledCheck>
            </Row>
          </>
        )}
        {needBilling === 1 && (
          <>
            <Row className="mb-4">
              <StyledCheck
                type="checkbox"
                name="billing"
                label="Utilizar dirección de envío para facturación"
                value={billing}
                onChange={() => {
                  setBilling(!billing);
                  fillBilling();
                }}
              />
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingRfc"
                label="RFC *"
                error={props.errors?.billing?.rfc ? 1 : 0}
              >
                <StyledInput
                  name="floatingRfc"
                  type="text"
                  {...props.register("billing.rfc", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="RFC *"
                  defaultValue={""}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.rfc ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors?.billing?.rfc?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingName"
                label="Nombre/Razón social *"
                error={props.errors?.billing?.name ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingName"
                  type="text"
                  {...props.register("billing.name", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Nombre/Razón social *"
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.name ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors?.billing?.name?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingEmail"
                label="Correo electrónico *"
                error={props.errors?.billing?.email ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingEmail"
                  type="email"
                  {...props.register("billing.email", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Correo electrónico *"
                  defaultValue={""}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.email ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors?.billing?.email?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingRegimen"
                label="Regimen fiscal *"
                error={props.errors?.billing?.regimen ? 1 : 0}
              >
                <StyledSelect
                  name="floatingBillingRegimen"
                  {...props.register("billing.regimen", {
                    required: "Selecciona un regimen fiscal",
                  })}
                  onChange={(e) => {
                    props.handleForm(e);
                    if (e.target.value == "605") {
                      setcfdi([
                        {
                          id: 12,
                          clave: "D01",
                          nombre:
                            "Honorarios médicos, dentales y gastos hospitalarios.",
                          tipo: 1,
                        },
                        {
                          id: 13,
                          clave: "D02",
                          nombre:
                            "Gastos médicos por incapacidad o discapacidad.",
                          tipo: 1,
                        },
                        {
                          id: 14,
                          clave: "D03",
                          nombre: "Gastos funerales.",
                          tipo: 1,
                        },
                        {
                          id: 18,
                          clave: "D07",
                          nombre: "Primas por seguros de gastos médicos.",
                          tipo: 1,
                        },
                        {
                          id: 22,
                          clave: "S01",
                          nombre: "Sin efectos fiscales.",
                          tipo: 0,
                        },
                      ]);
                    } else if (e.target.value == "610") {
                      setcfdi([
                        {
                          id: 22,
                          clave: "S01",
                          nombre: "Sin efectos fiscales.",
                          tipo: 0,
                        },
                      ]);
                    } else {
                      setcfdi([
                        {
                          id: 3,
                          clave: "G03",
                          nombre: "Gastos en general.",
                          tipo: 0,
                        },
                        {
                          id: 12,
                          clave: "D01",
                          nombre:
                            "Honorarios médicos, dentales y gastos hospitalarios.",
                          tipo: 1,
                        },
                        {
                          id: 13,
                          clave: "D02",
                          nombre:
                            "Gastos médicos por incapacidad o discapacidad.",
                          tipo: 1,
                        },
                        {
                          id: 14,
                          clave: "D03",
                          nombre: "Gastos funerales.",
                          tipo: 1,
                        },
                        {
                          id: 18,
                          clave: "D07",
                          nombre: "Primas por seguros de gastos médicos.",
                          tipo: 1,
                        },
                        {
                          id: 22,
                          clave: "S01",
                          nombre: "Sin efectos fiscales.",
                          tipo: 0,
                        },
                      ]);
                    }
                  }}
                >
                  <option value={""}>Selecciona</option>
                  {regimen.map((reg, index) =>
                    persona === 1 ? (
                      reg.tipo === 1 || reg.tipo === 0 ? (
                        <option key={index} value={reg.clave}>
                          {reg.clave} {reg.nombre}
                        </option>
                      ) : (
                        <></>
                      )
                    ) : persona === 2 ? (
                      reg.tipo === 2 || reg.tipo === 0 ? (
                        <option key={index} value={reg.clave}>
                          {reg.clave} {reg.nombre}
                        </option>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )
                  )}
                </StyledSelect>
              </StyledLabel>
              {props.errors?.billing?.regimen ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors?.billing?.regimen?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingCp"
                label="Código postal *"
                error={props.errors?.billing?.cp ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingCp"
                  type="text"
                  {...props.register("billing.cp", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Código postal *"
                  value={props.state.billing?.cp}
                  onChange={(e) => {
                    props.handleForm(e);
                  }}
                />
              </StyledLabel>
              {props.errors?.billing?.cp ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.cp?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingState"
                label="Estado *"
                error={props.errors?.billing?.state ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingState"
                  type="text"
                  {...props.register("billing.state", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Estado *"
                  value={props.state.billing?.state}
                  onChange={(e) => {
                    props.handleForm(e);
                  }}
                />
              </StyledLabel>
              {props.errors?.billing?.state ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.state?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingCity"
                label="Ciudad *"
                error={props.errors?.billing?.city ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingCity"
                  type="text"
                  {...props.register("billing.city", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Ciudad *"
                  value={props.state.billing?.city}
                  onChange={(e) => {
                    {
                      props.handleForm(e);
                    }
                  }}
                />
              </StyledLabel>
              {props.errors?.billing?.city ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.city?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingColonia"
                label="Colonia *"
                error={props.errors?.billing?.colonia ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingColonia"
                  type="text"
                  {...props.register("billing.colonia", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Colonia *"
                  value={props.state.billing?.colonia}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.colonia ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.colonia?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingStreet"
                label="Calle *"
                error={props.errors?.billing?.street ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingStreet"
                  type="text"
                  {...props.register("billing.street", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="Calle *"
                  value={props.state.billing?.street}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.street ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.street?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingStreet"
                label="No.Exterior  *"
                error={props.errors?.billing?.street ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingNext"
                  type="text"
                  {...props.register("billing.next", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="No.Exterior *"
                  value={props.state.billing?.next}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.street ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.street?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingNext"
                label="No.Interior  *"
                error={props.errors?.billing?.street ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingNint"
                  type="text"
                  {...props.register("billing.nint", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="No.Interior *"
                  value={props.state.billing?.next}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.street ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.street?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingCountry"
                label="País  *"
                error={props.errors?.billing?.street ? 1 : 0}
              >
                <StyledInput
                  name="floatingBillingCountry"
                  type="text"
                  {...props.register("billing.country", {
                    required: "Campo obligatorio",
                  })}
                  placeholder="País *"
                  value={props.state.billing?.next}
                  onChange={props.handleForm}
                />
              </StyledLabel>
              {props.errors?.billing?.street ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.street?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledLabel
                controlId="floatingBillingUsoFactura"
                label="Uso de la factura *"
                error={props.errors?.billing?.usoFactura ? 1 : 0}
              >
                <StyledSelect
                  name="floatingUsoFactura"
                  {...props.register("billing.usoFactura", {
                    required: "Campo obligatorio",
                  })}
                  onChange={props.handleForm}
                >
                  <option value={""}>Selecciona</option>
                  {cfdi.map((c, index) =>
                    persona === 1 ? (
                      c.tipo === 1 || c.tipo === 0 ? (
                        <option key={index} value={c.clave}>
                          {c.clave} {c.nombre}
                        </option>
                      ) : (
                        <></>
                      )
                    ) : persona === 2 ? (
                      c.tipo === 2 || c.tipo === 0 ? (
                        <option key={index} value={c.clave}>
                          {c.clave} {c.nombre}
                        </option>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )
                  )}
                </StyledSelect>
              </StyledLabel>
              {props.errors?.billing?.usoFactura ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors?.billing?.usoFactura?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mb-4 pb-2">
              <StyledInput
                error={props.errors?.billing?.comments ? 1 : 0}
                name="floatingBillingComments"
                type="text"
                {...props.register("billing.comments", {
                  required: "Campo obligatorio",
                })}
                placeholder="Comentarios y observaciones *"
                defaultValue={""}
                onChange={props.handleForm}
              />
              {props.errors?.billing?.comments ? (
                <ErrorMessage className="pt-2 ps-2">
                  {props.errors.billing?.comments?.message}
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
          </>
        )}
        <Row className="mb-4 pb-2">
          <SectionTitle>
            {!props.orderToPatient ? "3." : "2."} Información de contacto
          </SectionTitle>
        </Row>
        <Row className="mb-4 pb-2">
          <Form.Label className="p-0">
            <NormalText style={{ fontSize: "12px" }}>Teléfono</NormalText>
          </Form.Label>
          <StyledPhoneInput
            className="w-100 m-0"
            type="tel"
            international
            defaultCountry="MX"
            placeholder="Teléfono celular"
            name="phone"
            id="phone"
            {...props.register("phone")}
            value={phone}
            onChange={setPhone}
          />
        </Row>
        <Row className="mb-4">
          <StyledLabel controlId="floatingEmail" label="Email">
            <StyledInput
              name="floatingEmail"
              type="email"
              {...props.register("email", {
                required: "Campo obligatorio",
              })}
              placeholder="Email"
              value={props.personalData.email}
              onChange={props.handleForm}
            />
          </StyledLabel>
        </Row>
        <Row className="mb-4">
          <StyledHr />
        </Row>
      </Col>
    </Row>
  );
};
