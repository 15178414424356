import React from 'react';
import styled from 'styled-components';

const ContactForm = ({ page }) => {

    const breaks = (<><br /><br /></>);

    const ContactInfo = (
        <p style={{ fontSize: '17px' }}>
            <strong>Horario de Atención a clientes.</strong>{ breaks }
            Lunes a Viernes: 9:00 am - 5:00 pm<br />
            Sábado 9:00 am - 12:00 pm<br />
            222 375 4152 ext. 105<br />
            55 6917 6455{ breaks }
            <strong>*Los domingos no tenemos servicio.</strong>{ breaks }
            <strong>Atención a clientes</strong><br />
            servicios_genéticos@labopat.mx{ breaks }
        </p>
    );

    const Header = styled.h1`
        font-family: 'Assistant', sans-serif;
        font-size: 70px;
        color: #00827E;
        font-weight: 300;
        margin-bottom: 40px;
        text-align: left;
        margin-left: 80px;
    `;

    const Input = styled.input`
        margin-bottom: 20px;
        border: 1px solid #00827E;
        border-radius: 3px;
        color: #00827E;
        font-size: 17px;
        font-family: 'Assistant', sans-serif;

        :hover,
        :focus,
        :active {
            outline:0px !important;
            -webkit-appearance:none;
            box-shadow: none !important;
            border: 1px solid #00827E;
        }

        ::placeholder {
            color: #00827E;
            font-size: 17px;
        }

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `;

    const TextArea = styled.textarea`
        width: 100%;
        margin-bottom: 20px;
        border-width: 1px;
        border-color: #00827E;
        border-radius: 3px;
        color: #00827E;
        font-size: 17px;
        font-family: 'Assistant', sans-serif;
        height: 100px;
        resize: none;
        padding: 8px;

        ::placeholder {
            color: #00827E;
            font-size: 17px;
        }

        :focus {
            outline: none;
        }
    `;

    const Button = styled.button`
        font-family: 'Assistant', sans-serif;
        width: 100px;
        border-radius: 40px;
        font-size: 18px;
        border: none;
        background-color: #FF7B00 !important;
        resize: none;
    `;

    const Section = styled.section`
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        // border: 1px solid red;
        width: 85%;
        margin: 50px auto 0 auto;
    `;

    return (
        <Section id="form">
            <Header id="contact-h1">Contáctanos</Header>
            
            <div className="container">
                <div className="row" id="text-form">
                    <div className="col-md-6 col-xl-4 text-start" id="text-contact" style={{ marginLeft: '80px' }}>
                        { ContactInfo }
                    </div>
                    <div className="col-md-6 col-xl-6">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="name"
                                        placeholder="Nombre"
                                        autoComplete="off"
                                        required={ true }
                                    />
                                </div>
                                <div className="col">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="lastname"
                                        placeholder="Apellido"
                                        autoComplete="off"
                                        required={ true }
                                    />
                                </div>
                            </div>
                        </form>
                        <form>
                            <div className="row">
                                <div className="col">
                                    <Input
                                        className="form-control"
                                        type="email"
                                        id="email"
                                        placeholder="Email"
                                        autoComplete="off"
                                        required={ true }
                                    />
                                </div>
                                <div className="col">
                                    <Input
                                        className="form-control"
                                        type="number"
                                        id="cellphone"
                                        placeholder="Teléfono"
                                        autoComplete="off"
                                        required={ true }
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col">
                                <TextArea
                                    id="message-form"
                                    placeholder="Escribe tu mensaje aquí"
                                    spellCheck={ false }
                                    required={ true }
                                ></TextArea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{ marginTop: '10px' }}>
                                <Button
                                    id="btn-contact"
                                    className="btn btn-primary"
                                    type="button"
                                >
                                    Enviar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default ContactForm;
