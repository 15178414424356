import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from '@firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { db, app } from '../firebase/firebase-config';
import { types } from '../types/types';
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { toastSweet } from '../helpers/sweetAlert2';

export const createUser = (
  uid,
  name,
  email,
  phone,
  photoURL = null,
  fathersLastname = '',
  mothersLastname = ''
) => {
  return (dispatch) => {
    const newUser = {
      name: name,
      fathersLastname: fathersLastname,
      mothersLastname: mothersLastname,
      email: email,
      phone: phone,
      newUser: true,
      photoURL: photoURL,
      profile: {
        type: 0,
      },
      created: Timestamp.fromDate(new Date()),
    };

    setDoc(doc(db, 'users', `${uid}`), newUser)
      .then((res) => {
        window.location.search.includes('from=checkout') ? window.location.replace('/mi-perfil?new=1?from=checkout') : window.location.replace('/mi-perfil?new=1');
      })
      .catch((e) => {
        alert('Error al guardar usuario');
        console.log('[User] ', e);
        // toast('error', 'Error al guardar usuario');
      });
  };
};

export const updateInfoUser = (data) => {
  return (dispatch, getState) => {
    const { uid } = getState().auth;
    const dbRef = doc(db, 'users', `${uid}`);

    updateDoc(dbRef, data)
      .then((res) => {
        toastSweet('success', 'Perfil correctamente actualizado');
        getUserInfo(uid).then((user) => {
          dispatch(userInfo(user));
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log('[Error] ', error);
        toastSweet('error', 'Hubo un error al guardar los datos :c');
      });
  };
};

export const updateUserPhotoURL = (url) => {
  return (dispatch, getState) => {
    const { uid } = getState().auth;
    const dbRef = doc(db, 'users', `${uid}`);

    updateDoc(dbRef, { photoURL: url })
      .then((res) => {
        // toast('success', 'Datos guardados exitosamente');
        console.log('[User] ', res);
        getUserInfo(uid).then((user) => {
          dispatch(userInfo(user));
        });
      })
      .catch((error) => {
        console.log('[Error] ', error);
        // toast('error', 'Error al guardar datos');
      });
  };
};

export const uploadFile = (file, path) => {
  return (dispatch) => {
    const storageRef = getStorage(app, 'gs://covid19-cc1fe.appspot.com/');
    const fileRef = ref(storageRef, path);

    uploadBytes(fileRef, file)
      .then((res) => {
        console.log(res);
        getDownloadURL(fileRef)
          .then((url) => {
            console.log(url);
            dispatch(updateUserPhotoURL(url));
          })
          .catch((error) => {
            console.log('Error en URL');
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const doesUserExists = async (userUid) => {
  const docRef = doc(db, 'users', userUid);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
};

export const doesUserExistsByPhone = async (phone) => {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('phone', '==', phone));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    users.push({ uid: doc.id, data: doc.data() });
  });
  return users.length > 0 ? users[0] : false;
};

export const doesUserExistsByEmail = async (email) => {
  const userRef = collection(db, 'users');
  const q = query(userRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  const users = [];
  querySnapshot.forEach((doc) => {
    console.log({ uid: doc.id, data: doc.data() });
    users.push({ uid: doc.id, data: doc.data() });
  });
  return users.length > 0 ? true : false;
};

export const getUserInfo = async (uid) => {
  const docRef = doc(db, 'users', uid);
  const user = await getDoc(docRef);
  return { uid: uid, data: user.data() };
};

export const cleanSession = () => ({
  type: types.cleanSession,
});

export const userInfo = (data) => ({
  type: types.userInfo,
  payload: data,
});
