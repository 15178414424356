import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

const ContactContainer = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FPortada%20Cuestionario%202.png?alt=media&token=039481b5-cd8f-48ed-9bda-9f48730b2984")
    center center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 5em;
`;

const TitleContainer = styled.div`
  background: #4528d0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 12vh;
  p {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    color: #fff;
    line-height: 120%;
    margin-left: auto;
    margin-top: revert;
    margin-right: auto;
    @media only screen and (max-width: 768px) {
      font-size: 21px;
    }
  }
`;

const RowInput = styled(Col)`
  width: 100%;
  text-align: left;

  > * {
    padding-left: 0px;
    padding-right: 0px;
  }

  > div:nth-child(1) {
    @media only screen and (min-width: 768px) {
      padding-right: 10px;
    }
  }

  > div:nth-child(2) {
    @media only screen and (min-width: 768px) {
      padding-left: 10px;
    }
  }
`;

const Label = styled.label`
  margin-top: 10px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
`;

const Input = styled.input`
  margin: 1rem 0;
`;

const ContinueButton = styled(Button)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  width: 241px;
  height: 40px;
  background: #1c3245;
  border: 2px solid transparent;
  border-radius: 10px !important;
  margin-left: auto;
  margin-right: auto;
  a {
    color: #fff;
  }
  :hover {
    border: 2px solid #1c3245;
    transition: all 0.4s ease;
    background: #1c3245;
  }
  :disabled {
    filter: grayscale();
    :hover {
      color: #353433;
      box-shadow: none;
    }
  }
`;

// Request example
// https://web-service-email.uc.r.appspot.com/contact?nombre=luis&apellido=hernandez&email=luisangelhdz19@gmail.com&telefono=22232931239&msg=hola

export const Contacto = (props) => {
  const [token, setToken] = useState("");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  // const [phone, setPhone] = useState();
  const [buttonEnable, setButtonEnable] = useState(true);
  // const studyData = props.data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setButtonEnable(true);
    const id = toast.loading("Enviando...");
    Object.assign(data, { token: token });
    console.log(data);
    axios
      .get(
        `https://web-service-email.uc.r.appspot.com/contacto-aliados?nombre=${data.name}&profesion=${data.profesion}&ciudad=${data.ciudad}&telefono=${data.phone}&email=${data.email}&msg=${data.message}&to=contacto@vitalgene.com.mx`
      )
      .then((res) => {
        setSubmit("Enviado con éxito");
        toast.dismiss(id);
        setTimeout(() => {
          toast.success("Mensaje enviado!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.href = "/invitacion"
          setTimeout(() => {
            // setShow(false);
            setButtonEnable(false);
          }, 2000);
        }, 200);
      })
      .catch((err) => {
        setError("Mensaje no enviado");
        console.log(err);
        toast.dismiss(id);
        setTimeout(() => {
          toast.error("Ha ocurrido un error :(", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            setButtonEnable(false);
          }, 2000);
        }, 200);
      });
  };

  var printChange = (response) => {
    setButtonEnable(false);
    setToken(response);
    console.log(response);
  };

  return (
    <div className="align-items-center">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer>
          <p>Regístrate y forma parte de nuestras Alianzas Vitales</p>
        </TitleContainer>
        <ContactContainer>
          <RowInput style={{ marginBottom: "10px" }} md={12} className="row">
            <Col sm={12} md={12}>
              <Label>Nombre completo*</Label>
              <Form.Control
                style={{ background: "#D9D9D9", borderRadius: "15px" }}
                placeholder={""}
                {...register("name", {
                  required: `${"Campo obligatorio"}`,
                })}
              />
              {errors.name ? <p>{errors.name.message}</p> : null}
            </Col>
          </RowInput>
          <RowInput style={{ marginBottom: "10px" }} md={12} className="row">
            <Col sm={12} md={12}>
              <Label>Profesión y especialidad*</Label>
              <Form.Control
                style={{ background: "#D9D9D9", borderRadius: "15px" }}
                placeholder={""}
                {...register("profesion", {
                  required: `${"Campo obligatorio"}`,
                })}
              />
              {errors.profesion ? <p>{errors.profesion.message}</p> : null}
            </Col>
          </RowInput>
          <RowInput style={{ marginBottom: "10px" }} md={12} className="row">
            <Col sm={12} md={12}>
              <Label>Ciudad y estado*</Label>
              <Form.Control
                style={{ background: "#D9D9D9", borderRadius: "15px" }}
                placeholder={""}
                {...register("ciudad", {
                  required: `${"Campo obligatorio"}`,
                })}
              />
              {errors.ciudad ? <p>{errors.ciudad.message}</p> : null}
            </Col>
          </RowInput>
          <RowInput style={{ marginBottom: "10px" }} md={6} className="row">
            <Col sm={3} md={3}>
              <Label>Número de contacto*</Label>
              <Form.Control
                style={{ background: "#D9D9D9", borderRadius: "15px" }}
                type="number"
                placeholder={""}
                {...register("phone", {
                  required: `${"Campo obligatorio"}`,
                })}
              />
              {errors.name ? <p>{errors.name.message}</p> : null}
            </Col>
            <Col sm={3} md={3}>
              <Label>Correo electrónico*</Label>
              <Form.Control
                style={{ background: "#D9D9D9", borderRadius: "15px" }}
                placeholder={""}
                {...register("email", {
                  required: `${"Campo obligatorio"}`,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                    message: "El correo no cumple con el formato",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </Col>
          </RowInput>
          <RowInput style={{ marginBottom: "10px" }} md={6} className="row">
            <Label>
              ¿Por qué estás interesado en ser parte de nuestras Alianzas
              Vitales?
            </Label>
            <Form.Control
              style={{
                background: "#D9D9D9",
                minHeight: "10em",
                borderRadius: "15px",
              }}
              as="textarea"
              type="text"
              className="form-control"
              placeholder={""}
              name="message"
              {...register("message", {
                required: `${"Campo obligatorio"}`,
              })}
            ></Form.Control>
            {errors.consulta && <p>{errors.consulta.message}</p>}
          </RowInput>
          <br />
          <ReCAPTCHA
            sitekey="6LdyCCAdAAAAAN-aYVFJArnyj1TE7bgi6uu0NQNT"
            render="explicit"
            onChange={printChange}
          />
          <br />
          <ContinueButton disabled={buttonEnable ? true : false} type="submit">
            Enviar mensaje
          </ContinueButton>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ContactContainer>
      </Form>
    </div>
  );
};
