import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import muestra from '../../assets/covid/muestra.png';
import tiempo from '../../assets/covid/tiempo.png';
import indicaciones from '../../assets/covid/indicaciones.png';
import ubicacion from '../../assets/covid/ubicacion.png';
import { AiFillHeart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UilTimes } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { toastSweet } from '../../helpers/sweetAlert2';

const CardContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 16px;
  width: 96%;
  max-width: 359px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 16px 19px 16px;
  .row .col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Tag = styled.div`
  width: 154px;
  height: 30px;
  background: #0a7a71;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  left: 50%;
  transform: translate(-50%);
`;

const Desc = styled.div`
  font-family: 'Assistant', sans-serif !important;
  height: 100px;
  overflow: scroll;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #474a4b;
`;

const Mini = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #474a4b;
`;

const Prices = styled(Row)`
  background: linear-gradient(180deg, #85b4d2 -35.38%, #8acca4 166.15%);
  border-radius: 5px;
  width: 100%;
  color: #fff;
  letter-spacing: -0.03em;
  margin: 8px auto 18px auto;
  padding-top: 9px;
  p {
    margin-bottom: 7px;
  }
  #linea {
    margin-top: 2px;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
  }
  #sucursal {
    margin-top: 2px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }
  #price-online {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    margin-left: -20px;
  }
  #price-sucursal {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    margin-left: -20px;
  }
`;

const Title = styled.h1`
  display: flex;
  height: 75px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #00ad90;
  cursor: pointer;
  span {
    font-weight: 800;
  }
  :hover {
    text-decoration: underline;
  }
`;

const Subtitle = styled.h2`
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
`;

const OrangeButton = styled.button`
  width: 100%;
  height: 48px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;

const WhiteButton = styled.button`
  width: 100%;
  height: 48px;
  background: none;
  color: #f9ad62;
  border: 2px solid #f9ad62;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 800;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffecd9;
  }
`;

const Detail = styled.div`
  max-height: 231px;
  overflow-y: auto;
  padding: 16px 18px;
  > h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #00ad90;
    margin-bottom: 6px;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #45494a;
    margin-bottom: 10px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const TestCard = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const [viewDetail, setViewDetail] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    const exists = items.filter((item) => item.id === props.data.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(props.data));
    }
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === props.data.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(props.data));
      history.push('/checkout');
    }
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para ` +
      props.data.title;
  };

  return (
    <CardContainer
      data-aos='fade-up'
      style={{
        boxShadow: props.highlight
          ? '0px 4px 4px rgba(0, 173, 144, 0.5)'
          : '0px 4px 4px #F6F6F6',
        height: viewDetail ? 'auto' : '303px',
      }}
    >
      {props.data.tag && (
        <Tag>
          <AiFillHeart />
          &nbsp;{props.data.tag}
        </Tag>
      )}

      <Title
        onClick={() => {
          history.push(`/estudio/${props.data.id}`);
        }}
      >
        <span>{props.data.title}</span>
      </Title>
      {/* <Desc dangerouslySetInnerHTML={{ __html: props.data.description }}></Desc> */}
      <Prices>
        <Col xs={6} style={{ paddingRight: '0px' }}>
          {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
            <p id='linea'>Pago en línea</p>
          ) : (
            <></>
          )}
          <p id='sucursal'>Pago en sucursal</p>
        </Col>
        <Col xs={6} style={{ paddingLeft: '0px' }}>
          {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
            <p id='price-online'>{currency(props.data.price)} MXN</p>
          ) : (
            <></>
          )}
          <p id='price-sucursal'>{currency(props.data.oldPrice)} MXN</p>
        </Col>
      </Prices>
      {console.log(props.data)}
      {props.data.categoria !== 10 && props.data.categoria !== 8 && props.data.ventaOnline !== 0 ? (
        <Row>
          <Col xs={6} style={{ padding: '0 5px 0 0' }}>
            <OrangeButton onClick={() => addStudy()}>
              Agregar estudio
            </OrangeButton>
          </Col>
          <Col xs={6} style={{ padding: '0 0 0 5px' }}>
            <WhiteButton onClick={() => payStudy()}>Pagar ahora</WhiteButton>
          </Col>
        </Row>
      ) : (
        <Row className='justify-content-center'>
          <Col xs={10} style={{ padding: '0 0 0 5px' }}>
            <OrangeButton onClick={() => callToSchedule()}>
              Llamar para agendar
            </OrangeButton>
          </Col>
        </Row>
      )}
      <p
        style={{
          color: '#C4C4C4',
          textAlign: 'center',
          marginBottom: '0px',
          cursor: 'pointer',
        }}
        onClick={() => setViewDetail(!viewDetail)}
      >
        <span style={{ textDecoration: 'underline' }}>Ver detalles</span>{' '}
        {viewDetail ? <UilAngleUp size='28' /> : <UilAngleDown size='28' />}
      </p>
      {viewDetail && (
        <Detail>
          {props.data.tEntrega && (
            <>
              <h4>Tiempo de entrega</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.tEntrega }}
              ></div>
            </>
          )}

          {props.data.muestra && (
            <>
              <h4>Tipo de muestra</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.muestra }}
              ></div>
            </>
          )}

          {props.data.indicaciones && (
            <>
              <h4>Indicaciones</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.indicaciones }}
              ></div>
            </>
          )}

          {props.data.info && (
            <>
              <h4>Información</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.info }}
              ></div>
            </>
          )}
        </Detail>
      )}
    </CardContainer>
  );
};
