import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Mask from '../../assets/kit/container.png';
import Crono from '../../assets/kit/cronos.gif';

const StyledHr = styled.hr`
  background-color: #4f90ba;
  height: 1px !important;
  margin: 0;
`;

const WelcomeText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #121619;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const QuestionText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #121619;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const InstructionsContainer = styled.div`
  background: #f6f6f6;
  border-radius: 20px;
`;

const InstructionsText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #121619;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const StartButton = styled.a`
  background: #77ccd5;
  border-radius: 102px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  :hover {
    color: #ffffff;
  }
`;

const CirclesContainer = styled.div`
  max-width: 260px;
  max-height: 260px;
  position: relative;
`;

const CronoContainer = styled.div`
  max-width: 260px;
  max-height: 260px;
  background-color: #f1f8f9;
  border-radius: 50%;
  background-image: url(${Crono});
  background-size: 110%;
  background-position: 130% 1em;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
  position: relative;
  position: absolute;
  top: 0;
`;

const PinkContainer = styled.div`
  position: absolute;
  max-width: 95%;
  max-height: 95%;
  background-color: #d44dad;
  border-radius: 50%;
  top: -0.5em;
  left: -0.5em;
`;

const BlueContainer = styled.div`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  background-color: #77ccd5;
  border-radius: 50%;
  bottom: -0.6em;
  right: -0.8em;
`;

export const Start = (props) => {
  return (
    <>
      <Container className='pb-4'>
        <Row className='mb-3'>
          <WelcomeText>Te damos la bienvenida a VITALGENE</WelcomeText>
        </Row>
        <Row className='mb-5'>
          <QuestionText>¿Listo para conocerte mejor?</QuestionText>
        </Row>
        <Row>
          <InstructionsContainer className='p-4'>
            <Row className='align-items-center'>
              <Col xs={12} sm={12} md={4} className='mb-4 p-0 pt-4 d-flex flex-column align-items-center'>
                <CirclesContainer data-aos='zoom-in' data-aos-duration='600'>
                  <img src={Mask} className='img-fluid p-0' />
                  <PinkContainer
                    data-aos='fade-up-left'
                    data-aos-duration='400'
                  >
                    <img src={Mask} className='img-fluid p-0' />
                  </PinkContainer>
                  <BlueContainer
                    className='img-fluid p-0'
                    data-aos='fade-down-right'
                    data-aos-duration='400'
                  >
                    <img src={Mask} className='img-fluid p-0' />
                  </BlueContainer>
                  <CronoContainer>
                    <img src={Mask} className='img-fluid p-0' />
                  </CronoContainer>
                </CirclesContainer>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={8}
                className='d-flex flex-column justify-content-center'
              >
                <Row>
                  <InstructionsText className='mb-4'>
                    Antes de realizar tu toma de saliva, recuerda que debes
                    haber pasado{' '}
                    <b>
                      30 minutos sin haber ingerido alimentos o bebidas, lavarte
                      los dientes, mascar chicle, utilizar enjuague bucal o
                      fumar.
                    </b>
                  </InstructionsText>
                </Row>
                <Row>
                  <StartButton
                    className='pt-2 pe-5 pb-2 ps-5 w-auto'
                    onClick={() => props.continue()}
                  >
                    Empezar
                  </StartButton>
                </Row>
              </Col>
            </Row>
          </InstructionsContainer>
        </Row>
      </Container>
    </>
  );
};
