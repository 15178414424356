import React from "react";
import { Vg70 } from "./vg70";
import { Banners } from './banners';
import { StudyVg70 } from "./StudyVg70";
import { Beneficios } from "./beneficios";
import { Target } from "./target";
import { Reporte } from "./reporte";
import { HorizontalScrollSection } from "./HorizontalScrollSection";
import { Carrousel }   from "./carrousel";
import Testimonials from "./testimonials";
import { Studies } from "./studies";
import { Doubts } from "../../../doubts/doubts";

export const SecVg = () => {
    return (
      <>
        <Vg70/>
        {/*<Vimeo color='ffbc45' video={'https://player.vimeo.com/video/851738680?h=e7c0323fe5'} responsive={true} autoplay loop muted/>*/}
        <Banners/>
        <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FVideo%201.png?alt=media&token=270c6830-69db-455c-a39f-27461aabba8f" alt="vg70" style={{width: '100%'}}/>
        <Target/>
        <Carrousel/>
        {/*<HorizontalScrollSection/>
        <Studies/>*/}
        <Testimonials/>
        {/*<Beneficios/>*/}
        <Reporte/>
        <Doubts/>
        {/*<StudyVg70/>*/}
      </>
    );
  };