import React from 'react';
// import { RegisterFormNuevo } from '../components/login/RegisterFormNuevo';
import { RegisterForm } from "../components/login/registerForm";

export const Register = () => {
    return (
        <RegisterForm />
        // <RegisterFormNuevo />
    );
}
