import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Row,
  Table,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { UilCheck, UilExclamationCircle, UilTimes } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { handleAddItemToCart } from '../../../actions/shoppingCart';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const VG70Box = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG70Box.png?alt=media&token=3b33240a-6380-4e85-9fdb-e369a995079d';
const VG45Box = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVG45Box.png?alt=media&token=d268cba9-b484-4452-a3fd-0679194c2655';

const CompairsonContainer = styled.div`
  background-color: #FFFFFF;
  padding-left: 7%;
  padding-right: 7%;
  border-radius: 20px;
  @media only screen and (max-width: 768px){
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const StudyTitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: ${props => props.type === 1 ? '#8355B7' : '#D44DAD'};
  text-align: center;
  margin: 0;
  @media only screen and (max-width: 768px){
    font-size: 19px;
  }
`;

const StudyCover = styled.img`
  max-width: 300px;
  margin-top: -32%;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StudyPrice = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  color: #515151;
  text-align: center;
  margin: 0;
  width: auto;
  @media only screen and (max-width: 768px){
    font-size: 20px;
  }
  @media only screen and (max-width: 576px){
    font-size: 18px;
  }
`;

const Currency = styled.span`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: #515151;
  margin: 0;
  width: auto;
  padding: 0;
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: auto;
  :not(caption)>*>*, th, td {
    border: none;
  }
  th {
    padding: 0;
    vertical-align: middle !important;
  }
  .feature {
    border-bottom: 1px solid #E2EAF2;
  }
  @media only screen and (max-width: 768px) {
    tr {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }
    td {
      width: 100%;
    }
    .smallTd {
      max-width: 75% !important;
    }
  }
`;

const StudyFeature = styled.div`
> p {
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #515151;
  margin: 0;
  width: auto;
  display: inline;
  .none {
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
}
`;

const PayButton = styled.button`
  width: auto;
  border: none;
  border-radius: 102px;
  padding: 10px 29.68%;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  background-color: ${props => props.type === 1 ? '#8355B7' : '#D44DAD'};
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: ${props => props.type === 1 ? '#5c48db' : '#C52288'};
    color: #FFFFFF;
  }
  @media only screen and (max-width: 992px) {
    padding: 10px 24%;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 18%;
  }
  @media only screen and (max-width: 576px) {
    font-size: 14px;
    padding: 10px 10px;
    max-width: 282px;
    width: 100%;
  }
`;

const AddToCart = styled.button`
  width: auto;
  border: 1px solid transparent;
  border-radius: 102px;
  padding: 10px 23%;
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: ${props => props.type === 1 ? '#8355B7' : '#D44DAD'};
  background-color: #FFFFFF;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: #FFFFFF;
    color: ${props => props.type === 1 ? '#5c48db' : '#C52288'};
    border: ${props => props.type === 1 ? '1px solid #5c48db' : '1px solid #C52288'};
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px;
    border: none !important;
  }
  @media only screen and (max-width: 576px) {
    font-size: 14px;
    max-width: 282px;
    width: 100%;
  }
`;

const StyledContainer = styled.div`
  padding: 0;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    margin-top: 6rem;
  }
  @media only screen and (max-width: 456px) {
    margin-top: 4.5rem;
  }
`;

const PopoverContainer = styled.div`
  width: auto;
  margin: 0;
  padding: 0;
  display: inline;
  @media only screen and (max-width: 992px) {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: -1.5em;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media only screen and (max-width: 768px) {
    right: -1.8em;
  }
`;

const StyledPopOver = styled(Popover)`
  max-width: 282px;
`;

const PopOverText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8D8D8D;
  margin: 0;
`;

const PopButton = styled.button`
  border: none;
  background: none;
`;

const PopList = styled.li`
  ::marker {
    color: #8D8D8D;
  }
`;

export const StudiesTable = (props) => {
  const {width} = useWindowDimensions();
  let hiddenLgTable = width <= 768;
  const Studies = useCallback(() => {
    return [
      {
        cover: VG45Box,
        shortName: 'Vitalgene 45',
        id: 519,
        position: 1
      },
      {
        cover: VG70Box,
        shortName: 'Vitalgene 70',
        id: 598,
        position: 2
      }
    ]
  }, []);

  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [studies, setStudies] = useState([]);
  

  const getStudyInfo = async (studyId) => {
    try {
      let request = axios.get('https://lpqsystem.com/get-study/' + studyId);
      let response = await request;
      setStudies((prev) => ([
        ...prev,
        {
          ...response.data,
          shortName: response.data.id === 519 ? 'Vitalgene 45' : 'Vitalgene 70',
          cover: response.data.id === 519 ? VG45Box : VG70Box,
          type: response.data.id === 519 ? 1 : 0,
        },
      ]));
    } catch (error) {
      console.log(error);
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const PayStudy = (study) => {
    const exists = items.filter((item) => item.id === study?.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(study));
      setTimeout(() => {
        history.push('/checkout');
        window.scrollTo(0, 0);
      }, 300)
    }
  };

  const addStudy = (study) => {
    dispatch(handleAddItemToCart(study));
  };

  const features = [
    {
      name: 'Análisis de ',
      bold: '45 variantes en 36 genes',
      belongs: 0
    },
    {
      name: 'Análisis de ',
      bold: '70 variantes en 62 genes',
      belongs: 1
    },
    {
      name: 'Metabolismo de nutrimentos',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Vitamina A, B12, C, D, E, ácido fólico, calcio y hierro.',
            },
          ],
        },
        {
          titleBold: 'Vitalgene 70 ',
          titleNormal: 'incluye: ',
          dotsText: [
            {
              content: 'Colina.',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Salud cardiometabólica',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Cafeína, cereales integrales, sodio, ácidos grasos omega 3 y omega 6, actividad física para salud cardiovascular.',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Control de peso y composición corporal',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Actividad física para pérdida de peso, balance de energía, proteína, grasa total, grasa saturada, grasa insaturada, grasa monoinsaturada',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Intolerancia alimentaria: ',
      bold: 'lactosa y gluten',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Lactosa y gluten.',
            },
          ],
        },
        {
          titleBold: 'Vitalgene 70 ',
          titleNormal: 'incluye: ',
          dotsText: [
            {
              content: 'Cafeína.',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Hábitos alimenticios',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Percepción por el sabor graso, preferencia por el azúcar, comer entre comidas.',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Ejercicio y salud física',
      pop: [
        {
          titleBold: 'Vitalgene 70 y Vitalgene 45 ',
          titleNormal: 'incluyen: ',
          dotsText: [
            {
              content: 'Motivación para realizar ejercicio, comportamiento ante el ejercicio, potencia y fuerza, resistencia, dolor, lesión del tendón de Aquiles.',
            },
          ],
        },
        {
          titleBold: 'Vitalgene 70 ',
          titleNormal: 'incluye: ',
          dotsText: [
            {
              content: 'Daño muscular, masa ósea.',
            },
          ],
        },
      ],
      belongs: 2
    },
    {
      name: 'Sesión de interpretación de resultados',
      belongs: 2
    },
    {
      name: 'Componentes adicionales: ',
      bold: 'colina, daño muscular, masa ósea, sensibilidad a la cafeína',
      belongs: 1
    },
    {
      name: 'Apartado de genetic insights',
      pop: [
        {
          titleBold: 'Vitalgene 70 ',
          titleNormal: 'incluye: ',
          dotsText: [
            {
              nameBold: 'Nutrientes: ',
              content: 'Magnesio, Zinc, Almidón, Vitamina E.',
            },
            {
              nameBold: 'Inflamación y capacidad antioxidante: ',
              content: 'Adiponectina, Interleucina 6, Superóxido dismutasa, Óxido nítrico.',
            },
            {
              nameBold: 'Hábitos alimenticios: ',
              content: 'Hambre',
            },
            {
              nameBold: 'Control de peso y composición corporal: ',
              content: 'Mantenimiento de pérdida de peso a largo plazo.',
            },
            {
              nameBold: 'Sueño y estilo de vida: ',
              content: 'Sensibilidad al alcohol, Duración de sueño corto.',
            },
            {
              nameBold: 'Salud cardiometabólica: ',
              content: 'Colesterol, HDL, LDL, Triglicéridos, Glucosa en ayunas, Insulina.',
            },
            {
              nameBold: 'Lesiones: ',
              content: 'Manguito rotador.',
            },
          ],
        },
      ],
      belongs: 1
    },
    {
      name: 'Resultados de 3-4 semanas',
      belongs: 2
    },
  ]

  useEffect(() => {
    Studies().map((item) => getStudyInfo(item.id));
  }, [Studies])

  return(
    <>
      {!hiddenLgTable ? (
        <StyledContainer>
          <CompairsonContainer className='pb-3'>
            <StyledTable>
              <thead>
                <tr>
                  <th></th>
                  {studies.sort((a, b) => a.id - b.id).map((item, index) => (
                    <th key={index}>
                      <Row className='justify-content-center p-0'>
                        <StudyCover src={item.id === 519 ? VG45Box : VG70Box} alt={`Imagen de ${item.name}`} className='img-fluid' />
                      </Row>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  {studies.sort((a, b) => a.id - b.id).map((item, index) => (
                    <th key={index}>
                      <StudyTitle type={item.type}>
                        {item.shortName}
                      </StudyTitle>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  {studies.sort((a, b) => a.id - b.id).map((item, index) => (
                    <th key={index}>
                      <StudyPrice>
                        {currency(item.normal_price)}
                        <Currency className='ps-1'>
                          MXN
                        </Currency>
                      </StudyPrice>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features.map((item, index) => (
                  <tr key={index}>
                    <th className={index === features.length - 1 ? 'name' : 'feature name'}>
                      <StudyFeature className='pt-2 pb-2 position-relative'>
                        <p className='w-auto'>
                          {item.name}
                          {item.bold && (
                            <b>
                              {item.bold}
                            </b>
                          )}
                        </p>
                        {item.pop && (
                          <PopoverContainer>
                            <OverlayTrigger
                              trigger={'focus'}
                              placement='top'
                              overlay={(
                                <StyledPopOver id={`pop-${index}`}>
                                  <StyledPopOver.Body>
                                    {item.pop.map((element, index) => (
                                      <div key={index}>
                                        <ul className='ps-3'>
                                          <PopOverText>
                                            <b>{element.titleBold}</b>
                                            {element.titleNormal}
                                          </PopOverText>
                                            {element.dotsText.map((dot, index) => (
                                              <PopList key={index}>
                                                <PopOverText>
                                                  {dot.nameBold && (
                                                    <b>
                                                      {dot.nameBold}
                                                    </b>
                                                    )}
                                                  {dot.content}
                                                </PopOverText>
                                              </PopList>
                                            ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </StyledPopOver.Body>
                                </StyledPopOver>
                              )}
                            >
                              <PopButton className='w-auto p-0' type='button'>
                                <UilExclamationCircle
                                  className='w-auto p-0 ps-2 position-relative'
                                  size={20}
                                  color={'#A6A6A6'}
                                />
                              </PopButton>
                            </OverlayTrigger>
                          </PopoverContainer>
                        )}
                      </StudyFeature>
                    </th>
                    {item.belongs === 0 || item.belongs === 2 ? (
                      <th className={index === features.length - 1 ? '' : 'feature'}>
                        <StudyFeature className='d-flex flex-row justify-content-center pt-2 pb-2'>
                          <UilCheck size={18} color='#515151' />
                        </StudyFeature>
                      </th>
                    ) : (
                      <th className={index === features.length - 1 ? '' : 'feature'}></th>
                    )}
                    {item.belongs === 1 || item.belongs === 2 ? (
                      <th className={index === features.length - 1 ? '' : 'feature'}>
                        <StudyFeature className='d-flex flex-row justify-content-center pt-2 pb-2'>
                          <UilCheck size={18} color='#515151' />
                        </StudyFeature>
                      </th>
                    ) : (
                      <th className={index === features.length - 1 ? '' : 'feature'}></th>
                    )}
                  </tr>
                ))}
                <tr>
                  <th></th>
                  {studies.sort((a, b) => a.id - b.id).map((item, index) => (
                    <th key={index} className='pt-2'>
                      <Row className='justify-content-center'>
                        <PayButton type={item.type} onClick={() => {
                          PayStudy({...item, price: item.normal_price, title: item.name});
                        }}>
                          Pagar ahora
                        </PayButton>
                      </Row>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  {studies.sort((a, b) => a.id - b.id).map((item, index) => (
                    <th key={index} className='pt-3'>
                      <Row className='justify-content-center'>
                        <AddToCart type={item.type} onClick={() => {
                          addStudy({...item, price: item.normal_price, title: item.name, oldPrice: item.normal_price});
                        }}>
                          Agregar al carrito
                        </AddToCart>
                      </Row>
                    </th>
                  ))}
                </tr>
              </tbody>
            </StyledTable>
          </CompairsonContainer>
        </StyledContainer>
      ) : (
        <>
        {studies.sort((a, b) => a.id - b.id).map((item, studyIndex) => (
          <StyledContainer style={studyIndex == 1 ? {paddingTop: '3.5rem'} : {}}>
            <CompairsonContainer className='pb-3'>
              <StyledTable>
                <thead>
                  <tr>
                    <td className='w-100 p-0'>
                      <Row className='justify-content-center p-0 w-100'>
                        <StudyCover src={item.id === 519 ? VG45Box : VG70Box} alt={`Imagen de ${item.name}`} className='img-fluid' />
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-100 p-0'>
                      <StudyTitle type={item.type}>
                        {item.shortName}
                      </StudyTitle>
                    </td>
                  </tr>
                  <tr>
                    <td className='w-100 p-0'>
                      <StudyPrice>
                        {currency(item.normal_price)}
                        <Currency className='ps-1'>
                          MXN
                        </Currency>
                      </StudyPrice>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {features.map((item, index) => (
                    <tr key={index} className={index === features.length - 1 ? '' : 'feature'}>
                      <th className='smallTd'>
                        <StudyFeature className='pt-2 pb-2 position-relative'>
                          <p className='w-auto'>
                            {item.name}
                            {item.bold && (
                              <b>
                                {item.bold}
                              </b>
                            )}
                            {item.pop && (
                              <PopoverContainer>
                                <OverlayTrigger
                                  trigger={'focus'}
                                  placement='top'
                                  overlay={(
                                    <StyledPopOver id={`pop-${index}`}>
                                      <StyledPopOver.Body>
                                        {item.pop.map((element, index) => (
                                          <div key={index}>
                                            <ul className='ps-3'>
                                              <PopOverText>
                                                <b>{element.titleBold}</b>
                                                {element.titleNormal}
                                              </PopOverText>
                                                {element.dotsText.map((dot, index) => (
                                                  <PopList key={index}>
                                                    <PopOverText>
                                                      {dot.nameBold && (
                                                        <b>
                                                          {dot.nameBold}
                                                        </b>
                                                        )}
                                                      {dot.content}
                                                    </PopOverText>
                                                  </PopList>
                                                ))}
                                            </ul>
                                          </div>
                                        ))}
                                      </StyledPopOver.Body>
                                    </StyledPopOver>
                                  )}
                                >
                                  <PopButton className='w-auto p-0' type='button'>
                                    <UilExclamationCircle
                                      className='w-auto p-0 ps-2 position-relative'
                                      size={20}
                                      color={'#A6A6A6'}
                                    />
                                  </PopButton>
                                </OverlayTrigger>
                              </PopoverContainer>
                            )}
                          </p>
                        </StudyFeature>
                      </th>
                      {item.belongs === studyIndex || item.belongs === 2 ? (
                        <th>
                          <StudyFeature className='d-flex flex-row justify-content-center pt-2 pb-2'>
                            <UilCheck size={18} color='#515151' />
                          </StudyFeature>
                        </th>
                      ) : (
                        <th>
                          <StudyFeature className='d-flex flex-row justify-content-center pt-2 pb-2'>
                            <UilTimes size={18} color='#FB5959' />
                          </StudyFeature>
                        </th>
                      )}
                    </tr>
                  ))}
                  <tr>
                    <th className='pt-3 w-100'>
                      <Row className='justify-content-center w-100'>
                        <PayButton type={item.type} onClick={() => {
                          PayStudy({...item, price: item.normal_price, title: item.name});
                        }}>
                          Pagar ahora
                        </PayButton>
                      </Row>
                    </th>
                  </tr>
                  <tr>
                    <th className='pt-3 w-100'>
                      <Row className='justify-content-center w-100'>
                        <AddToCart type={item.type} onClick={() => {
                          addStudy({...item, price: item.normal_price, title: item.name, oldPrice: item.normal_price});
                        }}>
                          Agregar al carrito
                        </AddToCart>
                      </Row>
                    </th>
                  </tr>
                </tbody>
              </StyledTable>
            </CompairsonContainer>
          </StyledContainer>
        ))}
        </>
      )}
    </>
  );
}