import React from "react";
import { Banner } from "./banner";
import { Doubts } from "../doubts/doubts";
import { Highlights } from "./highlights";
import { Studies } from "./studies";

export const Covid = () => {
  return (
    <div style={{background: "#F2F7F9"}}>
      <Banner/>
      <Highlights/>
      <Studies/>
      <Doubts/>
    </div>
  )
};