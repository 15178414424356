import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Row, Col, Modal, FloatingLabel, Form } from 'react-bootstrap';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';

const Title = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  color: #77ccd5;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  padding: 0;
`;

const StyledModal = styled(Modal)`
.modal-header {
  padding: 0;
  margin: 0 1rem;
  padding: 1rem 0;
}
.btn-close {
  max-width: 32px;
  max-height: 32px;
  width: 100%;
  height: 100%;
  background-color: #77ccd5;
  border-radius: 50%;
  padding: 8px;
  border: none;
  position: absolute;
  top: -1em;
  right: 3em;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
  @media only screen and (max-width: 768px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
`;

const StyledFloating = styled(FloatingLabel)`
  .form-select~label {
    transform-origin: -8em -5em !important;
    background-color: #ffffff;
    color: #A6A6A6;
    opacity: 1;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding: 0px 9px;
  }
  width: 100%;
  padding: 0;
  > label {
    width: auto;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    transform-origin: -8em -10em;
    border-radius: 4px;
    padding: 4px 9px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'Assistant', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 200ms;
  }
  :active,
  :focus-within {
    .form-select~label {
      padding: 0px 9px;
      background-color: #ffffff;
    }
    > label {
      background-color: #ffffff;
      margin: 5px 0 0 30px;
      padding: 5px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  width: 100%;
  background: #ffffff;
  border: ${(props) => (props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6')};
  border-radius: 12px;
  max-height: 45px;
  height: 100%;
  padding: 0px 40px 0px 40px !important;
  z-index: 1;
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) => props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
    border-style: dashed;
    color: ${(props) => (props.disabled === 1 ? '#a6a6a6;' : '#000000')};
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  ::placeholder {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
  border: ${(props) =>
    props.error === 1 ? '1px solid #FF0505' : '1px solid #E9E9E9'};
  border-radius: 12px;
  font-family: 'Assistant', sans-serif;
  max-height: 45px;
  padding: 0 0 0 40px !important;
  box-shadow: 0px 4px 4px #f6f6f6;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2377CCD5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
  :disabled {
    background-color: #f6f6f6;
    border: ${(props) =>
      props.error === 1 ? '1px solid #FF0505' : '1px solid #cccccc'};
    border-style: dashed;
    color: #b8b8b8;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  width: 328px;
  height: 44px;
  background: #ffffff;
  border-radius: 12px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px 11px;
  border: 1px solid #E6E6E6;
  .PhoneInputCountry {
    margin-right: 3%;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputInput {
    border: none;
    background-color: #ffffff;
    font-family: 'Assistant', sans-serif;
  }
`;

const SaveButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
`;

export const AddressModal = (props) => {
  const [solData, setSolData] = useState({
    name: props.solData?.nombre,
    street: props.solData?.calle,
    next: props.solData?.next,
    nint: props.solData?.nint,
    cp: props.solData?.cp,
    colonia: props.solData?.colonia,
    state: props.solData?.estado,
    city: props.solData?.ciudad,
    street2: props.solData?.enfermedad1,
    street3: props.solData?.enfermedad2,
    indications: props.solData?.enfermedad4
  });
  const { width } = useWindowDimensions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const states = [
    {
      name: 'Aguascalientes',
    },
    {
      name: 'Baja California',
    },
    {
      name: 'Baja California Sur',
    },
    {
      name: 'Campeche',
    },
    {
      name: 'Chiapas',
    },
    {
      name: 'Chihuahua',
    },
    {
      name: 'Coahuila',
    },
    {
      name: 'Colima',
    },
    {
      name: 'CDMX',
    },
    {
      name: 'Estado de México',
    },
    {
      name: 'Guanajuato',
    },
    {
      name: 'Guerrero',
    },
    {
      name: 'Hidalgo',
    },
    {
      name: 'Jalisco',
    },
    {
      name: 'Michoacán',
    },
    {
      name: 'Morelos',
    },
    {
      name: 'Nayarit',
    },
    {
      name: 'Nuevo León',
    },
    {
      name: 'Oaxaca',
    },
    {
      name: 'Puebla',
    },
    {
      name: 'Querétaro',
    },
    {
      name: 'Quintana Roo',
    },
    {
      name: 'San Luis Potosí',
    },
    {
      name: 'Sinaloa',
    },
    {
      name: 'Sonora',
    },
    {
      name: 'Tabasco',
    },
    {
      name: 'Tamaulipas',
    },
    {
      name: 'Tlaxcala',
    },
    {
      name: 'Veracruz',
    },
    {
      name: 'Yucatán',
    },
    {
      name: 'Zacatecas',
    },
  ];

  const handleForm = (e) => {
    const { name, value } = e.target;
    setSolData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSubmit = (data) => {
    props.handleAddressForm({
      data: data,
    })
  }

  useEffect(() => {
    setSolData(props.solData);
  }, [props.solData])

  return (
    <>
      <StyledModal
        size='lg'
        show={props.show}
        onHide={() => props.setShow(false)}
      >
        <StyledModal.Header className='position-relative' closeButton>
          <StyledModal.Title id='example-modal-sizes-title-lg'>
            <Title>Editar datos</Title>
          </StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body className='pt-4'>
          <Form className='w-100 p-0' onSubmit={handleSubmit(onSubmit)}>
            <Row className='w-100 mb-4'>
              <Subtitle>
                Nombre y apellidos de quién recibe
              </Subtitle>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2'>
              <StyledFloating
                controlId='name'
                label='Nombre y apellidos *'
              >
                <StyledInput
                  type='text'
                  name='name'
                  placeholder='Nombre y apellidos *'
                  error={errors?.name?.type === 'required' ? 1 : 0}
                  {...register('name', {
                    required: 'Campo obligatorio'
                  })}
                  value={solData?.name}
                  onChange={(e) => handleForm(e)}
                />
              </StyledFloating>
            </Row>
            <Row className='w-100 mb-4'>
              <Subtitle>
                Dirección de envío
              </Subtitle>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2'>
              <StyledFloating
                controlId='street'
                label='Calle *'
              >
                <StyledInput
                  type='text'
                  name='street'
                  placeholder='Calle *'
                  error={errors?.street?.type === 'required' ? 1 : 0}
                  {...register('street', {
                    required: 'Campo obligatorio'
                  })}
                  value={solData?.street}
                  onChange={(e) => handleForm(e)}
                />
              </StyledFloating>
            </Row>
            <Row className='w-100 mb-4 pt-2 pb-2'>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='next'
                  label='Número exterior *'
                >
                  <StyledInput 
                    type='text'
                    name='next' 
                    placeholder='Número exterior *'
                    error={errors?.next?.type === 'required' ? 1 : 0}
                    {...register('next', {
                      required: 'Campo obligatorio'
                    })}
                    value={solData?.next}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='nint'
                  label='Número interior (opcional)'
                >
                  <StyledInput
                    name='nint'
                    type='text'
                    placeholder='Número interior (opcional)'
                    {...register('nint')}
                    value={solData?.nint}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
            </Row>
            <Row className='w-100 mb-4 pt-2 pb-2'>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={`${width <= 1200 ? 'p-0 mb-4 pb-2' : 'ps-0 pb-2'}`}
              >
                <StyledFloating
                  controlId='cp'
                  label='Código postal *'
                >
                  <StyledInput
                    type='text'
                    name='cp'
                    placeholder='Código postal *'
                    error={errors?.cp?.type === 'required' ? 1 : 0}
                    {...register('cp', {
                      required: 'Campo obligatorio'
                    })}
                    value={solData?.cp}
                    onChange={(e) => {
                      handleForm(e);
                    }}
                  />
                </StyledFloating>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='colonia'
                  label='Colonia *'
                >
                  <StyledInput
                    name='colonia'
                    type='text'
                    placeholder='Colonia *'
                    error={errors?.colonia?.type === 'required' ? 1 : 0}
                    {...register('colonia', {
                      required: 'Campo obligatorio'
                    })}
                    value={solData?.colonia}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2'>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='state'
                  label='Estado *'
                >
                  <StyledSelect 
                    name='state'
                    error={errors?.state?.type === 'required' ? 1 : 0}
                    {...register('state', {
                      required: 'Campo obligatorio'
                    })}
                    onChange={(e) => handleForm(e)}
                  >
                    <option value={solData?.state} hidden selected>{solData?.state}</option>
                    {states.map((state, index) => (
                      <option key={index} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledFloating>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='city'
                  label='Ciudad *'
                >
                  <StyledInput
                    name='city'
                    type='text'
                    error={errors?.city?.type === 'required' ? 1 : 0}
                    placeholder='Ciudad *'
                    {...register('city', {
                      required: 'Campo obligatorio'
                    })}
                    value={solData?.city}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
            </Row>
            <Row className='w-100 pt-2 mb-3 pb-2'>
              <Subtitle>
                Entre calles (Opcional)
              </Subtitle>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2'>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 mb-4 pb-2' : 'mb-0 pb-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='street2'
                  label='Calle 1'
                >
                  <StyledInput
                    type='text'
                    name='street2'
                    placeholder='Calle 1'
                    {...register('street2')}
                    value={solData?.street2}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={6}
                className={width <= 1200 ? 'p-0 pt-2' : 'mb-0 p-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledFloating
                  controlId='street3'
                  label='Calle 2'
                >
                  <StyledInput
                    name='street3'
                    type='text'
                    placeholder='Calle 2'
                    {...register('street3')}
                    value={solData?.street3}
                    onChange={(e) => handleForm(e)}
                  />
                </StyledFloating>
              </Col>
            </Row>
            <Row className='w-100 pt-2 mb-3 pb-2'>
              <Subtitle>
                Indicaciones adicionales (Opcional)
              </Subtitle>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2'>
              <Col
                xs={12}
                className={width <= 1200 ? 'p-0' : 'mb-0 p-0'}
                style={{ paddingLeft: '0' }}
              >
                <StyledInput
                  style={{ height: '100px', maxHeight: '250px' }}
                  className='w-100'
                  as='textarea'
                  name='floatingIndications'
                  type='text'
                  placeholder='Escribe las indicaciones aquí'
                  {...register('indications')}
                  value={solData?.indications}
                  onChange={(e) => handleForm(e)}
                />
              </Col>
            </Row>
            <Row className='w-100 pt-2 mb-4 pb-2 justify-content-center'>
              <SaveButton type='submit' className='pt-2 pb-2'>
                Guardar cambios
              </SaveButton>
            </Row>
          </Form>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
};
