import { types } from '../types/types';

const API = 'https://labopat-5792e.appspot.com'; //'http://localhost:3010';

export const apiReducer = (state = API, action) => {
  switch (action.type) {
    case types.api:
      return state;

    default:
      return state;
  }
};
