import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import muestra from '../../assets/covid/muestra.png';
import tiempo from '../../assets/covid/tiempo.png';
import indicaciones from '../../assets/covid/indicaciones.png';
import ubicacion from '../../assets/covid/ubicacion.png';
import { AiFillHeart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UilTimes } from '@iconscout/react-unicons';
import { useDispatch } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import {
  UilAngleDown,
  UilAngleUp,
  UilPlus,
  UilCheck,
} from '@iconscout/react-unicons';

const CardContainer = styled.div`
  transition: all 0.5s ease-out;
  margin: auto;
  position: relative;
  background: #ffffff;
  box-sizing: border-box;
  width: 96%;
  padding: 5px 0 10px 0;
  max-width: 359px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0ecec;
  .row .col-3 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Prices = styled(Row)`
  width: 100%;
  letter-spacing: -0.03em;
  margin: 0px;
  padding-top: 9px;
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
  }
  #sucursal {
    color: #c4c4c4;
    padding-right: 0px;
  }
  #online {
    color: #474a4b;
    padding-right: 0px;
  }
  #add,
  #added {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-weight: 800;
    font-size: 16px;
  }
  #add-btn,
  #added-btn {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
`;

const Title = styled.h1`
  text-align: left;
  font-weight: 800;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #1AC7C7;
  /* cursor: pointer; */
  /* :hover {
    text-decoration: underline;
  } */
`;

export const StudySearch = (props) => {
  const history = useHistory();
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };
  const [existsItem, setExistsItem] = useState(
    props.studies.some((e) => e.id === props.data.id)
  );
  useEffect(() => {
    props.studies.some((e) => e.id === props.data.id)
      ? setExistsItem(true)
      : setExistsItem(false);
  }, [props.data]);

  return (
    <CardContainer>
      <Title
        // onClick={() => {
        //   window.location.href = `/estudio/${props.data.id}`;
        // }}
      >
        {props.data.title}
      </Title>
      <Prices>
        {/* <Col xs={4} id='sucursal'>
          <p>
            En sucursal
            <br />
            {currency(props.data.oldPrice)} MXN
          </p>
        </Col> */}
        <Col xs={4} id='online' className='p-0'>
          {props.data.categoria !== 10 && props.data.categoria !== 8 ? (
            <p>
              Precio
              <br />
              {currency(props.data.price)} MXN
            </p>
          ) : (
            <></>
          )}
        </Col>
        {existsItem ? (
          <Col xs={4} id='added' className='d-flex flex-column align-items-end p-0'>
            <div className='d-flex flex-column align-items-center'>
              <div id='added-btn' style={{ background: '#1AC7C7', maxWidth: '20px', maxHeight: '20px' }} className='d-flex flex-row justify-content-center align-items-center w-100'>
                <UilCheck color='#fff' size={18}/>
              </div>
              <p style={{ color: '#1AC7C7' }}>Agregado</p>
            </div>
          </Col>
        ) : props.data.categoria !== 10 && props.data.categoria !== 8 !== 8 && props.data.ventaOnline !== 0 ? (
          <Col
            xs={4}
            id='add'
            onClick={() => {
              props.addItem({ ...props.data });
              setExistsItem(true);
            }}
            style={{ cursor: 'pointer' }}
            className='d-flex flex-column align-items-end p-0'
          >
            <div className='d-flex flex-column align-items-center'>
              <div id='add-btn' style={{ background: '#D44DAD', maxWidth: '20px', maxHeight: '20px' }} className='d-flex flex-row justify-content-center align-items-center w-100'>
                <UilPlus color='#fff' size={18} className='w-auto' />
              </div>
              <p style={{ color: '#D44DAD' }}>Agregar</p>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Prices>
    </CardContainer>
  );
};
