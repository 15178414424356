import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import medicSheet from '../../assets/checkout/medicSheet.png';


const ModalHeader = styled(Modal.Header)`
    border-bottom: 0px;
    padding: 0;
    > .modal-title {
        font-family: Rounded Mplus 1c;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 33px;
        color: #000000;
    }
`;

const StyledModal = styled(Modal)`
    > #contact-modal .modal-content {
        border: none;
        border-radius: 12px;
        padding: 24px;
    }
    .modal-header .btn-close {
        filter: invert(0%);
        position: absolute;
        right: 20px;
        top: 20px;
        width: 16px;
        height: 16px;
        color: #ce7e82;
        padding: 0;
        margin: 0;
    }
    > div .h4 {
        margin-top: 24px;
    }
    > div img {
        margin-top: 24px;
        width: 100%;
    }
`;

const StyledDiv = styled.div`
  margin-top: 24px;
  background: #DBF1FF;
  padding: 16px 20px;
  border-radius: 12px;
  > p {
    text-align: left;
    margin-bottom: 0px;
    > a {
      color: #00AD90;
      text-decoration: underline;
    }
  }
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  > span {
    font-weight: 800;
  }
`;

export const MedicalSheetModal = (props) => {
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        props.show && setShow(true);
      }, [props.show]);

    return(
        <>
        <StyledModal id="contact-modal" show={show} onHide={()=>{setShow(false)}} centered>
            <ModalHeader closeButton>
                <Modal.Title>Así se ve una hoja médica</Modal.Title>
            </ModalHeader>
            <Modal.Body style={{padding: '0px'}}>
                <img src={medicSheet} alt='Hoja médica ejemplo'/>
                <StyledDiv>
                    <RegularP>
                    Después de verificar tu cuenta enviaremos la orden de estudios a tu correo y al de tu paciente.
                    </RegularP>
                </StyledDiv>
            </Modal.Body>
        </StyledModal>
        </>
    );
};
