import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { UilEditAlt, UilExclamationCircle } from '@iconscout/react-unicons';
import { useForm } from 'react-hook-form';
import { AddressModal } from './addressModal';
import { useSelector } from 'react-redux';
import KitExample from '../../assets/kit/ejemploKit.png';
import { SomeoneElseForm } from './someoneElse';
import { useCallback } from 'react';

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #121619;
  margin: 0;
  padding: 0;
`;

const RadiosContainer = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Subtitle = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
  text-align: left;
  margin: 0;
  padding: 0;
`;

const AddressContainer = styled.div`
  background: #f1f8f9;
  border-radius: 12px;
`;

const AddressField = styled.span`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #121619;
  margin: 0;
  padding: 0;
  width: auto;
  display: inline;
`;

const AddressText = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #121619;
  margin: 0;
  padding: 0;
  width: auto;
  display: inline;
`;

const NormalText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  padding: 0;
  width: auto;
`;

const KitExampleImage = styled.img`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
`;

const StyledCheck = styled(Form.Check)`
  .form-check-input[type='radio'] {
    border-color: #77ccd5;
  }
  .form-check-input:checked {
    background-color: #ffffff;
    border-color: #77ccd5;
  }
  .form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2377CCD5'/%3e%3c/svg%3e") !important;
  }
`;

const StyledInput = styled(Form.Control)`
  background: #ffffff;
  border-radius: 12px;
  border: ${(props) => (props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6')};
  ::placeholder {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;

const ErrorMessage = styled.p`
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #ff0505;
  padding: 0;
  margin: 0;
`;

const WarningText = styled.p`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #3B3B3B;
  margin: 0;
  padding: 0;
`;

const StyledPopOver = styled(Popover)`
  max-width: 290px;
`;

const PopOverText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #8D8D8D;
  margin: 0;
`;

const PopButton = styled.button`
  border: none;
  background: none;
`;

export const StepOne = (props) => {
  const user = useSelector((state) => state?.user?.data.data);
  const [kitCode, setKitCode] = useState('');
  const [canValidate, setCanValidate] = useState(false);
  const [solData, setSolData] = useState();

  const {
    register,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      kitCode: '',
    },
  });

  const focusKitCode = useCallback (() => {
    props.codeUsed && setFocus('kitCode', { shouldSelect: false });
  }, [props.codeUsed, setFocus]);

  const kitCodeHandler = (e) => {
    setKitCode(e.target.value.toUpperCase());
    props.setValidated(false);
    if(e.target.value.length >= 12) {
      setCanValidate(true);
    } else {
      setCanValidate(false);
    }
  }

  const onSubmit = (data, event) => {
    focusKitCode();
    props.handleStepOne({
      personalData: data,
    });
  };

  useEffect(() => {
    focusKitCode();
  }, [props.codeUsed, focusKitCode])

  useEffect(() => {
    setSolData(props.solData);
  }, [props.solData])

  return (
    <Container data-aos='slide-left' data-aos-duration='500'>
      <Row className='mb-4'>
        <Title>Escribe el código de tu kit:</Title>
      </Row>
      <Row>
        <Col xs={12} sm={10} md={10} lg={8} xl={6} className='p-0'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{maxWidth: '462px'}} className='mb-3'>
              <KitExampleImage 
                src={KitExample} 
                alt='Ejemplo de Kit'
                className='img-fluid p-0'
              />
            </Row>
            <Row className='mb-4'>
              <StyledInput
                name='kitCode'
                id='kitCode'
                className='pe-3 ps-3'
                type='text'
                placeholder='Escribe el código aquí'
                error={errors?.kitCode ? 1 : props.codeUsed ? 1 : 0}
                {...register('kitCode', {
                  required: true,
                })}
                value={kitCode}
                onChange={(e) => {
                  kitCodeHandler(e);
                }}
              />
              {errors?.kitCode?.type === 'required' ? (
                <ErrorMessage className='pt-2 pb-1 ps-2'>
                  Campo obligatorio
                </ErrorMessage>
              ) : (
                <></>
              )}
            </Row>
            {!props.validated && (
              <Row className='mb-4'>
                <ContinueButton
                  type='submit'
                  className='pt-2 pb-2'
                  disabled={props.isLoading || !canValidate ? true : false}
                >
                  {props.isLoading ? 'Cargando...' : 'Validar'}
                </ContinueButton>
              </Row>
            )}
          </Form>
            {props.validated && (
              <>
                <Row className='mb-3'>
                  <Subtitle>
                    Este kit es:
                  </Subtitle>
                </Row>
                <Row className='mb-4'>
                  <RadiosContainer
                    {...register('otraPersona')}
                  >
                    <StyledCheck
                      inline
                      label='Para mí'
                      name='otraPersona'
                      id='otraPersona1'
                      type='radio'
                      value={0}
                      checked={props.belongs === 0 ? true : false}
                      onChange={() => {
                        props.setBelongs(0);
                      }}
                    />
                    <Row>
                      <StyledCheck
                        className='w-auto me-0'
                        inline
                        label='Para otra persona'
                        name='otraPersona'
                        id='otraPersona2'
                        type='radio'
                        value={1}
                        checked={props.belongs === 1 ? true : false}
                        onChange={() => {
                          props.setBelongs(1);
                        }}
                      />
                      <OverlayTrigger
                        trigger={'focus'}
                        placement='top'
                        overlay={(
                        <StyledPopOver id='belongsAnother'>
                          <StyledPopOver.Body>
                            <PopOverText>
                              Selecciona esta opción si no es para ti el KIT{' '}
                              y deseas activarlo{' '}
                              <b>
                                para un menor de edad o alguien más.  
                              </b>
                            </PopOverText>
                          </StyledPopOver.Body>
                        </StyledPopOver>
                      )}
                      >
                        <PopButton className='w-auto p-0' type='button'>
                          <UilExclamationCircle
                            className='w-auto p-0 position-relative'
                            size={24}
                            color={'#121619'}
                          />
                        </PopButton>
                      </OverlayTrigger>
                    </Row>
                  </RadiosContainer>
                </Row>
                {props.belongs === 0 && (
                <div className='p-0 m-0' data-aos='flip-left' data-aos-duration='300'>
                  <Row className='mb-4'>
                    <Subtitle>Confirma que tus datos sean los correctos</Subtitle>
                  </Row>
                  <Row className='mb-3'>
                    <AddressContainer className='pt-3 ps-3 pb-3 pe-3'>
                      <Row className='mb-1'>
                        <AddressText>
                          <AddressField>Nombre: </AddressField>
                          {solData?.nombre}
                        </AddressText>
                      </Row>
                      <Row className='mb-1'>
                        <AddressText>
                          <AddressField>Fecha de nacimiento: </AddressField>
                          {solData?.fnac ? solData?.fnac : user?.birthday}
                        </AddressText>
                      </Row>
                      <Row className='mb-1'>
                        <AddressText>
                          <AddressField>Correo electrónico: </AddressField>
                          {solData?.emailContacto}
                        </AddressText>
                      </Row>
                      <Row className='mb-1'>
                        <AddressText>
                          <AddressField>Teléfono: </AddressField>
                          {solData?.telContacto}
                        </AddressText>
                      </Row>
                      <Row className='mb-2'>
                        <AddressText>
                          <AddressField>Dirección: </AddressField>
                          {solData?.calle ? solData?.calle+' ' : ''}
                          {solData?.next ? solData?.next+' ' : ''}
                          {solData?.nint ? solData?.nint+' ' : ''}
                          {solData?.colonia ? solData?.colonia+' ' : ''}
                          {solData?.ciudad ? solData?.ciudad+' ' : ''}
                          {solData?.estado ? solData?.estado+' ' : ''}
                        </AddressText>
                      </Row>
                      <Row className='mb-1'>
                        <WarningText className='w-auto'>
                          *Utilizaremos esta dirección para la guía de paquetería
                          <OverlayTrigger
                            trigger={'focus'}
                            placement='top'
                            overlay={(
                            <StyledPopOver id='addressInfo'>
                              <StyledPopOver.Body>
                                <PopOverText>
                                Usaremos esta dirección como remitente para el envío{' '}
                                de tu kit de vuelta al laboratorio.{' '}
                                <b>
                                  Por favor revisa que sea correcta.  
                                </b>
                                </PopOverText>
                              </StyledPopOver.Body>
                            </StyledPopOver>
                            )}
                          >
                            <PopButton className='w-auto ps-2' type='button'>
                              <UilExclamationCircle
                                className='w-auto position-relative'
                                size={24}
                                color={'#3B3B3B'}
                              />
                            </PopButton>
                          </OverlayTrigger>
                        </WarningText>
                      </Row>
                    </AddressContainer>
                  </Row>
                  <Row className='mb-4'>
                  <button
                    className='d-flex row p-0 m-0 w-auto border-0'
                    onClick={() => props.setShowAddressModal(true)}
                    type='button'
                    style={{background: 'none'}}
                  >
                    <UilEditAlt size={20} color='#77CCD5' className='w-auto ps-0' />
                    <NormalText>Editar dirección</NormalText>
                  </button>
                </Row>
                </div>
                )}
                {props.belongs === 1 && (
                  <div className='p-0 m-0' data-aos='flip-right' data-aos-duration='300'>
                    <Row className='mb-4 pb-2'>
                      <Subtitle>Completa la siguiente información</Subtitle>
                    </Row>
                    <SomeoneElseForm solData={solData} handleAddressForm={props.handleAddressForm} isLoading={props.isLoading} />
                  </div>
                )}
                {props.belongs === 0 && (
                  <Row className='mb-5'>
                    <ContinueButton
                      type='button'
                      className='pt-2 pb-2'
                      disabled={props.isLoading ? true : false}
                      onClick={() => {
                        props.nextStep();
                      }}
                    >
                      {props.isLoading ? 'Cargando...' : 'Continuar'}
                    </ContinueButton>
                </Row>
                )}
              </>
            )}
        </Col>
      </Row>
      {props.showAddressModal && (
        <AddressModal
          show={props.showAddressModal}
          setShow={props.setShowAddressModal}
          handleAddressForm={props.handleAddressForm}
          solData={solData}
        />
      )}
    </Container>
  );
};
