import {
  addCartDB,
  getCartDB,
  updateCartDB,
  getStudyInfo,
} from '../helpers/api';
import { toastSweet } from '../helpers/sweetAlert2';
import { types } from '../types/types';

export const addItemToCart = (items) => ({
  type: types.addItemToCart,
  payload: items,
});

export const removeItemFromCart = (items) => ({
  type: types.removeItemFromCart,
  payload: items,
});

export const setTotalPrice = (data) => ({
  type: types.totalPrice,
  payload: data,
});

export const resetCart = () => ({
  type: types.resetCart,
});

export const addPersonalData = (data) => ({
  type: types.addPersonalData,
  payload: data,
});

export const getTotalPrice = (newCart) => {
  let newPrice = 0;
  newCart.forEach((item) => {
    newPrice += item.price;
  });
  return newPrice;
};

export const checkCart = () => {
  return (dispatch) => {
    const cartId = localStorage.getItem('cartId');
    if (cartId) {
      dispatch(getCartDB(cartId));
      setTimeout(() => {
        dispatch(checkForPricesUpdates());
      }, 1000);
    } else {
      dispatch(addCartDB());
    }
  };
};

export const checkForPricesUpdates = () => {
  return (dispatch, getState) => {
    const { items } = getState().shoppingCart;
    let newCart = [];

    items.forEach((item) => {
      newCart.push(
        getStudyInfo(item.id)
          .then(({ data }) => {
            return {
              ...item,
              oldPrice: parseInt(data.normal_price),
              price: item.howMany * parseInt(data.normal_price),
            };
          })
          .catch((error) => {
            console.log('Error: ', error);
          })
      );
    });

    Promise.all(newCart).then((values) => {
      // Update cart Redux
      dispatch(addItemToCart(values));
      // Update cart in DB
      dispatch(updateCartDB(values, getTotalPrice(values)));
    });
  };
};

export const handleAddItemToCart = (element) => {
  return (dispatch, getState) => {
    const { items } = getState().shoppingCart;

    let repeated = 1;
    let newItems = [];

    items.forEach((item) => {
      delete item.description;
      // If it's repeated then count how many times it appears
      if (item.id === element.id) {
        repeated = item.howMany + 1;
      } else {
        // If not then just add it to a new array
        newItems.push(item);
      }
    });

    // Create new element
    let newElement;
    if(repeated === 1) {
      newElement = {
        ...element,
        howMany: repeated,
        // If it's not repeated then it'll be multiplied by 1
        price: element.price * repeated,
      }
    } else if (repeated > 1) {
      newElement = {
        ...element,
        howMany: repeated,
        price: element.price * repeated / (repeated - 1),
      }
    }
    // Add new element to a new array
    newItems.push(newElement);
    const currentPrice = getTotalPrice(newItems);

    try {
      // Update cart
      dispatch(addItemToCart(newItems));
      // Update total price
      dispatch(setTotalPrice(currentPrice));
      // Update cart in DB
      dispatch(updateCartDB(newItems, currentPrice));
      // Message success
      toastSweet('success', '¡Artículo añadido!');
    } catch (error) {
      // Message error
      toastSweet('error', 'Hubo un error al tratar de añadir al carrido.');
    }
  };
};
