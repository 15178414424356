import { Button, Container, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { startRegisterWithEmailPasswordName } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';

export const RegisterPersonalData = () => {
  const containerStyle = {
    // border: '1px solid red',
    margin: '20px auto 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  };

  const text1Style = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '35px',
    lineHeight: '36px',
    color: '#000000',
  };

  const textContent0Style = {
    // padding: '0 10% 0 10%',
    width: '100%',
  };

  const itemListStyle = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#000000',
  };

  const buttonContinueStyle = {
    width: '328px',
    height: '40px',
    background: '#CECECE',
    borderRadius: '102px',
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#343434',
    border: 'none',
    margin: '20px 0 0 0',
  };

  const styleForm = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  };

  const styleInput = {
    width: '400px',
  };

  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.recaptcha);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const handleEmailAndPasswordRegister = () => {
    console.table([name, email, password, password2]);
    dispatch(startRegisterWithEmailPasswordName(email, password, name, phone));
  };

  return (
    <Container style={containerStyle} className='min-vh-100'>
      <Row style={textContent0Style}>
        <p style={text1Style}>Datos de registro</p>
      </Row>
      <Row style={textContent0Style}>
        <Form style={styleForm}>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Control
              type='text'
              placeholder='Nombre completo'
              value={name}
              style={styleInput}
              onChange={({ target }) => setName(target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='email'>
            <Form.Control
              type='email'
              placeholder='Dirección de correo electrónico'
              value={email}
              style={styleInput}
              onChange={({ target }) => setEmail(target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='password'>
            <Form.Control
              type='password'
              placeholder='Contraseña'
              value={password}
              style={styleInput}
              onChange={({ target }) => setPassword(target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='password2'>
            <Form.Control
              type='password'
              placeholder='Repetir contraseña'
              value={password2}
              style={styleInput}
              onChange={({ target }) => setPassword2(target.value)}
            />
          </Form.Group>
        </Form>
      </Row>
      <Button
        style={buttonContinueStyle}
        onClick={handleEmailAndPasswordRegister}
      >
        Completar registro
      </Button>
    </Container>
  );
};
