import React from "react";
import styled from "styled-components";
import { Modal, ModalBody } from "react-bootstrap";
import { Recomen } from "./recomen";
import { Desglose } from "./desglose";
import { Result } from "./result";

const Results =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FVariantes%20encontradas.png?alt=media&token=c7ffb0a5-9559-44e7-8ea0-f0ef8c529980";
const Alterations =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FResultado.png?alt=media&token=39451b9b-ad4d-4991-abf2-a6181ba5db9f";
const Biomarkers =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/gluten%2FImplicaciones%20cli%CC%81nicas.png?alt=media&token=c4d22266-5d7a-413e-a1d1-cfc599086891";
const Therapies =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fp4mov.png?alt=media&token=770844dc-9f42-4bc6-9af3-d5f650321ff0";

const DocumentImg = styled.img``;

const StyledModal = styled(Modal)`
  .large {
    max-width: 960px;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      max-width: 90%;
    }
    @media only screen and (max-width: 992px) {
      max-width: 100%;
      margin: 0;
    }
  }
  .content {
    border-radius: 40px;
    background-color: #f9f9f9;
    border: none;
    overflow-x: hidden;
    @media only screen and (max-width: 768px) {
        margin-top: 300px;
      overflow-x: scroll;
      border-radius: 40px 40px 40px 40px !important;
      ::-webkit-scrollbar {
        background-color: #f9f9f9 !important;
        height: 0px !important;
        -webkit-overflow-scrolling: auto !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #f9f9f9 !important;
        border-radius: 0 0 40px 40px !important;
      }
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0;
  @media only screen and (max-width: 768px) {
    margin-left:150px;
  }
`;

export const Modalmov = (props) => {
  return (
    <>
      {props.modalSelected === 1 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="results-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Results}
              style={{width:"520px"}}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 2 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="alterations-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Alterations}
              show={props.show}
              style={{width:"520px"}}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 3 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="biomarkers-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Biomarkers}
              style={{width:"520px"}}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
      {props.modalSelected === 4 && (
        <StyledModal
          show={props.show}
          onHide={() => props.setShowModal(!props.show)}
          aria-labelledby="therapies-modal"
          dialogClassName="large"
          backdropClassName="background"
          contentClassName="content"
        >
          <StyledModalBody>
            <DocumentImg
              src={Therapies}
              show={props.show}
              setShowModal={props.setShowModal}
            />
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
