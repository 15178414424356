import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const MenuContainer = styled.div`
  background-color: white;
  box-shadow: 0px 4px 15px rgba(24, 71, 101, 0.39);
`;

const Title = styled.span`
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #3B3B3B;
  transition: 300ms;
  :hover {
    color: ${props => props.color};
    transition: 300ms;
  }
`;

const ItemDiv = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0px;
  padding-right: 0px;
`;

const StyledCol = styled(Col)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  visibility: ${props => props.show === 1 ? 'hidden' : 'visible'};
  transform: ${props => props.showmenu === 1 ? 'translate(0)' : 'translate(0, -125px)'};
  z-index: -1;
  transition: all 300ms;
  transition-delay: 50ms;
  @media only screen and (max-width: 1200px) {
    transform: ${props => props.showmenu === 1 ? 'translate(0) !important' : 'translate(0, -145px) !important'};
  }
`;

export const DesktopMenu = (props) => {
  const [visible, setVisible] = useState(false);
  document.addEventListener('click', function (event) {
    if (event.target.id !== 'botonMenu' && event.target.id !== 'myMenu') {
      props.onHide();
    }
  });

  useEffect(() => {
    props.showMenu ? setVisible(true) : setTimeout(() => {
      setVisible(false);
    }, 250);
  }, [props.showMenu])

  return (
      <StyledCol md={12} showmenu={props.showMenu ? 1 : 0} show={!visible ? 1 : 0}>
        <MenuContainer id='myMenu'>
          <Row className='flex justify-content-around'>
            {props.menu.submenu.map((item, index) => (
              <ItemDiv key={index} className='w-auto'>
                <a href={item.link}>
                  <Row>
                    <Col md={2} className='text-center my-auto p-0'>
                      <img
                        src={item.icon}
                        width={item.width}
                        className='img-fluid'
                        alt='category-icon'
                      />
                    </Col>
                    <Col md={10} className='d-flex flex-column justify-content-center p-0 ps-2'>
                      <Title color={item.color}>{item.nameA}<br/>{item.nameB}</Title>
                    </Col>
                  </Row>
                </a>
              </ItemDiv>
            ))}
          </Row>
        </MenuContainer>
      </StyledCol>
  );
};
