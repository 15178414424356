import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../actions/auth';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { Drawer } from 'antd';
import Logo from '../../assets/navbar/logo.svg';
import {
  UilBars,
  UilMultiply,
  UilSignout,
  UilWhatsapp,
} from '@iconscout/react-unicons';
import { Sections } from './sections';

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 62;
  box-shadow: ${(props) =>
    props.scrolled === 1 ? 'rgb(10 10 10 / 5%) 0px 4px 8px' : 'none'};
  transition: 300ms;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const NormalText = styled.p`
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #697077;
  margin: 0;
`;

const StyledNav = styled.nav`
min-height: 65vh;
  a {
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: #878d96;
    :hover {
      color: #121619;
      .icon {
        filter: none;
      }
    }
  }
  .icon {
    filter: grayscale();
    padding-left: 0;
  }
  .active {
    color: #121619 !important;
    .icon {
      filter: none;
    }
  }
`;

const StyledHr = styled.hr`
  background-color: #4f90ba4d;
  padding: 0.5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background-color: #ffffff;
  border-radius: 12px;
`;

const HelpButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  a {
    color: #3b3b3b;
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0;
  }
`;

const LogOutBtn = styled.a`
  font-family: 'Assistant', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #697077;
  > .row .logOutIcon {
    filter: grayscale();
    padding-left: 0;
  }
  :hover {
    color: #000000;
    .row .logOutIcon {
      filter: none;
    }
  }
`;

const MenuBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  padding: 0;
  background: #f2f7f9;
`;

const CloseDrawerBtn = styled.div`
  display: flex;
  width: auto;
  background-color: #e0ecec;
  border-radius: 50rem;
  padding: 10px;
`;

const ChatBg = styled.div`
  background-color: #26a30b;
  width: auto;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  padding: 8px;
`;

export const SideDrawer = () => {
  const user = useSelector((state) => state?.user?.data.data);
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const [scrollY, setScrollY] = useState(0);
  const scroll = useRef({});
  const isDoctor = user?.profile?.type === 1;
  scroll.current = scrollY;

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    const onScroll = () => {
      window.addEventListener('scroll', handleScroll);
    };
    onScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <NavContainer scrolled={scroll.current > 15 ? 1 : 0}>
        <div
          className='d-flex flex-row justify-content-between align-items-center w-100'
          style={{ padding: '15px 20px' }}
        >
          <Link
            to='/'
            onClick={() => setShowMenu(false)}
            className='d-flex flex-row justify-content-center'
            style={{ maxWidth: '156px', maxHeight: '29px' }}
          >
            <img src={Logo} alt='Logo Labopat' className='img-fluid' />
          </Link>
          <MenuBtn
            variant='link'
            size='sm'
            onClick={() => {
              setShowMenu(true);
            }}
            style={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
            }}
          >
            <UilBars size='25' color='#77CCD5' />
          </MenuBtn>
          <Drawer
            placement='right'
            closable={false}
            onClose={() => setShowMenu(false)}
            visible={showMenu}
            key='right'
            contentWrapperStyle={{
              width: '100%',
              transition:
                'transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1)',
            }}
            drawerStyle={{ background: '#F1F8F9' }}
          >
            <div
              className='d-flex flex-row justify-content-between align-items-center position-fixed w-100'
              style={{
                backgroundColor: '#F2F7F9',
                top: '0',
                left: '0',
                padding: '15px 20px',
                zIndex: 63,
              }}
            >
              <Link
                onClick={() => setShowMenu(false)}
                to='/'
                className='d-flex flex-row justify-content-center'
                style={{ maxWidth: '156px', maxHeight: '29px' }}
              >
                <img src={Logo} alt='Logo Labopat' className='img-fluid' />
              </Link>
              <CloseDrawerBtn onClick={() => setShowMenu(false)}>
                <UilMultiply size='24' color={'#77CCD5'} />
              </CloseDrawerBtn>
            </div>
            <Row className='align-items-center mt-5 pt-4'>
              <Col xs={9} sm={10} style={{ paddingLeft: '0' }}>
                <NormalText className='mb-2'>
                  {user.name} {user.fathersLastname}
                </NormalText>
              </Col>
            </Row>
            <StyledHr className='mt-4 mb-4' />
            <div className='d-flex flex-column justify-content-between position-relative'>
              <StyledNav className='pt-1'>
                {isDoctor ? (
                  <>
                  {Sections.docSections.map((section, index) => (
                  <div className='mt-4 mb-4' key={index}>
                    <NavLink
                      to={section.route}
                      onClick={() => setShowMenu(false)}
                    >
                      <Row className='align-items-center'>
                        {section.icon}
                        {section.name}
                      </Row>
                    </NavLink>
                  </div>
                ))}
                </>
                ) : (
                  <>
                  {Sections.userSections.map((section, index) => (
                    <div className='mt-4 mb-4' key={index}>
                      <NavLink
                        to={section.route}
                        onClick={() => setShowMenu(false)}
                      >
                        <Row className='align-items-center'>
                          {section.icon}
                          {section.name}
                        </Row>
                      </NavLink>
                    </div>
                  ))}
                  </>
                )}
                
                <div className='mt-4 mb-4'>
                  <LogOutBtn
                    style={{ color: '#878D96' }}
                    onClick={() => {
                      setShowMenu(false);
                      dispatch(startLogout());
                    }}
                  >
                    <Row>
                      <UilSignout
                        size={24}
                        color={'#77CCD5'}
                        className='w-auto logOutIcon'
                      />
                      Cerrar sesión
                    </Row>
                  </LogOutBtn>
                </div>
              </StyledNav>
              <ButtonsContainer>
                <HelpButton>
                  <a
                    href='https://wa.me/525615077271'
                    target='_blank'
                    rel='noreferrer'
                    className='w-100 d-flex flex-row align-items-center'
                    onClick={() => setShowMenu(false)}
                  >
                    <ChatBg className='me-2'>
                      <UilWhatsapp size={28} color={'#ffffff'} />
                    </ChatBg>
                    Chat de Whatsapp
                  </a>
                </HelpButton>
              </ButtonsContainer>
            </div>
          </Drawer>
        </div>
      </NavContainer>
    </>
  );
};
