import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { UilPlayCircle } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toastSweet } from '../../helpers/sweetAlert2';
import { handleAddItemToCart } from '../../actions/shoppingCart';

const GrayBg = styled.div`
  padding-top: 58px;
  padding-bottom: 58px;
  background: linear-gradient(0deg, #00AD90 57%, rgba(224, 236, 236, 0.5) 50%);
  @media only screen and (min-width: 992px) {
    background: rgba(224, 236, 236, 0.5);
  }
  h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #25A78D;
    margin-bottom: 48px;
  }
  .row {
    position: relative;
  }
  .col-12 {
    z-index: 2;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 40px;
  }
  span {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
  }
  #text {
    p {
      box-sizing: border-box;
      display: block;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    p, span {
      width: 95%;
      max-width: 374px;
    }
  }
`;

const Title = styled.h1`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #24CAD3;
  padding-top: 41px;
  padding-bottom: 37px;
  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

const GreenBg = styled.div`
  z-index: 0;
  background: #00AD90;
  border-radius: 10px;
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  width: 70%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const BlueBg = styled.div`
  width: 100%;
  background: #24CAD3;
  padding-top: 51px;
  padding-bottom: 60px;
  p {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
`;

const OrangeButton = styled.button`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  width: 90%;
  height: 48px;
  background: #7945A3;
  border-radius: 24px;
  border: none;
  margin: 6px auto 10px;
  transition: all 0.4s ease;
  max-width: 468px;
  :hover {
    transition: all 0.4s ease;
    background: #9254c4;
    border: 2px solid #9254c4;
  }
`;

export const SuccessStories = ({study, setShowCart}) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      toastSweet('warning', 'Este estudio ya lo tienes agregado en tu solitud');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  return (
    <>
      <div style={{background: "rgba(36, 202, 211, 0.07)"}}>
      <Title>¡Transforma tu salud y enamórate del proceso!</Title>
      </div>
      {/*<BlueBg>
        <p>Inicio: 17 de Abril 2023</p>
        <div className='d-flex justify-content-center w-100'>
          <OrangeButton onClick={()=> addStudy()}>Reserva tu lugar hoy</OrangeButton>
        </div>
      </BlueBg>
      <GrayBg>
        <Container>
          <h2>Historias de éxito</h2>
          <Row>
            <Col xs={12} lg={5}>
              <div style={{width: "100%", height: "269px", background: "rgba(233, 233, 233, 1)", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "37px 0"}}>
                <UilPlayCircle color="#A6A6A6" size={55}/>
              </div>
            </Col>
            <Col xs={12} lg={7} id='text'>
              <p>“El programa Bienestar 360 me ayudó a mejorar mis hábitos alimenticios. Es un programa muy completo y estoy muy feliz por haberlo tomado.”</p>
              <span>-Juan Pérez Sánchez</span>
            </Col>
            <GreenBg/>
          </Row>
        </Container>
      </GrayBg> */}
    </>
  );
};
