import React from 'react';
import { Banner } from '../components/content/home/banner';
import { Course } from '../components/content/home/course';
import { FAQ } from '../components/content/home/faq';
import { HowItWorks } from '../components/content/home/howItWorks';
import { Studies } from '../components/content/home/studies';
import { Testimonials } from '../components/content/home/testimonials';
import { TestSlider } from '../components/content/home/testSlider';

export const Home = (props) => {
  return (
    <div style={{ background: '#F1F8F9' }}>
      <Banner setShowCart={(e)=>props.setShowCart(e)}/>
      <Studies />
      <TestSlider />
      <HowItWorks />
      <Testimonials />
      <FAQ />
      {/* <Course /> */}
    </div>
  );
};
