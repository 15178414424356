import React, { useEffect, useState } from "react";
import MexicoMap from "./MexicoMap.svg";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { UilLocationPinAlt } from "@iconscout/react-unicons";

const allowedStates = [
  {
    id: "state25",
    name: "Nuevo León",
    sedes: [
      {
        name: "LN. Emilio de la Garza",
        phone: "Tel. 81 13 96 69 61",
        address:
          "@nut.emiliodelagarza",
      },
    ],
    sucursales: [],
    mobileAdjust: "-85%, 0%",
  },
  {
    id: "state22",
    name: "Guadalajara",
    sedes: [
      {
        name: "Dr. Álvaro Santoscoy",
        phone: "Tel. 33 31 56 68 52 ",
        address:
          "",
      },
    ],
    sucursales: [],
    mobileAdjust: "-65%, -25%",
  },
  {
    id: "state23",
    name: "Veracruz",
    sedes: [
      {
        name: "LN. Mercedes Pérez",
        phone: "Tel. 229 254 8648",
        address:
          "@nutrimercedesps",
      },
    ],
    sucursales: [],
    mobileAdjust: "-100%, -30%",
  },
  {
    id: "state10",
    name: "Tijuana",
    sedes: [
      {
        name: "Dra. Andrea Guzmán",
        phone: "Tel. 664 689 0057 ",
        address:
          "@dra.andreaguzman_18",
      },
    ],
    sucursales: [],
    mobileAdjust: "-15%, 22%",
  },
  {
    id: "state26",
    name: "San Luis Potosi",
    sedes: [
      {
        name: "LNCA. Andrea Arreguín",
        phone: "Tel. 444 451 4504 ",
        address:
          "",
      },
    ],
    sucursales: [],
    mobileAdjust: "-80%, -15%",
  },
];

const StyledFloating = styled.div`
  width: ${({ width }) => width}px;
  padding: ${({ padding }) => padding};
  margin-left: -${({ marginLeft }) => marginLeft}px;
  position: absolute;
  opacity: 0;
  border-radius: 40px;
  border: 1px solid #e0ecec;
  background: linear-gradient(0deg, #fff 77.72%, rgba(255, 255, 255, 0) 77.72%),
    #fff;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 2;
  h1 {
    color: #4528D0;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
  }
  hr {
    width: 100%;
    margin-bottom: -10px;
  }
  h2 {
    color: #c4c4c4;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    background: #fff;
    z-index: 1;
    padding: 0 8px;
  }
  h3 {
    color: #474a4b;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #474a4b;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0px 0;
  #space-div {
    height: 830px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: -50px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const StyledDiv = styled.div`
  width: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: IBM Plex Sans;
  max-width: 1112px;
  margin: 0 auto;
  .row div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    transform: translate(-57%, 0);
  }
`;

const ButtonsMobile = styled.div`
  width: 100%;
  padding: 18px;
  z-index: 2;
  margin-top: -230px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileOffice = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  background: ${({ isHovered }) => (isHovered ? "#FFF" : "#4528D0")};
  margin-bottom: 20px;
  transition: all 0.3s ease !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  h1 {
    color: ${({ isHovered }) => (isHovered ? "#4528D0" : "#F3F3F3")};
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    transition: all 0.3s ease;
  }
  text-align: center;
  hr {
    width: 100%;
    margin-bottom: -10px;
    margin-top: 25px;
    z-index: 1;
  }
  h2 {
    color: #c4c4c4;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    background: #fff;
    z-index: 2;
    padding: 0 8px;
  }
  h3 {
    color: #474a4b;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #474a4b;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
`;

const BannerBackground = styled.div`
  width: 100%;
  height: 165px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fbanner1.png?alt=media&token=d7bdcac7-69c7-41c8-b68f-1bd6e25136b7")
    center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38"centerno-repeat);
    height: 130px;
    margin-bottom: 70px;
  }
  .row {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 48px;
  font-weight: 300;
  line-height: 180%;
  margin-top: revert;
  p {
    width:59vw;
    margin-bottom:60px;
    color: #000;
    text-align: center;
    font-size: 26px;
    font-weight: 300;
    line-height: 100%;
  }
  span {
    background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 95%;
      width: 100%;
      left: 0;
      height: 7px;
      border-radius: 2px;
      background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
    p {
      width: 96vw;
      margin-top: -70px;
      font-size: 17px;
    }
  }
`;

const MapComponent = () => {
  const [hoveredState, setHoveredState] = useState();
  const number = "522212311313";

  const handleMouseEnter = (event) => {
    const floatingUI = document.getElementById("floatingUI");
    // Get the ID of the state
    const stateId = event.target.id;

    // Check if the state is from allowed array
    if (allowedStates?.some((allowed) => allowed?.id === stateId)) {
      // Change the fill color
      event.target.setAttribute("cursor", "pointer");
      event.target.setAttribute("fill", "#4528D0");
      const myState = allowedStates?.find((allowed) => allowed?.id === stateId);
      setHoveredState(myState);
      const svgElement = event.target.closest("svg"); // Find the parent SVG
      const svgRect = svgElement.getBoundingClientRect(); // Get the SVG's bounding box
      const pathRect = event.target.getBoundingClientRect(); // Get the path's bounding box

      // Calculate horizontal center
      const centerX = pathRect.left - svgRect.left + pathRect.width / 2;
      // Calculate vertical center
      const centerY = pathRect.top - svgRect.top - 10;

      // Position and display the floating UI
      floatingUI.style.bottom = `${svgRect.height - centerY}px`; // Set 'bottom' to the vertical center
      floatingUI.style.opacity = "1";
      floatingUI.style.left = `${centerX}px`; // Set 'left' to the horizontal center
    }
  };

  const handleMouseLeave = (event) => {
    if (allowedStates?.some((allowed) => allowed?.id === event.target?.id)) {
      // Change the fill color
      event.target?.setAttribute("fill", "#d3d3d3");
      setHoveredState(undefined);
      const floatingUI = document.getElementById("floatingUI");
      floatingUI.style.opacity = "0";
    }
  };

  useEffect(() => {
    const mexicoMapObject = document.getElementById("mexicoMapObject");

    const handleSvgLoad = () => {
      const svgDoc = mexicoMapObject.contentDocument;
      // myMapSvg

      if (svgDoc) {
        // To set the drop shadow to all the map
        const svg = svgDoc.querySelectorAll("svg");
        svg[0]?.setAttribute(
          "filter",
          "drop-shadow(-10px 20px 1px rgba(0, 0, 0, 0.1))"
        );

        // Query the <path> elements within the SVG
        const paths = svgDoc.querySelectorAll("path");

        // Loop through the paths and log their IDs
        paths.forEach((path) => {
          // Check if the state is from allowed array
          if (allowedStates?.some((allowed) => allowed?.id === path?.id)) {
            // Change the fill color
            path.setAttribute("fill", "#d3d3d3");
            // Set the cursor to pointer on hover
            path.style.cursor = "pointer";
            path.style.transition = "all 0.3s ease";
          }
        });

        // Add event listeners to each state
        paths.forEach((state) => {
          state.addEventListener("mouseenter", handleMouseEnter);
          state.addEventListener("mouseleave", handleMouseLeave);
        });

        // Clean up event listeners when the component unmounts
        return () => {
          paths.forEach((state) => {
            state.removeEventListener("mouseenter", handleMouseEnter);
            state.removeEventListener("mouseleave", handleMouseLeave);
          });
        };
      } else {
        console.error("SVG document not loaded");
      }
    };

    // Add the event listener for SVG load
    mexicoMapObject.addEventListener("load", handleSvgLoad);

    // Clean up the SVG load event listener when the component unmounts
    return () => {
      mexicoMapObject.removeEventListener("load", handleSvgLoad);
    };
  }, []);

  const handleAllowedEnter = (tempState) => {
    setHoveredState(tempState);
    // Get map
    const mexicoMapObject = document.getElementById("mexicoMapObject");
    const mapDiv = document.getElementById("mapDiv");
    // Get svg
    const svgDoc = mexicoMapObject?.contentDocument;
    // Use querySelector to get the path by ID
    const path = svgDoc?.querySelector(`path#${tempState?.id}`);

    // Set color fill
    path?.setAttribute("fill", "#a62c77");

    mexicoMapObject.style.transition = "width 0.2s ease";
    mexicoMapObject.style.width = "1600px";

    mapDiv.style.transition = "transform 0.3s";
    mapDiv.style.transform = `translate(${tempState?.mobileAdjust})`;
  };

  const handleAllowedLeave = (tempState) => {
    setHoveredState(undefined);
    // Get map
    const mexicoMapObject = document.getElementById("mexicoMapObject");
    // Get svg
    const svgDoc = mexicoMapObject?.contentDocument;
    // Use querySelector to get the path by ID
    const path = svgDoc?.querySelector(`path#${tempState?.id}`);
    // Set color fill
    path?.setAttribute("fill", "#d3d3d3");
    //
    mexicoMapObject.style.width = "1112px";
    //
    const mapDiv = document.getElementById("mapDiv");
    mapDiv.style.transform = `translate(-57%, 0`;
  };

  // const handleClick = (event) => {
  //   console.log(event.target.className);
  // };

  // document.addEventListener("click", handleClick);

  return (
    <MainDiv>
      <BannerBackground>
        <Title className="mb-2 ms-auto me-auto">
          ¡Conoce nuestra Red de Alianzas Vitales!
        </Title>
      </BannerBackground>
      <Title>
        <p>
          Formada por profesionales de la salud ubicados en distintas partes de
          la República mexicana llevando de forma exclusiva nuestras pruebas a
          cada estado para brindarte todos sus beneficios, así como una atención
          más personalizada durante y después de tu toma de muestra.
        </p>
      </Title>
      <Container id="space-div">
        <StyledDiv id="mapDiv">
          <object
            id="mexicoMapObject"
            data={MexicoMap}
            type="image/svg+xml"
            aria-label="Mexico Map"
            style={{
              width: "1112px",
              // transition: 'all 0.3s ease',
              transition: "transform 0.3s ease",
            }}
          >
            Your browser does not support SVGs
          </object>
          <StyledFloating
            id="floatingUI"
            width={hoveredState?.sucursales?.length >= 1 ? "572" : "323"}
            padding={hoveredState ? "20px 10px" : "0"}
            marginLeft={hoveredState?.sucursales?.length >= 1 ? "286" : "161"}
          >
            {hoveredState !== undefined ? (
              <>
                <h1>
                  <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FPlace%20Marker.png?alt=media&token=2d69ffa6-4890-4061-894f-5e7a350ce8c6" alt="" width={"35px"} style={{color:"#a62c77"}}/> {hoveredState?.name}
                </h1>
                <Row>
                  <Col xs={hoveredState?.sucursales?.length >= 1 ? 6 : 12}>
                    <hr />
                    <h2>Datos{hoveredState?.sedes?.length >= 2 ? "s" : ""}</h2>
                    {hoveredState?.sedes?.map((sed) => (
                      <>
                        <h3>{sed?.name}</h3>
                        <h3>{sed?.phone}</h3>
                        <h3 style={{fontStyle:"italic"}}>{sed?.address}</h3>
                      </>
                    ))}
                  </Col>
                  {hoveredState?.sucursales?.length >= 1 ? (
                    <Col xs={6}>
                      <hr />
                      <h2>Sucursales</h2>
                      {hoveredState?.sucursales?.map((suc) => (
                        <>
                          <h3>{suc?.name}</h3>
                          <h3>{suc?.phone}</h3>
                          <h3 style={{fontStyle:"italic"}}>{suc?.address}</h3>
                        </>
                      ))}
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null}
          </StyledFloating>
        </StyledDiv>
      </Container>
      <ButtonsMobile>
        {allowedStates?.map((allowedSta) => (
          <MobileOffice
            isHovered={hoveredState?.name === allowedSta?.name}
            onClick={() => handleAllowedEnter(allowedSta)}
            onMouseLeave={() => handleAllowedLeave(allowedSta)}
            className="buttons-states"
          >
            <h1>
              <UilLocationPinAlt size={35} style={{ marginTop: "-5px" }} />
              {allowedSta?.name}
            </h1>
            {hoveredState?.name === allowedSta?.name ? (
              <>
                <hr />
                <h2>Datos{hoveredState?.sedes?.length >= 2 ? "s" : ""}</h2>
                {hoveredState?.sedes?.map((sed) => (
                  <>
                    <h3>{sed?.name}</h3>
                    <p>{sed?.address}</p>
                  </>
                ))}
                {hoveredState?.sucursales?.length >= 1 ? (
                  <>
                    <hr />
                    <h2>Sucursales</h2>
                    {hoveredState?.sucursales?.map((suc) => (
                      <>
                        <h3>{suc?.name}</h3>
                        <p>{suc?.address}</p>
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
          </MobileOffice>
        ))}
      </ButtonsMobile>
    </MainDiv>
  );
};

export default MapComponent;
