import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { StudyCard } from "./studyCard";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { StudyVg70 } from "./StudyVg70";

const Title = styled.h1`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  text-align: center;
  color: #2e363e;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 42px;
  }
`;

const SubTitle = styled.h2`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #515151;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
  @media only screen and (max-width: 400px) {
    max-width: 340px;
  }
`;

const Description = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #515151;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 576px) {
    max-width: 268px;
  }
`;

const StyledContainer = styled(Container)`
  background: #F3F3F3;
  padding-top: 40px;
  
`;

const CardsRow = styled(Row)`
  padding-top: 3rem;
    margin-bottom: 40px;
    margin-left: 0%;
    justify-content: center;
  @media only screen and (max-width: 1400px) {
    padding-top: 3rem;
    margin-bottom: 40px;
    margin-left: 0%;
    justify-content: center;
  @media only screen and (max-width: 768px) {
    padding-top: 3rem;
    margin-bottom: 40px;
    margin-left: 0%;
    justify-content: center;
  }
`;

const DescRow = styled(Row)`
  margin-bottom: 1.5rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0rem;
    justify-content: center;
  }
`;

const TableDescRow = styled(Row)`
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const StyledCol = styled(Col)`
  position: relative;
  margin: 0;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;

export const Studies = () => {
  const { width } = useWindowDimensions();
  const StudiesIds = [
    {
      id: 598,
    },

    {
      id: 1227
    },
    {
      id: 1228
    },
    //{
    //  id: 974
    //},
    //{
    //  id: 590
    //},
  ];
  return (
    <section id="studi" style={{background:"#F3F3F3"}}>
      <StyledContainer id="studi">
      <Row className='mb-4'>
        <Title>Conoce {width <= 405 && ( <br/> )}nuestras pruebas</Title>
      </Row>
      <DescRow>
        <Description>
          Mejora tu alimentación y estilo de vida
        </Description>
      </DescRow>
      <CardsRow>
        {StudiesIds.map((study, index) => (
          <StyledCol xs={12} sm={12} md={6} lg={4} xl={4} key={index} className={index === StudiesIds.length - 1 ? 'm-0' : ''}>
            <StudyCard studyId={study.id} />
          </StyledCol>
        ))}
      </CardsRow>
      <StudyVg70/>
      </StyledContainer>
    </section>
  );
};
