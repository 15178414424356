import React, { useState } from 'react';
import styled from 'styled-components';
import { UilHeart } from '@iconscout/react-unicons';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { AiFillHeart } from 'react-icons/ai';
import { Col } from 'react-bootstrap';

const StudyContainer = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px #f6f6f6;
  width: 100%;
  max-width: 270px;
  border-radius: 16px;
  padding-top: 1px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Description = styled.span`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: #3b3b3b;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px auto;
  margin-top: -12px;
  margin-bottom: -12px;
`;

const Favorite = styled(UilHeart)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const FavoriteFilled = styled(AiFillHeart)`
  color: #ff004d;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Title = styled.h3`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #00AD90;
  margin-top: 12px;
`;

const Price = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #00AD90;
  margin-top: 16px;
  margin-bottom: 0px;
`;

const TotalResults = styled.span`
  padding: 6px;
  background: #dbf1ff;
  border-radius: 8px;
`;

const OldPrice = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: line-through;
  color: #c4c4c4;
  margin-bottom: 0px;
`;

const AddButton = styled.button`
  width: 140px;
  height: 40px;
  background-color: #00AD90;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 26px;
  margin-top: 16px;
`;

const EndRequest = styled.h2`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00AD90;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Category = (props) => {
  const [favorite, setFavorite] = useState(false);

  const toggleFav = () => {
    favorite ? setFavorite(false) : setFavorite(true);
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <Col xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <StudyContainer>
        <br />
        <TotalResults className='mt-2'>
          {props.data.results} resultados
        </TotalResults>
        <Title>{props.data.category}</Title>
        <Description>{props.data.description}</Description>
      </StudyContainer>
    </Col>
  );
};
