import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { HelmetTitle } from "../../../../../helpers/HelmetTitle";


const Banner = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2Finvitacion1.png?alt=media&token=ea4f777b-3632-4a2f-94f3-f90ceb5e9c73")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FMovilAliados.png?alt=media&token=0fa6bb78-7771-4acd-9784-1d44b0beba4b")
      center center/cover no-repeat;
  }
`;

const ContainerDiv = styled.section`
  position: relative;
  width: 100%;
  height: 97vh;
`;

export const Invitaciones = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 428;
  return (
    <>
      <HelmetTitle title="Quiz Gluten" name="description" content="content" />
      {!isMobile ? (
        <Banner>
          <ContainerDiv></ContainerDiv>
        </Banner>
      ) : (
        <Banner>
          <ContainerDiv></ContainerDiv>
        </Banner>
      )}
    </>
  );
};
