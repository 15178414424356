import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import TextureCourse from '../../../assets/home/textureCourse.png'
import { Instructors } from './Instructors';

const CourseSection = styled.div`
  background-color: #F4F4F3;
  background-image: url(${TextureCourse});
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
`;

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  text-align: center;
  color: #3B3B3B;
  margin: 0;
  @media only screen and (max-width: 768px) {
    padding: 0;
    font-size: 32px;
    line-height: 42px;
  }
`;

const Desc = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #3B3B3B;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

const StyledUl = styled.ul`
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    text-align: center;
    list-style-position: inside;
    padding: 0;
  }
`;

const StyledLi = styled.li`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #3B3B3B;
  margin: 0;
  ::before {
    color: #3B3B3B;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
    line-height: 36px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
  }
`;

const MoreInfoBtn = styled.a`
  background: #90CB15;
  border-radius: 102px;
  max-width: 282px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 400ms;
  :hover {
    transition: 400ms;
    background: #77CCD5;
    color: #ffffff;
  }
`;

export const Course = () => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 992;
  const listItems = [
    {
      content: 'Más de 7 horas de video'
    },
    {
      content: 'Material descargable'
    },
    {
      content: 'Actividades en cada módulo'
    },
    {
      content: 'Avanza a tu propio ritmo'
    },
    {
      content: 'Acceso de por vida'
    },
    {
      content: 'Desde tu computadora o celular'
    },
  ];
  return(
    <CourseSection id='curso'>
      <StyledContainer>
        <Row className='mb-4'>
          <Title>
            Curso online:{' '}
            {width <= 576 && (
              <br/>
            )}
            <b>
              Lo Vital de la Nutrición
            </b>
          </Title>
        </Row>
        <Row className='mb-4 mb-lg-5'>
          <Desc>
            Aprende a tomar decisiones más informadas y conscientes sobre tu alimentación y salud 
          </Desc>
        </Row>
        <Row className='justify-content-center mb-3 mb-lg-4'>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={4} className='d-flex flex-column align-items-center'>
            {!isMobile && (
              <StyledUl>
                {listItems.slice(0, 3).map((item, index) => (
                  <StyledLi key={index}>
                    {item.content}
                  </StyledLi>
                ))}
              </StyledUl>
            )}
            {isMobile && (
              <StyledUl>
                {listItems.map((item, index) => (
                  <StyledLi key={index}>
                    {item.content}
                  </StyledLi>
                ))}
              </StyledUl>
            )}
          </Col>
          {!isMobile && (
            <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={4} className='d-flex flex-column align-items-center'>
              <StyledUl>
                {listItems.slice(3, 6).map((item, index) => (
                  <StyledLi key={index}>
                    {item.content}
                  </StyledLi>
                ))}
              </StyledUl>
            </Col>
          )}
        </Row>
        <Row className='mb-4'>
          <Desc>
            Con los instructores:
          </Desc>
        </Row>
        <Instructors />
        <Row className='justify-content-center'>
          <MoreInfoBtn href='https://vitalgene.teachable.com/p/lovitaldelanutricion' target='_blank' rel='noreferrer'>
            Conoce más información
          </MoreInfoBtn>
        </Row>
      </StyledContainer>
    </CourseSection>
  );
}