import Carousel from "react-spring-3d-carousel";
import { useState } from "react";
import { config } from "react-spring";
import styled from "styled-components";
import Foto from "../../../../assets/packs/perfil.png";

const StyledDiv = styled.div`
  width: 90%;
  max-width: 900px;
  height: 500px;
  margin: 0 auto;
  padding-bottom: 50px ;
  @media only screen and (max-width: 768px) {
    width: 230px;
    height: 90vw;
    max-height: 500px;
    transform: rotate(90deg);
    padding-top: 30px;
    margin: -100px auto 100px;
  }
  @media only screen and (max-width: 420px) {
    padding-top: 40px;
    width: 150px;
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Assistant;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: -60px;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

const TCard = styled.div`
  cursor: pointer;
  position: relative;
  width: 358px;
  height: 252px;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(93.03deg, #008108 1.16%, #009899 96.98%);
  border-radius: 10px;
  transform: rotateY(0) translateZ(35vw);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 120px;
    position: absolute;
    bottom: -60px;
    left: 16px;
  }
  h1 {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    max-width: 300px;
    position: absolute;
    bottom: 10px;
    right: 30px;
    margin-bottom: 0;
  }
  p {
    color: #515253;
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-top: -15px;
  }
  @media only screen and (max-width: 768px) {
    transform: rotate(-90deg);
    width: 350px;
    height: 100px;
    margin: 0 -120px;
    padding-left: 70px;
    img {
      max-height: 120px !important;
      height: 120px;
      width: 120px;
      bottom: -10px;
      left: -50px;
    }
    h1 {
      font-size: 13px;
      bottom: 5px;
      right: 12px;
    }
    p {
      line-height: 11px;
      font-size: 9px;
    }
  }
  @media only screen and (max-width: 420px) {
    width: 300px;
  }
  @media only screen and (max-width: 372px) {
    width: 250px;
  }
`;

// This is used coause there is a gap after the section idk why :c
const MarginDiv = styled.div`
  margin: -60px;
  @media only screen and (max-width: 768px) {
    margin: -150px;
  }
`;

export const CardTestimonials = (props) => {
  return (
    <TCard>
      <img src={Foto} alt="Avatar" />
      <h1>{props.name}</h1>
      <p>{props.comment}</p>
    </TCard>
  )
}

export default function Carroussel() {
  let cardsArray = [
    {
      key: 1,
      content: <CardTestimonials
        avatar=""
        name="Dra. Damaris Pupo"
        comment="Ha sido un gusto trabajar con ustedes ya 
        que con sus  pruebas me ha permitido ayudar a mis 
        pacientes en diagnósticos más precisos.
        Intolerancia a la lactosa demostrada genéticamente.
        Buena atención, entrega de resultados rápidos y comunicación, 
        siempre les agradezco mucho sus servicios
        "
      />
    },
    {
     
    },
    {
      
    },
  ];

  const table = cardsArray.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  return (
    <div style={{paddingLeft: '20px'}}>
      <Title>Testimonios</Title>
      <StyledDiv>
        <Carousel
          slides={cards}
          goToSlide={goToSlide}
          offsetRadius={2}
          showNavigation={false}
          animationConfig={config.gentle}
        />
      </StyledDiv>
      <MarginDiv/>
    </div>
  );
}