import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

const BannerBackground = styled.div`
  width: 100%;
  height: 465px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Ffondosport.png?alt=media&token=7462d54a-43c3-4828-ae61-6786f7a09580&_gl=1*13ht40*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODc3NjYxMy4xMjcuMS4xNjk4Nzc2NjYzLjEwLjAuMA..")
    center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FComponent%203.png?alt=media&token=333ae045-3fea-4f95-a2d1-8d3a1871a956"centerno-repeat);
    height: 400px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
    }
    img {
      width: 650px;
      position: absolute;
      bottom: 90px;
      left: 214px;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 96%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 46px;
  line-height: 120%;
  color: #505153;
  max-width: 608px;
  margin: 0 auto;
  p {
    line-height: 120%;
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
    font-size: 20px;
    margin-bottom: 20px !important;
  }
`;

const Desc = styled.p`
  max-width: 413px;
  width: 100%;
  margin: 0 auto;
  height: 135px;
  flex-shrink: 0;
  color: #505153;
  text-align: center;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    max-width: 294px;
  }
`;

export const BannerSport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <BannerBackground>
        <Container>
          <Row>
            <DescColumn
              xs={12}
              md={7}
              className={
                isTablet
                  ? "justify-content-start mt-5"
                  : "justify-content-center"
              }
            >
              <Container data-aos="fade-up">
                <Title className={isMobile ? "mb-2 ms-auto me-auto" : "mb-4"}>
                  ¿Qué es la prueba VG Sport?
                </Title>
                {!isTablet ? (
                  <Desc>
                    VG Sport proporciona información específica sobre tu
                    predisposición genética a realizar diferentes tipos de
                    ejercicio de manera efectiva para aplicar esta información
                    en tus entrenamientos y alimentación con la finalidad de
                    lograr resultados de manera personalizada.
                  </Desc>
                ) : null}
              </Container>
            </DescColumn>
            <Col xs={12} md={5} id="col-img">
              <img
                alt="analisis"
                src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FLP_caja_sport.png?alt=media&token=54adce21-c11a-44e5-94a3-b1e82ffc0ff2&_gl=1*w0jt67*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODc3NjYxMy4xMjcuMS4xNjk4Nzc2Njg5LjU1LjAuMA.."
              />
            </Col>
            {isTablet ? (
              <Desc>
                VG Sport proporciona información específica sobre tu
                predisposición genética a realizar diferentes tipos de ejercicio
                de manera efectiva para aplicar esta información en tus
                entrenamientos y alimentación con la finalidad de lograr
                resultados de manera personalizada.
              </Desc>
            ) : null}
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
