import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilArrowRight, UilCircle } from "@iconscout/react-unicons";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Background = styled.div`
  background: linear-gradient(
    90deg,
    #fb5b6d 30%,
    #ffffff 0%,
    #ffffff 73.5%,
    #ffffff 134.41%,
    #ffffff 177.64%
  );
  @media only screen and (max-width: 1366px) {
    background: linear-gradient(
      90deg,
      #fb5b6d 36%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }

  @media only screen and (max-width: 768px) {
    background: linear-gradient(
      180deg,
      #fb5b6d 100%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }
`;

const ContQuiz = styled.section`
  position: relative;
  width: 100%;
  min-height: 49vh;
`;

const ContBanner = styled.div`
  position: relative;
  width: 100%;
  min-height: 25vh;
`;

const RedDiv = styled.div`
  position: absolute;
  top: 57px;
  left: 90px;
  background: none;
  // background: #FF00001A;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #000;
  @media only screen and (max-width: 1366px) {
    top: 39px;
    left: 35px;
  }
  @media only screen and (max-width: 768px) {
    top: 35px;
    left: 50px;
  }
  p {
    font-style: italic;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      margin-left: -15px;
    }
  }
  h3 {
    color: #000;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

const Texthome = styled.div`
  position: absolute;
  top: 57px;
  width: 950px;
  left: 470px;
  background: none;
  // background: #FF00001A;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #000;
  @media only screen and (max-width: 1366px) {
    left: 200px;
    top: 0px;
  }
  @media only screen and (max-width: 768px) {
    width: 390px;
    top: 60px;
    left: 0px;
  }
  p {
    text-align: center;
    font-size: 38px;
    line-height: 120%;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  h3 {
    text-align: center;
    color: #52c41a;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

const HomeBtn = styled.button`
  margin-left: 94%;
  background: none;
  border: none;
  margin-bottom: 18px;
  @media only screen and (max-width: 768px) {
    margin-left: 37%;
  }
`;

const Circle = styled.div`
  position: relative;
  width: 100%;

  p {
    color: #000;
    font-weight: 700;
    text-align: initial;
    font-size: 26px;
    line-height: 120%;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1366px) {
    margin-top: -83px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 0%;
  }
`;

export const ResultadoBajoCafeina = () => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 992;
  return (
    <>
      {!isMobile && (
        <>
          <Background>
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                <img
                src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                alt="paso 1"
              />
                  <br />
                  <br />
                  <p>Prueba de riesgo de intolerancia a la cafeína.</p>
                </RedDiv>
              </Col>
              <Col xs={12} md={10} lg={10} xl={12}>
              <HomeBtn
              onClick={() => {
                window.location.href = `/cafeina/estudio/974`;
              }}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2Fhome%20caf.png?alt=media&token=ff8864d1-b3a1-4bed-9a52-efffc13bdd3e&_gl=1*1m5x6hh*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5NjM1NDk4NC42Ni4xLjE2OTYzNTUwMDQuNDAuMC4w"
                alt="paso 2"
              />
            </HomeBtn>
              </Col>
            </ContBanner>
          </Background>
          <ContQuiz>
            <Col xs={12} md={10} lg={10} xl={12}>
              <Texthome>
                <h3>BAJA</h3>
                <br />
                <p>
                  De acuerdo a tus resultados, tus síntomas pueden estar
                  relacionado a otro padecimiento, te recomendamos acudir con tu
                  médico para un correcto diagnóstico.
                </p>
              </Texthome>
            </Col>
          </ContQuiz>
          <Row>
            <Col xs={12} md={10} lg={3} xl={3} id="moderacion">
              <Circle>
                <p>
                  <UilCircle
                    size="27"
                    style={{
                      color: "#3AAA35",
                      background: "#3AAA35",
                      borderRadius: "40px",
                    }}
                  />
                  BAJO
                </p>
                <p>
                  <UilCircle
                    size="27"
                    style={{
                      color: "#D8BC00",
                      background: "#D8BC00",
                      borderRadius: "40px",
                    }}
                  />
                  MODERADO
                </p>
                <p>
                  <UilCircle
                    size="27"
                    style={{
                      color: "#E30613",
                      background: "#E30613",
                      borderRadius: "40px",
                    }}
                  />
                  ALTO
                </p>
              </Circle>
            </Col>
          </Row>
        </>
      )}
      {isMobile && (
        <>
        <Background>
          <ContBanner>
            <Col xs={12} md={10} lg={10} xl={12}>
              <RedDiv>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                  alt="paso 1"
                />
                <br />
                <br />
                <p>Prueba de riesgo de intolerancia a la cafeína.</p>
              </RedDiv>
            </Col>
            {/*<Col xs={12} md={10} lg={10} xl={12}>
              <HomeBtn
                onClick={() => {
                  window.location.href = `/gluten/estudio/166`;
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FHome%201.png?alt=media&token=321caac5-f5d7-4cb0-bd02-8ddeb71cc636&_gl=1*gq6ah*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5NjM0ODk2Ni42NS4xLjE2OTYzNTA4MjIuNjAuMC4w"
                  alt="paso 1"
                />
              </HomeBtn>
              </Col>*/}
          </ContBanner>
        </Background>
        <ContQuiz>
          <Col xs={12} md={10} lg={10} xl={12}>
            <Texthome>
              <h3>BAJA</h3>
              <br />
              <p>
                De acuerdo a tus resultados, tus síntomas pueden estar
                relacionado a otro padecimiento, te recomendamos acudir con tu
                médico para un correcto diagnóstico.
              </p>
            </Texthome>
          </Col>
        </ContQuiz>
        <Row>
          <Col xs={12} md={10} lg={3} xl={3} id="moderacion">
            <Circle>
              <p>
                <UilCircle
                  size="27"
                  style={{
                    color: "#3AAA35",
                    background: "#3AAA35",
                    borderRadius: "40px",
                  }}
                />
                BAJO
                <UilCircle
                  size="27"
                  style={{
                    color: "#D8BC00",
                    background: "#D8BC00",
                    borderRadius: "40px",
                  }}
                />
                MODERADO
                <UilCircle
                  size="27"
                  style={{
                    color: "#E30613",
                    background: "#E30613",
                    borderRadius: "40px",
                  }}
                />
                ALTO
              </p>
  
            </Circle>
          </Col>
        </Row>
      </>
      )}
    </>
  );
};
