import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { UilDocumentInfo } from "@iconscout/react-unicons";

const BannerBackground = styled.div`
  width: 100%;
  height: 140px;
  background: #fff;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    background: #fff;

    height: 150px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    img {
      width: 650px;
      position: absolute;
      bottom: 90px;
      left: 214px;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 768px) {
        width: 340px;
        height: 180px;
        left: auto;
        right: -10px;
        bottom: 200px;
        transform: translate(0, 0);
      }
    }
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 34px;
  line-height: 120%;
  color: #000;
  max-width: 875px;
  margin-right: auto;
  margin-left: auto;
  margin-top: revert;

  p {
    line-height: 120%;
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 260px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px !important;
    p {
      font-size: 12px;
    }
  }
`;

const Title1 = styled.p`
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 34px;
  line-height: 120%;
  color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  margin-top: revert;

  p {
    font-weight: 400;
    line-height: 120%;
    font-size: 22px;
    //max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
  }
  @media only screen and (max-width: 576px) {
    max-width: 740px;
    font-size: 16px;
    margin-bottom: 20px !important;
    p {
      font-size: 12px;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 400;
  line-height: 120%;
  color: #F14CAE;
  padding: 5px 1rem 5px 1rem;
  background: #fff;
  border-radius: 40px;
  border: 1px solid #F14CAE;
  text-align: center;
  width: 340px;
  height: 60px;
  box-shadow: 0px 16px 9px 0px #25060e33;
  @media only screen and (max-width: 1200px) {
    max-width: 528px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    max-width: 171px;
    font-size: 14px;
    height: 38px;
  }
`;

export const Reporte = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <BannerBackground>
        <Title className={isMobile ? "mb-2 ms-auto me-auto" : "mb-4"}>
          Descarga un ejemplo completo de Reporte de resultados
          <Desc>
            {isMobile && (
              <>
                <a
                  className="mb-0"
                  style={{
                    color: "#F14CAE",
                    
                  }}
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Freportes%2FVG_reporte_intolerancia_lactosa%20(1de2)%20(1).pdf?alt=media&token=5c04bc9f-8ea0-4393-b150-f211d83ecda8"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <UilDocumentInfo size={16} />
                  Descargar reporte.
                </a>
              </>
            )}
            {!isMobile && (
              <>
                <a
                  className="mb-0"
                  style={{
                    color: "#F14CAE",
                    
                  }}
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Freportes%2FVG_reporte_intolerancia_lactosa%20(1de2)%20(1).pdf?alt=media&token=5c04bc9f-8ea0-4393-b150-f211d83ecda8"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <UilDocumentInfo />
                  Descargar reporte.
                </a>
              </>
            )}
          </Desc>
        </Title>
      </BannerBackground>
    </>
  );
};
