import React from "react";
import { Cafeina } from "./cafeina";
import Vimeo from "@u-wave/react-vimeo";
import { Banners } from "./banners";
import { StudyCafeina } from "./StudyCafeina";
import { Beneficios } from "./beneficios";
import { Target } from "./target";
import Testimonials from "./testimonials";
import { Reporte } from "./reporte";

export const stuCafeina = () => {
  return (
    <>
      <Cafeina />
     <img src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2Ffoto.png?alt=media&token=94042871-a737-456b-99b6-ff9ab688fae2" alt="cafe" style={{width: '100%'}}/>
        {/* <Vimeo color='ffbc45' video={'https://player.vimeo.com/video/851738680?h=e7c0323fe5'} responsive={true} autoplay loop muted/>
        <Banners/>*/}
      <Target />
      
      <Testimonials />
      <Beneficios/>
      <Reporte />
      <StudyCafeina />
    </>
  );
};
