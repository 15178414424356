import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

const BannerBackground = styled.div`
  width: 100%;
  height: 465px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FGroup%2036647.png?alt=media&token=ca7957b2-bce6-4234-a228-87d58fb0c545") center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FGroup%2036647.png?alt=media&token=ca7957b2-bce6-4234-a228-87d58fb0c545"centerno-repeat);
    height: 400px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
    }
    img {
      width: 650px;
      position: absolute;
      bottom: 90px;
      left: 214px;
      transform: translate(-50%, 0);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 96%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 48px;
  line-height: 120%;
  color: #ffffff;
  max-width: 520px;
  margin: 0 auto;
  p {
    line-height: 120%;
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 40px;
    font-size: 20px;
    margin-bottom: 20px !important;
  }
`;

const Desc = styled.p`
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  height: 100px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    max-width: 294px;
  }
`;

export const BannerLactosa = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  return (
    <>
      <BannerBackground>
        <Container>
          <Row>
            <DescColumn
              xs={12}
              md={7}
              className={
                isTablet
                  ? "justify-content-start mt-5"
                  : "justify-content-center"
              }
            >
              <Container data-aos="fade-up">
                <Title className={isMobile ? "mb-2 ms-auto me-auto" : "mb-4"}>
                  Prueba de intolerancia a la lactosa
                </Title>
                {!isTablet ? <Desc>  Esta prueba sirve para la detección del 
                  polimorfismo -13910 T/C en la región reguladora del gen 
                  LCT (lactasa), el cual esta asociado al riesgo de presentar 
                  intolerancia a la lactosa.</Desc> : null}
              </Container>
            </DescColumn>
            <Col xs={12} md={5} id="col-img">
              <img
                alt="analisis"
                src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Flactosa.png?alt=media&token=3fcdbf38-df70-4d9f-a9c5-9432bd8b9205"
              />
            </Col>
            {isTablet ? <Desc>  Esta prueba sirve para la detección del 
                  polimorfismo -13910 T/C en la región reguladora del gen 
                  LCT (lactasa), el cual esta asociado al riesgo de presentar 
                  intolerancia a la lactosa.</Desc> : null}
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
