import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const inputStyle = {
    width: '45px',
    height: '60px',
    background: '#F6F6F6',
    borderRadius: '12px',
    fontSize: '35px',
    textAlign: 'center',
    marginRight: '10px'
};

const buttonStyle = {
    width: '214px',
    height: '48px',
    border: 'none',
    background: '#77CCD5',
    borderRadius: '102px',
    fontweight: '800',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const linkResendCode = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    color: '#00AD90',
    cursor: 'pointer',
};

const formStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '15px auto 0 auto',
};

export const CodeVerificationModal = ({ resolve, handleClose }) => {
    const [digit1, setDigit1] = useState('');
    const [digit2, setDigit2] = useState('');
    const [digit3, setDigit3] = useState('');
    const [digit4, setDigit4] = useState('');
    const [digit5, setDigit5] = useState('');
    const [digit6, setDigit6] = useState('');
    const refDigit1 = document.getElementById('digit-1');
    const refDigit2 = document.getElementById('digit-2');
    const refDigit3 = document.getElementById('digit-3');
    const refDigit4 = document.getElementById('digit-4');
    const refDigit5 = document.getElementById('digit-5');
    const refDigit6 = document.getElementById('digit-6');
    const digitsEvent = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    useEffect(() => {
        if (document.getElementById('digit-1'))
            document.getElementById('digit-1').focus();
    }, []);

    const handleResolve = () => {
        const code = `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`;
        handleClose();
        resolve(code);
    };

    const digitInput = (value, reference, referenceBack, id, handleRef) => (
        <Form.Control
            type={"password"}
            placeholder="-"
            value={value}
            onKeyUp={(e) => {
                if (reference && digitsEvent.includes(e.key))
                    reference.focus();
                else if (referenceBack && e.key === 'Backspace')
                    referenceBack.focus();
            }}
            id={id}
            maxLength={1}
            style={inputStyle}
            onChange={({ target }) => handleRef(target.value)}
        />
    );

    return (
        <>
            <div style={formStyle}>
                {digitInput(digit1, refDigit2, null, 'digit-1', setDigit1)}
                {digitInput(digit2, refDigit3, refDigit1, 'digit-2', setDigit2)}
                {digitInput(digit3, refDigit4, refDigit2, 'digit-3', setDigit3)}
                {digitInput(digit4, refDigit5, refDigit3, 'digit-4', setDigit4)}
                {digitInput(digit5, refDigit6, refDigit4, 'digit-5', setDigit5)}
                {digitInput(digit6, null, refDigit5, 'digit-6', setDigit6)}
            </div>
            <br />
            <p style={{color: '#77CCD5'}}>
                Si no recibes el mensaje de verificación, intenta con otra opción.
            </p>
            <Button
                variant="primary"
                style={buttonStyle}
                onClick={handleResolve}
            >
                <b>Continuar</b>
            </Button>
            <br />
        </>
    )
}
