import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";

const StyledRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CloseBtn = styled.p`
  font-size: 24px;
  color: #1379bc;
  position: absolute;
  top: 3%;
  right: 3%;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    top: 1%;
    right: 2%;
  }
`;

const TitleContainer = styled.div`
  background: #00b9c4;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledModalTitle = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  max-width: 180px;
`;

const StyledModalDesc = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const ExampleText = styled.p`
  font-family: "IBM Plex Sans";
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
  color: #353433;
  margin: 0;
  padding: 0;
  max-width: 560px;
`;

const StyledRowExample = styled(Row)`
  padding: 15px 25px;
  padding-bottom: 2px !important;

  // z-index: 10;
  .header {
    background: #a32e78;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .header1 {
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
    background: #none;
    font-weight: 700;
    border-radius: 10px;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 6px;
  }
  .right {
    display: flex;
    padding: 0 5px;
  }
  .header-col {
    padding: 0 5px;
    div {
      width: 100%;
      height: 100%;
      background: rgba(19, 121, 188, 0.15);
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #353433;
      justify-content: center;
    }
  }
  .header-row {
    background: rgba(19, 121, 188, 0.15);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .info-col {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353433;
  }
  .yellow-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #eaac34;
  }
  .black-text,
  .yellow-text-bold,
  .blue-text-bold {
    padding-right: 20px;
  }
  .yellow-text-bold,
  .blue-text-bold {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #eaac34;
  }
  .blue-text-bold {
    color: #1379bc !important;
  }
  hr {
    margin-bottom: 0px;
  }
  img {
    margin-top: 0px !important;
    position: absolute;
    right: 8px;
  }
  .botoncito {
    cursor: pointer;
  }
`;

const StyledRowDown = styled(Row)`
  padding: 0px 25px;
  .right {
    display: flex;
    padding: 0;
  }
  .text-col {
    padding: 0 5px;
    div {
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #00b9c4;
      border-radius: 0px 0px 20px 20px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 77%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      min-height: 130px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1379bc;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .botoncito {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
      }
    }
  }
`;

export const Genetic = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  let isDesktop = width <= 1366;
  return (
    <>
      <StyledRow className="pt-4 ps-4 pe-4 pb-0 mb-4">
        <CloseBtn onClick={() => props.setShowModal(!props.show)}>
          <IoMdClose />
        </CloseBtn>
        <Col xs={4} sm={4} md={3} className="ps-0">
          <TitleContainer>
            <StyledModalTitle>Genetic insights</StyledModalTitle>
          </TitleContainer>
        </Col>
        <Col xs={7} sm={7} md={8} lg={8} xl={8} className="pe-0">
          <Row className="mb-4 pe-3">
            <StyledModalDesc>
              Esta muestra resultados preliminares de otros componentes con{" "}
              <b>
                {" "}
                relevancia nutricia, tales como marcadores clínicos, estilo de
                vida y lesiones, entre otros.
              </b>
            </StyledModalDesc>
          </Row>
          <Row>
            <ExampleText>
              Ejemplo creado únicamente con fines explicativos
            </ExampleText>
          </Row>
        </Col>
      </StyledRow>
      {!isDesktop ? (
        <StyledRowExample className="pb-4" style={{width: "44vw"}}>
        <Col
          xs={12}
          className="header ps-3"
          style={{
            marginBottom: "115px",
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          Genetic insights
        </Col>
        <Row
          className="pe-0 ps-0"
          style={{
            marginBottom: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          <div
            xs={3}
            className="pe-0 d-flex flex-column justify-content-center align-items-start position-relative"
          >
            <img
              style={{
                width: "90%",
                height: "auto",
                left: "40px",
                zIndex: "1",
              }}
              className="position-absolute"
              src={
                "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fmagnesio.png?alt=media&token=9be09537-a82a-494c-afa4-de3cb7f33077"
              }
              alt="Icono"
            />
          </div>
        </Row>
      </StyledRowExample>
      ) : (
        <StyledRowExample className="pb-4" style={{width: "60vw"}}>
        <Col
          xs={12}
          className="header ps-3"
          style={{
            marginBottom: "90px",
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          Genetic insights
        </Col>
        <Row
          className="pe-0 ps-0"
          style={{
            marginBottom: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          <div
            xs={3}
            className="pe-0 d-flex flex-column justify-content-center align-items-start position-relative"
          >
            <img
              style={{
                width: "90%",
                height: "auto",
                left: "40px",
                zIndex: "1",
              }}
              className="position-absolute"
              src={
                "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fmagnesio.png?alt=media&token=9be09537-a82a-494c-afa4-de3cb7f33077"
              }
              alt="Icono"
            />
          </div>
        </Row>
      </StyledRowExample>
      )}
      <div className="position-relative" style={{marginBottom:"70px"}}>
        <StyledRowDown className="pb-2">
          <Col md={4} className="text-col">
            <div style={{padding: "5px 80px"}}></div>
          </Col>
          <Col md={4} className="text-col">
            <div style={{padding: "5px 80px"}}></div>
          </Col>
          <Col md={4} className="text-col">
            <div style={{padding: "5px 80px"}}></div>
          </Col>
        </StyledRowDown>
      </div>
      {!isDesktop ? (
        <StyledRowExample className="pb-4" style={{width: "42vw"}}>
        <Row
            className="pe-0 ps-0"
            style={{
              marginBottom: "6px",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
          >
            <div
              xs={3}
              className="pe-0 d-flex flex-column justify-content-center align-items-start position-relative"
            >
              <img
                style={{
                  width: "90%",
                  height: "auto",
                  left: "40px",
                  zIndex: "1",
                }}
                className="position-absolute"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fdetalles.png?alt=media&token=e6a7ad5f-8ef0-4304-8c3c-288d751b6e31"
                }
                alt="Icono"
              />
            </div>
          </Row>
        </StyledRowExample>
      ):(
        <StyledRowExample className="pb-4" style={{width: "60vw"}}>
      <Row
          className="pe-0 ps-0"
          style={{
            marginBottom: "6px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          <div
            xs={3}
            className="pe-0 d-flex flex-column justify-content-center align-items-start position-relative"
          >
            <img
              style={{
                width: "90%",
                height: "auto",
                left: "40px",
                zIndex: "1",
              }}
              className="position-absolute"
              src={
                "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fdetalles.png?alt=media&token=e6a7ad5f-8ef0-4304-8c3c-288d751b6e31"
              }
              alt="Icono"
            />
          </div>
        </Row>
      </StyledRowExample>
      )}
      <div className="position-relative">
        <StyledRowDown className="pb-2">
          <Col md={12} className="text-col" style={{width:"20%"}}>
            <div style={{padding: "5px 300px"}}></div>
          </Col>
        </StyledRowDown>
      </div>
    </>
  );
};
