import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
//import { Banner } from "./banner";
import { BannerUno } from "./bannerGluten";
import { BannerDos } from "./bannerLactosa";
import { BannerTres } from "./bannerVG70";
import { BannerCuatro } from "./bannerCafeina";

const MySlider = styled(Slider)`
  position: relative;
  .slick-dots {
    position: absolute;
    bottom: 2px;
    @media only screen and (max-width: 768px) {
      bottom: 3px !important;
    }
    button {
      background: rgba(143, 143, 143, 0.5);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px 1px rgba(100, 100, 100, 0.2);
      ::before {
        display: none;
      }
    }

    .slick-active {
      width: 24px;
      margin-right: 10px;
    }
    .slick-active button {
      border-radius: 81px;
      background: rgba(222, 220, 224, 1);
      width: 24px;
    }
  }
`;

export const Carousel = (props) => {
  return (
    <MySlider
      width="100%"
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={5000}
      cssEase={"linear"}
      speed={500}
    >
      <BannerUno/>
      <BannerTres/>
      {/*<BannerDos/>
      <BannerCuatro/>*/}

    </MySlider>
  );
};
