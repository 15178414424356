import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";
import "./sliderstyle.css";

const BannerBackground = styled.div`
  width: 100%;
  height: 193px;
  overflow: hidden;
  margin-bottom: 50px;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FGroup%2036809.png?alt=media&token=5bc8a9e9-4c09-41b9-a8d9-7c4ed4948ac6")
    center center/cover no-repeat;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38"centerno-repeat);
    height: 130px;
    margin-bottom: 70px;
  }
  .row {
    width: 100%;
  }
`;

export const Fuerzas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { studyId } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 166);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getInfo();
  }, [studyId]);

  return (
    <>
      <BannerBackground>
        <Container>
          <HelmetTitle
            title="Aliados vitales"
            name="description"
            content="content"
          />
        </Container>
      </BannerBackground>
    </>
  );
};
