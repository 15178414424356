import React from "react";
import { Aliados } from "./aliados";
import { SliderImg } from "./SliderImg";
import MapComponent from "./mapComponent";
import { HelmetTitle } from '../../../../helpers/HelmetTitle';
import { Fuerzas } from "./fuerzas";
import { Funciones } from "./funciones";
import { Nutriologos } from "./nutriologos";
import { Contacto } from "./contacto";

const SectionsAliados = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <HelmetTitle
        title="Nosotros"
        name="description"
        content="content"
      />
      <MapComponent/>
      <SliderImg/>
      <Aliados/>
      <Nutriologos/>
      <Fuerzas/>
      <Funciones/>
      <Contacto/>
    </div>
  )
};

export default SectionsAliados;