import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import styled from 'styled-components';

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-weight: 500;
  line-height: 130%;
  font-size: 38px;
  color: #00AD90;
  margin: 0;
  @media only screen and (max-width: 992px){
    font-size: 22px;
  }
  @media only screen and (max-width: 576px){
    font-size: 16px;
  }
`;

const Desc = styled.p`
  font-family: Rounded Mplus 1c;
  font-weight: 500;
  line-height: 130%;
  font-size: 38px;
  color: #474A4B;
  @media only screen and (max-width: 992px){
    font-size: 22px;
  }
  @media only screen and (max-width: 576px){
    font-size: 16px;
  }
`;

export const AboutSlide = (props) => {
  return(
    <>
    <Row className='justify-content-end'>
      <Col xs={5} className='d-flex flex-column justify-content-center p-3'>
        <Title data-aos='fade-up' className='w-100'>{props.data.title}</Title>
        <Desc data-aos='fade-up' className='w-100'>{props.data.desc}</Desc>
      </Col>
      <Col xs={6} className='d-flex flex-column justify-content-start'>
        <img className='img-fluid m-0' src={props.data.img} />
      </Col>
    </Row>
    </>
  );
}