import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StudiesSlider } from "./studiesSlider";

const Title = styled.h5`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 33px;
  /* identical to box height */
  color: #0A7A71;
`;

export const Highlights = () => {
  const [studies, setStudies] = useState([]);

  const getStudies = async () => {
    try {
      let request = axios.get(
        'https://lpqsystem.com/get-featured-studies/5'
      );
      let data = await request;
      console.log(data);
      setStudies(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudies();
  }, []);
  return (
    <div style={{background: "linear-gradient(157.11deg, rgba(0, 173, 144, 0.5), rgba(138, 204, 164, 0.5))"}}>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <Title className='mt-3'>Estudios Destacados</Title>
          </Col>
          <Col md={12}>
            <StudiesSlider studies={studies} />
          </Col>
        </Row>
      </Container>
    </div>
  )
};