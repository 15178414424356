import Button from 'react-bootstrap/Button';
import { addCartDB, updateCartDB } from '../../helpers/api';
import ListGroup from 'react-bootstrap/ListGroup';
import { UilClipboardAlt } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { UilMultiply } from '@iconscout/react-unicons';
import {
  getTotalPrice,
  setTotalPrice,
  removeItemFromCart,
  resetCart,
} from '../../actions/shoppingCart';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { ListItemCart } from './listItemCart';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Column = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 15px;
  text-align: left;
`;

const OrangeButton = styled.button`
  width: 90%;
  height: 48px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;

const WhiteButton = styled.button`
  width: 90%;
  height: 48px;
  background: none;
  color: #f9ad62;
  border: 2px solid #f9ad62;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffecd9;
  }
`;

const GreenButton = styled.button`
  width: 90%;
  height: 48px;
  background: #8acca4;
  color: #fff;
  border: none;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #92e0b1;
  }
`;

const titleModal = {
  fontFamily: 'Assistant',
  fontStyle: 'normal',
  fontSize: '15px',
  lineHeight: '33px',
  textAlign: 'left',
  color: '#00AD90',
  margin: '0 0 10px 30px',
};

const subtotalStyle = {
  width: '63px',
  height: '21px',
  fontFamily: 'Assistant',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '21px',
  color: '#45494A',
  margin: '0 0 15px 20px',
};

const totalPriceText = {
  width: '91px',
  height: '21px',
  fontFamily: 'Assistant',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '21px',
  color: '#00AD90',
};

const closeStyle = {
  display: 'flex',
  margin: '17px 0 0 85%',
  cursor: 'pointer',
};

export const ModalCart = () => {
  const [opened, setOpened] = useState(true);
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const { bannerOpen } = useSelector((state) => state.aux);
  const sortItems = (items) => items.sort((a, b) => a.id - b.id);
  const modal = document.getElementById('myModal');
  const isDoctor =
    useSelector((state) => state.user?.data?.data.profile?.type) === 1;
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isOrder = urlParams.get('doctor');
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 768;

  const modalStyle = {
    width: '90%',
    maxWidth: '641px',
    height: items.length > 0 ? '470px' : '330px',
    background: '#FFFFFF',
    border: '1px solid #C1D9D8',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px #F6F6F6',
    borderRadius: '12px',
    zIndex: '7',
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    if (currentItem.howMany > 1) {
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
    }

    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const handleCloseModalCart = () => {
    modal.style.display = 'none';
  };

  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  // document.addEventListener('click', function(event) {
  //     if(event.target.id !== 'openCartButton' && event.target.id !== 'myModal'){
  //         modal.style.display = "none";
  //     }
  // });

  return (
    <center>
      <div
        style={modalStyle}
        id='myModal'
        className={`myModal ${
          bannerOpen ? '' : 'modal-cart-res'
        } animate__animated animate__fadeIn animate__fast`}
      >
        <UilMultiply
          color='#00AD90'
          style={closeStyle}
          onClick={handleCloseModalCart}
        />
        <p style={titleModal}>Mis estudios</p>
        <ListGroup
          variant='flush'
          style={{
            height: items.length > 0 ? '175px' : '50px',
            overflow: 'auto',
          }}
        >
          {sortItems(items).map((item) => (
            <ListItemCart
              key={item.id}
              item={item}
              onDelete={handleRemoveItemFromCart}
            />
          ))}
        </ListGroup>
        <Row>
          <Column
            md={{ span: 7 }}
            sm={7}
            xs={5}
            id='titles'
            style={{ paddingLeft: '10px' }}
          >
            <p style={subtotalStyle}>
              <b>Subtotal</b>
            </p>
          </Column>
          <Column md={3} sm={3} xs={5} style={{ textAlign: 'right' }}>
            <span style={totalPriceText}> {currency(totalPrice)} MXN</span>
          </Column>
        </Row>
        {/* <div style={priceStyle}>
                    <p style={subtotalStyle}><b>Subtotal</b></p>
                    <span style={totalPriceText}> ${totalPrice} MXN</span>
                </div> */}
        <Row>
          <Col xs={6}>
            <OrangeButton
              onClick={() => {
                if (isOrder) {
                  history.push('/checkout');
                  window.location.reload();
                } else {
                  history.push('/checkout');
                }
                handleCloseModalCart();
              }}
            >
              Pagar {!isMobile && 'estudios '} ahora
            </OrangeButton>
          </Col>
          <Col xs={6}>
            <WhiteButton
              onClick={() => {
                history.push('/laboratorio-clinico');
                handleCloseModalCart();
              }}
            >
              Buscar mas {!isMobile && 'estudios'}
            </WhiteButton>
          </Col>
          {isDoctor && (
            <Col xs={12}>
              <GreenButton
                onClick={() => {
                  if (window.location.pathname === '/checkout' && !isOrder) {
                    history.push('/checkout?doctor=1');
                    window.location.reload();
                  } else {
                    history.push('/checkout?doctor=1');
                  }
                  handleCloseModalCart();
                }}
              >
                Generar orden {!isMobile && 'para paciente'}
              </GreenButton>
            </Col>
          )}
        </Row>
      </div>
    </center>
  );
};
