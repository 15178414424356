import { useState } from 'react';
import styled from 'styled-components';
import { UilQrcodeScan } from '@iconscout/react-unicons';
import { QrReader } from 'react-qr-reader';
import { Button } from 'react-bootstrap';
import { getNL, setCurrentCheckin } from '../../actions/checkin';
import { useDispatch } from 'react-redux';

export const Scan = ({ onScan }) => {
  // QR
  const [qrText, setData] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState();

  const getData = async (result) => {
    try {
      const nlResult = await getNL(result);
      if (nlResult?.data) {
        console.log('RES: ', nlResult.data);
        dispatch(setCurrentCheckin(nlResult.data));
        // setData(encode(result?.text).replace('=', ''));
        setData(`Bienvenido, ${nlResult.data.name}`);
        console.log('Pagado??? ', nlResult.data.drive.fase === 0);
        onScan({
          pay: nlResult.data.drive.fase === 0,
          sampleTaked: false,
        });
        setShowScanner(!showScanner);
      } else {
        setData('No se encontró checkin');
      }
    } catch (error) {
      setData('No se encontró checkin');
    }
  };

  return (
    <ScanDiv>
      <h1>Escanea el código QR</h1>
      <div className='form-group'>
        <input
          type='text'
          className='form-control'
          name='reader'
          autoFocus={true}
          onChange={(e) => {
            if (e.target.value.includes('labopat.mx/completar-cita?order=')){
              window.location.href = e.target.value;
            }else if (e.target.value > 160000) {
              getData(e.target.value);
            }
          }}
        />
      </div>
      <h1 style={{ margin: '10px 0', fontSize: '25px', color: 'black' }}>
        {qrText}
      </h1>
    </ScanDiv>
  );
};

const btnScanStyle = {
  margin: '15px 0',
};

const containerStyle = {
  // border: '3px solid red',
  width: '286px',
  borderRadius: '15px',
  margin: '0',
  padding: '0px',
};

const videoContainerStyle = {
  borderRadius: '15px',
  width: '101%',
  height: '215px',
  padding: '0px',
  // border: '3px solid blue',
  margin: '0px',
};

const videoStyle = {
  borderRadius: '15px',
  width: '100%',
  height: '100%',
  display: 'block',
  transform: 'scaleX(-1)',
  // border: '3px solid green',
  padding: '0px',
  margin: '0px',
};

const ScanDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  > div svg {
    color: #00ad90;
  }
  > h1 {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #00ad90;
  }
`;

const Qr = styled.div`
  padding: 20px;
  border-radius: 16px;
`;
