import React, { useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

const InfoDiv = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 14px;
  color: #717171;
  position: relative;
  span {
    color: #3b3b3b;
  }
  h4 {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
  }
  #edit-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
`;

const Birthday = styled(Row)`
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    :focus {
      box-shadow: none;
      filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
      border-radius: 10px;
      background: #ffffff;
      border-radius: 10px;
    }
  }
  .border-div {
    border-radius: 12px;
    padding: 12px 2px 2px;
  }
  .border-div:focus-within {
    background: linear-gradient(90deg, #5731b2 -1.87%, #77ccd5 100%);
  }

  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

let days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
let months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 2px;
  border-radius: 12px;
  transition: all ease 0.5s;
  position: relative;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
  .form-control {
    border: none;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 20px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  .form-select {
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 40px !important;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: "IBM Plex Sans";
    transition: 300ms;
  }
  :active,
  :focus-within {
    background: linear-gradient(90deg, #5731b2 -1.87%, #77ccd5 100%);
    label {
      margin: 5px 0 0 35px;
      padding: 4px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  background: rgba(77, 153, 229, 0.1);
  border: none;
  border-radius: 10px !important;
  height: 60px;
  padding-left: 20px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :focus {
    filter: drop-shadow(0px 0px 8px #5731b2);
    box-shadow: none;
    border-color: #00000000;
    border-radius: 10px;
    background: #ffffff;
  }
`;

const years = [];
for (var i = 2024; i >= 1918; i--) {
  years.push(i.toString());
}

export const PersonalForm = (props) => {
  const personalData = useSelector((state) => state.shoppingCart.personalData);
  const [necesitoFactura, setNecesitoFactura] = useState(0);
  const [medicoTratante, setMedicoTratante] = useState(0);
  const [persona, setPersona] = useState(1);
  const [regimen, setRegimen] = useState([
    { id: 1, nombre: 'General de Ley Personas Morales', clave: '601', tipo: 2 },
    {
      id: 2,
      nombre: 'Personas Morales con Fines no Lucrativos',
      clave: '603',
      tipo: 2,
    },
    {
      id: 3,
      nombre: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
      clave: '605',
      tipo: 1,
    },
    { id: 4, nombre: 'Arrendamiento', clave: '606', tipo: 1 },
    {
      id: 5,
      nombre: 'Régimen de Enajenación o Adquisición de Bienes',
      clave: '607',
      tipo: 1,
    },
    { id: 6, nombre: 'Demás ingresos', clave: '608', tipo: 1 },
    {
      id: 7,
      nombre:
        'Residentes en el Extranjero sin Establecimiento Permanente en México',
      clave: '610',
      tipo: 0,
    },
    {
      id: 8,
      nombre: 'Ingresos por Dividendos (socios y accionistas)',
      clave: '611',
      tipo: 1,
    },
    {
      id: 9,
      nombre: 'Personas Físicas con Actividades Empresariales y Profesionales',
      clave: '612',
      tipo: 1,
    },
    { id: 10, nombre: 'Ingresos por intereses', clave: '614', tipo: 1 },
    {
      id: 11,
      nombre: 'Régimen de los ingresos por obtención de premios',
      clave: '615',
      tipo: 1,
    },
    { id: 12, nombre: 'Sin obligaciones fiscales', clave: '616', tipo: 1 },
    {
      id: 13,
      nombre:
        'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
      clave: '620',
      tipo: 2,
    },
    { id: 14, nombre: 'Incorporación Fiscal', clave: '621', tipo: 1 },
    {
      id: 15,
      nombre: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
      clave: '622',
      tipo: 2,
    },
    {
      id: 16,
      nombre: 'Opcional para Grupos de Sociedades',
      clave: '623',
      tipo: 2,
    },
    { id: 17, nombre: 'Coordinados', clave: '624', tipo: 2 },
    {
      id: 18,
      nombre:
        'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
      clave: '625',
      tipo: 1,
    },
    {
      id: 19,
      nombre: 'Régimen Simplificado de Confianza',
      clave: '626',
      tipo: 0,
    },
  ]);
  const [cfdi, setcfdi] = useState([
    //{ id: 1,clave: "G01",nombre: "Adquisición de mercancías.",tipo: 0 },
    //{ id: 2,clave: "G02",nombre: "Devoluciones, descuentos o bonificaciones.",tipo: 0 },
    { id: 3, clave: 'G03', nombre: 'Gastos en general.', tipo: 0 },
    /*{ id: 4,clave: "I01",nombre: "Construcciones.",tipo: 0 },
      { id: 5,clave: "I02",nombre: "Mobiliario y equipo de oficina por inversiones.",tipo: 0 },
      { id: 6,clave: "I03",nombre: "Equipo de transporte.",tipo: 0 },
      { id: 7,clave: "I04",nombre: "Equipo de computo y accesorios.",tipo: 0 },
      { id: 8,clave: "I05",nombre: "Dados, troqueles, moldes, matrices y herramental.",tipo: 0 },
      { id: 9,clave: "I06",nombre: "Comunicaciones telefónicas.",tipo: 0 },
      { id: 10,clave: "I07",nombre: "Comunicaciones satelitales.",tipo: 0 },
      { id: 11,clave: "I08",nombre: "Otra maquinaria y equipo.",tipo: 0 },*/
    {
      id: 12,
      clave: 'D01',
      nombre: 'Honorarios médicos, dentales y gastos hospitalarios.',
      tipo: 1,
    },
    {
      id: 13,
      clave: 'D02',
      nombre: 'Gastos médicos por incapacidad o discapacidad.',
      tipo: 1,
    },
    { id: 14, clave: 'D03', nombre: 'Gastos funerales.', tipo: 1 },
    /*{ id: 15,clave: "D04",nombre: "Donativos.",tipo: 1 },
      { id: 16,clave: "D05",nombre: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",tipo: 1 },
      { id: 17,clave: "D06",nombre: "Aportaciones voluntarias al SAR.",tipo: 1 },*/
    {
      id: 18,
      clave: 'D07',
      nombre: 'Primas por seguros de gastos médicos.',
      tipo: 1,
    },
    /*{ id: 19,clave: "D08",nombre: "Gastos de transportación escolar obligatoria.",tipo: 1 },
      { id: 20,clave: "D09",nombre: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",tipo: 1 },
      { id: 21,clave: "D10",nombre: "Pagos por servicios educativos (colegiaturas).",tipo: 1 },*/
    { id: 22, clave: 'S01', nombre: 'Sin efectos fiscales.', tipo: 0 },
    /*{ id: 23,clave: "CP01",nombre: "Pagos",tipo: 0 },
      { id: 24,clave: "CN01",nombre: "Nómina",tipo: 1 },*/
  ]);

  const wrongStyle = {
    outline: "1px solid rgba(255,0,0,0.2)",
    WebkitBoxShadow: "0px 0px 5px 5px rgba(255,0,0,0.4)",
    boxShadow: "0px 0px 3px 3px rgba(255,0,0,0.4)",
  };
  
  return (
    <>
      <hr />
      {/*<RegularP>
        {props.user?.doctor ? '' : 'Escribe los datos para la solicitud'}
      </RegularP>*/}
      <StyledLabel controlId='floatingName' label='Nombre'>
        <StyledInput
          type='text'
          {...props.register('name', {
            required: true,
          })}
          placeholder='Nombre'
          value={
            props.orderToPatient
              ? null
              : props.state.name
              ? props.state.name
              : personalData
              ? personalData.nombre
              : null
          }
          onChange={props.handleChange}
        />
      </StyledLabel>
      {/* {errors.name ? <p>{errors.name.message}</p> : null} */}
      <StyledLabel controlId='floatingSecondName' label='Apellido paterno'>
        <StyledInput
          type='text'
          {...props.register('fathersLastname', {
            required: true,
          })}
          placeholder='Apellido Paterno'
          value={
            props.orderToPatient
              ? null
              : props.state.fathersLastname
              ? props.state.fathersLastname
              : personalData
              ? personalData.app
              : null
          }
          onChange={props.handleChange}
        />
      </StyledLabel>
      <StyledLabel controlId='floatingLastName' label='Apellido materno'>
        <StyledInput
          type='text'
          {...props.register('mothersLastname', {
            required: true,
          })}
          placeholder='Apellido Materno'
          value={
            props.orderToPatient
              ? null
              : props.state.mothersLastname
              ? props.state.mothersLastname
              : personalData
              ? personalData.apm
              : null
          }
          onChange={props.handleChange}
        />
      </StyledLabel>
      <StyledPhoneInput
        {...props.register('phone', {
          required: false,
        })}
        international
        defaultCountry='MX'
        placeholder='Teléfono celular'
        id='phoneNumber'
        value={
          props.orderToPatient
            ? null
            : props.state.phone
            ? props.state.phone
            : personalData
            ? personalData.telContacto
            : null
        }
        onChange={(phone) => {
          props.handleChange({ target: { value: phone, name: "phone" } });
        }}
        // onChange={props.handleChange}
      />
      <StyledLabel
        controlId='floatingInput'
        label='Correo electrónico del paciente'
        className='mb-3'
      >
        <StyledInput
          {...props.register('email', {
            required: true,
            pattern: {
              value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
              message: 'El correo no cumple con el formato',
            },
          })}
          type='email'
          placeholder='name@example.com'
          value={
            props.orderToPatient
              ? null
              : props.state.email
              ? props.state.email
              : personalData
              ? personalData.emailContacto
              : null
          }
          onChange={props.handleChange}
        />
      </StyledLabel>
      {console.log(props.state)}

      <RegularP>Fecha de nacimiento</RegularP>
      <Birthday>
        <Col xs={4} style={{ paddingLeft: '0px' }}>
          <Form.Label>Día</Form.Label>
          <div className="border-div">
          <Form.Select
            {...props.register('day', {
              required: true,
              pattern: {
                value: /[0-9]/i,
                message: 'Selecciona una fecha',
              },
            })}
            defaultValue={
              props.orderToPatient
                ? 'DD'
                : props.state.birthday && props.state.birthday !== ''
                ? props.state.birthday.substring(0, 2)
                : personalData
                ? personalData.fnacimiento.substring(8, 10)
                : 'DD'
            }
            onChange={props.handleChange}
          >
            <option disabled hidden>
              DD
            </option>
            {days.map((day, index) => (
              <option value={day} key={index}>
                {day}
              </option>
            ))}
          </Form.Select>
          </div>
        </Col>
        <Col xs={4} style={{ paddingLeft: '0px' }}>
          <Form.Label>Mes</Form.Label>
          <div className="border-div">
          <Form.Select
            {...props.register('month', {
              required: true,
              pattern: {
                value: /[0-9]/i,
                message: 'Selecciona una fecha',
              },
            })}
            defaultValue={
              props.orderToPatient
                ? 'MM'
                : props.state.birthday && props.state.birthday !== ''
                ? props.state.birthday.substring(3, 5)
                : personalData
                ? personalData.fnacimiento.substring(5, 7)
                : 'MM'
            }
            onChange={props.handleChange}
          >
            <option disabled hidden>
              MM
            </option>
            {months.map((month, index) => (
              <option value={month} key={index}>
                {month}
              </option>
            ))}
          </Form.Select>
          </div>
        </Col>
        <Col xs={4} style={{ paddingRight: '0px' }}>
          <Form.Label>Año</Form.Label>
          <div className="border-div">
          <Form.Select
            className='form-control'
            {...props.register('year', {
              required: true,
              pattern: {
                value: /[0-9]/i,
                message: 'Selecciona una fecha',
              },
            })}
            defaultValue={
              props.orderToPatient
                ? 'AAAA'
                : props.state.birthday && props.state.birthday !== ''
                ? props.state.birthday.substring(6, 11)
                : personalData
                ? personalData.fnacimiento.substring(0, 4)
                : 'AAAA'
            }
            onChange={props.handleChange}
          >
            <option disabled hidden>
              AAAA
            </option>
            {years.map((year, index) => (
              <option value={year} key={index}>
                {year}
              </option>
            ))}
          </Form.Select>
          </div>
        </Col>
      </Birthday>
      <br />
      <br />
      <RegularP>Sexo</RegularP>
      <Form.Group controlId='genderM'>
        <Form.Check
          {...props.register('gender', {
            required: true,
          })}
          value='Masculino'
          type='radio'
          aria-label='radio 1'
          label='Masculino'
          checked={
            props.orderToPatient
              ? null
              : props.state.gender
              ? props.state.gender === 'Masculino'
              : personalData
              ? personalData.genero === 'Masculino'
              : false
          }
          onChange={props.handleChange}
          // checked={true} //Add validation with user info
        />
      </Form.Group>
      <hr />
      <Form.Group controlId='genderF'>
        <Form.Check
          {...props.register('gender', {
            required: true,
          })}
          value='Femenino'
          type='radio'
          aria-label='radio 3'
          label='Femenino'
          checked={
            props.orderToPatient
              ? null
              : props.state.gender
              ? props.state.gender === 'Femenino'
              : personalData
              ? personalData.genero === 'Femenino'
              : false
          }
          onChange={props.handleChange}
        />
      </Form.Group>
      
      {/*!props.orderToPatient ? (
        <>
          <br />
          <RegularP>¿Cuentas con médico tratante?</RegularP>
          <Form.Group controlId='medicoTratanteTrue'>
            <Form.Check
              {...props.register('medicoTratante', {
                required: true,
              })}
              value={1}
              type='radio'
              aria-label='radio 1'
              label='Sí'
              checked={medicoTratante === 1 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setMedicoTratante(1);
              }}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId='medicoTratanteFalse'>
            <Form.Check
              {...props.register('medicoTratante', {
                required: true,
              })}
              value={0}
              type='radio'
              aria-label='radio 3'
              label='No'
              checked={medicoTratante === 0 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setMedicoTratante(0);
              }}
            />
          </Form.Group>
          <input type='hidden' name='refMedico' value=' ' id='refMedico' />
        </>
      ) : (
        <>
          <input type='hidden' name='refMedico' value='11315' id='refMedico' />
        </>
      )}

      {medicoTratante === 1 && (
        <>
          <FloatingLabel
            controlId='floatingName'
            label='Nombre del Médico(a) *'
          >
            <Form.Control
              type='text'
              {...props.register('doctorName', {
                required: true,
              })}
              placeholder='Nombre del Médico(a) *'
              // value={props.orderToPatient ? null : props.state.name}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId='floatingInput'
            label='E-mail del Médico(a)'
            className='mb-3'
          >
            <Form.Control
              {...props.register('doctorEmail', {
                required: false,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: 'El correo no cumple con el formato',
                },
              })}
              type='email'
              placeholder='E-mail del Médico(a) *'
              // value={}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <Form.Check
            {...props.register('shareResults', {})}
            type='checkbox'
            id='shareResults'
            label='Acepto enviar los resultados al médico tratante'
            onChange={props.handleChange}
          />
        </>
            )*/}

      {/* Billing info */}
      {!props.orderToPatient && (
        <>
          <br />
          <RegularP>Necesito factura *</RegularP>
          <Form.Group controlId='facturaTrue'>
            <Form.Check
              {...props.register('necesitoFactura', {
                required: true,
              })}
              value={1}
              type='radio'
              aria-label='radio 1'
              label='Sí'
              checked={necesitoFactura === 1 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setNecesitoFactura(1);
              }}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId='necesitoFactura'>
            <Form.Check
              {...props.register('necesitoFactura', {
                required: true,
              })}
              value={0}
              type='radio'
              aria-label='radio 3'
              label='No'
              checked={necesitoFactura === 0 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setNecesitoFactura(0);
              }}
            />
          </Form.Group>
        </>
      )}
      {necesitoFactura === 1 && (
        <>
          <br />
          <h1 style={{ textAlign: 'center', fontSize: '20px' }}>
            Datos de facturación
          </h1>
          <FloatingLabel controlId='floatingRFC' label='RFC *'>
            <Form.Control
              type='text'
              {...props.register('rfc', {
                required: true,
              })}
              placeholder='RFC *'
              // value={props.orderToPatient ? null : props.state.name}
              // onChange={props.handleChange}
              onChange={(e) => {
                props.handleChange(e);
                var valor = e.target.value;
                console.log(valor);
                if (isNaN(valor[3])) {
                  setPersona(1);
                  console.log('fisica');
                } else {
                  setPersona(2);
                  console.log('moral');
                }
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingName' label='Nombre/razón social *'>
            <Form.Control
              type='text'
              {...props.register('razon', {
                required: true,
              })}
              placeholder='Nombre/razón social'
              // value={props.orderToPatient ? null : props.state.name}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId='floatingInput'
            label='Correo electrónico *'
            className='mb-3'
          >
            <Form.Control
              {...props.register('emailRFC', {
                required: true,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: 'El correo no cumple con el formato',
                },
              })}
              type='email'
              placeholder='name@example.com'
              // value={
              //   props.orderToPatient
              //     ? null
              //     : props.state.email
              // }
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <Form.Label>Regimen fiscal *</Form.Label>
          <Form.Select
            className='form-control'
            {...props.register('regimen', {
              required: true,
            })}
            defaultValue={props.regimen ? 'Selecciona' : 'Selecciona'}
            onChange={(e) => {
              props.handleChange(e);
              if (e.target.value == '605') {
                setcfdi([
                  {
                    id: 12,
                    clave: 'D01',
                    nombre:
                      'Honorarios médicos, dentales y gastos hospitalarios.',
                    tipo: 1,
                  },
                  {
                    id: 13,
                    clave: 'D02',
                    nombre: 'Gastos médicos por incapacidad o discapacidad.',
                    tipo: 1,
                  },
                  {
                    id: 14,
                    clave: 'D03',
                    nombre: 'Gastos funerales.',
                    tipo: 1,
                  },
                  {
                    id: 18,
                    clave: 'D07',
                    nombre: 'Primas por seguros de gastos médicos.',
                    tipo: 1,
                  },
                  {
                    id: 22,
                    clave: 'S01',
                    nombre: 'Sin efectos fiscales.',
                    tipo: 0,
                  },
                ]);
              } else if (e.target.value == '610') {
                setcfdi([
                  {
                    id: 22,
                    clave: 'S01',
                    nombre: 'Sin efectos fiscales.',
                    tipo: 0,
                  },
                ]);
              } else {
                setcfdi([
                  {
                    id: 3,
                    clave: 'G03',
                    nombre: 'Gastos en general.',
                    tipo: 0,
                  },
                  {
                    id: 12,
                    clave: 'D01',
                    nombre:
                      'Honorarios médicos, dentales y gastos hospitalarios.',
                    tipo: 1,
                  },
                  {
                    id: 13,
                    clave: 'D02',
                    nombre: 'Gastos médicos por incapacidad o discapacidad.',
                    tipo: 1,
                  },
                  {
                    id: 14,
                    clave: 'D03',
                    nombre: 'Gastos funerales.',
                    tipo: 1,
                  },
                  {
                    id: 18,
                    clave: 'D07',
                    nombre: 'Primas por seguros de gastos médicos.',
                    tipo: 1,
                  },
                  {
                    id: 22,
                    clave: 'S01',
                    nombre: 'Sin efectos fiscales.',
                    tipo: 0,
                  },
                ]);
              }
            }}
          >
            <option value='Selecciona'>Selecciona</option>
            {regimen.map((reg) =>
              persona === 1 ? (
                reg.tipo === 1 || reg.tipo === 0 ? (
                  <option value={reg.clave}>
                    {reg.clave} {reg.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : persona === 2 ? (
                reg.tipo === 2 || reg.tipo === 0 ? (
                  <option value={reg.clave}>
                    {reg.clave} {reg.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            )}
          </Form.Select>
          <FloatingLabel controlId='floatingCalle1' label='Calle *'>
            <Form.Control
              type='text'
              {...props.register('calle1', {
                required: true,
              })}
              placeholder='Calle'
              // value={props.calle1 ? props.calle1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingNext1' label='No. Exterior *'>
            <Form.Control
              type='text'
              {...props.register('next1', {
                required: true,
              })}
              placeholder='No. Exterior *'
              // value={props.next1 ? props.next1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingNint1' label='No. Interior *'>
            <Form.Control
              type='text'
              {...props.register('nint1', {
                required: true,
              })}
              placeholder='No. Interior *'
              // value={props.nint1 ? props.nint1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingCP1' label='C.P. *'>
            <Form.Control
              type='text'
              {...props.register('cp1', {
                required: true,
              })}
              placeholder='C.P. *'
              // value={props.cp1 ? props.cp1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingColonia1' label='Colonia *'>
            <Form.Control
              type='text'
              {...props.register('colonia1', {
                required: true,
              })}
              placeholder='Colonia *'
              // value={props.colonia1 ? props.colonia1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingMunicipio1' label='Municipio *'>
            <Form.Control
              type='text'
              {...props.register('municipio1', {
                required: true,
              })}
              placeholder='Municipio *'
              // value={props.municipio1 ? props.municipio1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingEstado1' label='Estado *'>
            <Form.Control
              type='text'
              {...props.register('estado1', {
                required: true,
              })}
              placeholder='Estado *'
              // value={props.estado1 ? props.estado1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId='floatingPais1' label='País *'>
            <Form.Control
              type='text'
              {...props.register('pais1', {
                required: true,
              })}
              placeholder='País'
              // value={props.pais1 ? props.pais1 : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <br />
          <Form.Label>Uso de la factura *</Form.Label>
          <Form.Select
            className='form-control'
            {...props.register('usoFactura', {
              required: true,
            })}
            defaultValue={'Selecciona'}
            onChange={props.handleChange}
          >
            <option value='Selecciona'>Selecciona</option>
            {cfdi.map((c) =>
              persona === 1 ? (
                c.tipo === 1 || c.tipo === 0 ? (
                  <option value={c.clave}>
                    {c.clave} {c.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : persona === 2 ? (
                c.tipo === 2 || c.tipo === 0 ? (
                  <option value={c.clave}>
                    {c.clave} {c.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            )}
          </Form.Select>
          <FloatingLabel
            controlId='floatingcomentarioFactura'
            label='Comentarios y observaciones facturación'
          >
            <Form.Control
              as='textarea'
              {...props.register('comentarioFactura', {})}
              placeholder='Comentarios y observaciones facturación'
              // value={props.comentarioFactura ? props.comentarioFactura : null}
              onChange={props.handleChange}
            />
          </FloatingLabel>
        </>
      )}
    </>
  );
};
