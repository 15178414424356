import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { UilAngleRight, UilClipboardAlt } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemCart } from './listItemCart';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from '../../actions/shoppingCart';
import { updateCartDB } from '../../helpers/api';
import useAnalyticsEventTracker from '../../analytics/useAnalyticsEventTracker';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    text-align: left;
    color: #7A4DC7;
  }
  .ant-drawer-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #7A4DC7;
  }
  .ant-drawer-content-wrapper {
    width: 540px !important;
    @media only screen and (max-width: 992px) {
      width: 480px !important;
    }
    @media only screen and (max-width: 768px) {
      width: 400px !important;
    }
    @media only screen and (max-width: 576px) {
      max-width: 390px;
      width: 100% !important;
    }
  }
`;

const Title = styled.p`
  color: #03aea2;
  font-size: 1.1rem;
  font-family: 'Assistant', sans-serif;
`;

const Total = styled.h5`
  color: #45494a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #c1d9d8; */
  position: relative;
  text-align: center;
  span {
    right: 0px;
    color: #7A4DC7;
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: #02A1B5;
  border: 1px solid #02A1B5;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #02A1B5;
  }
`;

const PayButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: #02A1B5;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #64c3d0;
    border: 1px solid #02A1B5;
  }
  :disabled {
    background: #c4c4c4;
    color: #fff;
    border: 2px solid #c4c4c4;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const Bottom = styled.div`
  background: #fff;
  border-top: 1px solid #c1d9d8;
  position: absolute;
  bottom: 0px;
  width: 85%;
  padding-top: 10px;
  p {
    text-align: center;
    margin-bottom: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  span {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: rgb(122, 77, 199);
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 20px;
    height: 20px;
    border: 2px solid #00ad90;
  }
  label {
    margin-bottom: -20px;
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  padding-right: 3px;
  margin-right: -5px;
  max-height: 50vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #45494a;
    margin-bottom: 40px;
  }
`;

const Advice = styled.div`
  text-align: center;
  p {
    display: inline;
    width: auto;
    margin: 0;
    font-family: Rounded Mplus 1c, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #45494A;
  }
  .blueText {
    width: auto;
    margin: 0;
    font-family: Rounded Mplus 1c, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #02A1B5;
  }
`;

export const Cart = (props) => {
  const { uid } = useSelector((state) => state.auth);
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const isDoctor =
    useSelector((state) => state.user?.data?.data.profile?.type) === 1;
  const history = useHistory();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var isOrder = urlParams.get('doctor');
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker('Cart');

  useEffect(() => {
    // console.log('---| ' + props.visible);
  }, [props.visible]);

  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <StyledDrawer
      id="studies"
      headerStyle={{
        fontFamily: 'Rounded Mplus 1c, sans-serif',
        color: '#000',
      }}
      title='Pruebas agregadas'
      placement='right'
      closeIcon={<UilAngleRight color='#FFFFFF' size={30} />}
      onClose={props.onClose}
      visible={props.visible}
    >
      <p 
        style={{
          fontFamily: 'Assistant, sans-serif', 
          fontWeight: '800',
          fontSize: '15px'}}
        className='mb-4'>Hola, revisa tu pedido</p>
      {items?.length > 0 ? (
        <div style={{ position: 'relative', paddingBottom: '150px' }}>
          <ListDiv>
            {items.map((item, index) => (
              <ListItemCart
                index={index}
                key={item.id}
                item={item}
              />
            ))}
          </ListDiv>
          
          <Total>
            Total de pruebas: <span>{currency(totalPrice)} MXN</span>
          </Total>
          <br />
        </div>
      ) : (
        <EmptyCart>
          <div id='empty'>
            <UilClipboardAlt color='#A6A6A6' size={100} />
          </div>
          <p>No hay estudios agregados</p>
        </EmptyCart>
      )}
      <Bottom>
          <WhiteButton
            onClick={() => {
              window.location.replace('/#studi');
              props.onClose();
            }}
          >
            Buscar más pruebas
          </WhiteButton>
        {!uid && (
          <Advice className='mt-3'>
            <p className='blueText'><u role='button' onClick={() => (window.location.href = '/iniciar-sesion')}>Iniciar sesión</u> </p>
            <p>para hacer un checkout más rápido</p>
          </Advice>
        )}
        <PayButton
          onClick={() => {
            gaEventTracker('carrito-pago-online');
            window.location.href = "/checkout";
            props.onClose();
          }}
          disabled={items?.length === 0}
        >
          Pagar ahora
        </PayButton>
        {isDoctor && (
          <p>
            ¿Eres médico?{' '}
            <span
              onClick={() => {
                gaEventTracker('carrito-generar-order');
                if (window.location.pathname === '/checkoutVG' && !isOrder) {
                  history.push('/checkoutVG?doctor=1');
                  window.location.reload();
                } else {
                  history.push('/checkoutVG?doctor=1');
                }
                props.onClose();
              }}
              className={items?.length === 0 && 'disabled'}
            >
              Genera una orden aquí
            </span>
          </p>
        )}
      </Bottom>
    </StyledDrawer>
  );
};
