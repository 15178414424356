import React from "react";
import { Accordion, Container } from "react-bootstrap";
import styled from 'styled-components';

const HelpContainer = styled(Container)`
  display: flex;
  align-items: center;
  min-height: 80vh;
  flex-direction: column;
`;

const HelpAccordion = styled(Accordion)`
  width: 80%;
  .accordion-button::after {
    margin-top: 0px;
  }
  .accordion-button {
    padding-top: 0px;
  }
`;

const Title = styled.h1`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  margin: 60px 0 40px 0;
  color: #00AD90;
`;

export const FAQs = () => {
  return (
    <>
      <HelpContainer>
        <Title>Preguntas frecuentes</Title>
        <HelpAccordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>¿Cómo solicito mis estudios?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
              est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>¿Puedo pagar mis estudios en línea?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
              est laborum.
            </Accordion.Body>
          </Accordion.Item>
        </HelpAccordion>
      </HelpContainer>
    </>
  )
};