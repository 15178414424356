import React, { useState } from "react";
import styled from "styled-components";
import { UilPadlock } from "@iconscout/react-unicons";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Doubts } from "../../../doubts/doubts";
import { Loader } from "../../../general/spinner";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 120%;
  color: #000;
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 115%;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
    line-height: 115%;
  }
`;

const Subtitle = styled.h2`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #000;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const BlueButton = styled.button`
  width: 100%;
  max-width: 208px;
  height: 40px;
  background: #fff;
  border-radius: 102px;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #24cad3;
  margin: 15px 11px 15px 0;
  border: 1px solid #24cad3;
  padding: 0;
  font-weight: 800;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #24cad3;
    border: none;
    color: #fff;
  }
  @media only screen and (max-width: 576px) {
    max-width: 146px;
    color: #24cad3;
    margin-bottom: 0px;
  }
`;

const BlueButton1 = styled.button`
  width: 100%;
  max-width: 208px;
  height: 40px;
  background: #24cad3;
  border-radius: 102px;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  margin: 15px 11px 15px 0;
  border: 1px solid #24cad3;
  padding: 0;
  font-weight: 800;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 1px solid #24cad3;
    color: #24cad3;
  }
  @media only screen and (max-width: 576px) {
    max-width: 146px;
    color: #fff;
    margin-bottom: 0px;
  }
`;

const MainContainer = styled(Container)`
  padding: 70px;
  transition: all 300ms;
  background: #f3f3f3;
  border-radius: 60px;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 992px) {
    padding: 50px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px;
    .hidden-mobile {
      display: none !important;
    }
    .col-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    border-radius: 0;
    padding: 20px;
  }
`;

const Price = styled.p`
  font-family: "Assitant", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 27px;
  color: #000;
  margin: 0;
  @media only screen and (max-width: 576px) {
    font-size: 20px;
    font-family: system-ui;
    font-weight: 300;
  }
`;

const ExtraInfo = styled.div`
  height: 100%;
  background: #ffffffcc;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  #prueba {
    width: 100%;
    margin-left: auto;
    margin-top: -95px;
    margin-right: auto;
  }
`;

const ExtraTitle = styled.h3`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin: 10px 0px 10px;
  background: #24cad3;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
`;

const ExtraText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #6a6a6a;
  margin: 0;
`;

const ExtraRow = styled(Row)`
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const StripeGuaranty = styled(Row)`
  width: 100%;
  border-radius: 10px;
  border: 2px solid #e0ecec;
  background: none;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 5px 0px;
  text-align: center;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
  #pay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #stripe-g {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #e0ecec;
    padding: 5px 20px;
    img {
      width: 55px;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 15px;
    }
  }
  #card-logos {
    width: 100%;
    max-width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px auto 0;
    img {
      width: 45.5px;
      margin-inline: -40px;
    }
  }
`;

export const StudyCafeina = () => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();
  let isMobile = width <= 992;
  const [limit, setLimit] = useState(false);
  const { studyId } = useParams();
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 974);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const addStudy = () => {
    dispatch(handleAddItemToCart(study));
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    getInfo();
  }, [studyId]);

  const footerObserver = new IntersectionObserver((entries) => {
    if (entries[0].intersectionRatio <= 0) {
      setLimit(false);
    } else {
      setLimit(true);
    }
  });

  useEffect(() => {
    footerObserver.observe(document.getElementById("footer"));
  }, []);

  return (
    <>
      <Loader show={isLoading} />
      {study.detalleCategoria && (
        <div style={{ background: "#ffffff" }}>
          <Container>{!isMobile && <Top></Top>}</Container>
        </div>
      )}
      {!isMobile && (
        <div
          style={{
            background: "#ffffff",
            position: "relative",
          }}
        >
          <MainContainer>
            <Row className="justify-content-between" id="studyDetails">
              <Col xs={12} sm={12} md={7} lg={7} xl={6} id="left-div">
                <Row>
                  <Col xs={{ span: 12, order: 3 }} md={{ span: 12, order: 1 }}>
                    <Title style={{ width: "500px" }}>{study.name}</Title>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    className="align-items-end"
                    style={{ marginBottom: "24px" }}
                  >
                    <Price className="w-auto ps-2">
                      {currency(study.normal_price)} MXN
                    </Price>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 4 }}
                    md={{ span: 12, order: 3 }}
                    style={{ padding: "0 0px 10px 0" }}
                  >
                    <BlueButton onClick={() => addStudy()}>
                      Agregar a mi carrito
                    </BlueButton>
                    <BlueButton1 onClick={() => payStudy()}>
                      Pagar ahora
                    </BlueButton1>
                  </Col>
                </Row>
                <StripeGuaranty>
                  <Col xs={12} lg={6} id="pay">
                    <p>
                      <UilPadlock size={25} />
                      &nbsp;&nbsp;Pago garantizado y seguro.
                    </p>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div id="stripe-g">
                      <p>Procesado por&nbsp;&nbsp;</p>
                      <img
                        alt="Stripe"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={{ span: 4, offset: 4 }}>
                    <div id="card-logos">
                      <img
                        alt="mastercard"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                      />
                      <img
                        alt="visa"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                      />
                      <img
                        alt="amex"
                        src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                      />
                    </div>
                  </Col>
                </StripeGuaranty>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
                  <Subtitle>Hasta 3 MSI con tarjetas participantes*.</Subtitle>
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
                  <Subtitle>Envíos a todo México</Subtitle>
                </Col>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} className="p-0">
                <ExtraInfo>
                  <img
                    id="prueba"
                    src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2FCajaCafeina1.png?alt=media&token=3234691b-0ab6-4397-84c5-af9e8e8046c7"
                    alt="prueba"
                  />
                  {study.indicaciones && (
                    <ExtraRow>
                      <ExtraTitle>Indicaciones</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                  {study.muestra && (
                    <ExtraRow>
                      <ExtraTitle>Tipo de muestra</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.muestra }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                  {study.tEntrega && (
                    <ExtraRow>
                      <ExtraTitle>Tiempo de entrega:</ExtraTitle>
                      <ExtraText
                        dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                      ></ExtraText>
                    </ExtraRow>
                  )}
                </ExtraInfo>
              </Col>
            </Row>
          </MainContainer>
          <Doubts />
        </div>
      )}
      {isMobile && (
        <div
          style={{
            background: "#F3F3F3",
            position: "relative",
          }}
        >
          <MainContainer>
            <Row className="justify-content-between" id="studyDetails">
              <Col xs={12} sm={12} md={7} lg={7} xl={6} id="left-div">
                <Row>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    className="align-items-end"
                    style={{ marginBottom: "24px" }}
                  >
                    <Price
                      className="w-auto ps-2"
                      style={{ textAlign: "center" }}
                    >
                      <Title style={{ textAlign: "center" }}>
                        {study.name}
                      </Title>
                      <br />
                      {currency(study.normal_price)} MXN
                    </Price>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 4 }}
                    md={{ span: 12, order: 3 }}
                    style={{ padding: "0 0px 10px 0" }}
                  >
                    <BlueButton onClick={() => addStudy()}>
                      Agregar a mi carrito
                    </BlueButton>
                    <BlueButton1 onClick={() => payStudy()}>
                      Pagar ahora
                    </BlueButton1>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MainContainer>
          <StripeGuaranty>
            <Col xs={12} lg={6} id="pay">
              <p>
                <UilPadlock size={25} />
                &nbsp;&nbsp;Pago garantizado y seguro.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <div id="stripe-g" style={{ background: "#e0ecec" }}>
                <p>Procesado por&nbsp;&nbsp;</p>
                <img
                  alt="Stripe"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2FStripeLogo.png?alt=media&token=753fb8f9-81e1-44e0-bf2d-fc3ac60fa5c0"
                />
              </div>
            </Col>
            <Col xs={12} lg={{ span: 4, offset: 4 }}>
              <div id="card-logos">
                <img
                  alt="mastercard"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fmastercard.png?alt=media&token=5e06628e-f7c4-44d4-93a3-8a96e5a4aeb5"
                />
                <img
                  alt="visa"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Fvisa.png?alt=media&token=5d555483-9b64-443e-836d-be2a04a694db"
                />
                <img
                  alt="amex"
                  src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Ficons%2Famex.png?alt=media&token=74e2f785-1ae5-43fa-a686-aa6cf681dd40"
                />
              </div>
            </Col>
          </StripeGuaranty>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
            <Subtitle style={{ textAlign: "center" }}>
              Hasta 3 MSI con tarjetas participantes*.
            </Subtitle>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 4 }}>
            <Subtitle style={{ textAlign: "center", marginBottom: "75px" }}>
              Envíos a todo México
            </Subtitle>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} className="p-0">
            <ExtraInfo style={{ textAlign: "center", height: "410px" }}>
              <img
                id="prueba"
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2FCajaCafeina1.png?alt=media&token=3234691b-0ab6-4397-84c5-af9e8e8046c7"
                alt="prueba"
                style={{ width: "75vw" }}
              />
              {study.indicaciones && (
                <ExtraRow>
                  <ExtraTitle>Indicaciones</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.indicaciones }}
                  ></ExtraText>
                </ExtraRow>
              )}
              {study.muestra && (
                <ExtraRow>
                  <ExtraTitle>Tipo de muestra</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.muestra }}
                  ></ExtraText>
                </ExtraRow>
              )}
              {study.tEntrega && (
                <ExtraRow>
                  <ExtraTitle>Tiempo de entrega:</ExtraTitle>
                  <ExtraText
                    dangerouslySetInnerHTML={{ __html: study.tEntrega }}
                  ></ExtraText>
                </ExtraRow>
              )}
            </ExtraInfo>
          </Col>
          <Doubts />
        </div>
      )}
    </>
  );
};
