import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { UilAngleDown, UilAngleUp, UilPlus } from "@iconscout/react-unicons";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VG70Box =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FPrueba%20VG70.png?alt=media&token=775d541c-370c-4380-a371-cb62e4bca53a";
const VG70Paq =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FPaquete%20VG70.png?alt=media&token=57de6511-b596-44f9-9774-94ef9a86ef13";
const VG70Plus =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2FPaquete%20VG70%2B.png?alt=media&token=c8d0c39a-1ec6-484d-8217-31c351ade2d3";
const CeliaquiaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCeliaquiaBoxFull.png?alt=media&token=d2113dd7-eb93-49f2-9642-368f2e18e534";
const LactosaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FLactosaBoxFull.png?alt=media&token=62cd4ce0-9ea6-4065-9f81-e161e445bd1a";
const CafeinaBox =
  "https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FCafeinaBoxFull.png?alt=media&token=2874b510-71fa-49dc-8edf-4b5be251895e";

const CardContainer = styled.div`
  background-color: #f0f0f0;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(180deg, #d44dad 0.17%, #00b8c3 119.66%);
  text-align: center;

  border-radius: 20px;
  min-height: 235px;
  padding: 16px 16px 0 16px;
  /* Estilos por defecto */
  transform: scale(1);
  :hover {
    border: 4px solid; /* Ancho del borde aumentado para mostrar el degradado */

    /* Aplicamos un linear-gradient como borde */
    border-image: linear-gradient(180deg, #d44dad 0.17%, #00b8c3 119.66%) 1; /* Aplicamos un degradado lineal */
    border-image-slice: 1;
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

const StudyCover = styled.img`
  max-height: 170px;
  margin-top: -118px;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const StudyTitle = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: ${(props) =>
    props.type === 598
      ? "#F14CAE"
      : props.type === 519 || props.type === 974
      ? "#8355B7"
      : props.type === 166
      ? "#E49D5B"
      : "#90CB15"};
  max-width: 253px;
  margin: 0;
  padding: 0;
`;

const StudyText = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 31px;
  text-align: initial;
  color: ${(props) =>
    props.type === 598
      ? "#000"
      : props.type === 1227 || props.type === 1228
      ? "#000"
      : props.type === 166
      ? "#000"
      : "#000"};
  max-width: 300px;
  margin: 0;
  padding: 0;
`;

const StudySub = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  text-align: initial;
  color: ${(props) =>
    props.type === 598
      ? "#000"
      : props.type === 1227 || props.type === 1228
      ? "#000"
      : props.type === 166
      ? "#000"
      : "#000"};
  max-width: 300px;
  margin: 0;
  padding: 0;
`;

const StudyPrice = styled.p`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  color: #f14cae;
  border: 1px solid #f14cae;
  border-radius: 10px;
  padding: 0px;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  text-align: center;
`;

const TitleButton = styled.div`
  width: auto;
  border: none;
  border-color: ${(props) =>
    props.type === 598
      ? "#F14CAE"
      : props.type === 1227
      ? "#F14CAE"
      : props.type === 1228
      ? "#F14CAE"
      : "#F14CAE"};
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: ${(props) =>
    props.type === 598
      ? "#F14CAE"
      : props.type === 1227 || props.type === 1228
      ? "#F14CAE"
      : props.type === 166
      ? "#F14CAE"
      : "#F14CAE"};

  @media only screen and (max-width: 1400px) {
    max-width: 220px;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 380px;
  }
`;

const PayButton = styled.button`
  width: auto;
  border: none;
  height: 50px;
  border-color: ${(props) =>
    props.type === 598
      ? "#24CAD3"
      : props.type === 1227
      ? "#24CAD3"
      : props.type === 1228
      ? "#24CAD3"
      : "#24CAD3"};
  border-radius: 102px;
  padding: 10px;
  width: 100%;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: ${(props) =>
    props.type === 598
      ? "#24CAD3"
      : props.type === 1227 || props.type === 1228
      ? "#24CAD3"
      : props.type === 166
      ? "#24CAD3"
      : "#24CAD3"};
  transition: 300ms;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
  :hover {
    transition: 300ms;
    background-color: ${(props) =>
      props.type === 598
        ? "#68D8EC"
        : props.type === 1227 || props.type === 1228
        ? "#68D8EC"
        : props.type === 166
        ? "#EF6534"
        : "#F14CAE"};
    color: #ffffff;
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 220px;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 380px;
  }
`;

const AddToCart = styled.button`
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 39px;
  color: #24cad3;
  padding: 0px;
  width: 100%;
  height: 50px;
  text-align: center;
  background: #fff;
  border-radius: 40px;
  border: 2px solid #24cad3;

  /* Button Shadow */
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15);
  :hover {
    transition: 300ms;
    background: #fff;
    color: ${(props) =>
      props.type === 598
        ? "#95E5F4"
        : props.type === 1227 || props.type === 1228
        ? "#95E5F4"
        : props.type === 166
        ? "#EF6534"
        : "#62e218"};
    border: ${(props) =>
      props.type === 598
        ? "1px solid #95E5F4"
        : props.type === 1227 || props.type === 1228
        ? "1px solid #95E5F4"
        : props.type === 166
        ? "1px solid #EF6534"
        : "1px solid #62e218"};
  }
  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

const MoreInfoRow = styled(Row)`
  padding: 0 34px 0;
`;

export const StudyCard = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewDetail, setViewDetail] = useState(false);
  const [viewDetail1, setViewDetail1] = useState(false);
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const getStudyInfo = async (studyId) => {
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + studyId);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const PayStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      setTimeout(() => {
        history.push("/checkout");
        window.scrollTo(0, 0);
      }, 300);
    }
  };

  const addStudy = () => {
    dispatch(handleAddItemToCart(study));
  };

  useEffect(() => {
    getStudyInfo(props.studyId);
  }, [props.studyId]);

  const Title = () => {
    return (
      <>
        {study.id === 598 ? (
          <Row className="justify-content-center mb-4">
            <TitleButton type={study.id}>Prueba VG70</TitleButton>
          </Row>
        ) : study.id === 1227 ? (
          <Row className="justify-content-center mb-4">
            <TitleButton type={study.id}>Paquete VG70</TitleButton>
          </Row>
        ) : study.id === 1228 ? (
          <Row className="justify-content-center mb-4">
            <TitleButton type={study.id}>Paquete VG70 Plus</TitleButton>
          </Row>
        ) : study.id === 974 ? (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              Prueba genética de <b>intolerancia a la cafeína</b>
            </StudyTitle>
          </Row>
        ) : (
          <Row className="justify-content-center mb-4">
            <StudyTitle type={study.id}>
              Prueba genética de riesgo de <b>intolerancia a la lactosa</b>
            </StudyTitle>
          </Row>
        )}
      </>
    );
  };

  const Price = () => {
    return (
      <Row className="justify-content-center align-items-end">
        <StudyPrice>{currency(study.normal_price)} MXN</StudyPrice>
      </Row>
    );
  };

  const Text = ({ type, children, eventKey }) => {
    const onClick = useAccordionButton(eventKey);
    return (
      <Row className="justify-content-center align-items-end mb-4">
        <StudyText className="mb-2">
          <b>¿Qué incluye?</b>
        </StudyText>
        {study.id === 598 ? (
          <Row className="justify-content-center">
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              Prueba genética VG70
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheckout.png?alt=media&token=026ea7c6-25c6-401b-8a5a-bbeba9e79861"
                alt="check"
                style={{ maxWidth: "20px", opacity: "1" }}
              />{" "}
              <span
                onClick={() => setViewDetail(!viewDetail)}
                style={{ opacity: "0.5", cursor: "pointer" }}
              >
                {viewDetail ? "Check up nutricio" : "Check up nutricio"}
                {viewDetail ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                )}
                {viewDetail && (
                  <MoreInfoRow>
                    <ul>
                      <li>Medición inbody</li>
                      <li>Signos vitales</li>
                      <li>Glucosa</li>
                      <li>Recomendaciones personalizadas</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheckout.png?alt=media&token=026ea7c6-25c6-401b-8a5a-bbeba9e79861"
                alt="check"
                style={{ maxWidth: "20px", opacity: "1" }}
              />{" "}
              <span
                onClick={() => setViewDetail1(!viewDetail1)}
                style={{ opacity: "0.5", cursor: "pointer" }}
              >
                {viewDetail1
                  ? "Estudios de laboratorio"
                  : "Estudios de laboratorio"}
                {viewDetail1 ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                )}
                {viewDetail1 && (
                  <MoreInfoRow>
                    <ul>
                      <li>Examen general de orina</li>
                      <li>Biometria hemática</li>
                      <li>Química sanguínea de 6 elementos</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheckout.png?alt=media&token=026ea7c6-25c6-401b-8a5a-bbeba9e79861"
                alt="check"
                style={{ maxWidth: "20px", opacity: "1" }}
              />{" "}
              <span style={{ opacity: "0.5" }}>Consulta de nutrición</span>
            </StudySub>
          </Row>
        ) : study.id === 1227 ? (
          <Row className="justify-content-center">
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              Prueba genética VG70
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              <span
                onClick={() => setViewDetail(!viewDetail)}
                style={{ cursor: "pointer" }}
              >
                {viewDetail ? "Check up nutricio" : "Check up nutricio"}
                {viewDetail ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                )}
                {viewDetail && (
                  <MoreInfoRow>
                    <ul>
                      <li>Medición inbody</li>
                      <li>Signos vitales</li>
                      <li>Glucosa</li>
                      <li>Recomendaciones personalizadas</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheckout.png?alt=media&token=026ea7c6-25c6-401b-8a5a-bbeba9e79861"
                alt="check"
                style={{ maxWidth: "20px", opacity: "1" }}
              />{" "}
              <span
                onClick={() => setViewDetail1(!viewDetail1)}
                style={{ opacity: "0.5", cursor: "pointer" }}
              >
                {viewDetail1
                  ? "Estudios de laboratorio"
                  : "Estudios de laboratorio"}
                {viewDetail1 ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#F52C65" }}
                  />
                )}
                {viewDetail1 && (
                  <MoreInfoRow>
                    <ul>
                      <li>Examen general de orina</li>
                      <li>Biometria hemática</li>
                      <li>Química sanguínea de 6 elementos</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheckout.png?alt=media&token=026ea7c6-25c6-401b-8a5a-bbeba9e79861"
                alt="check"
                style={{ maxWidth: "20px", opacity: "1" }}
              />{" "}
              <span style={{ opacity: "0.5" }}>Consulta de nutrición</span>
            </StudySub>
          </Row>
        ) : study.id === 1228 ? (
          <Row className="justify-content-center">
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              Prueba genética VG70
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              <span
                onClick={() => setViewDetail(!viewDetail)}
                style={{ cursor: "pointer" }}
              >
                {viewDetail ? "Check up nutricio" : "Check up nutricio"}
                {viewDetail ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                )}
                {viewDetail && (
                  <MoreInfoRow>
                    <ul>
                      <li>Medición inbody</li>
                      <li>Signos vitales</li>
                      <li>Glucosa</li>
                      <li>Recomendaciones personalizadas</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              <span
                onClick={() => setViewDetail1(!viewDetail1)}
                style={{ cursor: "pointer" }}
              >
                {viewDetail1
                  ? "Estudios de laboratorio"
                  : "Estudios de laboratorio"}
                {viewDetail1 ? (
                  <UilAngleUp
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                ) : (
                  <UilAngleDown
                    size="28"
                    style={{ cursor: "pointer", color: "#24cad3" }}
                  />
                )}
                {viewDetail1 && (
                  <MoreInfoRow>
                    <ul>
                      <li>Examen general de orina</li>
                      <li>Biometria hemática</li>
                      <li>Química sanguínea de 6 elementos</li>
                    </ul>
                  </MoreInfoRow>
                )}
              </span>
            </StudySub>
            <StudySub type={study.id}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/vg70%2Fcheck.png?alt=media&token=8277f9bd-506b-49a0-af3e-097c0696b25d"
                alt="check"
                style={{ maxWidth: "20px" }}
              />{" "}
              Consulta de nutrición
            </StudySub>
          </Row>
          
        ) : null}
      </Row>
    );
  };

  return (
    <>
      <CardContainer className="mt-5 mb-0 d-flex flex-column justify-content-between">
        <Row className="justify-content-center mb-2">
          <div className="p-0" style={{ maxWidth: "100%" }}>
            <StudyCover
              src={
                study.id === 598
                  ? VG70Box
                  : study.id === 1227
                  ? VG70Paq
                  : study.id === 1228
                  ? VG70Plus
                  : study.id === 974
                  ? CafeinaBox
                  : LactosaBox
              }
              alt="Imagen de caja"
              className="img-fluid"
            />
          </div>
        </Row>
        <div className="p-0">
          <Title />
          <Price />
          <Text />
          <AddToCart
            className="justify-content-center mb-4"
            type={study.id}
            onClick={() => addStudy()}
          >
            <UilPlus />
            Añadir al carrito
          </AddToCart>
          <br />
          <Row className="justify-content-center mb-2">
            <PayButton type={study.id} onClick={() => PayStudy()}>
              Comprar ahora
            </PayButton>
          </Row>
        </div>
      </CardContainer>
    </>
  );
};
