import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Modalvg } from "./Modalvg";
import { Modalmov } from "./Modalmov";

const Results =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Frep1.png?alt=media&token=c8fec4fb-4d51-4f75-9f51-d80f0245bfb5&_gl=1*1frup5c*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODcwNDMyNi4xMjQuMS4xNjk4NzA0NjI5LjQ2LjAuMA..";
const Alterations =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Frep2.png?alt=media&token=ad6db63b-03d6-4b08-ba8f-23a9fe188f6a&_gl=1*1u0q009*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODcwNDMyNi4xMjQuMS4xNjk4NzA0NzU2LjYwLjAuMA..";
const Biomarkers =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Frep3.png?alt=media&token=d90f00be-6bdd-45fb-9a5d-de54d64622b4&_gl=1*k59qv1*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODcwNDMyNi4xMjQuMS4xNjk4NzA0Nzk4LjE4LjAuMA..";
const Therapies =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Frep4.png?alt=media&token=e6d4230b-daa1-4376-83e4-e78cd4aa495a&_gl=1*sccz3g*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODcwNDMyNi4xMjQuMS4xNjk4NzA0ODE3LjYwLjAuMA..";

const Title = styled.h1`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000;
  width: 480px;

  padding: 0;
  p {
    font-weight: 500;
    font-size: 20px;
  }
  @media only screen and (max-width: 576px) {
    width: 323px;
    font-size: 30px;
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #00b9c4;
  border-radius: 40px;
  border: none;
  text-align: center;
  width: 360px;
  height: 60px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 151px;
    font-size: 14px;
  }
`;

const AxionaDocuments = styled.div`
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  .first {
    width: 410px;
    /* position: absolute; */
    /* left: -20px; */
    /* right: 0; */
    margin-left: 450px;
    margin-right: auto;
    transition: all 300ms;
    @media only screen and (max-width: 1366px) {
      margin-left: 385px;
    }
  }
  .second {
    transition: all 300ms;
    width: 410px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .third {
    transition: all 300ms;
    width: 410px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
  }
  .mask {
    width: 410px;
  }
  @media only screen and (max-width: 992px) {
    .second {
      top: 5%;
    }
    .third {
      top: 5%;
    }
  }
  @media only screen and (max-width: 768px) {
    .first {
      margin-left: 0px;
      max-width: 380px;
      height: auto;
    }
    .second {
      max-width: 320px;
      height: auto;
    }
    .third {
      max-width: 320px;
      height: auto;
    }
    .mask {
      max-width: 320px;
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 400px) {
    .first {
      max-width: 100%;
      height: auto;
    }
    .second {
      max-width: 85%;
      height: auto;
    }
    .third {
      max-width: 85%;
      height: auto;
    }
    .mask {
      max-width: 85%;
      width: auto;
      height: auto;
    }
  }
`;

const DocumentImg = styled.img`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  pointer-events: none;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 140px;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.selected === 1 ? "#A42874" : "#00B9C4")};
  border-radius: 100px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  margin: 0;
  width: auto;
  outline: none;
  border: none;
  transition: 300ms;
  margin-bottom: 57px;
  :hover {
    transition: 300ms;
    color: #ffffff;
    background-color: #a42874;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const StepText = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #353433;
  margin: 0;
  text-align: left;
`;

const DocumentInfo = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353433;
  max-width: 460px;
  margin-bottom: 0;
`;

const BannerB2 = styled.div`
  width: 100%;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FRectangle%20991.png?alt=media&token=63bae657-3b62-41e7-9f31-5deda22cc3f1&_gl=1*748zsk*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5NjI4MzY1OS42My4xLjE2OTYyODM2NjIuNTcuMC4w")
    center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  .row {
    width: 100%;
  }
`;

const Title1 = styled.p`
  font-family: Assistant;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 48px;
  line-height: 120%;
  color: #ffffff;
  max-width: 681px;
  margin-right: auto;
  margin-left: auto;
  margin-top: revert;

  p {
    line-height: 120%;
    max-width: 640px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
    font-size: 20px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-bottom: 20px !important;
    p {
      font-size: 12px;
    }
  }
`;

export const Beneficios = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [documentSelected, setDocumentSelected] = useState(0);
  const [modalSelected, setModalSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  let isDesktop = width <= 1366;
  let isTablet = width <= 992;
  let isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Container
        className={`pt-${isMobile ? "4" : "5"} `}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Row className="mb-5">
          <Title>
            ¿Qué información ofrece el reporte de resultados de{" "}
            <span style={{ fontWeight: "700" }}>VG 70</span>?
            <p>Seleccione la sección de su interés para más información.</p>
          </Title>
        </Row>
        {width <= 992 ? (
          <>
            <Row className="mb-4 pb-2">
              <AxionaDocuments>
                <DocumentImg
                  src={
                    documentSelected === 4
                      ? Therapies
                      : documentSelected === 3
                      ? Biomarkers
                      : documentSelected === 2
                      ? Alterations
                      : Results
                  }
                  alt="Documento de ejemplo"
                  className="first"
                />
              </AxionaDocuments>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-3"
            >
              <StepText className="ps-4 mb-2">Parte 1</StepText>
              <StyledButton
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 1 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(1);
                  setModalSelected(1);
                  setShowModal(true);
                }}
              >
                Resumen de resultados
              </StyledButton>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-3"
            >
              <StepText className="ps-4 mb-2">Parte 2</StepText>
              <StyledButton
                style={{
                  maxWidth: "300px",
                }}
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 2 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(2);
                  setModalSelected(2);
                  setShowModal(true);
                }}
              >
                Desglose de apartados
              </StyledButton>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-4"
            >
              <StepText className="ps-4 mb-2">Parte 3</StepText>
              <StyledButton
                style={{
                  maxWidth: "235px",
                }}
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 3 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(3);
                  setModalSelected(3);
                  setShowModal(true);
                }}
              >
                Recomendaciones personalizadas
              </StyledButton>
            </Row>
            <Row
              // data-aos="fade-up"
              className="mb-4"
            >
              <StepText className="ps-4 mb-2">Parte 4</StepText>
              <StyledButton
                style={{
                  marginRight: "50px",
                  maxWidth: "300px",
                }}
                className="pt-2 pe-4 pb-2 ps-4"
                selected={documentSelected === 4 ? 1 : 0}
                onClick={() => {
                  setDocumentSelected(4);
                  setModalSelected(4);
                  setShowModal(true);
                }}
              >
                Genetic insights
              </StyledButton>
            </Row>
            <Row className="justify-content-center position-relative mb-4 pb-2">
              <DocumentInfo
              // data-aos="fade-up"
              >
                Este documento se ha creado únicamente con fines explicativos y
                la totalidad de los datos no corresponden a un sujeto
                particular.
              </DocumentInfo>
            </Row>
          </>
        ) : (
          <Row className="mb-5">
            <AxionaDocuments className="pt-4 pb-4">
              <DocumentImg
                src={
                  documentSelected === 4
                    ? Therapies
                    : documentSelected === 3
                    ? Biomarkers
                    : documentSelected === 2
                    ? Alterations
                    : Results
                }
                alt="Documento de ejemplo"
                className="first"
              />
              <ButtonsContainer>
                <Col xs={12}>
                  <Row className="h-100 justify-content-between">
                    <Col md={5}>
                      <div className="h-100 d-flex flex-column justify-content-start">
                        <Row data-aos="fade-up">
                          <StepText
                            style={{ marginTop: "-125px", marginLeft: "180px" }}
                            className="ps-4 mb-2"
                          >
                            Parte 1
                          </StepText>
                          <StyledButton
                            style={{ marginTop: "-100px", marginLeft: "200px" }}
                            className="pt-2 pe-4 pb-2 ps-4"
                            selected={documentSelected === 1 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(1)}
                            onClick={() => {
                              setModalSelected(1);
                              setShowModal(true);
                            }}
                          >
                            Resumen de resultados
                          </StyledButton>
                        </Row>
                        <Row data-aos="fade-up">
                          <StepText
                            style={{ marginTop: "80px", marginLeft: "100px" }}
                            className="ps-4 mb-2"
                          >
                            Parte 3
                          </StepText>
                          <StyledButton
                            style={{
                              maxWidth: "250px",
                              marginLeft: "100px",
                            }}
                            className="pt-2 pe-4 pb-2 ps-4"
                            selected={documentSelected === 3 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(3)}
                            onClick={() => {
                              setModalSelected(3);
                              setShowModal(true);
                            }}
                          >
                            Recomendaciones personalizadas
                          </StyledButton>
                        </Row>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="h-100 d-flex flex-column justify-content-start">
                        <Row className="justify-content-end" data-aos="fade-up">
                          {!isDesktop && (
                            <StepText
                              style={{
                                marginTop: "-30px",
                                marginRight: "-80px",
                                width: "100px",
                              }}
                              className="ps-4 mb-2"
                            >
                              Parte 2
                            </StepText>
                          )}
                          {isDesktop && (
                            <StepText
                              style={{
                                marginTop: "-30px",
                                marginRight: "-80px",
                              }}
                              className="ps-4 mb-2"
                            >
                              Parte 2
                            </StepText>
                          )}
                          <StyledButton
                            style={{
                              maxWidth: "260px",
                              marginRight: "80px",
                            }}
                            className="pt-2 pe-4 pb-2 ps-4 w-100"
                            selected={documentSelected === 2 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(2)}
                            onClick={() => {
                              setModalSelected(2);
                              setShowModal(true);
                            }}
                          >
                            Desglose de apartados
                          </StyledButton>
                        </Row>
                        <Row className="justify-content-end" data-aos="fade-up">
                          {!isDesktop && (
                            <StepText
                              style={{
                                marginTop: "80px",
                                marginRight: "270px",
                                width: "100px",
                              }}
                              className="ps-4 mb-2"
                            >
                              Parte 4
                            </StepText>
                          )}
                          {isDesktop && (
                            <StepText
                              style={{
                                marginTop: "80px",
                                marginRight: "-70px",
                              }}
                              className="ps-4 mb-2"
                            >
                              Parte 4
                            </StepText>
                          )}
                          <StyledButton
                            style={{
                              maxWidth: "260px",
                              marginRight: "120px",
                            }}
                            className="pt-2 pe-4 pb-2 ps-4 w-100"
                            selected={documentSelected === 4 ? 1 : 0}
                            onMouseEnter={() => setDocumentSelected(4)}
                            onClick={() => {
                              setModalSelected(4);
                              setShowModal(true);
                            }}
                          >
                            Genetic insights
                          </StyledButton>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </ButtonsContainer>
            </AxionaDocuments>
            <Row className="mt-4 justify-content-center position-relative mb-3">
              <DocumentInfo data-aos="fade-up">
                Este documento se ha creado únicamente con fines explicativos y
                la totalidad de los datos no corresponden a un sujeto
                particular.
              </DocumentInfo>
            </Row>
          </Row>
        )}
        {!isMobile ? (
          <Modalvg
            show={showModal}
            setShowModal={setShowModal}
            modalSelected={modalSelected}
          />
        ) : (
          <Modalmov
            show={showModal}
            setShowModal={setShowModal}
            modalSelected={modalSelected}
          />
        )}
      </Container>
    </>
  );
};
