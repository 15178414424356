import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";

const BannerBackground = styled.div`
  width: 100%;
  height: 480px;
  overflow: hidden;
  box-shadow: 0px 16px 9px 0px #25060e33;
  background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2FBanner.png?alt=media&token=090240e8-935e-4d20-9a67-5d01b0cfddd9")
  center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2FBannermov.png?alt=media&token=f5bf3c96-b245-4b32-98bf-46f321c30399") center center/cover no-repeat;;
    height: 715px;
  }
  .row {
    width: 100%;
  }
  #col-img {
    height: 535px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
      width: 130%;
    }
    img {
      width: 570px;
      position: absolute;
      bottom: 82px;
      left: 115px;
      transform: translate(-24%, 0px);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 29%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        bottom: -130%;
      }
    }
  }
  #col-img1 {
    height: 430px;
    position: relative;
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
      height: 200px;
      width: 130%;
    }
    img {
      width: 700px;
      position: absolute;
      bottom: 90px;
      left: 115px;
      transform: translate(-36%, 0);
      @media only screen and (max-width: 992px) {
        width: 150%;
        bottom: 29%;
        left: 50%;
        transform: translate(-50%, 50%);
        max-width: 500px;
      }
      @media only screen and (max-width: 768px) {
        width: 130%;
      }
    }
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 460px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const DescColumn1 = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 255px;
  @media only screen and (max-width: 768px) {
    height: 10px;
  }
`;

const Title = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  color: #ffffff;
  text-align: star;
  max-width: 564px;
  margin: 20px;
  p {
    font-size: 20px;
    line-height: 156%;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 560px;
    font-size: 40px;
  }
  @media only screen and (max-width: 992px) {
    max-width: 450px;
    font-size: 34px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 30px;
    max-width: 349px;
  }
`;

const Desc = styled.button`
  font-family: "IBM Plex Sans";
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: rgb(255, 255, 255);
  width: 404px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #24CAD3;
  box-shadow: 0px 16px 9px 0px #25060e33;
  border: none;
  filter: drop-shadow(rgba(0, 0, 0, 0.13) 0px 4px 5px);
  margin-left: -118px;
  transition: all 0.2s ease 0s;
  :hover {
    background-color:#24CAD3;
  }
  @media only screen and (max-width: 768px) {
    height: 50px;
    width: 325px;
    font-size: 24px;
    margin: 20px auto;
  }
`;

export const Cafeina = () => {
  const { items } = useSelector((state) => state.shoppingCart);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { studyId } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [load, setLoaded] = useState(false);
  let isTablet = width <= 768;
  let isMobile = width <= 576;

  const callToSchedule = () => {
    window.location.href = `https://wa.me/525615077271?text=Quiero agendar una cita para prueba de cafeína`;
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getInfo = async () => {
    setIsLoading(true);
    try {
      let request = axios.get("https://lpqsystem.com/get-study/" + 974);
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.normal_price,
        oldPrice: response.data.normal_price,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getInfo();
  }, [studyId]);

  return (
    <>
      <BannerBackground>
        <Container>
          <HelmetTitle title="Cafeina" name="description" content="content" />
          <Row>
            <DescColumn
              xs={12}
              md={7}
              className={
                isTablet
                  ? "justify-content-start mt-3"
                  : "justify-content-center"
              }
            >
              <Title className={isTablet ? "mb-2 ms-auto me-auto" : "mb-4"}>

              Conoce tu riesgo de<br /> intolerancia a la cafeína
              <br /><br />
                <p>
                <ul style={{ listStyle: "inside" }}>
                    <li>Basada en PCR</li>
                    <li>Muestra en saliva</li>
                    <li>Tiempo de entrega de 5 a 7 días habiles</li>
                    <li>$2,600.00 MXN</li>
                  </ul>
                </p>
                ) : (
                  <p>
                    <ul style={{ listStyle: "inside" }}>
                      <li>Basada en PCR</li>
                      <li>Muestra de saliva</li>
                      <li>Tiempo de entrega de 5 a 7 días habiles</li>
                      <li>$2600.00 MXN</li>
                    </ul>
                  </p>
                )}

              </Title>
              {!isTablet ? (
                <Desc
                onClick={() => callToSchedule()}
                className={isMobile ? "ms-auto me-auto" : "mb-4"}
              >
                Llamar para agendar
              </Desc>
              ) : null}
            </DescColumn>
            <Col xs={12} md={5} id="col-img">
              <img
                alt="cafeina"
                src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cafeina%2Fcajacafeina.png?alt=media&token=ef77afd2-b096-48a7-8355-edf22839f66a"
              />
            </Col>
            {isTablet ? (
              <Desc
              onClick={() => callToSchedule()}
              className={isMobile ? "ms-auto me-auto" : "mb-4"}
            >
              Llamar para agendar
            </Desc>
            ) : null}
          </Row>
        </Container>
      </BannerBackground>
    </>
  );
};
