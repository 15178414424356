import React, { useState, useEffect, useRef, useInsertionEffect } from "react";
import "./carruselstyles.css";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import styled from "styled-components";

const BannerBackground = styled.div`
  width: 100%;
  height: 165px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2Fbanner1.png?alt=media&token=d7bdcac7-69c7-41c8-b68f-1bd6e25136b7")
    center center/cover no-repeat;
  box-shadow: 0px 16px 9px 0px #25060e33;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38"centerno-repeat);
    height: 130px;
    margin-bottom: 70px;
  }
  .row {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 48px;
  font-weight: 300;
  line-height: 180%;
  margin-top: revert;
  p {
    margin-bottom: 60px;
    color: #000;
    text-align: center;
    font-size: 38px;
    font-weight: 600;
    line-height: 100%;
  }
  span {
    background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 95%;
      width: 100%;
      left: 0;
      height: 7px;
      border-radius: 2px;
      background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
    p {
      width: 96vw;
      margin-top: -70px;
      font-size: 17px;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #e16e46;
  border-radius: 20px;
  border: none;
  text-align: center;
  width: 360px;
  height: 70px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 151px;
    font-size: 14px;
  }
`;

const imag2 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FLNCA.%20Andrea%20Arregui%CC%81n.png?alt=media&token=bd88618f-0501-4c6a-901d-1b53160e337f";
const imag3 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FLN.%20EmiliodelaGarza.png?alt=media&token=e5a84f50-d31e-4e42-bdf3-b4fb618c32f6";
const imag4 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FDr.%20AlvaroSantoscoy.png?alt=media&token=d8116094-71e3-4ea5-a7d2-a37a6a362f8b";
const imag5 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FDra.%20AndreaGuzman.png?alt=media&token=3bfe0a2b-ba6c-47aa-a2b8-0aecfc6c0a11";
const imag6 =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/aliados%2FLN.%20MercedesPerez.png?alt=media&token=4afd9cda-3626-4794-913a-12ca2c6b8132";

const images = [imag2, imag3, imag4, imag5, imag6];

export const SliderImg = () => {
  const carousel = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    console.log(carousel.current?.scrollWidth, carousel.current?.offsetWidth);
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);
  }, []);

  return (
    <>
      <AnimateSharedLayout>
        <AnimatePresence>
          <Title className="mb-2 ms-auto me-auto">
            <p>¡Conoce a nuestros Aliados Vitales!</p>
          </Title>
          <div className="contapp">
            <motion.div
              ref={carousel}
              className="carousel"
              whileTap={{ cursor: "grabbing" }}
            >
              <motion.div
                className="inner"
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.8 }}
              >
                {images.map((image) => (
                  <motion.div className="item" key={image}>
                    <img src={image} alt="Texto alt" />
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </div>
        </AnimatePresence>
      </AnimateSharedLayout>
      <BannerBackground>
        <Title className="mb-2 ms-auto me-auto">
          <Desc>
            <a href="." target="_blank" rel="noopener noreferrer">
              Quiero ser un Aliado Vital
            </a>
          </Desc>
        </Title>
      </BannerBackground>
    </>
  );
};
