import './App.css';
import { AppRouter } from './routes/AppRouter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-3MPCQ3JGQC"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
	useEffect(()=>{
		AOS.init({ duration: 2000 });
		AOS.refresh();
	},[])
	const stripeKey = loadStripe(
		'pk_test_51HB5FdFXCgaXtV7e0RJkxbKJLqZhdyemy1bW5iB94IJlG5FfRt1OzAN4nn888cBMSUgODxwyJytJjj2ZvA88tyKk00G4UUAcLv'
	);

	return (
		<Elements stripe={stripeKey}>
			<AppRouter />
			{() => {AOS.refresh()}}
		</Elements>
	);
}

export default App;