import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { StartTestModal } from './testModal';

const Title = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: #121619;
  margin: 0;
  padding: 0;
`;

const Question = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3b3b3b;
  margin: 0;
  padding: 0;
`;

const RadiosContainer = styled.div`
  /* max-width: 865px; */
`;

const StyledCheck = styled(Form.Check)`
  .form-check-input[type='radio'] {
    border-color: #77ccd5;
  }
  .form-check-input:checked {
    background-color: #ffffff;
    border-color: #77ccd5;
  }
  .form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2377CCD5'/%3e%3c/svg%3e") !important;
  }
  .form-check-input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2377CCD5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const StyledInput = styled(Form.Control)`
  background: #ffffff;
  border-radius: 12px;
  border: ${(props) => (props.error === 1 ? '1px solid #FF0505' : '1px solid #E6E6E6')};
  ::placeholder {
    font-family: 'Assistant', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  :focus {
    border-color: ${(props) => (props.error === 1 ? '#FF0505' : '#77CCD5')};
    box-shadow: rgba(119, 204, 213, 0.5) 0px 0px 0px 3px;
  }
`;

const ErrorMessage = styled.p`
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  color: #ff0505;
  padding: 0;
  margin: 0;
`;

const ContinueButton = styled.button`
  border: none;
  background-color: #77ccd5;
  border-radius: 102px;
  max-width: 176px;
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  :disabled {
    filter: grayscale();
  }
`;

const Link = styled.a`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #121619;
  margin: 0;
  padding: 0;
  transition: 300ms;
  :hover {
    transition: 300ms;
    color: #77ccd5;
  }
`;

const BoldText = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #121619;
  margin: 0;
  padding: 0;
`;

export const StepThree = (props) => {
  const [reasons, setReasons] = useState();
  const [policy, setPolicy] = useState(false);
  const [consent, setConsent] = useState(false);
  const [sample, setSample] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const checks = {
    reasons: [
      {
        name: 'Cuestiones médicas',
      },
      {
        name: 'Quiero cuidar mejor de mí',
      },
      {
        name: 'Soy deportista',
      },
      {
        name: 'Otro',
      },
    ],
  };

  const onSubmit = (data, event) => {
    if(consent && policy && sample) {
      props.handleStepThree({
        personalData: data,
      });
    } else {
      console.log('Acepta el consentimiento y el aviso de privacidad');
    }
  };

  return (
    <Container className='pb-4' data-aos='slide-left' data-aos-duration='500'>
      <Row className='mb-5'>
        <Title>Nos gustaría conocerte un poco mejor...</Title>
      </Row>
      <Row>
        <Col xs={12} sm={10} md={10} lg={8} xl={7} className='p-0'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question>
                  ¿Por qué tienes interés en realizarte la prueba genética?
                </Question>
              </Row>
              <RadiosContainer className='row'>
                {checks.reasons.map((radio, index) => (
                  <Col xs={6} sm={6} md={6} className='mb-1 p-0' key={index}>
                    <StyledCheck
                      inline
                      {...register('reason')}
                      label={radio.name}
                      name='reason'
                      id={`reason-${index}`}
                      type='radio'
                      value={index}
                      checked={reasons === index ? true : false}
                      onChange={() => {
                        setReasons(index);
                      }}
                    />
                  </Col>
                ))}
              </RadiosContainer>
              {reasons === 3 && (
                <Row className='pt-3'>
                  <StyledInput
                    className='pe-3 ps-3'
                    type='text'
                    placeholder='Escribe aquí'
                    {...register('anotherReason')}
                  />
                </Row>
              )}
            </div>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question>
                  Acepto y doy consentimiento que he leído los siguientes
                  documentos
                </Question>
              </Row>
              <RadiosContainer>
                <StyledCheck className='mb-4'>
                  <StyledCheck.Input
                    type='checkbox'
                    checked={consent}
                    onChange={() => {
                      setConsent(!consent);
                    }}
                  />
                  <StyledCheck.Label>
                    <Link
                      href='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Consentimiento%20informado.pdf?alt=media&token=b8fed55c-a5ef-422b-b6d1-b4e2d7cda1b5'
                      rel='noreferrer'
                      target='_blank'
                    >
                      Consentimiento informado - investigación Vitalgene
                    </Link>
                  </StyledCheck.Label>
                </StyledCheck>
                <StyledCheck>
                  <StyledCheck.Input
                    type='checkbox'
                    checked={policy}
                    onChange={() => {
                      setPolicy(!policy);
                    }}
                  />
                  <StyledCheck.Label>
                    <Link
                      href='https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/Aviso%20de%20privacidad.pdf?alt=media&token=433f0150-15bc-4ed3-bf68-8f881c29a057'
                      rel='noreferrer'
                      target='_blank'
                    >Aviso de privacidad de datos</Link>
                  </StyledCheck.Label>
                </StyledCheck>
              </RadiosContainer>
            </div>
            <div className='p-0 mb-5'>
              <Row className='mb-4'>
                <Question>Prepárate para realizar tu toma de saliva:</Question>
              </Row>
              <RadiosContainer>
                <StyledCheck>
                  <StyledCheck.Input
                    type='checkbox'
                    checked={sample}
                    onChange={() => {
                      setSample(!sample);
                    }}
                  />
                  <StyledCheck.Label>
                    <BoldText>
                      Doy mi consentimiento de que tengo 30 minutos de ayuno
                      estricto.
                    </BoldText>
                  </StyledCheck.Label>
                </StyledCheck>
              </RadiosContainer>
            </div>
            <Row>
              <ContinueButton
                type='submit'
                className='pt-2 pb-2'
                disabled={consent && policy && sample ? false : true}
                onClick={() => {
                  window.scrollTo(0, 0);
                  consent && policy && sample ? setShowModal(true) : setShowModal(false);
                }}
              >
                Tomar muestra
              </ContinueButton>
            </Row>
          </Form>
        </Col>
      </Row>
      <StartTestModal show={showModal} setShow={setShowModal} handleTestModal={props.handleTestModal} />
    </Container>
  );
};
