import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Cafeina =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/cardsVG%2FLP_caja_cafeina2a.png?alt=media&token=474d2c33-753b-43ca-ad8a-937bcab4c413";

const MainDiv = styled.div`
  margin-top: 10px;
  border-radius: 30px;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(213.31deg, rgba(255, 91, 108, 0.6) 21.64%, rgba(104, 61, 137, 0.6) 96.37%);
  @media only screen and (max-width: 1366px) {
    height: 450px;
  }
  @media only screen and (max-width: 768px) {
    height: 400px;
  }
  h1 {
    font-family: "Assistant";
    font-weight: 800;
    font-size: 30px;
    line-height: 110%;
    color: #ffffff;
    @media only screen and (max-width: 768px) {
      max-width: 233px;
      font-size: 18px;
      line-height: 34px;
      margin-left: 0px;
    }
  }
  p {
    font-family: "Assistant";
    font-weight: 200;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  #main-col {
    text-align: center;
    padding-top: 50px;
    width: 370px;
  }
  #smiling {
    width: 350px;
    
    @media only screen and (max-width: 1366px) {
      width: 300px;
    }
    @media only screen and (max-width: 768px) {
      width: 300px;
    }
  }
`;

export const BannerCuatro = () => {

  return (
    <>
      <MainDiv>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={9} lg={7} xl={6} id="main-col">
              <Row>
                <Col xs={12}>
                  <h1>Prueba de intolerancia a la cafeína</h1>
                  <br />
                  <a href="/cafeina/estudio/974"><img id="smiling" src={Cafeina} alt="Cafeina" /></a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainDiv>
    </>
  );
};
