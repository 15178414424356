import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledSlide = styled.div`
`;

const Title = styled.h1`
  margin-top: 227px;
  font-weight: 800;
  font-size: 38px;
  line-height: 130%;
  color: #F2F7F9;
  @media only screen and (max-width: 992px) {
    margin-top: 117px;
    font-size: 22px;
  }
`;

const Desc = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #F2F7F9;
  min-height: 54px;
  @media only screen and (max-width: 992px) {
    font-size: 15px;
  }
`;

const OrangeButton = styled.button`
  width: 219px;
  height: 48px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-bottom: 220px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 85%;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  @media only screen and (max-width: 992px) {
    max-height: 100%;
  }
  // @media only screen and (max-width: 419px) {
  //   bottom: -25px;
  // }
`;

const ImageCol = styled(Col)`
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    height: 400px;
  }
`;

export const Slide = (props) => {

  return (
    <StyledSlide>
      <Row>
        <Col xs={12} lg={6}>
          <Title>{props.data.title}</Title>
          <Desc>{props.data.desc}</Desc>
          <OrangeButton>{props.data.textButton}</OrangeButton>
        </Col>
        <ImageCol xs={12} lg={6}>
          <StyledImg src={props.data.img}/>
        </ImageCol>
      </Row>
    </StyledSlide>
  );
};
