import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { UilArrowRight } from "@iconscout/react-unicons";
import "./lactosastyles.css";
import { RemoveScroll } from "react-remove-scroll";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";

const Background = styled.div`
  background: linear-gradient(
    90deg,
    #dfdfdf 40%,
    #ffffff 0%,
    #ffffff 73.5%,
    #ffffff 134.41%,
    #ffffff 177.64%
  );

  @media only screen and (max-width: 1366px) {
    background: linear-gradient(
      90deg,
      #dfdfdf 40%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }

  @media only screen and (max-width: 428px) {
    background: linear-gradient(
      180deg,
      #dfdfdf 31%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }
`;

const Background1 = styled.div`
  background: linear-gradient(
    90deg,
    #dfdfdf 40%,
    #ffffff 0%,
    #ffffff 73.5%,
    #ffffff 134.41%,
    #ffffff 177.64%
  );

  @media only screen and (max-width: 428px) {
    background: linear-gradient(
      180deg,
      #dfdfdf 50%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }
`;

const Banner = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FQuizlactosa.png?alt=media&token=a6273710-7d8d-416e-a9dc-910176981ac5")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FQuizlactosamov.png?alt=media&token=fbb6bd02-9e88-4767-be70-c545c3193a5b")
      center center/cover no-repeat;
  }
`;

const ContainerDiv = styled.section`
  position: relative;
  width: 100%;
  height: 76vh;
  @media only screen and (max-width: 1366px) {
    height: 67vh;
  }
  @media only screen and (max-width: 428px),(max-height: 767px){
    height: 63vh;
  }
`;

const ContQuiz = styled.section`
  position: relative;
  width: 100%;
  max-height: 65vh;
  @media only screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;

const ContBanner = styled.div`
  position: relative;
  width: 100%;
  min-height: 85vh;
  @media only screen and (max-width: 1366px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 428px),(max-height: 768px) {
    min-height: 95vh;
  }
`;

const SampleCard1 = styled.div`
  position: relative;
  height: 70vh;
  width: 45vw;
  background-color: none;
  // background-color: #111f301A;
  margin-left: 145%;
  padding-top: 250px;
  flex-shrink: 0;
  @media only screen and (max-width: 1366px) {
    width: 50vw;
    padding-top: 146px;
  }
  @media only screen and (max-width: 768px) {
    width: 85vw;
    margin-left: 18px;
    padding-top: 260px;
  }
  .text-col {
    display: flex;
    position: relative;
    padding-left: 80px;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
    h1 {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 150px;
      line-height: 80%;
      color: #cef719;
      margin-right: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 60px;
        margin-top: 5px;
        margin-right: 18px;
      }
    }
    p {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 35px;
      line-height: 50px;
      color: #ffffff;
      span {
        font-weight: 700;
      }
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 30px;
        max-width: 245px;
      }
    }
  }
  img {
    max-width: 60%;
    max-height: 85vh;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 20px;
    @media only screen and (max-width: 768px) {
      max-width: 120%;
      max-height: 60vh;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
  }
  #create {
    h4 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff !important;
      max-width: 237px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  #create-bottom {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #ffffff;
    position: absolute;
    bottom: 70px;
    left: 80px;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const SampleCard2 = styled.div`
  position: relative;
  height: 75vh;
  width: 30vw;
  background-color: none;
  // background-color: #111f301A;
  margin-left: 145%;
  padding-top: 240px;
  flex-shrink: 0;
  @media only screen and (max-width: 1366px) {
    width: 34vw;
    padding-top: 190px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 25px;
    padding-top: 280px;
    width: 80vw;
  }
  .text-col {
    display: flex;
    position: relative;
    padding-left: 80px;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
    h1 {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 150px;
      line-height: 80%;
      color: #cef719;
      margin-right: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 60px;
        margin-top: 5px;
        margin-right: 18px;
      }
    }
    p {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 35px;
      line-height: 50px;
      color: #ffffff;
      span {
        font-weight: 700;
      }
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 30px;
        max-width: 245px;
      }
    }
  }
  img {
    max-width: 60%;
    max-height: 85vh;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 20px;
    @media only screen and (max-width: 768px) {
      max-width: 120%;
      max-height: 60vh;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
  }
  #create {
    h4 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff !important;
      max-width: 237px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  #create-bottom {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #ffffff;
    position: absolute;
    bottom: 70px;
    left: 80px;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const RedDiv = styled.div`
  position: absolute;
  top: 150px;
  left: 90px;
  background: none;
  // background: #FF00001A;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #000;
  @media only screen and (max-width: 768px) {
    top: 120px;
    left: 50px;
  }
  p {
    font-style: italic;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  h3 {
    color: #000;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

const BackBtn = styled.button`
  margin-left: 46.5%;
  background: none;
  border: none;
  margin-bottom: 18px;
  @media only screen and (max-width: 768px) {
    margin-left: 37%;
  }
`;

const NextBtn = styled.button`
  text-align: center;
  background-color: #009899;
  width: 120px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #009899;
  border: none;
  color: #fff;
  p {
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size: 26px;
    line-height: 120%;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 37%;
  }
`;

export const QuizLactosa = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 428;
  const displayRadioValue = () => {
    var ele = document.getElementsByName("padecimiento");
    var cuenta = 0;
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        cuenta++;
        //console.log("hola");
      }
    }
    //document.getElementById("result").innerHTML = "cuenta: " + cuenta;

    var res = document.getElementsByName("semanas");
    var resultado = 0;
    for (let i = 0; i < res.length; i++) {
      if (res[i].checked) {
        //document.getElementById("resultado").innerHTML = "Resultado: " + res[i].value;
        resultado = res[i].value;
      }
    }
    //console.log(cuenta);
    // eslint-disable-next-line eqeqeq
    if (cuenta == 1 && resultado == 1) {
      window.location.href = "/resultadoLactosaBajo";
    }
    // eslint-disable-next-line eqeqeq
    if (cuenta == 1 && resultado == 2) {
      window.location.href = "/resultadoLactosaBajo";
    }
    // eslint-disable-next-line eqeqeq
    if (cuenta >= 2 && resultado == 1) {
      window.location.href = "/resultadoLactosaAlto";
    }
    // eslint-disable-next-line eqeqeq
    if (cuenta >= 2 && resultado == 2) {
      window.location.href = "/resultadoLactosaAlto";
    }
  };

  return (
    <>
      <HelmetTitle title="Quiz Lactosa" name="description" content="content" />
      {!isMobile ? (
        <Banner>
          <ContainerDiv></ContainerDiv>
          <BackBtn
            variant="light"
            onClick={() => (window.location.href = "#preguntas")}
          >
            <UilArrowRight color="#fff" size="90" style={{}} />
          </BackBtn>
        </Banner>
      ) : (
        <Banner>
          <ContainerDiv></ContainerDiv>
          <BackBtn
            variant="light"
            onClick={() => (window.location.href = "#preguntas")}
          >
            <UilArrowRight color="#fff" size="90" style={{}} />
          </BackBtn>
        </Banner>
      )}
      {!isMobile ? (
        <>
          <Background id="preguntas">
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <br />
                  <br />
                  <p>Prueba de riesgo de intolerancia a la lactosa.</p>
                  <br />
                  <br />
                  <p>Pregunta 1/2</p>
                </RedDiv>
              </Col>
              <Row id="question1">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        Instrucciones: Selecciona la casilla de los siguientes
                        síntomas que presentes para saber si tu padecimiento
                        puede estar relacionado con la intolerancia a la
                        lactosa:{" "}
                      </h4>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            value="1"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Cólicos
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="diarrea"
                            value="2"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Diarrea
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="estreñimiento"
                            value="3"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Vómito
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="dolor"
                            value="4"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Náuseas
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="debilidad"
                            value="5"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            <span className="checkmark lactosa" />
                            Flatulencias
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="inflamacion"
                            value="6"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Dolor en el abdomen
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="adormecimineto"
                            value="7"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Ruidos estomacales
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="amenorrea"
                            value="8"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Inflamación abdominal
                          </h4>
                        </label>
                      </div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question2")}
                    >
                      {" "}
                      Siguiente
                      <UilArrowRight color="#fff" size="20" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>
          <RemoveScroll>
            <Background1>
              <ContQuiz>
                <Col xs={12} md={10} lg={10} xl={12}>
                  <RedDiv>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                      alt="paso 4"
                    />
                    <br />
                    <br />
                    <p>Prueba de riesgo de intolerancia a la lactosa.</p>
                    <br />
                    <br />
                    <p>Pregunta 2/2</p>
                  </RedDiv>
                </Col>
                <Row id="question2">
                  <Col xs={8} md={8} lg={8} xl={4}>
                    <SampleCard2>
                      <div>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            textAlign: "center",
                          }}
                        >
                          ¿Has presentado los síntomas por más de 2 semanas?{" "}
                        </h4>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="semanas"
                              onclick="checarango(0)"
                              id="si"
                              value="1"
                              style={{
                                display: "inline-block",
                                color: "#009899",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              Si
                            </h4>
                          </label>
                        </div>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="semanas"
                              onclick="checarango(0)"
                              id="no"
                              value="2"
                              style={{
                                display: "inline-block",
                                color: "#009899",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "16px",
                              }}
                            >
                              {" "}
                              No
                            </h4>
                          </label>
                        </div>
                        <div id="resultado"></div>
                        <NextBtn variant="light" onClick={displayRadioValue}>
                          {" "}
                          Finalizar
                          <UilArrowRight color="#fff" size="20" style={{}} />
                        </NextBtn>
                      </div>
                    </SampleCard2>
                  </Col>
                </Row>
              </ContQuiz>
            </Background1>
          </RemoveScroll>
        </>
      ) : (
        <>
          <Background id="preguntas">
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba de riesgo de intolerancia a la lactosa.</p>
                  <p>Pregunta 1/2</p>
                </RedDiv>
              </Col>
              <Row id="question1">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Instrucciones: Selecciona la casilla de los siguientes
                        síntomas que presentes para saber si tu padecimiento
                        puede estar relacionado con la intolerancia a la
                        lactosa:{" "}
                      </h4>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            value="1"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Cólicos
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="diarrea"
                            value="2"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Diarrea
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="estreñimiento"
                            value="3"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Vómito
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="dolor"
                            value="4"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Náuseas
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="debilidad"
                            value="5"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            <span className="checkmark lactosa" />
                            Flatulencias
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="inflamacion"
                            value="6"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Dolor en el abdomen
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="adormecimineto"
                            value="7"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Ruidos estomacales
                          </h4>
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="padecimiento"
                            onclick="checarango(0)"
                            id="amenorrea"
                            value="8"
                            style={{ display: "inline-block" }}
                          />
                          <span className="checkmark lactosa" />
                          <h4
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Inflamación abdominal
                          </h4>
                        </label>
                      </div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question2")}
                    >
                      {" "}
                      Siguiente
                      <UilArrowRight color="#fff" size="20" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>
          
            <Background>
              <ContBanner>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba de riesgo de intolerancia a la lactosa.</p>
                  <p>Pregunta 2/2</p>
                </RedDiv>
                <Row id="question2">
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        ¿Has presentado los síntomas por más de 2 semanas?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="semanas"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-flex",
                              color: "#009899",
                              flexWrap: "wrap",
                              alignContent: "space-around",
                              justifyContent: "space-around",
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="semanas"
                            onclick="checarango(0)"
                            id="no"
                            value="2"
                            style={{
                              display: "inline-flex",
                              color: "#009899",
                              flexWrap: "wrap",
                              alignContent: "space-around",
                              justifyContent: "space-around",
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                      <NextBtn variant="light" onClick={displayRadioValue}>
                        {" "}
                        Finalizar
                        <UilArrowRight color="#fff" size="20" style={{}} />
                      </NextBtn>
                    </div>
                  </SampleCard1>
                </Row>
              </ContBanner>
            </Background>
          
        </>
      )}
    </>
  );
};
