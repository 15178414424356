import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const symptoms = [
  { name: 'Cólicos', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F1.png?alt=media&token=faf3665d-4639-47a3-b1ae-27cbf9ce30c4'},
  { name: 'Náuseas', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F2.png?alt=media&token=267f2ae1-ad9d-4f82-8749-02c3c39731a3'},
  { name: 'Flatulencias', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F3.png?alt=media&token=77812d00-80a6-4403-afad-eb2b8373da1d'},
  { name: 'Diarrea', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F4.png?alt=media&token=bf2e90aa-936e-440e-9fb1-448b86742644'},
  { name: 'Ruidos estomacales', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F5.png?alt=media&token=973641fd-b12e-4af3-a834-91144c614be4'},
  { name: 'Inflación abdominal', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F6.png?alt=media&token=c30a36db-c484-42db-a5fd-0e9fefee5f6d'},
  { name: 'Dolor de abdomen', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F7.png?alt=media&token=c80dc26a-0ab5-41ac-bc25-516d487832ef'},
  { name: 'Vómito', image: 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2F8.png?alt=media&token=6b213ef0-af6c-4a1e-8abc-a983f6ffb2ef'},
];

const BannerB2 = styled.div`
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  padding: 40px 0;
  #col-img1 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      width: 609px;
      @media only screen and (max-width: 1400px) {
        width: 535px;
      }
      @media only screen and (max-width: 768px) {
        width: 300px;
        margin-bottom: -1px;
      }
    }
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;
const BannerB3 = styled.div`
  width: 100%;
  height: 206px;
  border-radius: 85px;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2Ffoto.png?alt=media&token=d8a818fc-138e-4939-ac92-e17361753b96")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2Ffotomov.png?alt=media&token=909326c7-4a4b-4c93-b686-e0278383e7bb")
      center center/cover no-repeat;
    height: 206px;
    border-radius: 0px;
    overflow: hidden;
  }
  #col-img2 {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 65vw;
      @media only screen and (max-width: 1400px) {
        max-width: 440px;
      }
      @media only screen and (max-width: 768px) {
        width: 70%;
        max-width: 300px;
        margin-bottom: 15px;
      }
    }
  }
`;

const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Assistant;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  #test {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    margin-top: revert;
    font-weight: 300;
    line-height: 135%;
    @media only screen and (max-width: 768px) {
      font-size: 33px;
      margin-top: revert;
    }
    @media only screen and (max-width: 576px) {
      font-size: 30px;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 1400px) {
    font-size: 38px;
  }
  @media only screen and (max-width: 992px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 25px;
  }
`;

const DescColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  #subtitle {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }
  #symp-desk1 {
    max-width: 450px;
    justify-content: center;
  }
  #desc {
    color: #000;
    border-radius: 15px;
    
    background: #F4FFEF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-family: Assistant;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 8px;
    line-height: normal;
    width: 470px;
    @media only screen and (max-width: 1400px) {
      font-size: 17px;
    }
  }
  .row {
    width: 100%;
  }
  #symp-desk {
    max-width: 450px;
  }
  .symp-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
  }
  .symp-name {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 12px;
    font-weight: 600;
    @media only screen and (max-width: 1400px) {
      font-size: 12px;
    }
  }
  img {
    width: 80%;
    max-width: 75px;
    margin: 0 auto;
    @media only screen and (max-width: 1400px) {
      max-width: 70px;
    }
  }
  @media only screen and (max-width: 992px) {
    #subtitle,
    #desc {
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 768px) {
    #symp-mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    #subtitle,
    #desc {
      font-size: 18px;
      width: 98vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    #subtitle,
    #desc {
      font-size: 16px;
      width: 76vw;
    }
    #symp-desk {
      display: none;
    }
    #symp-mobile1 {
      display: none;
    }
  }
`;

const Desc = styled.button`
  font-family: IBM Plex Sans;
  margin-top: -30px;
  font-size: 24px;
  margin-left: 30%;
  font-weight: 600;
  line-height: 120%;
  color: #ffffff;
  padding: 5px 1rem 5px 1rem;
  background: #F14CAE;
  border-radius: 40px;
  border: none;
  text-align: center;
  width: 505px;
  height: 60px;
  a {
    color: #ffffff;
  }
  @media only screen and (max-width: 992px) {
    width: 180px;
    font-size: 16px;
    height: 36px;
  }
  @media only screen and (max-width: 576px) {
    width: 250px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
  }
`;

export const Target = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isTablet = width <= 768;
  let isMobile = width <= 576;
  return (
    <>
      {!isMobile ? (
        <BannerB2>
          <Container>
            <Row>
              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2Fpersona.png?alt=media&token=6a6056e2-a396-44e1-8a65-ad42c4991cb1"
                />
              </Col>

              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  Personas que presenten uno o más de los siguientes síntomas:
                </p>

                {/* Symptoms in desktop */}
                <Row id="symp-desk">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={3} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  Interesados en:
                  <ul>
                    <li>
                      Mejorar su calidad de vida y relación con los alimentos.
                    </li>
                    <li>Personalizar su alimentación.</li>
                    <li>Conocerse mejor genéticamente.</li>
                  </ul>
                </p>
              </DescColumn>
            </Row>
            <Row>
              <BannerB3>
                <Title>
                  <p id="test">
                    ¿Esta prueba es para mí? <br />
                    <span style={{ textDecoration: "underline" }}>
                      Descúbrelo
                    </span>{" "}
                    con nuestro quiz.
                  </p>
                </Title>
              </BannerB3>
            </Row>
            <Row>
              <Desc>
                <a href="/quizLactosa" target="_blank" rel="noopener noreferrer">
                  Comenzar quiz
                </a>
              </Desc>
            </Row>
          </Container>
        </BannerB2>
      ) : (
        <BannerB2>
          <Container>
            <Row>
              {/* Right column -> Text column */}
              <DescColumn xs={12} md={6} data-aos="fade-up">
                {/* Header and subtitle */}
                <Title>¿A quién va dirigida esta prueba?</Title>
                <p id="subtitle">
                  Personas que presenten uno o más de los siguientes síntomas:
                </p>

                {/* Symptoms slider in mobile */}
                <Row id="symp-mobile">
                  {symptoms?.map((symp) => (
                    <Col xs={6} md={4} className="symp-col" key={symp?.name}>
                      <img src={symp?.image} alt={symp?.name} />
                      <p className="symp-name">{symp?.name}</p>
                    </Col>
                  ))}
                </Row>

                {/* Description */}
                <p id="desc">
                  Interesados en:
                  <ul>
                    <li>
                      Mejorar su calidad de vida y relación con los alimentos.
                    </li>
                    <li>Personalizar su alimentación.</li>
                    <li>Conocerse mejor genéticamente.</li>
                  </ul>
                </p>
              </DescColumn>

              {/* Left cxolumn -> Image column */}
              <Col xs={12} md={6} id="col-img1">
                <img
                  alt="mama"
                  src="https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/lactosa%2Fpersona.png?alt=media&token=6a6056e2-a396-44e1-8a65-ad42c4991cb1"
                />
              </Col>
            </Row>
          </Container>
          <Row>
            <BannerB3>
              <Title>
                <p id="test">
                  ¿Esta prueba es para mí? <br />
                  <span style={{ textDecoration: "underline" }}>
                    Descúbrelo
                  </span>{" "}
                  con nuestro quiz.
                </p>
              </Title>
            </BannerB3>
          </Row>
          <Row>
            <Desc>
              <a href="/quizLactosa" target="_blank" rel="noopener noreferrer">
                Comenzar quiz
              </a>
            </Desc>
          </Row>
        </BannerB2>
      )}
    </>
  );
};
