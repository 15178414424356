import { Modal } from "react-bootstrap";

const titleModal = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '19px',
    textAlign: 'left',
    color: '#000000',
    marginTop: '4px',
    paddingRight: '17px',
};

const normalText = {
    fontFamily: 'Assistant',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'left',
    color: '#000000',
    margin: '0 21px 0 15px',
};

export const ModalTemplate = ({ show, handleClose, content, title = '', subtitle = '', classSize, isAuth = false }) => {
    const isThereSubtitle = subtitle.length > 0;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            dialogClassName={classSize}
        >
            {
                !isAuth && (
                    <Modal.Header closeButton style={{ borderBottom: 'none', marginBottom: 0 }}>
                        <span style={titleModal}>{title}</span>
                    </Modal.Header>
                )
            }
            {
                isThereSubtitle && (
                    <span style={normalText}>{subtitle}</span>
                )
            }
            {
                !isAuth
                    ? (
                        <Modal.Body
                            style={{
                                paddingTop: 0,
                                marginTop: isThereSubtitle ? 0 : -17,
                            }}
                        >
                            {content}
                        </Modal.Body>
                    )
                    : <>{content}</>
            }
        </Modal>
    );
}