import React, { useEffect, useState } from "react";
import Slide from "./Slide/Slide";
import "./Carousel.css";
import { slides } from "../Appconstants";
import styled from "styled-components";

const BannerBackground = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;
  background: none;
  //background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FfondoB.png?alt=media&token=9607a9e2-1962-44d6-b18d-76b596d1b77b&_gl=1*sxrtsp*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5ODc5MTg3NC4xMjkuMS4xNjk4NzkyMjcyLjYwLjAuMA..") center center/cover no-repeat;
  z-index: 1;
  box-shadow: 0px 16px 9px 0px #25060e33;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/axiona%2Fbanners%2FblueBgMb.png?alt=media&token=ffca6a4b-c4e0-411e-9a87-08d433618e38"center no-repeat);
    height: 130px;
    //margin-bottom: 70px;
  }
  .row {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #1D1D1B;
  text-align: center;
  font-size: 70px;
  font-weight: 600;
  line-height: 105.5%;
  margin-top: revert;

  span {
    background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 95%;
      width: 100%;
      left: 0;
      height: 7px;
      border-radius: 2px;
      background: linear-gradient(0deg, #a62c77 0%, #00b8c3 100%);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
    p {
      margin-top: -70px;
      font-size: 17px;
    }
  }
`;

const Carrusel = (props) => {
  const [timeduration] = useState(8000);
  const [width, setWidth] = useState(window.innerWidth);
  const [play, setPlay] = useState(true);
  const [pos, setPos] = useState(0);
  const slidesrow = slides?.map((slide, i) => {
    return (
      <Slide
        className={`slide ${slide.class} ${
          pos % slides.length === i && "active"
        }`}
        slide={slide}
        pos={pos}
      />
    );
  });
  const carouselnavrow = slides.map((slide, i) => {
    return (
      <div
        className={`carouselitem ${
          pos % slides.length === i && "activecarouselitem"
        }`}
        onClick={() => {
          setPos(i);
          setPlay(false);
          setTimeout(() => {
            setPlay(true);
          }, 0);
        }}
      >
        <i className="fal fa-dot-circle"></i>
      </div>
    );
  });
  let isMobile = width <= 768;
  const [elap, setElap] = useState(0);
  useEffect(() => {
    setPos(0);
  }, []);
  useEffect(() => {
    let timer;
    let elap;
    if (play) {
      timer = setInterval(() => {
        setPos((prev) => prev + 1);
        setElap(0);
      }, timeduration);
      elap = setInterval(() => {
        setElap((prev) => prev + 1);
      }, 1);
    } else {
      clearInterval(timer);
      clearInterval(elap);
      setElap(0);
    }
    return () => {
      clearInterval(timer);
      clearInterval(elap);
      setElap(0);
    };
  }, [play, timeduration]);

  return (
    <>
      <BannerBackground>
        <Title className={isMobile ? "mb-2 ms-auto me-auto" : "mb-4"}>
          ¿A quién va dirigida esta prueba?
        </Title>
      </BannerBackground>
      <div className="carouselbanner">
        {/* <div className="progress">
        <div className="prog" style={{width: (elap*400)/(timeduration)+'%'}}></div>
      </div> */}
        <div className="slides">{slidesrow}</div>
        <div className="carouselcontrols">
          {carouselnavrow}
          <div className="pause">
            <i
              onClick={() => setPlay(!play)}
              className={play ? "fal fa-pause" : "fal fa-play"}
            ></i>
          </div>
        </div>
      </div>
    </>
  );
};
export default Carrusel;
