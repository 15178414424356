import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { UilArrowRight } from "@iconscout/react-unicons";
import "./estilos.css";
import { RemoveScroll } from "react-remove-scroll";
import { HelmetTitle } from "../../../../helpers/HelmetTitle";

const Background = styled.div`
  background: linear-gradient(
    90deg,
    #dfdfdf 40%,
    #ffffff 0%,
    #ffffff 73.5%,
    #ffffff 134.41%,
    #ffffff 177.64%
  );

  @media only screen and (max-width: 1366px) {
    background: linear-gradient(
      90deg,
      #dfdfdf 40%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }

  @media only screen and (max-width: 428px) {
    background: linear-gradient(
      180deg,
      #dfdfdf 31%,
      #ffffff 0%,
      #ffffff 73.5%,
      #ffffff 134.41%,
      #ffffff 177.64%
    );
  }
`;

const Banner = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FQuizVg70.png?alt=media&token=f9f582d6-0b4b-44d6-aa42-2ad11b310f03&_gl=1*1s0gp6r*_ga*MTAzNzY2MTE3OS4xNjkzNTk3OTc1*_ga_CW55HF8NVT*MTY5NzY0NDQyNy44OC4xLjE2OTc2NDQ0MzcuNTAuMC4w")
    center center/cover no-repeat;
  @media only screen and (max-width: 768px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/banners%2FQuizVg70mob.png?alt=media&token=51453681-f43f-48cb-906e-b861826675ac")
      center center/cover no-repeat;
  }
`;

const ContainerDiv = styled.section`
  position: relative;
  width: 100%;
  height: 76vh;
  @media only screen and (max-width: 1366px) {
    height: 67vh;
  }
  @media only screen and (max-width: 428px),(max-height: 767px){
    height: 63vh;
  }
`;

const ContBanner = styled.div`
  position: relative;
  width: 100%;
  min-height: 85vh;
  @media only screen and (max-width: 1366px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 428px),(max-height: 768px) {
    min-height: 95vh;
  }
`;

const SampleCard1 = styled.div`
  position: relative;
  height: 70vh;
  width: 45vw;
  background-color: none;
  // background-color: #111f301A;
  margin-left: 145%;
  padding-top: 280px;
  flex-shrink: 0;
  @media only screen and (max-width: 1366px) {
    padding-top: 235px;
  }
  @media only screen and (max-width: 768px) {
    width: 85vw;
    margin-left: 18px;
    padding-top: 260px;
  }
  .text-col {
    display: flex;
    position: relative;
    padding-left: 80px;
    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }
    h1 {
      font-family: "IBM Plex Sans";
      font-weight: 700;
      font-size: 150px;
      line-height: 80%;
      color: #cef719;
      margin-right: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 60px;
        margin-top: 5px;
        margin-right: 18px;
      }
    }
    p {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 35px;
      line-height: 50px;
      color: #ffffff;
      span {
        font-weight: 700;
      }
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        line-height: 30px;
        max-width: 245px;
      }
    }
  }
  img {
    max-width: 60%;
    max-height: 85vh;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 20px;
    @media only screen and (max-width: 768px) {
      max-width: 120%;
      max-height: 60vh;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 600;
  }
  #create {
    h4 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff !important;
      max-width: 237px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
  #create-bottom {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    color: #ffffff;
    position: absolute;
    bottom: 70px;
    left: 80px;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const RedDiv = styled.div`
  position: absolute;
  top: 150px;
  left: 90px;
  background: none;
  // background: #FF00001A;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #000;
  @media only screen and (max-width: 768px) {
    top: 120px;
    left: 50px;
  }
  p {
    font-style: italic;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
  h3 {
    color: #000;
    font-weight: 700;
    font-size: 44px;
    line-height: 130%;
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

const BackBtn = styled.button`
  margin-left: 46.5%;
  background: none;
  border: none;
  margin-bottom: 18px;
  @media only screen and (max-width: 768px) {
    margin-left: 37%;
  }
`;

const NextBtn = styled.button`
  text-align: center;
  background-color: #a42874;
  width: 130px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #a42874;
  border: none;
  color: #fff;
  p {
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 37%;
  }
`;

export const QuizVg70 = () => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 428;
  const displayRadioValue = () => {
    var fields = ["uno", "dos", "tres", "cuatro", "cinco"];
    var cuenta = 0;
    for (let i = 0; i < fields.length; i++) {
      var ele = document.getElementsByName(fields[i]);
      var elenum = ele.length;
      for (let a = 0; a < elenum; a++) {
        if (ele[a].checked) {
          cuenta = cuenta + parseInt(ele[a].value);
          console.log(ele[a].value);
        }
      }
    }
    console.log(cuenta);
    // eslint-disable-next-line eqeqeq
    if (cuenta >= 0 && cuenta <= 2) {
      window.location.href = "/resultadoVg70Bajo";
    }
    // eslint-disable-next-line eqeqeq
    if (cuenta > 3 && cuenta < 9) {
      window.location.href = "/resultadoVg70Alto";
    }
  };

  return (
    <>
      <HelmetTitle title="Quiz VG70" name="description" content="content" />
      {!isMobile ? (
        <Banner>
          <ContainerDiv></ContainerDiv>
          <BackBtn
            variant="light"
            onClick={() => (window.location.href = "#preguntas")}
          >
            <UilArrowRight color="#fff" size="90" style={{}} />
          </BackBtn>
        </Banner>
      ) : (
        <Banner>
          <ContainerDiv></ContainerDiv>
          <BackBtn
            variant="light"
            onClick={() => (window.location.href = "#preguntas")}
          >
            <UilArrowRight color="#fff" size="90" style={{}} />
          </BackBtn>
        </Banner>
      )}
      {!isMobile ? (
        <>
          <Background id="preguntas">
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <br />
                  <br />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <br />
                  <br />
                  <p>Pregunta 1/5</p>
                </RedDiv>
              </Col>
              <Row id="question1">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "22px",
                          textAlign: "center",
                        }}
                      >
                        ¿Has encontrado un tipo de alimentación que te ayude a
                        lograr tus objetivos de una forma saludable y
                        personalizada?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="uno"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-block",
                              color: "#a42874",
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "22px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="uno"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-block",
                              color: "#a42874",
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "22px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question2")}
                    >
                      {" "}
                      <p>
                        Siguiente{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </p>
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>
          <RemoveScroll>
            <Background>
              <ContBanner>
                <Col xs={12} md={10} lg={10} xl={12}>
                  <RedDiv>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                      alt="paso 4"
                    />
                    <br />
                    <br />
                    <p>Prueba VG70, ¡Conócete genéticamente!</p>
                    <br />
                    <br />
                    <p>Pregunta 2/5</p>
                  </RedDiv>
                </Col>
                <Row id="question2">
                  <Col xs={8} md={8} lg={8} xl={4}>
                    <SampleCard1>
                      <div>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                        >
                          ¿Te cuesta seguir patrones de alimentación muy
                          estrictos y es difícil que veas resultados positivos?{" "}
                        </h4>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="dos"
                              onclick="checarango(0)"
                              id="si"
                              value="1"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              Si
                            </h4>
                          </label>
                        </div>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="dos"
                              onclick="checarango(0)"
                              id="no"
                              value="0"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              No
                            </h4>
                          </label>
                        </div>
                        <div id="resultado"></div>
                      </div>
                      <NextBtn
                        variant="light"
                        onClick={() => (window.location.href = "#question3")}
                      >
                        Siguiente{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </NextBtn>
                    </SampleCard1>
                  </Col>
                </Row>
              </ContBanner>
            </Background>

            <Background>
              <ContBanner>
                <Col xs={12} md={10} lg={10} xl={12}>
                  <RedDiv>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                      alt="paso 4"
                    />
                    <br />
                    <br />
                    <p>Prueba VG70, ¡Conócete genéticamente!</p>
                    <br />
                    <br />
                    <p>Pregunta 3/5</p>
                  </RedDiv>
                </Col>
                <Row id="question3">
                  <Col xs={8} md={8} lg={8} xl={4}>
                    <SampleCard1>
                      <div>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                        >
                          ¿Presentas algunos síntomas como cansancio, pérdida de
                          cabello, malestar estomacal, entre otros, y no has
                          encontrado la raíz del problema?{" "}
                        </h4>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="tres"
                              onclick="checarango(0)"
                              id="si"
                              value="1"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              Si
                            </h4>
                          </label>
                        </div>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="tres"
                              onclick="checarango(0)"
                              id="no"
                              value="0"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              No
                            </h4>
                          </label>
                        </div>
                        <div id="resultado"></div>
                      </div>
                      <NextBtn
                        variant="light"
                        onClick={() => (window.location.href = "#question4")}
                      >
                        Siguiente{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </NextBtn>
                    </SampleCard1>
                  </Col>
                </Row>
              </ContBanner>
            </Background>

            <Background>
              <ContBanner>
                <Col xs={12} md={10} lg={10} xl={12}>
                  <RedDiv>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                      alt="paso 4"
                    />
                    <br />
                    <br />
                    <p>Prueba VG70, ¡Conócete genéticamente!</p>
                    <br />
                    <br />
                    <p>Pregunta 4/5</p>
                  </RedDiv>
                </Col>
                <Row id="question4">
                  <Col xs={8} md={8} lg={8} xl={4}>
                    <SampleCard1>
                      <div>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                        >
                          ¿Tienes un objetivo de salud, deportivo o de
                          fertilidad al que te gustaría llegar de la forma más
                          saludable?{" "}
                        </h4>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="cuatro"
                              onclick="checarango(0)"
                              id="si"
                              value="1"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              Si
                            </h4>
                          </label>
                        </div>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="cuatro"
                              onclick="checarango(0)"
                              id="no"
                              value="0"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              No
                            </h4>
                          </label>
                        </div>
                        <div id="resultado"></div>
                      </div>
                      <NextBtn
                        variant="light"
                        onClick={() => (window.location.href = "#question5")}
                      >
                        Siguiente{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </NextBtn>
                    </SampleCard1>
                  </Col>
                </Row>
              </ContBanner>
            </Background>

            <Background>
              <ContBanner>
                <Col xs={12} md={10} lg={10} xl={12}>
                  <RedDiv>
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                      alt="paso 4"
                    />
                    <br />
                    <br />
                    <p>Prueba VG70, ¡Conócete genéticamente!</p>
                    <br />
                    <br />
                    <p>Pregunta 5/5</p>
                  </RedDiv>
                </Col>
                <Row id="question5">
                  <Col xs={8} md={8} lg={8} xl={4}>
                    <SampleCard1>
                      <div>
                        <h4
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            textAlign: "center",
                          }}
                        >
                          ¿Te interesa conocer más sobre ti y tu composición
                          genética?{" "}
                        </h4>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="cinco"
                              onclick="checarango(0)"
                              id="si"
                              value="5"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              Si
                            </h4>
                          </label>
                        </div>
                        <div class="radio">
                          <label class="radio">
                            <input
                              type="radio"
                              name="cinco"
                              onclick="checarango(0)"
                              id="no"
                              value="0"
                              style={{
                                display: "inline-block",
                                color: "#a42874",
                              }}
                            />
                            <h4
                              style={{
                                padding: "5px",
                                display: "inline-block",
                                fontSize: "22px",
                              }}
                            >
                              {" "}
                              No
                            </h4>
                          </label>
                        </div>
                        <div id="resultado"></div>
                      </div>
                      <NextBtn variant="light" onClick={displayRadioValue}>
                        Finalizar{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </NextBtn>
                    </SampleCard1>
                  </Col>
                </Row>
              </ContBanner>
            </Background>
          </RemoveScroll>
        </>
      ) : (
        <>
          <Background id="preguntas">
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <p>Pregunta 1/5</p>
                </RedDiv>
              </Col>
              <Row id="question1">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        ¿Has encontrado un tipo de alimentación que te ayude a
                        lograr tus objetivos de una forma saludable y
                        personalizada?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="uno"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="uno"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question2")}
                    >
                      {" "}
                      <p>
                        Siguiente{" "}
                        <UilArrowRight color="#fff" size="24" style={{}} />
                      </p>
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>

          <Background>
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <p>Pregunta 2/5</p>
                </RedDiv>
              </Col>
              <Row id="question2">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        ¿Te cuesta seguir patrones de alimentación muy estrictos
                        y es difícil que veas resultados positivos?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="dos"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="dos"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question3")}
                    >
                      Siguiente{" "}
                      <UilArrowRight color="#fff" size="24" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>

          <Background>
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <p>Pregunta 3/5</p>
                </RedDiv>
              </Col>
              <Row id="question3">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        ¿Presentas algunos síntomas como cansancio, pérdida de
                        cabello, malestar estomacal, entre otros, y no has
                        encontrado la raíz del problema?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="tres"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="tres"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question4")}
                    >
                      Siguiente{" "}
                      <UilArrowRight color="#fff" size="24" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>

          <Background>
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <p>Pregunta 4/5</p>
                </RedDiv>
              </Col>
              <Row id="question4">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        ¿Tienes un objetivo de salud, deportivo o de fertilidad
                        al que te gustaría llegar de la forma más saludable?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="cuatro"
                            onclick="checarango(0)"
                            id="si"
                            value="1"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="cuatro"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn
                      variant="light"
                      onClick={() => (window.location.href = "#question5")}
                    >
                      Siguiente{" "}
                      <UilArrowRight color="#fff" size="24" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>

          <Background>
            <ContBanner>
              <Col xs={12} md={10} lg={10} xl={12}>
                <RedDiv>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/iconsvitalgene%2FLogo%20Vg%20Negro%201.png?alt=media&token=5c95c2f0-1021-4e84-9b86-62e7560f8c01"
                    alt="paso 4"
                  />
                  <p>Prueba VG70, ¡Conócete genéticamente!</p>
                  <p>Pregunta 5/5</p>
                </RedDiv>
              </Col>
              <Row id="question5">
                <Col xs={8} md={8} lg={8} xl={4}>
                  <SampleCard1>
                    <div>
                      <h4
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        ¿Te interesa conocer más sobre ti y tu composición
                        genética?{" "}
                      </h4>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="cinco"
                            onclick="checarango(0)"
                            id="si"
                            value="5"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Si
                          </h4>
                        </label>
                      </div>
                      <div class="radio">
                        <label class="radio">
                          <input
                            type="radio"
                            name="cinco"
                            onclick="checarango(0)"
                            id="no"
                            value="0"
                            style={{
                              display: "inline-flex",
                              color: "#a42874",
                              alignContent: "space-around",
                              justifyContent: "space-around"
                            }}
                          />
                          <h4
                            style={{
                              padding: "5px",
                              display: "inline-block",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            No
                          </h4>
                        </label>
                      </div>
                      <div id="resultado"></div>
                    </div>
                    <NextBtn variant="light" onClick={displayRadioValue}>
                      Finalizar{" "}
                      <UilArrowRight color="#fff" size="24" style={{}} />
                    </NextBtn>
                  </SampleCard1>
                </Col>
              </Row>
            </ContBanner>
          </Background>
        </>
      )}
    </>
  );
};
