import React from "react";
import { Gluten } from "./gluten";
import { StudyGluten } from "./StudyGluten";
import { Beneficios } from "./beneficios";
import { Target } from "./target";
import { Reporte } from "./reporte";
import Testimonials from "./testimonials";

export const stuGluten = () => {
  return (
    <>
      <Gluten />
      <Target />
      <Testimonials />
      <Beneficios/>
      <Reporte />
      <StudyGluten />
    </>
  );
};
