import React, { useState } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

const Span = styled.span`
    font-weight: 600;
`;

const Item = styled(ListGroup.Item)`
    font-weight: 800;
`;

export const PersonalData = (props) => {
    const [info, setInfo] = useState(props.info[0])
    return (
        <>
            <ListGroup>
                {console.log(info.address)}
            <Item>Nombre: <Span>{info.name}</Span></Item>
            <Item>Teléfono: <Span>{info.phone}</Span></Item>
            <Item>Email: <Span>{info.email}</Span></Item>
            <Item>Dirección:
                <ul>
                    <li>Calle: <Span>{info.address.calle}</Span></li>
                    <li>N Exterior: <Span>{info.address.nExt}</Span></li>
                    <li>N Interior: <Span>{info.address.nInt}</Span></li>
                    <li>Colonia: <Span>{info.address.colonia}</Span></li>
                    <li>Municipio: <Span>{info.address.municipio}</Span></li>
                    <li>Estado: <Span>{info.address.estado}</Span></li>
                    <li>C.P: <Span>{info.address.cPostal}</Span></li>
                    <li>Pais: <Span>{info.address.pais}</Span></li>
                </ul>
            </Item>
            <Item>Datos de facturación:
                <ul>
                    <li>RFC: <Span>{info.billing.rfc}</Span></li>
                    <li>Razón Solcial: <Span>{info.billing.razonSocial}</Span></li>
                    <li>Uso factura: <Span>{info.billing.usoFactura ? 'Sí': 'No'}</Span></li>
                    <li>Calle: <Span>{info.billing.calle}</Span></li>
                    <li>N Exterior: <Span>{info.billing.nExt}</Span></li>
                    <li>N Interior: <Span>{info.billing.nInt}</Span></li>
                    <li>Colonia: <Span>{info.billing.colonia}</Span></li>
                    <li>Municipio: <Span>{info.billing.municipio}</Span></li>
                    <li>Estado: <Span>{info.billing.estado}</Span></li>
                    <li>C.P: <Span>{info.billing.cPostal}</Span></li>
                    <li>Pais: <Span>{info.billing.pais}</Span></li>
                </ul>
            </Item>
            </ListGroup>
        </>
    );
}