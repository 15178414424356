import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Slider from 'react-slick';
import { UilArrowCircleLeft } from '@iconscout/react-unicons';
import { UilArrowCircleRight } from '@iconscout/react-unicons';
import { Slide } from './slide';
import one from '../../assets/doctors/one.png';
import two from '../../assets/doctors/two.png';
import three from '../../assets/doctors/three.png';
import four from '../../assets/doctors/four.png';
import five from '../../assets/doctors/five.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Slides = [
  {
    id: 1,
    title: 'Queremos ser ese puente  entre tú y tu paciente',
    desc: 'Utiliza sin costo nuestro generador de solicitudes de estudios personalizado.',
    textButton: 'Generar Solicitud',
    urlButton: '/#',
    img: one,
  },
  {
    id: 2,
    title: 'Queremos ser ese puente  entre tú y tu paciente',
    desc: 'Recibe los resultados de tus pacientes en tu correo electrónico',
    textButton: 'Ver pacientes',
    urlButton: '/#',
    img: two,
  },
  {
    id: 3,
    title: 'Queremos ser ese puente  entre tú y tu paciente',
    desc: 'Personaliza paquetes de estudios para tus pacientes',
    textButton: 'Paquete de estudios',
    urlButton: '/#',
    img: three,
  },
  {
    id: 4,
    title: 'Queremos ser ese puente  entre tú y tu paciente',
    desc: 'Resguarda el historial de tus pacientes y consulta su historial digital',
    textButton: 'Ver pacientes',
    urlButton: '/#',
    img: four,
  },
  {
    id: 5,
    title: 'Queremos ser ese puente  entre tú y tu paciente',
    desc: 'Recibe boletines sobre nuevos estudios y pruebas',
    textButton: 'Suscribirse',
    urlButton: '/#',
    img: five,
  },
]

const StyledSlider = styled(Slider)`
  position: relative;
  > .slick-slider {
    position: absolute;
    bottom: 0;
  }
  > button {
    display: none !important;
  } 
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 60px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #00AD90;
  }
`;

export const DocsSlider = (props) => {
  // const [index, setIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <StyledSlider
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
    >
      {Slides.map((slide, index) => (
        <Slide data={slide} key={index}/>
      ))}
    </StyledSlider>
  );
};
