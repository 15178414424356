import "./styletrack.css";
import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import {
  UilEdit,
  UilArrowLeft,
  UilSave,
  UilTimes,
} from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import { Loader } from "../general/spinner";

const Title = styled.div`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #fff;
  margin-bottom: 0;
  padding: 0 0 0 8px;
  width: auto;
  span {
    font-weight: 400;
    color: #fff;
  }
`;

const Subtitle = styled.div`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #1d1d1d;
  margin-bottom: 0;
  padding: 0 0 0 8px;
  width: auto;
  span {
    font-weight: 400;
    font-size: 16px;
    color: #515253;
  }
`;

const DivHr = styled.div`
  width: 100%;
  border: 0.5px solid transparent;
  background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  padding-top: 1px;
  @media only screen and (max-width: 992px) {
    background: linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  }
`;

const BackBtn = styled(Button)`
  background: none;
  border: none;
  margin-bottom: 18px;
  color: ${(props) => (props.isDoctor ? "#8055ad" : "#77CCD5")};
  :hover {
    background: none;
    color: ${(props) => (props.isDoctor ? "#8055ad" : "#77CCD5")};
  }
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #697077;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const ResBtn = styled.button`
  max-width: 250px;
  height: 75px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  margin-left: 40px;
  border: 1px solid transparent;
  border-radius: 30px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #515253;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  :focus {
    background: #f529ad;
  }
  :hover {
    border: #f529ad;
    background: #f529ad;
    color: #fff;
    transition: 300ms;
  }
  :disabled {
    filter: grayscale();
    :hover {
      color: #353433;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 576px) {
    max-width: 250px;
    height: 15px;
    font-size: 16px;
    background: transparent;
    margin-left: -5px;
    color: #f529ad;
    text-decoration: underline;
    border: none;
    :focus {
      background: #f529ad;
    }
    :hover {
      border: none;
      background: none;
      color: #f529ad;
      transition: 300ms;
    }
  }
`;

const Res1Btn = styled.button`
max-width: 250px;
height: 75px;
background-clip: padding-box, border-box;
background-origin: padding-box, border-box;
background-image: linear-gradient(45deg, #fff, #fff),linear-gradient(90deg, #77CCD5 0.17%, #77CCD5 119.66%);
margin-left: 40px;
border: 1px solid transparent;
border-radius: 30px;
font-family: "IBM Plex Sans", sans-serif;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #515253;
display: flex;
align-items: center;
justify-content: center;
transition: 300ms;
:focus {
  background: #77CCD5;
}
:hover {
  border: #77CCD5;
  background: #77CCD5;
  color: #fff;
  transition: 300ms;
}
:disabled {
  filter: grayscale();
  :hover {
    color: #353433;
    box-shadow: none;
  }
}
@media only screen and (max-width: 576px) {
  max-width: 250px;
  height: 15px;
  background: transparent;
  margin-left: -5px;
  color: #77CCD5;
  text-decoration: underline;
  border: none;
  :focus {
    background: #77CCD5;
  }
  :hover {
    border: none;
    background: none;
    color: #77CCD5;
    transition: 300ms;
  }
}
`;

const GuiaBtn = styled.button`
  width: 340px;
  height: 52px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(90deg, #f529ad 0.17%, #8055ad 119.66%);
  margin-left: auto;
  margin-bottom: 30px;
  margin-right: auto;
  border: 1px solid transparent;
  border-radius: 30px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: rgb(81, 82, 83);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 300ms ease 0s;
`;

const DeleteButton = styled.button`
  max-width: 200px;
  height: 32px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(90deg, #8055ad 0.17%, #8055ad 119.66%);
  margin-right: 20px;
  border: 1px solid transparent;
  border-radius: 30px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8055ad;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
`;

const DataForm = styled.form`
  width: 90%;
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans", sans-serif;
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans", sans-serif;
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #8055ad;
  }

  > div input.form-check-input:checked {
    background-color: #8055ad;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #8055ad;
  }

  > div input.form-check-input:checked {
    background-color: #8055ad;
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

export default function TrackBar(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const isDoctor =
    useSelector((state) => state.user?.data?.data.profile?.type) === 1;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(10);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tests, setTests] = useState([]);
  const [phase, setPhase] = useState(0);
  const history = useHistory();
  const params = useParams();
  const { id } = useParams();
  const [value, setValu] = useState({
    shipped: false,
    process: false,
    pickup: false,
    received: false,
    processing: false,
    result: false,
  });
  let isMobile = width <= 576;
  let isDesk = width <= 1366;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (status === 11) {
      setValu({ ...value, shipped: true });
    } else if (status === 12) {
      setValu({ ...value, shipped: true, process: true });
    } else if (status === 13) {
      setValu({ ...value, pickup: true, shipped: true, process: true });
    } else if (status === 14) {
      setValu({
        ...value,
        received: true,
        pickup: true,
        shipped: true,
        process: true,
      });
    } else if (status === 15) {
      setValu({
        ...value,
        processing: true,
        received: true,
        pickup: true,
        shipped: true,
        process: true,
      });
      setShowModal(true);
    } else if (status === 16) {
      setValu({
        ...value,
        result: true,
        processing: true,
        received: true,
        pickup: true,
        shipped: true,
        process: true,
      });
      setShowModal(true);
    }
  }, [status]);

  useEffect(() => {
    dd();
  }, []);

  const getTestHistoryByPhase = async (code) => {
    setLoading(true);
    try {
      await axios
        .get(
          `https://clienteslabopat.com/user-sol-vg?id=${id}${
            code === undefined ? "" : `&fase=${code}`
          }`
        )
        .then((response) => {
          let orderByDate = response?.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setTests(orderByDate);
          setStatus(response.data[0].fase);
          console.log(response.data[0].fase);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const dd = async () => {
    try {
      setLoading(true);
      let requestPending = await axios.get(
        `https://clienteslabopat.com/user-sol-vg?id=${id}`
      );
      // console.log(requestPending.data);
      setTests(requestPending.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (datal) => {
    setLoading(true);
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://clienteslabopat.com/editsolmed?id=${id}&nombre=${datal?.nombre}&app=${datal?.app}&apm=${datal?.apm}`
      );
      // {id: params?.id, nombre:datal?.nombre, app: datal?.app, apm:datal?.apm}
      console.log(requestPending.data);
      setLoading(false);
      setEditing(false);
      dd();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // Set initial values with user info
    if (editing) {
      setValue("nombre", tests[0]?.nombre ?? "");
      setValue("app", tests[0]?.app ?? "");
      setValue("apm", tests[0]?.apm ?? "");
    }
    console.log(tests);
  }, [editing]);

  const formatDate = (date) => {
    date.split("-").join("/");
    const formatDate = date.split(" ")[0];
    return formatDate;
  };

  const formatHours = (date) => {
    date.split("-").join("/");
    const formatHours = date.split(" ")[1];
    const result = formatHours.split(":")[0] + ":" + formatHours.split(":")[1];
    return result;
  };

  useEffect(() => {
    getTestHistoryByPhase(phase);
  }, [phase]);

  return (
    <>
      {isDoctor ? (
        <>
          {!isMobile && (
            <Container
              style={{
                minHeight: "80vh",
                paddingTop: "25px",
                minWidth: "80vh",
              }}
            >
              <HelmetTitle
                title={`TrackBar-${id}`}
                name="description"
                content="content"
              />
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#8055ad" size="26" />
                Regresar
              </BackBtn>
              <DivHr />
              <Container>
                {tests.map((test, index) => (
                  <>
                    <HeaderSection test={test}>
                      <HeaderTitle>
                        {test?.nombre} {test?.app} {test?.apm}
                      </HeaderTitle>
                    </HeaderSection>
                    {!editing && (
                      <Row className="mb-4">
                        <DeleteButton onClick={() => setEditing(true)}>
                          <UilEdit /> &nbsp;&nbsp;Editar
                        </DeleteButton>
                      </Row>
                    )}
                    {editing && (
                      <>
                        <DataForm onSubmit={handleSubmit(onSubmit)}>
                          <FloatingLabel
                            controlId="floatingName"
                            label="Nombre"
                          >
                            <Form.Control
                              type="text"
                              {...register("nombre", {
                                required: false,
                              })}
                              placeholder="Nombre"
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingApp"
                            label="Apellido Paterno"
                          >
                            <Form.Control
                              type="text"
                              {...register("app", {
                                required: false,
                              })}
                              placeholder="Apellido Paterno"
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingApm"
                            label="Apellido Materno"
                          >
                            <Form.Control
                              type="text"
                              {...register("apm", {
                                required: false,
                              })}
                              placeholder="Apellido Materno"
                            />
                          </FloatingLabel>
                          <div className="d-flex w-100 mb-4 mt-4">
                            <DeleteButton type="submit">
                              <UilSave /> &nbsp;&nbsp;Guardar cambios
                            </DeleteButton>
                            <DeleteButton onClick={() => setEditing(false)}>
                              <UilTimes /> &nbsp;&nbsp;Cancelar
                            </DeleteButton>
                          </div>
                        </DataForm>
                      </>
                    )}
                    {!isDesk && (
                      <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                        <div class="card">
                          <div class="card1">
                            <div class="row d-flex justify-content-between px-3 top">
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex">
                                  <Title>
                                    No. Caso <br />
                                    {test?.casoLB}
                                  </Title>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de solicitud <br />
                                    <span>
                                      {formatDate(test?.created_at)}
                                      {" a las "}
                                      {formatHours(test?.created_at)}
                                    </span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={3}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Prueba solicitada <br />
                                    <span>{test?.laminilla}</span>
                                    <br />
                                    <br />
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de N. <br />
                                    <span>{test?.fnacimiento}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Edad <br />
                                    <span>{test?.edad}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Sexo <br />
                                    <span>{test?.genero}</span>
                                  </Title>
                                </div>
                              </Col>
                            </div>
                          </div>
                          <div class="row d-flex justify-content-center">
                            <div class="col-12">
                              <ul id="progressbar" class="text-center">
                                <li
                                  class={
                                    value.shipped === true
                                      ? "active step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Enviado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.process === true
                                      ? "active1 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Activado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.pickup === true
                                      ? "active2 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Pendiente de recolección
                                    <br />
                                    <span>{test?.frecoleccion}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.received === true
                                      ? "active3 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Muestra Recibida
                                    <br />
                                    <span>{test?.fmuestra}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.processing === true
                                      ? "active4 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Procesamiento de la prueba
                                    <br />
                                    <span>{test?.fproc}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.result === true
                                      ? "active5 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Liberación de resultados
                                    <br />
                                    <span>{test?.fliberacion}</span>
                                  </Subtitle>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isDesk && (
                      <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                        <div class="card" style={{ width: "78vw" }}>
                          <div class="card1">
                            <div class="row d-flex justify-content-between px-3 top">
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex">
                                  <Title>
                                    No. Caso <br />
                                    {test?.casoLB}
                                  </Title>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de solicitud <br />
                                    <span>
                                      {formatDate(test?.created_at)}
                                      {" a las "}
                                      {formatHours(test?.created_at)}
                                    </span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={3}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Prueba solicitada <br />
                                    <span>{test?.laminilla}</span>
                                    <br />
                                    <br />
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de N. <br />
                                    <span>{test?.fnacimiento}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Edad <br />
                                    <span>{test?.edad}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Sexo <br />
                                    <span>{test?.genero}</span>
                                  </Title>
                                </div>
                              </Col>
                            </div>
                          </div>
                          <div class="row d-flex justify-content-center">
                            <div class="col-12">
                              <ul id="progressbar" class="text-center">
                                <li
                                  class={
                                    value.shipped === true
                                      ? "active step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Enviado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.process === true
                                      ? "active1 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Activado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.pickup === true
                                      ? "active2 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Pendiente de recolección
                                    <br />
                                    <span>{test?.frecoleccion}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.received === true
                                      ? "active3 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Muestra Recibida
                                    <br />
                                    <span>{test?.fmuestra}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.processing === true
                                      ? "active4 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Procesamiento de la prueba
                                    <br />
                                    <span>{test?.fproc}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.result === true
                                      ? "active5 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Liberación de resultados
                                    <br />
                                    <span>{test?.fliberacion}</span>
                                  </Subtitle>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </Container>
              <Row style={{ paddingBottom: "15px" }}>
                {/*<ResBtn onClick={() => history.replace("#")}>
                  Guía para interpretación de resultados
                                </ResBtn>*/}
                {showModal && (
                  <>
                    <ResBtn
                      show={showModal}
                      onHide={() => {
                        setShowModal(false);
                      }}
                      onClick={() => {
                        window.location.href = `https://clienteslabopat.com/descargaDocumento?id=${id}&cual=1`;
                      }}
                    >
                      Guía de envío
                    </ResBtn>
                  </>
                )}
                {showModal && (
                  <>
                    <ResBtn
                      show={showModal}
                      onHide={() => {
                        setShowModal(false);
                      }}
                      onClick={() => {
                        window.location.href = `https://lpqsystem.com/descargaDocumento?id=${id}`;
                      }}
                    >
                      Reporte de resultados
                    </ResBtn>
                  </>
                )}
              </Row>
            </Container>
          )}
          {isMobile && (
            <Container style={{ minHeight: "80vh", paddingTop: "25px" }}>
              <HelmetTitle
                title={`TrackBar-${id}`}
                name="description"
                content="content"
              />
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#8055ad" size="26" />
                Regresar
              </BackBtn>
              <DivHr />
              <Container>
                {tests.map((test, index) => (
                  <>
                    <HeaderSection test={test}>
                      <HeaderTitle>{test?.nombre}</HeaderTitle>
                    </HeaderSection>
                    {!editing && (
                      <Row className="mb-4">
                        <DeleteButton onClick={() => setEditing(true)}>
                          <UilEdit /> &nbsp;&nbsp;Editar
                        </DeleteButton>
                      </Row>
                    )}
                    {editing && (
                      <>
                        <DataForm test={test} onSubmit={handleSubmit(onSubmit)}>
                          <FloatingLabel
                            controlId="floatingName"
                            label="Nombre y apellidos"
                          >
                            <Form.Control
                              type="text"
                              {...register("nombre", "app", "apm", {
                                required: true,
                              })}
                              placeholder="Nombre y apellidos"
                            />
                          </FloatingLabel>
                          <div className="d-flex w-100 mb-4 mt-4">
                            <DeleteButton type="submit">
                              <UilSave /> &nbsp;&nbsp;Guardar cambios
                            </DeleteButton>
                            <DeleteButton onClick={() => setEditing(false)}>
                              <UilTimes /> &nbsp;&nbsp;Cancelar
                            </DeleteButton>
                          </div>
                        </DataForm>
                      </>
                    )}
                    <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                      <div
                        class="card"
                        style={{
                          width: "86vw",
                          height: "88vh",
                          marginBottom: "20px",
                        }}
                      >
                        <div class="card1">
                          <div class="row d-flex justify-content-between px-3 top">
                            <div class="d-flex flex-column text-sm-right">
                              <Title>
                                Prueba solicitada <br />
                                <span>{test?.laminilla}</span>
                                <br />
                                <br />
                              </Title>
                            </div>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  No. Caso <br />
                                  {test?.casoLB}
                                  <br />
                                </Title>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div class="d-flex">
                                <Title>
                                  Fecha de solicitud <br />
                                  <span>
                                    {formatDate(test?.created_at)}
                                    <br />
                                    <br />
                                  </span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  F de N. <br />
                                  <span>{test?.fnacimiento}</span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  Edad <br />
                                  <span>{test?.edad}</span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  Sexo <br />
                                  <span>{test?.genero}</span>
                                </Title>
                              </div>
                            </Col>
                            <br />
                            <br />
                          </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div>
                            <ul
                              id="progressbar"
                              class="text-center"
                              style={{
                                rotate: "90deg",
                                marginTop: "250px",
                                marginLeft: "-195px",
                              }}
                            >
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.result === true
                                    ? "active5 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.processing === true
                                    ? "active4 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.received === true
                                    ? "active3 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.pickup === true
                                    ? "active2 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.process === true
                                    ? "active1 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.6%"}}
                                class={
                                  value.shipped === true
                                    ? "active step0"
                                    : "step0"
                                }
                              ></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            rotate: "0deg",
                            marginTop: "-275px",
                            marginLeft: "120px",
                          }}
                        >
                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Liberación de resultados
                            <br />
                            <span>{test?.fproc}</span>
                            {showModal && (
                              <>
                                <ResBtn
                                  show={showModal}
                                  onHide={() => {
                                    setShowModal(false);
                                  }}
                                  onClick={() => {
                                    window.location.href = `https://lpqsystem.com/descargaDocumento?id=${id}`;
                                  }}
                                >
                                  Reporte de resultados
                                </ResBtn>
                              </>
                            )}
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Procesamiento de la prueba
                            <br />
                            <span>{test?.fproc}</span>
                            <br />
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Muestra Recibida
                            <br />
                            <span>{test?.fmuestra}</span>
                            <br />
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Pendiente de recolección
                            <br />
                            <span>{test?.frecoleccion}</span>
                            <br />
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Kit Activado
                            <br />
                            <span>N/A</span>
                            <br />
                            {showModal && (
                              <>
                                <ResBtn
                                  show={showModal}
                                  onHide={() => {
                                    setShowModal(false);
                                  }}
                                  onClick={() => {
                                    window.location.href = `https://clienteslabopat.com/descargaDocumento?id=${id}&cual=1`;
                                  }}
                                >
                                  Guía de envío
                                </ResBtn>
                              </>
                            )}
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Kit Enviado
                            <br />
                            <span>N/A</span>
                            <br />
                            <br />
                          </Subtitle>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {/*<ResBtn onClick={() => history.replace("#")}>
                  Guía para interpretación de resultados
                                </ResBtn>*/}
              </Container>
            </Container>
          )}
        </>
      ) : (
        <>
          {!isMobile && (
            <Container
              style={{
                minHeight: "80vh",
                paddingTop: "25px",
                minWidth: "80vh",
              }}
            >
              <HelmetTitle
                title={`TrackBar-${id}`}
                name="description"
                content="content"
              />
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#77CCD5" size="26" />
                Regresar
              </BackBtn>
              <DivHr />
              <Container>
                {tests.map((test, index) => (
                  <>
                    <HeaderSection test={test}>
                      <HeaderTitle>
                        {test?.nombre} {test?.app} {test?.apm}
                      </HeaderTitle>
                    </HeaderSection>
                    {!isDesk && (
                      <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                        <div
                          class="card"
                          style={{
                            backgroundImage:
                              "linear-gradient(45deg, #fff, #fff),linear-gradient(90deg, #77CCD5 0.17%, #77CCD5 119.66%)",
                          }}
                        >
                          <div class="card1" style={{ background: "#77CCD5" }}>
                            <div class="row d-flex justify-content-between px-3 top">
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex">
                                  <Title>
                                    No. Caso <br />
                                    {test?.casoLB}
                                  </Title>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de solicitud <br />
                                    <span>
                                      {formatDate(test?.created_at)}
                                      {" a las "}
                                      {formatHours(test?.created_at)}
                                    </span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={3}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Prueba solicitada <br />
                                    <span>{test?.laminilla}</span>
                                    <br />
                                    <br />
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de N. <br />
                                    <span>{test?.fnacimiento}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Edad <br />
                                    <span>{test?.edad}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Sexo <br />
                                    <span>{test?.genero}</span>
                                  </Title>
                                </div>
                              </Col>
                            </div>
                          </div>
                          <div class="row d-flex justify-content-center">
                            <div class="col-12">
                              <ul id="progressbar2" class="text-center">
                                <li
                                  style={{ width: "18.4%" }}
                                  class={
                                    value.shipped === true
                                      ? "active step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Enviado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  style={{ width: "18.4%" }}
                                  class={
                                    value.process === true
                                      ? "active1 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Activado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  style={{ width: "18.4%" }}
                                  class={
                                    value.pickup === true
                                      ? "active2 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Pendiente de recolección
                                    <br />
                                    <span>{test?.frecoleccion}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  style={{ width: "18.4%" }}
                                  class={
                                    value.processing === true
                                      ? "active3 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    En Procesamiento
                                    <br />
                                    <span>{test?.fproc}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  style={{ width: "18.4%" }}
                                  class={
                                    value.result === true
                                      ? "active4 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Resultados listos
                                    <br />
                                    <span>{test?.fliberacion}</span>
                                  </Subtitle>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isDesk && (
                      <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                        <div class="card" style={{ width: "78vw" }}>
                          <div class="card1">
                            <div class="row d-flex justify-content-between px-3 top">
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex">
                                  <Title>
                                    No. Caso <br />
                                    {test?.casoLB}
                                  </Title>
                                </div>
                              </Col>
                              <Col xs={2} sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de solicitud <br />
                                    <span>
                                      {formatDate(test?.created_at)}
                                      {" a las "}
                                      {formatHours(test?.created_at)}
                                    </span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={3}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Prueba solicitada <br />
                                    <span>{test?.laminilla}</span>
                                    <br />
                                    <br />
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={2}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Fecha de N. <br />
                                    <span>{test?.fnacimiento}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Edad <br />
                                    <span>{test?.edad}</span>
                                  </Title>
                                </div>
                              </Col>
                              <Col sm={2} md={1}>
                                <div class="d-flex flex-column text-sm-right">
                                  <Title>
                                    Sexo <br />
                                    <span>{test?.genero}</span>
                                  </Title>
                                </div>
                              </Col>
                            </div>
                          </div>
                          <div class="row d-flex justify-content-center">
                            <div class="col-12">
                              <ul id="progressbar2" class="text-center">
                                <li
                                  class={
                                    value.shipped === true
                                      ? "active step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Kit Enviado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.process === true
                                      ? "active1 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Activado
                                    <br />
                                    <span>N/A</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.pickup === true
                                      ? "active2 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Pendiente de recolección
                                    <br />
                                    <span>{test?.frecoleccion}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.processing === true
                                      ? "active3 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    En Procesamiento
                                    <br />
                                    <span>{test?.fproc}</span>
                                  </Subtitle>
                                </li>
                                <li
                                  class={
                                    value.result === true
                                      ? "active4 step0"
                                      : "step0"
                                  }
                                >
                                  <Subtitle>
                                    <br />
                                    Resultados listos
                                    <br />
                                    <span>{test?.fliberacion}</span>
                                  </Subtitle>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </Container>
              <Row style={{ paddingBottom: "15px" }}>
               {/*<ResBtn onClick={() => history.replace("#")}>
                  Guía para interpretación de resultados
                                </ResBtn>*/}
                {showModal && (
                  <>
                    <Res1Btn
                      show={showModal}
                      onHide={() => {
                        setShowModal(false);
                      }}
                      onClick={() => {
                        window.location.href = `https://clienteslabopat.com/descargaDocumento?id=${id}&cual=1`;
                      }}
                    >
                      Guía de envío
                    </Res1Btn>
                  </>
                )}
                {showModal && (
                  <>
                    <Res1Btn
                      show={showModal}
                      onHide={() => {
                        setShowModal(false);
                      }}
                      onClick={() => {
                        window.location.href = `https://lpqsystem.com/descargaDocumento?id=${id}`;
                      }}
                    >
                      Reporte de resultados
                    </Res1Btn>
                  </>
                )}
              </Row>
            </Container>
          )}
          {isMobile && (
            <Container style={{ minHeight: "80vh", paddingTop: "25px" }}>
              <HelmetTitle
                title={`TrackBar-${id}`}
                name="description"
                content="content"
              />
              <BackBtn variant="light" onClick={() => window.history.back()}>
                <UilArrowLeft color="#77CCD5" size="26" />
                Regresar
              </BackBtn>
              <DivHr />
              <Container>
                {tests.map((test, index) => (
                  <>
                    <HeaderSection test={test}>
                      <HeaderTitle>{test?.nombre}</HeaderTitle>
                    </HeaderSection>
                    <div class="px-1 px-md-4 mx-auto" test={test} key={index}>
                      <div
                        class="card"
                        style={{
                          width: "86vw",
                          height: "82vh",
                          marginBottom: "20px",
                          backgroundImage:
                            "linear-gradient(45deg, #fff, #fff),linear-gradient(90deg, #77CCD5 0.17%, #77CCD5 119.66%)"
                        }}
                      >
                        <div class="card1" style={{ background: "#77CCD5" }}>
                          <div class="row d-flex justify-content-between px-3 top">
                            <div class="d-flex flex-column text-sm-right">
                              <Title>
                                Prueba solicitada <br />
                                <span>{test?.laminilla}</span>
                                <br />
                                <br />
                              </Title>
                            </div>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  No. Caso <br />
                                  {test?.casoLB}
                                  <br />
                                </Title>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div class="d-flex">
                                <Title>
                                  Fecha de solicitud <br />
                                  <span>
                                    {formatDate(test?.created_at)}
                                    <br />
                                    <br />
                                  </span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  F de N. <br />
                                  <span>{test?.fnacimiento}</span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  Edad <br />
                                  <span>{test?.edad}</span>
                                </Title>
                              </div>
                            </Col>
                            <Col xs={4}>
                              <div class="d-flex">
                                <Title>
                                  Sexo <br />
                                  <span>{test?.genero}</span>
                                </Title>
                              </div>
                            </Col>
                            <br />
                            <br />
                          </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div>
                            <ul
                              id="progressbar2"
                              class="text-center"
                              style={{
                                rotate: "90deg",
                                marginTop: "250px",
                                marginLeft: "-195px",
                              }}
                            >
                              <li
                              style={{width:"16.4%"}}
                                class={
                                  value.result === true
                                    ? "active4 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.4%"}}
                                class={
                                  value.processing === true
                                    ? "active3 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.4%"}}
                                class={
                                  value.pickup === true
                                    ? "active2 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.4%"}}
                                class={
                                  value.process === true
                                    ? "active1 step0"
                                    : "step0"
                                }
                              ></li>
                              <li
                              style={{width:"16.4%"}}
                                class={
                                  value.shipped === true
                                    ? "active step0"
                                    : "step0"
                                }
                              ></li>
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            rotate: "0deg",
                            marginTop: "-275px",
                            marginLeft: "120px",
                          }}
                        >
                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Liberación de resultados
                            <br />
                            <span>{test?.fliberacion}</span>
                            {showModal && (
                              <>
                                <Res1Btn
                                  show={showModal}
                                  onHide={() => {
                                    setShowModal(false);
                                  }}
                                  onClick={() => {
                                    window.location.href = `https://lpqsystem.com/descargaDocumento?id=${id}`;
                                  }}
                                >
                                  Reporte de resultados
                                </Res1Btn>
                              </>
                            )}
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Procesamiento de la prueba
                            <br />
                            <span>{test?.fproc}</span>
                            <br />
                            <br />
                          </Subtitle>
                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Pendiente de recolección
                            <br />
                            <span>{test?.frecoleccion}</span>
                            <br />
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Kit Activado
                            <br />
                            <span>N/A</span>
                            <br />
                            {showModal && (
                              <>
                                <Res1Btn
                                  show={showModal}
                                  onHide={() => {
                                    setShowModal(false);
                                  }}
                                  onClick={() => {
                                    window.location.href = `https://clienteslabopat.com/descargaDocumento?id=${id}&cual=1`;
                                  }}
                                >
                                  Guía de envío
                                </Res1Btn>
                              </>
                            )}
                            <br />
                          </Subtitle>

                          <Subtitle
                            style={{
                              rotate: "0deg",
                            }}
                          >
                            <br />
                            Kit Enviado
                            <br />
                            <span>N/A</span>
                            <br />
                            <br />
                          </Subtitle>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {/*<ResBtn onClick={() => history.replace("#")}>
                  Guía para interpretación de resultados
                                </ResBtn>*/}
              </Container>
            </Container>
          )}
        </>
      )}
    </>
  );
}
