import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import PathologyIcon from "../../assets/aboutUs/icons/patologia.png";
import RoutineIcon from "../../assets/aboutUs/icons/rutina.png";
import MolecularIcon from "../../assets/aboutUs/icons/molecular.png";
import GeneticsIcon from "../../assets/aboutUs/icons/geneticos.png";
import Genetics2Icon from "../../assets/aboutUs/icons/geneticos2.png";
import ClinicalsIcon from "../../assets/aboutUs/icons/clinicos.png";
import BiopsyIcon from "../../assets/aboutUs/icons/biopsia.png";
import UltrasoundIcon from "../../assets/aboutUs/icons/ultrasonido.png";
import CytopathologyIcon from "../../assets/aboutUs/icons/citopatologia.png";
import MaquilaIcon from "../../assets/aboutUs/icons/maquila.png";

const GreenTitle = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 115%;
  color: #00AD90;
  margin-bottom: 50px;
  `;

const Introduction = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 548px;
  p {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  @media(max-width: 768px){
    ${GreenTitle}{
      font-size: 22px;
    }
    p{
      font-size: 15px;
    }
  }
`;

const ServicesDiv = styled.div`
  padding: 38px 160px 38px 160px;
  flex-direction: column;
  @media (max-width: 576px){
    padding: 0;
  }
`;

const ServiceCard = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  width: 262px;
  height: 200px;
  background-color: rgba(111, 190, 160, 0.1);
`;

const ServiceIcon = styled.img`
  max-width: 110px;
  height: auto;
`;

const services = [
  {
    name: "Patología Quirúrgica",
    icon: `${PathologyIcon}`
  },
  {
    name: "Citopatología",
    icon: `${CytopathologyIcon}`
  },
  {
    name: "Inmunohistoquímica de diagnóstico: de rutina y especializada",
    icon: `${RoutineIcon}`
  },
  {
    name: "Maquila de Anticuerpos",
    icon: `${MaquilaIcon}`
  },
  {
    name: "Biología Molecular: Patología Molecular, Microbiología molecular, Paneles Respiratorios",
    icon: `${MolecularIcon}`
  },
  {
    name: "Servicios Genéticos",
    icon: `${GeneticsIcon}`
  },
  {
    name: "Secuenciación de Nueva Generación",
    icon: `${Genetics2Icon}`
  },
  {
    name: "Análisis clínicos",
    icon: `${ClinicalsIcon}`
  },
  {
    name: "Biopsias",
    icon: `${BiopsyIcon}`
  },
  {
    name: "Ultrasonido e Imagen",
    icon: `${UltrasoundIcon}`
  }
]

export const OurServices = () => {
  return(
    <>
      <Container className="d-flex flex-column align-items-center" style={{padding: "80px 0 0 0"}}>
        <Introduction data-aos='fade-up'>
          <Col xl={12} md={12} sm={6} xs={6}>
            <GreenTitle>Nuestros servicios</GreenTitle>
            <p>A lo largo de más de 30 años hemos trabajado en implementar estudios y tecnología de vanguardia para poder dar a nuestros médicos y pacientes la mejor calidad en el diagnóstico.</p>
          </Col>
        </Introduction>
        <ServicesDiv>
          <Row className="d-flex justify-content-around">
            {services?.map((service, index) => (
              <Col data-aos='fade-up' key={index} className="p-2 text-center" xl={4} lg={6} md={12}>
                <ServiceCard>
                  <ServiceIcon src={service.icon} />
                </ServiceCard>
                <p className="mt-2">{service.name}</p>
              </Col>
            ))}
          </Row>
        </ServicesDiv>
      </Container>
    </>
  );
}