import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import TestBox from '../../../assets/home/cel.png';
import Vial1 from '../../../assets/home/vial1.png';
import Vial2 from '../../../assets/home/vial2.png';
import Slider from 'react-slick';
import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons'
import { useState } from 'react';

const VialCeliaquia = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVialCeliaquia.png?alt=media&token=b8ba9916-0614-446f-85d9-cf0d38852af8';
const VialLactosa = 'https://firebasestorage.googleapis.com/v0/b/vitalgene-3866f.appspot.com/o/cajas%2FVialLactosa.png?alt=media&token=224bb78b-3978-4ba2-bec0-c2b2a0348258';


const SliderSection = styled.div`
  background-color: #ffffff;
`;

const StyledContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  };
`;

const StyledColDesc = styled(Col)`
  position: absolute;
  top: 0;
  left: 0;
  @media only screen and (max-width: 992px) {
    margin-bottom: 0 !important;
  };
  @media only screen and (max-width: 768px) {
    margin-bottom: 1.5rem !important;
    position: unset;
  };
`;

const SlideContainer = styled.div`
  padding: 0;
  max-width: 562px;
  max-height: 369px;
  > img {
    max-width: 562px !important;
    max-height: 369px !important;
  }
`;

const StyledColSlider = styled(Col)`
  .slick-slider, .slick-list {
    position: unset;
    @media only screen and (max-width: 768px) {
      position: relative;
    }
  }
`;

const SliderTitle = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  letter-spacing: 0.01em;
  color: #2E363E;
  margin: 0;
  max-width: 380px;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 32px;
    line-height: 42px;
  }
`;

const SliderDesc = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #2E363E;
  margin: 0;
  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
  }
`;

const ImageDesc = styled.p`
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #2E363E;
  margin: 0;
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  .slick-dots {
    position: absolute;
    left: 0;
    bottom: 15%;
    width: auto;
    display: flex !important;
    align-items: center;
    align-content: center;
    padding-left: calc(var(--bs-gutter-x) * .5);
    @media only screen and (max-width: 992px) {
      bottom: -1em;
    }
    @media only screen and (max-width: 768px) {
      position: unset;
      justify-content: center;
      padding: 1rem 0 0 0 !important;
    }
  }
  .slick-dots li {
    height: 12px;
    width: 24px;
    display: flex;
    align-items: center;
    align-content: center;
  }
  .slick-dots li button {
    height: 20px;
    display: flex;
    align-items: center;
  }
  .slick-dots li button:before {
    font-size: 10px;
    height: 12px;
    display: flex;
    color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    transition: width 300ms;
  }
  .slick-dots li.slick-active button:before {
    color: #F529AD;
    background: #F529AD;
    border-radius: 81px;
    height: 10px;
    width: 28px;
    transition: width 300ms;
  }
  .slick-prev {
    background-color: #F529AD;
    left: 37rem;
    @media only screen and (max-width: 1400px){
      left: 31.88rem;
    }
    @media only screen and (max-width: 1200px){
      left: 27.74rem;
    }
    @media only screen and (max-width: 991px){
      left: 19.74rem;
    }
    @media only screen and (max-width: 768px){
      display: none !important;
    }
  }
  .slick-next {
    background-color: #F529AD;
    right: -3rem;
    @media only screen and (max-width: 1200px){
      right: -1.5rem;
    }
    @media only screen and (max-width: 991px){
      right: -2rem;
    }
    @media only screen and (max-width: 768px){
      display: none !important;
    }
  }
`;

const PrevArrowBtn = styled.button`
  width: 46px;
  height: 46px;
  background-color: #F529AD;
  border-radius: 50%;
  ::before {
    display: none;
  }
  :hover {
    background-color: #F529AD;
  }
  @media only screen and (max-width: 1400px){
    width: 34px;
    height: 34px;
  }
  @media only screen and (max-width: 1200px){
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 991px){
    width: 36px;
    height: 36px;
  }
`;

const NextArrowBtn = styled.button`
  width: 46px;
  height: 46px;
  background-color: #F529AD;
  border-radius: 50%;
  ::before {
    display: none;
  }
  :hover {
    background-color: #F529AD;
  }
  @media only screen and (max-width: 1400px){
    width: 34px;
    height: 34px;
  }
  @media only screen and (max-width: 1200px){
    width: 30px;
    height: 30px;
  }
  @media only screen and (max-width: 991px){
    width: 36px;
    height: 36px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 75vw;
    width: 100%;
    max-height: 95vh;
    height: auto;
    @media only screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: auto;
      height: 100%;
    }
  }
  .modal-content {
    height: 100%;
    @media only screen and (max-width: 576px) {
      height: auto;
    }
  }
  .modal-header {
    border-bottom: none;
  }
  .current-img {
    max-width: 55vw;
    width: 100%;
    height: auto;
    @media only screen and (max-width: 576px) {
      max-width: 100vw;
    }
  }
`;

export const TestSlider = () => {
  const [showModal, setShowModal] = useState(false);
  const [slideId, setSlideId] = useState(0);
  const slides = [
    {
      id: 0,
      img: TestBox,
      desc: '¡Lo que encontrarás en tu KIT!',
      alt: 'Imagen de Kit de ejemplo'
    },
    {
      id: 1,
      img: Vial1,
      desc: 'Ejemplo de la toma de muestra',
      alt: 'Imagen de ejemplo de toma de muestra'
    },
    {
      id: 2,
      img: Vial2,
      desc: 'Ejemplo de la toma de muestra',
      alt: 'Imagen de ejemplo de toma de muestra 2'
    },
    {
      id: 3,
      img: VialCeliaquia,
      desc: 'Lo que encontrarás en tu kit.',
      alt: 'Imagen del contenido del Kit de celiaquia'
    },
    {
      id: 4,
      img: VialLactosa,
      desc: 'Lo que encontrarás en tu kit.',
      alt: 'Imagen del contenido del Kit de lactosa'
    },
  ];

  const DetailModal = (props) => {
    let currentSlide = slides.find((slide) => slide.id === slideId);
    return(
      <StyledModal
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <StyledModal.Header closeButton>
        </StyledModal.Header>
        <StyledModal.Body>
          <img src={currentSlide.img} alt={currentSlide.alt} className='current-img' />
        </StyledModal.Body>
      </StyledModal>
    );
  };

  const PrevArrow = (props) => {
    const {className, style, onClick} = props;
    return(
      <PrevArrowBtn
        type='button'
        className={className}
        style={{...style}}
        onClick={onClick}
      >
        <UilArrowLeft
          size={26}
          color={'#ffffff'}
          className='w-auto'
        />
      </PrevArrowBtn>
    );
  };

  const NextArrow = (props) => {
    const {className, style, onClick} = props;
    return(
      <NextArrowBtn
        type='button'
        className={className}
        style={{...style}}
        onClick={onClick}
      >
        <UilArrowRight
          size={26}
          color={'#ffffff'}
          className='w-auto'
        />
      </NextArrowBtn>
    );
  };
    
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };

  return(
    <SliderSection>
      <StyledContainer>
        <Row className='position-relative justify-content-end'>
          <StyledColDesc xs={12} sm={12} md={6}>
            <div className='pt-4 w-auto'>
              <SliderTitle className='mb-4'>
                Fácil, sin dolor y sin agujas
              </SliderTitle>
              <SliderDesc>
                Muestra{' '}
                <b>
                  en saliva
                </b>
              </SliderDesc>
            </div>
          </StyledColDesc>
          <DetailModal />
          <StyledColSlider xs={12} sm={12} md={6} className='mb-0'>
            <StyledSlider {...settings}>
              {slides.map((slide, index) => (
                <div className='w-auto' key={index}>
                  <SlideContainer className='mb-2' onClick={() => {setShowModal(true); setSlideId(slide.id)}}>
                    <img src={slide.img} alt={slide.alt} className='img-fluid w-100' />
                  </SlideContainer>
                  <ImageDesc>{slide.desc}</ImageDesc>
                </div>
              ))}
            </StyledSlider>
          </StyledColSlider>
        </Row>
      </StyledContainer>
    </SliderSection>
  );
}