import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Desktop } from "./desktop";
import { MobileMenu } from "./mobile";
import { useCookies } from "react-cookie";
import axios from "axios";
import { DesktopMenu } from "../../components/menu/desktop";
import {
  UilExclamationTriangle,
  UilShoppingCart,
  UilUserCircle,
  UilTicket,
  UilPhone,
} from "@iconscout/react-unicons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLogged } from "../../actions/auth";
import { ModalCart } from "../shopping-cart/ModalCart";
import { setOfferBanner } from "../../actions/auxiliar";
import { UilMultiply } from "@iconscout/react-unicons";
import { Cart } from "../shopping-cart/cart";
import { Searcher } from "./searcher";
import VG70Icon from "../../assets/menu/VG70.svg";
import VG45Icon from "../../assets/menu/VG45.svg";
import CeliaquiaIcon from "../../assets/menu/Celiaquia.svg";
import LactosaIcon from "../../assets/menu/Lactosa.svg";
import CafeinaIcon from "../../assets/menu/cafeina.png";
import { getUserInfo } from "../../../src/actions/user";

let menu = [
  {
    name: "Pruebas genéticas",
    link: "/estudios-servicios",
    section: "studi",
    arrow: true,
    submenu: [
      {
        nameA: "Nutrición personalizada",
        nameB: "Vitalgene 70",
        link: "/vg70/estudio/598",
        icon: `${VG70Icon}`,
        width: 70,
        color: "#D44DAD",
        description: "",
      },
      /*{
        nameA: "Nutrición personalizada",
        nameB: "Vitalgene 45",
        link: "/estudio/519",
        icon: `${VG45Icon}`,
        width: 70,
        color: "#8355B7",
        description: "",
      },*/
      {
        nameA: "Intolerancia",
        nameB: "al gluten",
        link: "/gluten/estudio/166",
        icon: `${CeliaquiaIcon}`,
        width: 60,
        color: "#E49D5B",
        description: "",
      },
      /*{
        nameA: "Intolerancia a",
        nameB: "la lactosa",
        link: "/lactosa/estudio/590",
        icon: `${LactosaIcon}`,
        width: 60,
        color: "#90CB15",
        description: "",
      },
      {
        nameA: "Intolerancia a",
        nameB: "la cafeína",
        link: "/cafeina/estudio/974",
        icon: `${CafeinaIcon}`,
        width: 60,
        color: "#8355B7",
        description: "",
      }*/
    ],
  },
  {
    name: "¿Cómo funciona?",
    link: "/covid-19",
    arrow: false,
    section: "como-funciona",
  },
  {
    /*
    name: 'Curso',
    link: 'https://vitalgene.teachable.com/p/lovitaldelanutricion',
    arrow: false,
    section: 'curso'
  },
  {
    name: 'Programa Transforma',
    link: '/programa-transforma',
    arrow: false,
    section: 'programa-transforma'
  */
  },
  {
    name: "Descubre Vitalgene",
    link: "/medicos",
    arrow: false,
    section: "descubre",
  },
  // {
  //   name: 'Membresias',
  //   link: '/membresias',
  //   arrow: false,
  // },
];

const Menu = styled(Row)`
  background-color: #ffffff;
`;

const DetailMenu = styled(Row)`
  padding: 10px 0 10px 0;
  padding-right: 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  justify-content: space-between;
`;

const ShowListStudies = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-weight: 500;
  padding-left: 16px;
  padding-right: 16px;
  @media only screen and (max-width: 576px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const OfferMenu = styled(Row)`
  background: #0a7a71;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  margin-left: 0px;
  margin-right: 0px;
`;
const TextOffer = styled.p`
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin-top: 3px;
`;

const DetailItemA = styled.div`
  width: auto !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    text-align: right;
  }
  > div svg:hover {
    cursor: pointer;
  }
`;
const DetailItemB = styled.div`
  width: auto !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const CloseButton = {
  color: "red",
  width: "100%",
  fontWeight: "bold",
  fontSize: "16px",
  padding: "0",
};

const MoreInfo = styled.a`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #ffffff;
  ::-webkit-input-placeholder {
    font-size: 14px;
    color: #a6a6a6;
  }
`;

const Item = styled.span`
  display: inline-block;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const CartCount = styled.span`
  background-color: #ff4581;
  display: block;
  position: absolute;
  top: -5px;
  right: -15px;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 13px;
`;

const PerfilLink = styled.a`
  text-decoration: none;
  color: #000;
  :hover {
    text-decoration: underline;
    color: #000;
  }
`;

const SectionDiv = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  border-right: 1px solid #e2eaf2;
  @media only screen and (max-width: 576px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PhoneIconContainer = styled.div`
  padding: 2px;
  width: 27px;
  height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #77ccd5;
  border-radius: 50%;
  > a svg {
    transform: rotate(14.21deg);
  }
`;

export const NavBar = (props) => {
  const location = useLocation();
  const theUID = useSelector((state) => state.auth?.uid || "");
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);
  const API = useSelector((state) => state.api);
  const [cart, setCart] = useState({ items: [], id: "" });
  const [showOffer, setShowOffer] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [showSearcher, setShowSearcher] = useState(false);
  const [search, setSearching] = useState("");
  const [inCheckout, setInCheckout] = useState("block");
  const { items } = useSelector((state) => state.shoppingCart);
  const user = useSelector((state) => state?.user?.data.data);
  const [userName, setUserName] = useState("");
  //const userName = useSelector((state) => state.auth?.name || undefined);
  let index = userName?.indexOf(" ");
  const isDoctor =
  useSelector((state) => state.user?.data?.data.profile?.type) === 1;

  const showSearch = () => {
    setShowSearcher(true);
  };
  const hideSearch = () => {
    setShowSearcher(false);
  };

  const searching = (search) => {
    console.log("busqueda: ", search);
    setSearching(search);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (location.pathname === "/checkout") {
      setInCheckout("none");
    } else {
      setInCheckout("block");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 900;

  const history = useHistory();

  const handleMenu = () => setShowMenu(!showMenu);

  const handleOpenModalCart = () => {
    props.setShowCart(true);
  };

  const setUser = async () => {
    //console.log("the uid", theUID)
    let user = await getUserInfo(theUID);
    var drGender = "Dr. ";
    if (user.data.gender === "Femenino") {
      drGender = "Dra. ";
    }
    var name = "";
    if (user.data?.profile?.type === 1) {
      name = `${user.data.name} ${user.data.fathersLastname}`;
    } else {
      name = `${user.data.name} ${user.data.fathersLastname}`;
    }
    setUserName(name);
  };

  useEffect(() => {
    setUser();
  }, []);

  return (
    <div style={{ zIndex: 7, position: "sticky", top: 0 }} id="topNavBar">
      <ModalCart />
      <Menu className="gx-0">
        {showOffer ? (
          <OfferMenu id="offerBanner">
            <Col sm={1} xs={1} />
            <Col
              sm={10}
              xs={10}
              className="text-left my-auto mt-1 mb-1"
              style={{ display: "inline-block" }}
            >
              <UilExclamationTriangle size="28" color="#F9AD62" />{" "}
              <TextOffer
                className="mt-2"
                style={{ display: "inline", paddingLeft: 8 }}
              >
                En LABOPAT tenemos múltiples opciones para detectar COVID{" "}
                <MoreInfo href="covid-19">Más información</MoreInfo>
              </TextOffer>
            </Col>
            <Col
              sm={1}
              xs={1}
              className="text-center"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="link"
                style={CloseButton}
                className="btn btn-sm"
                onClick={() => {
                  setShowOffer(false);
                  dispatch(setOfferBanner(false));
                }}
              >
                <UilMultiply color="#fff" />
              </Button>
            </Col>
          </OfferMenu>
        ) : null}
        <div
          style={{ backgroundColor: "rgba(1, 181, 203, 0.1)", width: "100%" }}
        >
          <div className={width > 992 && "container"}>
            <DetailMenu>
              <div className="p-0 w-auto d-flex flex-row align-items=center">
                <DetailItemA>
                  <Row className="align-items-center">
                    <PhoneIconContainer>
                      <a
                        className="m-0 p-0"
                        href={"https://wa.me/525615077271"}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <UilPhone
                          size={18}
                          color="#77CCD5"
                          className="p-0 w-auto"
                        />
                      </a>
                    </PhoneIconContainer>
                    <p className="w-auto p-0 ps-2 m-0">
                      {width > 576 && "Atención a clientes"}
                      <a
                        className="m-o p-0"
                        style={{
                          textDecoration: "underline",
                          color: "#333333",
                        }}
                        href={"https://wa.me/525615077271"}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <b>+52 561 507 72 71</b>
                      </a>
                    </p>
                  </Row>
                </DetailItemA>
              </div>
              <div className="p-0 d-flex flex-row w-auto align-items-center">
                <DetailItemA>
                  <SectionDiv className="d-flex flex-row align-items-center">
                    <UilUserCircle
                      size="30"
                      className="w-auto p-0 pe-2"
                      color="#1AC7C7"
                      onClick={() =>
                        history.replace(
                          isLogged ? "/mi-perfil" : "/iniciar-sesion"
                        )
                      }
                    />
                    {isLoggedIn ? (
                      <Item
                        onClick={() => history.replace(
                          isDoctor ? "/dashboard" : "/historial-pruebas"
                        )}
                      >
                        <PerfilLink>
                          {userName}
                        </PerfilLink>
                      </Item>
                    ) : (
                      <Item
                        onClick={() =>
                          (window.location.href = "/iniciar-sesion")
                        }
                      >
                        Cuenta
                      </Item>
                    )}
                  </SectionDiv>
                </DetailItemA>
                <DetailItemA>
                  <SectionDiv className="d-flex flex-row align-items-center">
                    <UilTicket
                      size="30"
                      className="w-auto p-0 pe-2"
                      color="#1AC7C7"
                      onClick={() =>
                        history.replace(
                          isLogged ? "/activar-kit" : "/iniciar-sesion"
                        )
                      }
                    />
                    <Item
                      onClick={() =>
                        (window.location.href = isLogged
                          ? "/activar-kit"
                          : "/iniciar-sesion")
                      }
                    >
                      Activa tu kit
                    </Item>
                  </SectionDiv>
                </DetailItemA>
                <DetailItemB
                  onClick={handleOpenModalCart}
                  className="w-auto p-0"
                >
                  <ShowListStudies id="openCartButton" className="w-auto m-0">
                    <div
                      style={{ position: "relative" }}
                      className="d-flex flex-row align-items-center w-auto"
                    >
                      <UilShoppingCart
                        className="w-auto p-0"
                        size={30}
                        color={"#1AC7C7"}
                      />
                      {items.length > 0 ? (
                        <CartCount>{items.length}</CartCount>
                      ) : null}
                    </div>
                  </ShowListStudies>
                </DetailItemB>
              </div>
            </DetailMenu>
          </div>
        </div>
        {isMobile ? (
          <MobileMenu
            menu={menu}
            showSearch={showSearch}
            hideSearch={hideSearch}
            setSearching={searching}
            showSearcher={showSearcher}
            search={search}
            display={inCheckout}
          />
        ) : (
          <Desktop
            cart={cart}
            menu={menu}
            showTheMenu={handleMenu}
            showMenu={showMenu}
            showSearch={showSearch}
            hideSearch={hideSearch}
            setSearching={setSearching}
            showSearcher={showSearcher}
            search={search}
            display={inCheckout}
          />
        )}
      </Menu>
      {showSearcher && (
        <div style={{ position: "relative" }}>
          <Searcher hideSearch={hideSearch} />
        </div>
      )}
      <DesktopMenu
        menu={menu[0]}
        showMenu={showMenu}
        onHide={() => setShowMenu(false)}
      />
      <Cart
        onClose={() => {
          props.setShowCart(false);
        }}
        visible={props.showCart}
      />
    </div>
  );
};
