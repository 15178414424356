import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilAngleDown, UilAngleUp, UilMessage } from '@iconscout/react-unicons';
import axios from 'axios';
import loading from '../../assets/ecommerce/loading.gif';
const API = 'https://labopat-5792e.appspot.com/';

const StyledForDesktop = styled.div`
  position: relative;
  max-width: 548px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
`;

const Title = styled.h1`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
  margin-top: 20px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const RegularP = styled.p`
  font-family: Rounded Mplus 1c;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const PostalInput = styled(Form.Control)`
  position: unset !important;
  width: 150px;
  height: 61px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  background: #f6f6f6 !important;
  border: none;
  border-radius: 0px 12px 12px 0px !important;
  height: 50px;
  margin-right: 16px;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
  }
`;

const PostalButton = styled(Button)`
  z-index: 0 !important;
  font-weight: 800;
  width: 100px;
  height: 48px;
  background: #00ad90;
  border: none;
  font-weight: 500;
  color: #fff;
  margin-left: 15px;
  font-size: 16px;
  border-radius: 16px !important;
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
  :focus {
    background: #00ad90;
  }
`;

const branches = [
  {
    id: 4,
    idLpq: 5,
    name: 'Palmas Plaza Drive Thru',
    address:
      'Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
    mapsId: '',
    coordinates: ['19.0267687', '-98.2311346'],
  },
  {
    id: 8,
    idLpq: 14,
    name: 'Labopat Palmas Plaza planta baja',
    address:
      'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/8DMZYY7ERixC7xGR6',
    mapsId: '',
    coordinates: ['19.0267687', '-98.2311346'],
  },
  {
    id: 1,
    idLpq: 9,
    name: 'Plaza Zavaleta',
    address:
      'Cerca de Olivos 113, Santa Cruz Buenavista Norte, Sta. Cruz Buena Vista, 72150 Puebla, Pue. “Puerta Olivos” (puerta trasera de PlaZavaleta)',
    mapURL: 'https://goo.gl/maps/FjxjiZvngk6dyZxv9',
    mapsId: 'ChIJETt5bL_Az4URV1vbyxVEMfU',
    coordinates: ['19.067373', '-98.248656'],
  },
  {
    id: 3,
    idLpq: 13,
    name: 'Torres médicas',
    address:
      'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
    mapURL: 'https://goo.gl/maps/GKRKLQSnVXQPgt8o7',
    mapsId: '',
    coordinates: ['19.02051237624928', '-98.26456209191247'],
  },
  {
    id: 7,
    idLpq: 21,
    name: 'Pabellón R&M',
    address:
      'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
    mapURL: 'https://goo.gl/maps/nfcNL45qi89A5Lhw7',
    mapsId: '',
    coordinates: ['19.0522198', '-98.2362645'],
  },
  {
    id: 2,
    idLpq: 11,
    name: 'Elementa',
    address:
      'Torre Elementa. Blvd. América 308, local 3; Lomas de Angelópolis, 72228 San Andrés Cholula, Pue.',
    mapURL: 'https://goo.gl/maps/wPfV3oCtYEcmmgxVA',
    mapsId: '',
    coordinates: ['18.9976678', '-98.2818113'],
  },
  {
    id: 6,
    idLpq: 20,
    name: 'Bosques de Zavaleta',
    address:
      'Plaza Bosques De Zavaleta, Calz Zavaleta 133-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
    mapURL: 'https://goo.gl/maps/S7DapoFhy9F8zgNM9',
    mapsId: '',
    coordinates: ['19.066824', '-98.2503983'],
  },
];

const Nearest = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > p {
    margin-bottom: 4px;
  }
  > :nth-child(1) {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #3b3b3b;
  }
  > :nth-child(2) {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3b3b3b;
  }
  > :nth-child(3) {
    font-family: Rounded Mplus 1c;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    > a {
      color: #00ad90;
    }
  }
  > :nth-child(3):hover {
    text-decoration: underline;
    color: #3eb8b2;
  }
`;

const ButtonMenu = styled.button`
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: none;
  margin-bottom: 20px;
`;

const StyledPlaces = styled.div`
  .simple {
    background: none !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
`;

export const Branches = () => {
  const [postal, setPostal] = useState('');
  const [nearestPlace, setNearestPlace] = useState();
  const [showPlaces, setShowPlaces] = useState(true);
  const [checking, setChecking] = useState(false);

  const findNearestPlace = async (zipCode) => {
    setChecking(true);
    let distance = 100000000;
    try {
      let request = axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=mexico+${zipCode}&key=AIzaSyD8p1E3vVMWsjaCpWxnqHQDb-2cktQSk4I`
      );
      let { data } = await request;
      const location = data.results[0].geometry.location;
      branches.map(async (branch) => {
        let response = await axios.get(
          API +
            `distance?place1x=${branch.coordinates[0]}&place1y=${branch.coordinates[1]}&place2x=${location.lat}&place2y=${location.lng}`
        );
        const tempDistance =
          response.data.data.rows[0].elements[0].distance.value;
        console.log(
          branch.name +
            ' Distance: ' +
            response.data.data.rows[0].elements[0].distance.value
        );
        if (tempDistance < distance) {
          distance = response.data.data.rows[0].elements[0].distance.value;
          setNearestPlace(branch);
        }
      });
      setChecking(false);
    } catch (error) {
      console.log(error);
      // dispatch(updateSpinner(false));
    }
  };

  return (
    <StyledForDesktop>
      {checking && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
            width: '100%',
            position: 'absolute',
            background: 'rgba(256, 256, 256, 0.8)',
          }}
        >
          <img src={loading} alt='loading' width={75} />
        </div>
      )}
      <Title>Sucursales</Title>
      <RegularP>Encuentra la sucursal más cercana</RegularP>
      <Form.Group className='mb-3' controlId='formBasicEmail'>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='input-group'>
            <span
              className='input-group-text'
              id='basic-addon1'
              style={{
                backgroundColor: '#f6f6f6',
                border: 'none',
                borderBottomLeftRadius: '10px',
                height: '50px',
              }}
            >
              <UilMessage style={{ color: '#A6A6A6' }} />
            </span>

            <PostalInput
              type='number'
              onChange={(e) => {
                setPostal(e.target.value);
              }}
              placeholder='Ingresa tu código postal aquí'
            />
            <PostalButton
              disabled={postal.length !== 5}
              onClick={() => findNearestPlace(postal)}
            >
              Aplicar
            </PostalButton>
          </div>
        </Row>
      </Form.Group>
      {nearestPlace && (
        <Nearest>
          <p>{nearestPlace.name}</p>
          <p>{nearestPlace.address}</p>
          <p>
            <a
              href={nearestPlace.mapURL}
              target='_blank'
              rel='noopener noreferrer'
            >
              Abrir dirección en google maps
            </a>
          </p>
        </Nearest>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col xs={5}>
          <hr />
        </Col>
        <Col
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ó
        </Col>
        <Col xs={5}>
          <hr />
        </Col>
      </Row>
      <ButtonMenu variant='light' onClick={() => setShowPlaces(!showPlaces)}>
        <Row style={{ marginTop: '10px' }}>
          <Col sm={10} xs={10} style={{ paddingLeft: '5px' }}>
            <p style={{ fontSize: '16px' }}> Ver sucursales disponibles</p>
          </Col>
          <Col sm={2} xs={2}>
            {showPlaces ? (
              <UilAngleUp size='32' color='#00AD90' />
            ) : (
              <UilAngleDown size='32' color='#00AD90' />
            )}
          </Col>
        </Row>
      </ButtonMenu>
      {showPlaces ? (
        <StyledPlaces>
          {branches.map((branch) => (
            <>
              <Nearest className='simple'>
                <p>{branch.name}</p>
                <p>{branch.address}</p>
                <p>
                  <a
                    href={branch.mapURL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Abrir dirección en google maps
                  </a>
                </p>
              </Nearest>
              <hr />
            </>
          ))}
        </StyledPlaces>
      ) : null}
    </StyledForDesktop>
  );
};
